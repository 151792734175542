<template>
  <div class="row">
    <div class="row text-center">
      <div class="mt-2 text-lg font-medium text-gray-900">
        <h2 class="capitalize">{{ templateObject.templateName }}</h2>
      </div>
    </div>

    <AlertSuccess
      v-if="successMessage"
      :message="successMessage"
      @hide="successMessage = null"
    />
    <AlertError
      v-if="errorMessage"
      :message="[errorMessage]"
      @hide="errorMessage = null"
    />

    <TextEditor
      v-if="!loading"
      @saveEditorContent="setContent"
      :disable-save-button="disableSaveButton"
      :content="content"
      :can-edit="true"
      :editable="true"
      :task-status="2"
      :is-template="true"
      :can-comment="false"
    />
  </div>
</template>

<script>
import { ref, reactive } from "vue";
import Api from "@/api/Api.js";
import { useEditor, EditorContent } from "@tiptap/vue-3";
import AlertSuccess from "./AlertSuccess";
import AlertError from "./AlertError";
import TextEditor from "./TextEditor";
import { useLoading } from "vue-loading-overlay";

export default {
  components: {
    EditorContent,
    TextEditor,
    AlertSuccess,
    AlertError,
  },

  props: {
    templateObject: {
      type: Object,
      required: false,
      default: false,
    },
  },

  setup(props, context) {
    const content = ref("");
    const loading = ref(true);
    let successMessage = ref("");
    let errorMessage = ref("");
    let templateObject = reactive(props.templateObject);
    const $loading = useLoading();

    let disableSaveButton = ref(false);

    getTemplateContent(templateObject.id);

    function getTemplateContent(_templateid) {
      const loader = $loading.show({
        color: "#2581ef",
        opacity: 1,
      });

      Api()
        .get(`/templates/get-template-structure/${_templateid}`)
        .then((response) => {
          // Start designing your document template from here
          if (response.data.status === "success") {
            let templateData = JSON.parse(response.data.templateStructure);
            content.value = templateData;
            loading.value = false;
          }
        })
        .catch((error) => {
          
        })
        .finally(() => {
          loader.hide();
        });
    }

    function setContent(content) {
      disableSaveButton.value = true;

      let _templateObject = {
        templateName: templateObject.templateName,
        templateStructure: null,
        id: templateObject.id,
      };

      _templateObject.templateStructure = JSON.stringify(content);

      const loader = $loading.show({
        color: "#2581ef",
        opacity: 1,
      });

      Api()
        .patch(`/templates/update-template-structure`, _templateObject)
        .then((response) => {
          let message = response.data.message;
          let status = response.data.status;

          if (status === "success") {
            context.emit("refreshTemplates");
            successMessage.value = message + "\n";
          } else {
            errorMessage.value = message + "\n";
          }

          disableSaveButton.value = false;
        })
        .catch((error) => {
          
        })
        .finally(() => {
          loader.hide();
        });
    }

    return {
      setContent,
      templateObject,
      disableSaveButton,
      content,
      loading,
      successMessage,
      errorMessage,
    };
  },
};
</script>
