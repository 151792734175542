<template>
  <main class="relative z-10 flex-auto flex items-center justify-center text-sm text-center  py-16 px-4 sm:px-6 lg:px-8 md:mt-36">
    <div class="w-full max-w-sm">

      <AlertSuccess v-if="successMessage" :message="successMessage" @hide="successMessage=null"/>
      <AlertError v-if="generalMessage" :message="[generalMessage]" @hide="generalMessage=null"/>


      <h1 class="font-bold my-5">Use this form to enter your new password</h1>

      <div>

        <div class="relative">
          <label  for="password" class="sr-only">Password:</label>
          <input
              type="password"
              v-model="password"
              placeholder="Password"
              class="text-gray-900 ring-gray-900 ring-opacity-5 placeholder-gray-400 appearance-none bg-white rounded-md block w-full px-3 py-2 border border-transparent shadow ring-1 sm:text-sm mb-4 focus:border-blue-500 focus:ring-blue-500 focus:outline-none"
              id="password"
          />

          <p
              v-for="(errorMessage, index) in passwordMessage"
              :key="index"
              style="font-size: 0.8rem !important"
              class="text-red-500 mb-2"
          >
            {{ errorMessage }}
          </p>
        </div>
        <div class="relative mb-3">
          <label  for="password_confirmation" class="sr-only"
          >Confirm Password:</label
          >
          <input
              type="password"
              v-model="password_confirmation"
              placeholder="Confirm Password"
              class="text-gray-900 ring-gray-900 ring-opacity-5 placeholder-gray-400 appearance-none bg-white rounded-md block w-full px-3 py-2 border border-transparent shadow ring-1 sm:text-sm mb-4 focus:border-blue-500 focus:ring-blue-500 focus:outline-none"
              id="password_confirmation"
          />
        </div>
        <button
            v-bind:class="{ disabled: disableButton }"
            :disabled="disableChangePasswordButton"
            @click.prevent="resetPassword"
            class=" flex justify-center items-center  w-full py-2 px-3 border border-transparent rounded-md text-white font-medium bg-blue-500
        shadow-sm sm:text-sm mb-10 hover:bg-blue-600 focus:outline-none focus-visible:ring-2
        focus-visible:ring-gray-700 focus-visible:ring-offset-2 focus-visible:ring-offset-gray-50"
        >
          <InlineLoader :h="25" :w="25" :show="canShow"/>
          Change Password
        </button>
      </div>
    </div>
  </main>

</template>

<script>
import { ref } from "vue";
import { computed } from "vue";
import Api from "@/api/Api.js";
import { useRoute, useRouter } from "vue-router";
import AlertSuccess from "../components/AlertSuccess";
import AlertError from "../components/AlertError";
import InlineLoader from "../components/InlineLoader";

export default {
  components:{AlertSuccess,AlertError,InlineLoader},
  setup() {
    let password = ref("");
    let password_confirmation = ref("");
    let disableButton = ref(false);
    let generalMessage = ref("");
    let successMessage = ref("");
    let passwordMessage = ref(null);
    const canShow = ref(false)

    const route = useRoute();
    const router = useRouter();

    const token = route.params.token;

    const disableChangePasswordButton = computed(() => {
      if (
        password.value.trim().length > 0 &&
        password_confirmation.value.trim().length > 0
      ) {
        generalMessage.value = null;
        return false;
      }

      return true;
    });

    function resetPassword() {
      generalMessage.value = null;
      successMessage.value = null;
      disableButton.value = true;
      passwordMessage.value = [];
      let userResetPassword = {
        'password': password.value,
        'password_confirmation': password_confirmation.value,
        'token': token
      };

      canShow.value = true;

      Api()
        .post("/resetpassword", userResetPassword)

        .then((response) => {
          let status = response.data.status;
          let message = response.data.message;

          if (status === "success") {
              
              successMessage.value = "Your Password has been reset succesfully"
              password.value = "";
              password_confirmation.value = "";
              disableButton.value = false;
              
         }else{

           generalMessage.value =  message;
         }

            
         // router.push({ name: "dashboardHome" });

        })
        .catch((error) => {
          

          if (!error.message.includes("Network")) {
            if (error.response.status === 401) {
              generalMessage.value = error.response.data.message;
            } else if (error.response.status === 422) {
              passwordMessage.value = error.response.data.errors.password;
            }
          }
          disableButton.value = false;
        }).finally(()=>{
        canShow.value = false;
      });
    }

    return {
      password,
      password_confirmation,
      disableButton,
      disableChangePasswordButton,
      passwordMessage,
      successMessage,
      generalMessage,
      resetPassword,
      canShow
    };
  },
};
</script>

<style>
</style>