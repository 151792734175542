<template>
  <div>
    <div class="px-4 sm:px-6 md:px-8 mb-10">
      <button
          class="capitalize flex text-blue-500 hover:underline float-left -ml-4 mr-10 items-center"
          @click="$router.back()"
      >
        <ArrowLeftIcon class="mr-2 h-6 w-6" />
        back
      </button>
      <h1 class="text-2xl font-semibold text-gray-700">Your Report</h1>
    </div>
  </div>

  <div class="mt-12 flex justify-center">
    <div class="flex flex-col w-full mr-2">
      <h3 class="text-lg leading-6 font-medium text-gray-900  text-center">
        Team Members for <span class="text-blue-500">{{$route.params.name}}</span>
      </h3>
      <div class="my-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="shadow overflow-hidden border border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead>
              <tr>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Member Name
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">
                  Tasks Completed
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">
                  Tasks Not Completed
                </th>

              </tr>
              </thead>
              <tbody class="bg-white">
              <tr v-for="member in yourTeamMembers" :key="member.memberId">
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500  ">
                  <router-link v-if="member.assignedToMemberId" class="text-blue-500 hover:text-blue-600 hover:underline" :to="{ name: 'team-member-detail-report',
                   params: {
                    id:member.assignedToMemberId,
                    project:$route.params.project,
                    team:$route.params.id,
                    name:member.name
                  }
                  }">
                    {{ member.name }}
                  </router-link>

                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-center">
                  {{ member.data.taskCompleted }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500  text-center">
                  {{ member.data.taskNotCompleted }}
                </td>


              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>


</template>

<script>
import Api from "../api/Api";
import {useRoute} from "vue-router";
import {inject,ref} from "vue";
import {ArrowLeftIcon} from "@heroicons/vue/outline";
import {useLoading} from "vue-loading-overlay";



export default {
  name: "MemberReport",
  components:{ArrowLeftIcon},
  setup() {
    const user  =  inject('user');
    const route = useRoute();
    const yourTeamMembers= ref();
    const $loading = useLoading();
    const getReportmemberSummary = () => {
      const loader = $loading.show({
        color: "#2581ef",
        opacity: 1,
      });
      Api()
          .get(`/reports/show-team-members-in-project-summary/${user.id}/${route.params.project}/${route.params.id}`)
          .then((response) => {
            if (response.data.status === "success") {
              yourTeamMembers.value = response.data.report
            }
          })
          .catch((error) => {})
          .finally(() => {
            loader.hide();
          });
    }
    getReportmemberSummary();
    return {
      yourTeamMembers
    }
  }
}
</script>

<style scoped>

</style>