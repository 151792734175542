import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import About from "../views/About.vue";
import Login from "../views/Login.vue";
import VerifyEmail from "../views/VerifyEmail.vue";
import Register from "../views/Register.vue";
import Dashboard from "../views/Dashboard.vue";
import ForgotPassword from "../views/ForgotPassword.vue";
import ResetPassword from "../views/ResetPassword.vue";
import ProjectsForTasksAssignedToMe from "../components/ProjectsForTasksAssignedToMe.vue";
import Teams from "../components/Teams.vue";
import ViewProfile from "../components/ViewProfile.vue";
import MyWorkFlows from "../components/MyWorkFlows.vue";
import ViewProjects from "../components/ViewProjects.vue";
import MyTemplates from "../components/MyTemplates.vue";
import Calender from "../components/Calender";
import Policy from "../views/Policy";
import Terms from "../views/Terms";
import Faq from "../views/Faq";
import Pricing from "../views/Pricing";
import MyReport from "../components/MyReport";
import ProjectReport from "../components/ProjectReport";
import MemberReport from "../components/MemberReport";
import MemberDetails from "../components/MemberDetails";
import Billing from "../components/Billing";
import Payment from "../components/Payment";
import BeginnersTutorial from "../views/tutorials/BeginnersTutorial.vue";
import HowToAcceptATeamInvite from "../views/tutorials/HowToAcceptATeamInvite.vue";
import HowToAssignATask from "../views/tutorials/HowToAssignATask.vue";
import HowToCreateAProject from "../views/tutorials/HowToCreateAProject.vue";
import HowToCreateATeam from "../views/tutorials/HowToCreateATeam.vue";
import HowToCreateAWorkflow from "../views/tutorials/HowToCreateAWorkflow.vue";
import HowToGetStartedOnATask from "../views/tutorials/HowToGetStartedOnATask.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { guestOnly: true },
  },
  {
    path: "/privacy-policy",
    name: "policy",
    component: Policy,
  },
  {
    path: "/faq",
    name: "faq",
    component: Faq,
  },
  {
    path: "/terms-of-service",
    name: "terms",
    component: Terms,
  },
  {
    path: "/pricing",
    name: "pricing",
    component: Pricing,
  },

  {
    path: "/payments",
    name: "payments",
    component: Payment,
  },
  {
    path: "/register/:invitationID?",
    name: "register",
    component: Register,
    meta: { guestOnly: true },
  },
  {
    path: "/verify/:verification_code",
    name: "VerifyEmail",
    component: VerifyEmail,
    meta: { guestOnly: true },
  },
  {
    path: "/forgotpassword",
    name: "ForgotPassword",
    component: ForgotPassword,
    meta: { guestOnly: true },
  },
  {
    path: "/resetpassword/:token",
    name: "ResetPassword",
    component: ResetPassword,
    meta: { guestOnly: true },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: { authOnly: true },
    children: [
      {
        name: "projectsForTasksAssignedToMe",
        path: "tasks",
        component: ProjectsForTasksAssignedToMe,
      },
      {
        name: "dashboardHome",
        path: "home",
        component: ViewProjects,
      },
      {
        name: "teams",
        path: "teams",
        component: Teams,
      },
      {
        name: "myWorkFlows",
        path: "work-flows",
        component: MyWorkFlows,
      },
      {
        name: "billing",
        path: "billing",
        component: Billing,
      },
      {
        name: "myTemplates",
        path: "templates",
        component: MyTemplates,
      },
      {
        name: "viewProfile",
        path: "profile",
        component: ViewProfile,
      },

      {
        name: "calender",
        path: "calender",
        component: Calender,
      },
      {
        name: "report",
        path: "report",
        component: MyReport,
      },
      {
        name: "project-report",
        path: "/:project/teams/:id",
        component: ProjectReport,
      },
      {
        name: "team-member-report",
        path: "report/:name/members/:project/:id",
        component: MemberReport,
      },

      {
        name: "team-member-detail-report",
        path: "report/:name/details/:project/:team/:id",
        component: MemberDetails,
      },
    ],
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/beginners-tutorial",
    name: "BeginnersTutorial",
    component: BeginnersTutorial,
  },
  {
    path: "/how-to-create-a-workflow",
    name: "HowToCreateAWorkflow",
    component: HowToCreateAWorkflow,
  },
  {
    path: "/how-to-create-a-project",
    name: "HowToCreateAProject",
    component: HowToCreateAProject,
  },
  {
    path: "/how-to-create-a-team",
    name: "HowToCreateATeam",
    component: HowToCreateATeam,
  },
  {
    path: "/how-to-accept-a-team-invite",
    name: "HowToAcceptATeamInvite",
    component: HowToAcceptATeamInvite,
  },
  {
    path: "/how-to-assign-a-task",
    name: "HowToAssignATask",
    component: HowToAssignATask,
  },
  {
    path: "/how-to-get-started-on-a-task",
    name: "HowToGetStartedOnATask",
    component: HowToGetStartedOnATask,
  },

  { path: "/:catchAll(.*)", redirect: "/" },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

function isLoggedIn() {
  return localStorage.getItem("token");
}

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.authOnly)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!isLoggedIn()) {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.guestOnly)) {
    // this route requires guestonly, check if logged in
    // if not, redirect to dashboard page.
    if (isLoggedIn()) {
      next({
        path: "/dashboard",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});

export default router;
