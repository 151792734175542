<template>
  <div>
    <div class="px-4 sm:px-6 md:px-8 mb-10">
      <h1 class="text-2xl font-semibold text-gray-700">Your Teams</h1>
    </div>
    <div class="block">
      <nav
        class="relative z-0 rounded-lg shadow flex divide-x divide-gray-200"
        aria-label="Tabs"
      >
        <a
          v-for="(tab, tabIdx) in tabs"
          :key="tab.componentTitle"
          @click="currentTab = tab.componentName"
          href="#"
          :class="[
            currentTab === tab.componentName
              ? 'text-gray-900'
              : 'text-gray-500 hover:text-gray-700',
            tabIdx === 0 ? 'rounded-l-lg' : '',
            tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '',
            'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10',
          ]"
          :aria-current="tab.componentTitle ? 'page' : undefined"
        >
          <span>{{ tab.componentTitle }}</span>
          <span
            aria-hidden="true"
            :class="[
              currentTab === tab.componentName
                ? 'bg-indigo-500'
                : 'bg-transparent',
              'absolute inset-x-0 bottom-0 h-0.5',
            ]"
          />
        </a>
      </nav>
    </div>
  </div>

  <div class="container mt-20">
    <div class="tab-content p-3">
      <component v-bind:is="currentTabComponent" @goToInvite="goToInvite" class="tab"> </component>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { ref,inject } from "vue";
import GuideModal from "./GuideModal";
import { useRoute } from "vue-router";

import TeamsYouCreated from "@/components/TeamsYouCreated.vue";
import TeamsYouHaveBeenAddedTo from "@/components/TeamsYouHaveBeenAddedTo.vue";

const tabsAlt = [
  { name: "Teams You Created", href: "#", current: true },
  { name: "Teams You Were Added To ", href: "#", current: false },
];

export default {
  components: {
    TeamsYouCreated,
    TeamsYouHaveBeenAddedTo,
    GuideModal
  },

  setup() {
    let currentTab = ref("TeamsYouCreated");
    const showGuide = ref(false)
    const guideStage =  inject('currentGuideStage');
    const canShowTeamsGuide = inject('canShowTeamsGuide');

    const route = useRoute(); 

    if(route.params.invite){

      currentTab.value = 'TeamsYouHaveBeenAddedTo';

    }else{

      if(canShowTeamsGuide.value == null){

        canShowTeamsGuide.value = 1;
        guideStage.value   = 6;
        localStorage.setItem('can-show-teams-guide',0);

      }else if(canShowTeamsGuide.value == 1){

        guideStage.value   = 6;
        localStorage.setItem('can-show-teams-guide',0);

      }

    }
    // create an object array of our components that we want in our tabs
    // include componentName && title of component you want rendered
    let tabs = ref([
      { componentName: "TeamsYouCreated", componentTitle: "Teams You Created" },
      {
        componentName: "TeamsYouHaveBeenAddedTo",
        componentTitle: "Teams You Have Been Added To",
      },
    ]);

    const currentTabComponent = computed(() => {
      return currentTab.value;
    });

    const goToInvite = () => {
      
      currentTab.value =  "TeamsYouHaveBeenAddedTo";

    }

    return {
      currentTab,
      tabs,
      currentTabComponent,
      tabsAlt,
      showGuide,
      guideStage,
      canShowTeamsGuide,
      goToInvite


    };
  },
};
</script>

<style></style>
