<template>
   <div class="py-16 bg-white overflow-hidden">
    <div class="px-4 sm:px-6 lg:px-8">
      <div class="text-lg max-w-prose mx-auto">
        <h1>
          <span class="block text-2xl text-center text-indigo-600 font-semibold tracking-wide uppercase">How to create a Team</span>
          <span class="mt-2 block text-xs text-center tracking-tight text-gray-900">A team is group of people that you will need to complete a task e.g. legal team, content team etc</span>
        </h1>
      </div>
      <div class="mt-6 w-1/2 prose-indigo prose-lg text-black mx-auto">

           <div class="text-base my-6">
               <p class="py-3"><span class="font-medium text-lg">1.</span> Click on <span class="italic font-bold">Teams</span> on the left navigation menu</p>
                <!-- <figure class="border rounded-lg border-1">
                    <img class="w-full rounded-lg" src="/images/4_create_a_workflow.png" alt="" width="1310" height="873" />
                </figure> -->
           </div>
           <div class="text-base my-6">
               <p class="py-3"><span class="font-medium text-lg">2.</span> Enter a Team Name eg. <span class="italic text-blue-500"> team dc </span>, and click 
               <button disabled class="ml-1 flex-shrink-0 px-4 py-2 border border-transparent text-xs font-normal rounded-md shadow-sm text-white
                bg-blue-600"> Add New Team</button>
                </p>
                 <figure class="border border-1 rounded-lg">
                      <img class="w-full rounded-lg" src="/images/1_add_team.png" alt="" width="1310" height="873" />
                      <!-- <figcaption>Sagittis scelerisque nulla cursus in enim consectetur quam.</figcaption> -->
                  </figure>
           </div>

           <div class="text-base my-6">
               <p class="py-3"><span class="font-medium text-lg">3.</span> Click on newly created team card or <span class="italic">on the team name</span></p>
           </div>

           <div class="text-base my-6">
               <p class="py-3"><span class="font-medium text-lg">4.</span> Enter team member email address eg. <span class="italic text-blue-500"> youremail@email.com </span>, and click
               <button disabled class="ml-1 flex-shrink-0 px-2 py-1 border border-transparent text-xs font-normal rounded-md shadow-sm text-white
                bg-blue-600"> Add Team Member</button></p>
                 <p class="text-xs italic font-normal pb-3">For this tutorial, you can use your own email address for demonstration purposes</p>
                  <figure class="border border-1 rounded-lg">
                    <img class="w-full rounded-lg" src="/images/2_add_invite_team_member.png" alt="" width="1310" height="873" />
                </figure>
           </div>
        <p> You will have to wait for the invited team member to accept your invite before you can start assigning them tasks</p>
        <br/>
        <p>After they accept your invite, you can start using this team by assigning tasks to its team members. Find out how to <a href="/how-to-accept-a-team-invite"
          target="_blank" class="text-blue-500 underline">accept an invite </a> or  <a href="/how-to-assign-a-task"
          target="_blank" class="text-blue-500 underline"> assign a content task </a>

        </p>
      </div>
    </div>
   </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
