<template>
  <div>
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog
        as="div"
        class="fixed inset-0 flex z-40 md:hidden"
        @close="sidebarOpen = false"
      >
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </TransitionChild>
        <TransitionChild
          as="template"
          enter="transition ease-in-out duration-300 transform"
          enter-from="-translate-x-full"
          enter-to="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leave-from="translate-x-0"
          leave-to="-translate-x-full"
        >
          <div
            class="
              relative
              flex-1 flex flex-col
              max-w-xs
              w-full
              pt-5
              pb-4
              bg-white
            "
          >
            <TransitionChild
              as="template"
              enter="ease-in-out duration-300"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="ease-in-out duration-300"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <div class="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  type="button"
                  class="
                    ml-1
                    flex
                    items-center
                    justify-center
                    h-10
                    w-10
                    rounded-full
                    focus:outline-none
                    focus:ring-2
                    focus:ring-inset
                    focus:ring-white
                  "
                  @click="sidebarOpen = false"
                >
                  <span class="sr-only">Close sidebar</span>
                  <XIcon class="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </TransitionChild>
            <div class="flex-shrink-0 flex items-center px-4">
              <h1
                class="
                  toggleColour
                  no-underline
                  hover:no-underline
                  font-bold
                  text-xl
                  lg:text-2xl
                  text-center
                "
              >
                <div class="flex">
                  <img
                    src="/images/logo.png"
                    alt="SliceLoop"
                    style="width: 200px"
                  />
                  <!-- <sup
                    class="text-blue-500 text-xs uppercase"
                    style="position: absolute; top: 30px; right: 50px"
                    >Beta</sup
                  > -->
                </div>
              </h1>
            </div>
            <div class="mt-5 flex-1 h-0 overflow-y-auto">
              <nav class="px-2 space-y-1">
                <router-link
                  v-for="item in navigation"
                  :key="item.name"
                  :to="item.href"
                  :class="[
                    item.current
                      ? 'bg-gray-100 text-gray-900'
                      : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                    'group flex items-center px-2 py-2 text-sm font-medium rounded-md',
                  ]"
                  exact-active-class="active"
                  @click="sidebarOpen = false"
                >
                  <span data-feather="user"></span>
                  <component
                    :is="item.icon"
                    :class="[
                      item.current
                        ? 'text-gray-500'
                        : 'text-gray-400 group-hover:text-gray-500',
                      'mr-3 flex-shrink-0 h-6 w-6',
                    ]"
                    aria-hidden="true"
                  />
                  {{ item.name }}
                </router-link>
              </nav>
               <nav
                  class="space-y-1 px-2 pb-10 border-t pt-5 capitalize"
                  aria-label="Sidebar">

                   <button
                    @click="
                      showFeedBackModal = true;
                      sidebarOpen = false;
                    "
                    :class="[
                      'text-gray-600 hover:bg-blue-500 hover:text-white',
                      'flex items-center px-3 py-2 text-sm font-medium rounded-md cursor-pointer w-full',
                    ]"
                  >
                    <span class="truncate"> FeedBack Survey </span>
                  </button>

                  <router-link
                      :to="{name:'billing'}"
                      :class=" [ 'text-gray-600 hover:bg-blue-500 hover:text-white',
                      'flex items-center px-3 py-2 text-sm font-medium rounded-md cursor-pointer w-full',]"
                      @click="sidebarOpen = false"
                  >
                    <span data-feather="user"></span>
                    Billing
                  </router-link>

                  <button
                    @click="
                      showContactForm = true;
                      subject = 'Contact us for  support';
                      sidebarOpen = false;
                    "
                    :class="[
                      'text-gray-600 hover:bg-blue-500 hover:text-white',
                      'flex items-center px-3 py-2 text-sm font-medium rounded-md cursor-pointer w-full',
                    ]"
                  >
                    <span class="truncate"> Support </span>
                  </button>

                  <button
                    @click="showContactForm = true; subject = 'Request a Feature'; sidebarOpen = false"
                    :class="[
                      'text-gray-600 hover:bg-blue-500 hover:text-white',
                      'flex items-center px-3 py-2 text-sm font-medium rounded-md cursor-pointer w-full',
                    ]"
                  >
                    <span class="truncate"> Request a Feature </span>
                  </button>
                </nav>
            </div>
          </div>
        </TransitionChild>
        <div class="flex-shrink-0 w-14" aria-hidden="true">
          <!-- Dummy element to force sidebar to shrink to fit close icon -->
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div class="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div
        class="
          flex flex-col flex-grow
          border-r border-gray-200
          pt-5
          bg-white
          overflow-y-auto
        "
      >
        <div class="flex items-center flex-shrink-0 px-4">
          <h1
            class="
              toggleColour
              no-underline
              hover:no-underline
              font-bold
              text-xl
              lg:text-2xl
              text-center
            "
          >
            <div class="flex">
              <img
                src="/images/logo.png"
                alt="SliceLoop"
                style="width: 200px"
              />
              <!-- <sup
                class="text-blue-500 text-xs uppercase"
                style="position: absolute; top: 30px; right: 50px"
                >Beta</sup
              > -->
            </div>
          </h1>
        </div>
        <div class="mt-5 flex-grow flex flex-col justify-between">
          <nav class="px-2 pb-4 space-y-1 pt-5">
            <router-link
              v-for="item in navigation"
              :key="item.name"
              :to="item.href"
              :id="item.id"
              :class="[
                item.current
                  ? 'bg-gray-100 text-gray-900'
                  : 'text-gray-600 hover:bg-blue-500 hover:text-white',
                'group flex items-center px-2 py-2 text-sm font-medium rounded-md',
              ]"
              exact-active-class="active"
            >
              <span data-feather="user"></span>
              <component
                :is="item.icon"
                :class="[
                  item.current
                    ? 'text-gray-500'
                    : 'text-gray-400 group-hover:text-white',
                  'mr-3 flex-shrink-0 h-6 w-6',
                ]"
                aria-hidden="true"
              />
              {{ item.name }}
            </router-link>
          </nav>

          <nav
            class="space-y-1 px-2 pb-10 border-t pt-5 capitalize"
            aria-label="Sidebar"
          >
            <button
             id="nav-step-10"
              @click="
                showFeedBackModal = true;
              "
              :class="[
                'text-gray-600 hover:bg-blue-500 hover:text-white',
                'flex items-center px-3 py-2 text-sm font-medium rounded-md cursor-pointer w-full',
              ]"
            >
              <span class="truncate"> FeedBack Survey </span>
            </button>

            <router-link
                id="nav-step-7"
                :to="{name:'billing'}"
                :class=" [ 'text-gray-600 hover:bg-blue-500 hover:text-white',
                'flex items-center px-3 py-2 text-sm font-medium rounded-md cursor-pointer w-full',]"
            >
              <span data-feather="user"></span>
              Billing
            </router-link>

            <button
             id="nav-step-8"
              @click="
                showContactForm = true;
                subject = 'Contact us for  support';
              "
              :class="[
                'text-gray-600 hover:bg-blue-500 hover:text-white',
                'flex items-center px-3 py-2 text-sm font-medium rounded-md cursor-pointer w-full',
              ]"
            >
              <span class="truncate"> Support </span>
            </button>

            <button
              id="nav-step-9"
              @click="
                showContactForm = true;
                subject = 'Request a Feature';
              "
              :class="[
                'text-gray-600 hover:bg-blue-500 hover:text-white',
                'flex items-center px-3 py-2 text-sm font-medium rounded-md cursor-pointer w-full',
              ]"
            >
              <span class="truncate"> Request a Feature </span>
            </button>
          </nav>
        </div>
      </div>
    </div>
    <div class="md:pl-64 flex flex-col flex-1">
      <div class="sticky top-0 z-10 py-4 flex-shrink-0 flex h-16 bg-white shadow">
        <button
          type="button"
          class="
            px-4
            border-r border-gray-200
            text-gray-500
            focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500
            md:hidden
          "
          @click="sidebarOpen = true"
        >
          <span class="sr-only">Open sidebar</span>
          <MenuAlt2Icon class="h-6 w-6" aria-hidden="true" />
        </button>
        <div class="flex-1 px-4 flex items-start">

          <div class="flex sm:mt-1.5">
            <button @click="showGuideAgain" class="flex flex-row justify-center items-center  hover:text-blue-500">
              <span class="mr-1 hidden sm:block text-sm font-medium">
                Help Guide

              </span>
              <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class=" w-6 h-6 -mb-7 sm:-mb-0 bi bi-question-circle" viewBox="0 0 20 20">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
              </svg>
              
            </button>
          </div>

          <div class="ml-2 flex items-center justify-center sm:mt-2">
            <!-- Profile dropdown -->
            <Menu as="div" class="sm:ml-2 relative">
              <div>
                <MenuButton
                  class="
                    max-w-xs
                    flex
                    items-center
                    text-sm
                    hover:outline-none
                    hover:text-blue-500
                    hover:ring-blue-500
                  "
                >
                  <span class="sr-only">Open user menu</span>
                  <span class="hidden sm:flex justify-center items-center text-sm font-medium">
                    Help Docs
                  </span>
                  <svg xmlns="http://www.w3.org/2000/svg"  fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 sm:hidden">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                  </svg>

                </MenuButton>
              </div>
              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="
                    origin-top-left
                    absolute
                    left-0
                    mt-2
                    w-56
                    rounded-md
                    shadow-lg
                    py-1
                    bg-white
                    ring-1 ring-black ring-opacity-5
                    focus:outline-none
                  "
                >
                  <MenuItem v-slot="{ active }">
                  <router-link
                      to="/beginners-tutorial"
                      target="_blank"
                      :class="[
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm text-gray-700',
                      ]">
                      Beginners Tutorial
                      </router-link>
                    
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                  <router-link
                      to="/how-to-create-a-workflow"
                      target="_blank"
                      :class="[
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm text-gray-700',
                      ]">
                      How to create a workflow 
                  </router-link>
                  </MenuItem>

                  <MenuItem v-slot="{ active }">
                  <router-link
                      to="/how-to-create-a-project"
                      target="_blank"
                      :class="[
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm text-gray-700',
                      ]">
                      How to create a project
                  </router-link>
                  </MenuItem>
                   <MenuItem v-slot="{ active }">
                    <router-link
                        to="/how-to-create-a-team"
                        target="_blank"
                        :class="[
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm text-gray-700',
                      ]"
                    >How to create a Team
                    </router-link>
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                    <router-link
                        to="/how-to-accept-a-team-invite"
                        target="_blank"
                        :class="[
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm text-gray-700',
                      ]">
                    How to accept a team invite
                    </router-link>
                  </MenuItem>

                  <MenuItem v-slot="{ active }">
                     <router-link
                        to="/how-to-assign-a-task"
                        target="_blank"
                        :class="[
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm text-gray-700',
                      ]">
                         How to assign a task
                     </router-link>
                  </MenuItem>
                  

                  <MenuItem v-slot="{ active }">
                     <router-link
                        to="/how-to-get-started-on-a-task"
                        target="_blank"
                      :class="[
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm text-gray-700',
                      ]">
                      How to get started on a Task 
                      </router-link>
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
          </div>

           <div class="ml-2 flex items-center justify-center sm:mt-2">
            <Menu as="div" class="sm:ml-2 relative">
              <div>
                <MenuButton
                  class="
                    max-w-xs
                    flex
                    items-center
                    text-sm
                    hover:outline-none
                    hover:text-blue-500
                    hover:ring-blue-500
                  "
                >
                  <span class="sr-only">Open user menu</span>
                  <span class="hidden sm:flex justify-center items-center text-sm font-medium">
                    Self Help 
                  </span>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 sm:hidden">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15.042 21.672L13.684 16.6m0 0l-2.51 2.225.569-9.47 5.227 7.917-3.286-.672zm-7.518-.267A8.25 8.25 0 1120.25 10.5M8.288 14.212A5.25 5.25 0 1117.25 10.5" />
                  </svg>

                </MenuButton>
              </div>
              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="
                    origin-top-left
                    absolute
                    left-0
                    mt-2
                    w-48
                    rounded-md
                    shadow-lg
                    py-1
                    bg-white
                    ring-1 ring-black ring-opacity-5
                    focus:outline-none
                  "
                >
                  <MenuItem v-slot="{ active }">
                    <a
                      href="#"
                      :class="[
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm text-gray-700',
                      ]"
                      @click="startNavigationTutorial()"
                      >Take Me Around</a>
                  </MenuItem>

                  <MenuItem v-slot="{ active }">
                    <a
                      href="#"
                      :class="[
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm text-gray-700',
                      ]"
                      @click="startMyProjectsTutorial()"
                      >My Projects Quick Tips</a>
                  </MenuItem>

                  <MenuItem v-slot="{ active }">
                    <a
                        href ="#"
                        :class="[
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm text-gray-700',
                      ]"
                      @click="startTaskManagementTutorial()"
                    >Task Management</a>
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
          </div>

          <div class="ml-auto flex items-center">
            <!-- Profile dropdown -->
            <Menu as="div" class="ml-3 relative">
              <div>
                <MenuButton
                  class="
                    max-w-xs
                    bg-white
                    flex
                    items-center
                    text-sm
                    rounded-full
                    hover:outline-none
                    focus:ring-2
                    hover:ring-offset-2 hover:ring-blue-500
                  "
                >
                  <span class="sr-only">Open user menu</span>
                  <span
                    class="
                      w-10
                      h-10
                      rounded-full
                      border
                      flex
                      justify-center
                      items-center
                      font-bold
                      text-xl
                      bg-blue-500
                      text-white
                      hover:bg-blue-600
                    "
                  >
                    {{ initials }}
                  </span>
                </MenuButton>
              </div>
              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="
                    origin-top-right
                    absolute
                    right-0
                    mt-2
                    w-48
                    rounded-md
                    shadow-lg
                    py-1
                    bg-white
                    ring-1 ring-black ring-opacity-5
                    focus:outline-none
                  "
                >
                  <MenuItem v-slot="{ active }">
                    <router-link
                      to="/dashboard/profile"
                      :class="[
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm text-gray-700',
                      ]"
                      >Your Profile</router-link
                    >
                  </MenuItem>

                  <MenuItem v-slot="{ active }">
                    <router-link
                        :to="{name:'billing'}"
                        :class="[
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm text-gray-700',
                      ]"
                    >Billing</router-link
                    >
                  </MenuItem>

                  <MenuItem v-slot="{ active }">
                    <a
                      href="#"
                      @click="signout()"
                      :class="[
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm text-red-500 ',
                      ]"
                      >Sign Out</a
                    >
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
          </div>
        </div>
      </div>

      <main class="flex-1">
        <div class="py-6">
          <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <!-- Replace with your content -->
            <div class="py-4">
              <AlertSuccess
                  v-if="successMessage"
                  :message="successMessage"
                  @hide="successMessage = null"
              />
              <AlertError
                  v-if="errorMessage"
                  :message="[errorMessage]"
                  @hide="errorMessage = null"
              />
              <!-- End of Component Head -->
              <!-- we insert our components here -->
              <router-view />
              <!-- end of component -->
            </div>
            <!-- /End replace -->
          </div>
        </div>
      </main>
    </div>
  </div>

  <Modal :open="showContactForm" @dismiss="showContactForm = false">
    <template v-slot:header>
      <h5 class="modal-title text-danger capitalize">{{ subject }}</h5>
      <br />
      <AlertSuccess
        v-if="successMessage"
        message="You message has been received our team will contact you shortly "
        @hide="successMessage = null"
      />
    </template>

    <template v-slot:body>
      <form id="support-form" action="post" @submit.prevent="submitMessage">
        <input type="hidden" placeholder="Name" v-model="name" required />

        <input type="hidden" placeholder="Email" v-model="email" required />

        <div class="relative">
          <label for="message" class="sr-only">Message:</label>
          <textarea
            type="text"
            placeholder="Your message"
            v-model="message"
            required
            class="
              text-gray-900
              ring-gray-900 ring-opacity-5
              placeholder-gray-400
              appearance-none
              bg-white
              rounded-md
              block
              w-full
              px-3
              py-2
              border border-transparent
              shadow
              ring-1
              sm:text-sm
              mb-4
              focus:border-blue-500 focus:ring-blue-500 focus:outline-none
            "
            cols="30"
            rows="10"
            id="message"
          ></textarea>
          <p
            v-for="(errorMessage, index) in textMessage"
            :key="index"
            style="font-size: 0.8rem !important"
            class="text-red-500 mb-2"
          >
            {{ errorMessage }}
          </p>
        </div>

        <button
          type="submit"
          :disabled="disableContactButton"
          v-bind:class="{ disabled: disableButton }"
          class="
            flex
            justify-center
            items-center
            w-full
            py-2
            px-3
            border border-transparent
            rounded-md
            text-white
            font-medium
            bg-blue-500
            shadow-sm
            sm:text-sm
            mb-10
            disabled:bg-blue-200
            hover:bg-blue-600
            focus:outline-none
            focus-visible:ring-2
            focus-visible:ring-gray-700
            focus-visible:ring-offset-2
            focus-visible:ring-offset-gray-50
          "
        >
          <InlineLoader :h="25" :w="25" :show="canShow" />
          Submit Message
        </button>
      </form>
    </template>
  </Modal>
  <GuideModal v-if="!canShowGuide"  :is-open="showGuide" @close="showGuide = false;" />
 <FeedBackModal :show="showFeedBackModal">
  <template v-slot:header>
    <div v-if="current_card == 1">
       Do you have 5 minutes?
    </div>
    <div v-else>
      {{ current_card + '/' + totalCards }}
    </div>
            
  </template>
   <template v-slot:body>
    <div class="w-full overflow-hidden">
        <div class="flex mx-3" ref="inner">
          <!-- <div class=" w-screen flex flex-none h-96 bg-green-300 text-white rounded p-5" v-for="card in cards" :key="card"> -->
          <Transition  
                enter-from-class="opacity-0"
                leave-to-class="opacity-0"
                enter-active-class="transition"
                leave-active-class=" transition" >
                <div v-show="current_card == 1" class=" w-full flex flex-none h-96 text-white rounded p-5">
                  <div class="flex flex-col w-full items-center justify-center">
                      <p class="text-lg pb-5 break-words text-gray-500">We're always trying our best to improve <span class="text-blue-500">sliceloop</span> and your <span class="text-blue-500"> feedback </span> can help us do that. </p>
                      <p class="text-lg break-words text-gray-500">Please help us and take this <span class="text-blue-500">5 minute survey</span> and tell us what you think about sliceloop</p>
                  </div>

                </div>
          </Transition>
          
          <Transition
             enter-from-class="opacity-0"
             leave-to-class="opacity-0"
             enter-active-class="transition"
             leave-active-class="transition" >
                <div v-show="current_card == 2" class=" w-full flex flex-col flex-none h-fit text-white rounded p-5">
                   <div class="mt-2">
                    <label for="question0" class="block text-sm text-left font-normal text-gray-700">1. {{ surveyAnswers[0].Question }}</label>
                    <div class="mt-1">
                      <textarea id="question0" v-model="surveyAnswers[0].Answer" name="question0" rows="3" class="mt-1 p-4 text-black block w-full rounded-md border shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"></textarea>
                    </div>
                  </div>
                   <div class="mt-3">
                    <label for="question1" class="block text-sm text-left font-normal text-gray-700">2. {{ surveyAnswers[1].Question }} </label>
                    <div class="mt-1">
                      <textarea id="question1" v-model="surveyAnswers[1].Answer" name="question1" rows="3" class="mt-1 p-4 text-black block w-full rounded-md border shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"></textarea>
                    </div>
                  </div>
                  
                  <div class="flex flex-col items-start text-left mt-3">
                      <p class=" text-sm font-normal text-gray-900 dark:text-gray-300">3. {{ surveyAnswers[2].Question }} </p>
                      <div class="sm:flex flex-col ml-4 sm:space-y-4 pt-3">
                        <div class="flex items-center">
                            <input id="question2-1" v-model="surveyAnswers[2].Answer" type="radio" value="I need someone to show me how to use the app." name="question2" class="w-3 h-3 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                            <label for="question2-1"  class="ml-2 text-sm font-normal text-gray-900 dark:text-gray-300">I need someone to show me how to use the app.</label>
                        </div>
                        <div class="flex items-center">
                            <input id="question2-2" v-model="surveyAnswers[2].Answer" type="radio" value="I need someone to show me how to use certain features." name="question2" class="w-3 h-3 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                            <label for="question2-2" class="ml-2 text-sm font-normal text-gray-900 dark:text-gray-300">I need someone to show me how to use certain features.</label>
                        </div>
                        <div class="flex items-center">
                            <input id="question2-3" v-model="surveyAnswers[2].Answer" type="radio" value="It will take me a few uses to master the app." name="question2" class="w-3 h-3 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                            <label for="question2-3" class="ml-2 text-sm font-normal text-gray-900 dark:text-gray-300">It will take me a few uses to master the app.</label>
                        </div>
                        <div class="flex items-center">
                            <input id="question2-4" v-model="surveyAnswers[2].Answer" type="radio" value="Next time I use it, I'll know exactly what to do.." name="question2" class="w-3 h-3 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                            <label for="question2-4" class="ml-2 text-sm font-normal text-gray-900 dark:text-gray-300">Next time I use it, I'll know exactly what to do.</label>
                        </div>
                        <div class="flex items-center">
                            <input id="question2-5" v-model="surveyAnswers[2].Answer" type="radio" value="It felt like I've been using it all my life." name="question2" class="w-3 h-3 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                            <label for="question2-5" class="ml-2 text-sm font-normal text-gray-900 dark:text-gray-300">It felt like I've been using it all my life.</label>
                        </div>
                      </div>
                  </div>
                </div>
          </Transition>

           <Transition
             enter-from-class="opacity-0"
             leave-to-class="opacity-0"
             enter-active-class="transition"
             leave-active-class="transition" >
                <div v-show="current_card == 3" class=" w-full flex flex-col flex-none h-fit text-white rounded p-5">
                   <div class="flex flex-col items-start text-left">
                      <p class=" text-sm font-normal text-gray-900 dark:text-gray-300">4. {{ surveyAnswers[3].Question }} </p>
                      <div class="sm:flex flex-col ml-4 sm:space-y-2 pt-3">
                        <div class="flex items-center">
                            <input id="question3-1" v-model="surveyAnswers[3].Answer" type="radio" value="They helped" name="question3" class="w-3 h-3 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                            <label for="question3-1"  class="ml-2 text-sm font-normal text-gray-900 dark:text-gray-300">They helped</label>
                        </div>
                        <div class="flex items-center">
                            <input id="question3-2" v-model="surveyAnswers[3].Answer" type="radio" value="I did not find them useful." name="question3" class="w-3 h-3 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                            <label for="question3-2" class="ml-2 text-sm font-normal text-gray-900 dark:text-gray-300">I did not find them useful.</label>
                        </div>
                        <div class="flex items-center">
                            <input id="question3-3" v-model="surveyAnswers[3].Answer" type="radio" value="I did not see any guides." name="question3" class="w-3 h-3 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                            <label for="question3-3" class="ml-2 text-sm font-normal text-gray-900 dark:text-gray-300">I did not see any guides.</label>
                        </div>
                        <div class="flex items-center">
                            <input id="question3-4" v-model="surveyAnswers[3].Answer" type="radio" value="I still had to figure stuff out on my own." name="question3" class="w-3 h-3 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                            <label for="question3-4" class="ml-2 text-sm font-normal text-gray-900 dark:text-gray-300">I still had to figure stuff out on my own.</label>
                        </div>
                    
                      </div>
                  </div>

                  <div class="mt-3">
                    <label for="question4" class="block text-sm text-left font-normal text-gray-700">5. {{ surveyAnswers[4].Question }} </label>
                    <div class="mt-1">
                      <textarea id="question4" v-model="surveyAnswers[4].Answer" name="question4" rows="3" class="mt-1 p-4 text-black block w-full rounded-md border shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"></textarea>
                    </div>
                  </div>

                  <div class="mt-3">
                    <label for="question5" class="block text-sm text-left font-normal text-gray-700">6. {{ surveyAnswers[5].Question }}</label>
                    <div class="mt-1">
                      <textarea id="question5" v-model="surveyAnswers[5].Answer" name="question5" rows="3" class="mt-1 p-4 text-black block w-full rounded-md border shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"></textarea>
                    </div>
                  </div>

                  <div class="mt-3">
                    <label for="question6" class="block text-sm text-left font-normal text-gray-700">7. {{ surveyAnswers[6].Question }}</label>
                    <div class="mt-1">
                      <textarea id="question6" v-model="surveyAnswers[6].Answer" name="question6" rows="3" class="mt-1 p-4 text-black block w-full rounded-md border shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"></textarea>
                    </div>
                  </div>
                  
                </div>
          </Transition>
          <Transition
             enter-from-class="opacity-0"
             leave-to-class="opacity-0"
             enter-active-class="transition"
             leave-active-class="transition" >
                <div v-show="current_card == 4" class=" w-full flex flex-col flex-none h-fit text-white rounded p-5">
                  <div>
                    <label for="question7" class="block text-sm text-left font-normal text-gray-700">8. {{ surveyAnswers[7].Question }}</label>
                    <div class="mt-1">
                      <textarea id="question7" v-model="surveyAnswers[7].Answer" name="question7" rows="3" class="mt-1 p-4 text-black block w-full rounded-md border shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"></textarea>
                    </div>
                  </div>
                  <div class="mt-3">
                    <label for="question8" class="block text-sm text-left font-normal text-gray-700">9. {{ surveyAnswers[8].Question }}</label>
                    <div class="mt-1">
                      <textarea id="question8" v-model="surveyAnswers[8].Answer" name="question8" rows="3" class="mt-1 p-4 text-black block w-full rounded-md border shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"></textarea>
                    </div>
                  </div>

                  <div class="mt-3">
                    <label for="question9" class="block text-sm text-left font-normal text-gray-700">10. {{ surveyAnswers[9].Question }}</label>
                    <div class="mt-1">
                      <textarea id="question9" v-model="surveyAnswers[9].Answer" name="question9" rows="3" class="mt-1 p-4 text-black block w-full rounded-md border shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"></textarea>
                    </div>
                  </div>

                  <div class="mt-3">
                    <label for="question10" class="block text-sm text-left font-normal text-gray-700">11. {{ surveyAnswers[10].Question }}</label>
                    <div class="mt-1">
                      <textarea id="question10" v-model="surveyAnswers[10].Answer" name="question10" rows="3" class="mt-1 p-4 text-black block w-full rounded-md border shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"></textarea>
                    </div>
                  </div>
                  
                </div>
          </Transition>
          <Transition
             enter-from-class="opacity-0"
             leave-to-class="opacity-0"
             enter-active-class="transition"
             leave-active-class="transition" >
                <div v-show="current_card == 5" class=" w-full flex flex-col flex-none h-fit text-white rounded p-5">
                  <div>
                    <label for="question11" class="block text-sm text-left font-normal text-gray-700">12. {{ surveyAnswers[11].Question }}</label>
                    <div class="mt-1">
                      <textarea id="question11" v-model="surveyAnswers[11].Answer" name="question11" rows="3" class="mt-1 p-4 text-black block w-full rounded-md border shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"></textarea>
                    </div>
                  </div>
                  <div class="mt-3">
                    <label for="question12" class="block text-sm text-left font-normal text-gray-700">13.{{ surveyAnswers[12].Question }}</label>
                    <div class="mt-1">
                      <textarea id="question12" v-model="surveyAnswers[12].Answer" name="question12" rows="3" class="mt-1 p-4 text-black block w-full rounded-md border shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"></textarea>
                    </div>
                  </div>

                  <div class="mt-3">
                    <label for="question13" class="block text-sm text-left font-normal text-gray-700">14. {{ surveyAnswers[13].Question }}</label>
                    <div class="mt-1">
                      <textarea id="question13" v-model="surveyAnswers[13].Answer" name="question13" rows="3" class="mt-1 p-4 text-black block w-full rounded-md border shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"></textarea>
                    </div>
                  </div>

                  <div class="mt-3">
                    <label for="question14" class="block text-sm text-left font-normal text-gray-700">15. {{ surveyAnswers[14].Question }}</label>
                    <div class="mt-1">
                      <textarea id="question14" v-model="surveyAnswers[14].Answer" name="question14" rows="3" class="mt-1 p-4 text-black block w-full rounded-md border shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"></textarea>
                    </div>
                  </div>
                  
                </div>
          </Transition>
           <Transition
            enter-from-class="opacity-0"
            leave-to-class="opacity-0"
            enter-active-class="transition"
            leave-active-class="transition" >
            <div v-show="current_card == totalCards" class=" w-full flex flex-none h-96 text-white rounded p-5">

              <div class="flex grow w-full items-center justify-center">
                  <p class="text-xl pb-5 break-words text-blue-500">Thank you !!</p>
              </div>

            </div>
           </Transition>
        </div>
    </div>

   </template>
    <template v-slot:footer>
      <button  v-if="current_card != totalCards" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:col-start-2 sm:text-sm" @click="next">Next</button>
      <button  v-if="current_card == totalCards" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:col-start-2 sm:text-sm" @click="sendFeedback()">Submit</button>
      <button  v-if="current_card != 1" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm" @click="prev">Previous</button>
      <button v-if="current_card == 1" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm" @click="logoutAferFeedBack? logout() : (showFeedBackModal = false, dontShowFeedBackPopUpModal(), dontShowFeedBackModal())" ref="cancelButtonRef">Skip</button>
    </template>

 </FeedBackModal>
  <div>
    <v-tour class="navtourclass" name="navTour" :steps="steps" :callbacks="myCallbacks" :options="myOptions"></v-tour>
  </div>
</template>

<script>
import { onMounted, provide, ref, computed, inject } from "vue";
import InlineLoader from "../components/InlineLoader";
import GuideModal from "../components/GuideModal";
import FeedBackModal from "../components/FeedBackModal.vue";
import {
  Dialog, DialogOverlay, Menu, MenuButton,
  MenuItem, MenuItems, TransitionChild, TransitionRoot,
} from "@headlessui/vue";
import {
  BellIcon, CalendarIcon, TemplateIcon, FolderIcon, HomeIcon,
  InboxIcon, MenuAlt2Icon, UsersIcon, XIcon, ClipboardListIcon, DocumentReportIcon
} from "@heroicons/vue/outline";
import { SearchIcon } from "@heroicons/vue/solid";
import { useRouter } from "vue-router";
import Api from "@/api/Api.js";
import Modal from "../components/Modal";
import AlertSuccess from "../components/AlertSuccess";
import AlertError from "../components/AlertError.vue";
import {useLoading} from "vue-loading-overlay";

const navigation = [
  { 
    name: "My Projects", 
    href: "/dashboard/home", 
    icon: HomeIcon, 
    current: false,
    id: "nav-step-0"
  },
  { 
    name: "Teams", 
    href: "/dashboard/teams", 
    icon: UsersIcon, 
    current: false,
    id: "nav-step-1" 
  },
  {
    name: "My Tasks",
    href: "/dashboard/tasks",
    icon: FolderIcon,
    current: false,
    id: "nav-step-2"
  },
  {
    name: "My Workflows",
    href: "/dashboard/work-flows",
    icon: ClipboardListIcon,
    current: false,
    id: "nav-step-3"
  },
  {
    name: "My Templates",
    href: "/dashboard/templates",
    icon: TemplateIcon,
    current: false,
    id: "nav-step-4"
  },
  {
    name: "Calendar",
    href: "/dashboard/calender",
    current: false,
    icon: CalendarIcon,
    id: "nav-step-5"
  },

  {
    name: "Reports",
    href: "/dashboard/report",
    current: false,
    icon: DocumentReportIcon,
    id: "nav-step-6"
  },
];

const support = [
  {
    name: "Billing",
    href: "/billing",
    current: false,
  },
  { name: "support", href: "mailto:support@sliceloop.com", current: false },
  {
    name: "Request Feature",
    href: "mailto:support@sliceloop.com",
    current: false,
  },

];

const userNavigation = [
  { name: "Your Profile", href: "/dashboard/profile" },
  { name: "Settings", href: "#" },
  { name: "Sign out", href: "#" },
];

export default {
  props: ["activeTab"],
  components: {
    Dialog,
    DialogOverlay,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
    BellIcon,
    MenuAlt2Icon,
    SearchIcon,
    XIcon,
    ClipboardListIcon,
    Modal,
    InlineLoader,
    AlertSuccess,
    GuideModal,
    FeedBackModal,
    AlertError
  },
  setup(props) {
    const sidebarOpen = ref(false);
    const router = useRouter();
    const initials = ref();
    const showContactForm = ref(false);
    let userFromStorage = JSON.parse(localStorage.getItem("user"));
    const name = ref(userFromStorage.name);
    const email = ref(userFromStorage.email);
    const subject = ref("");
    const message = ref("");
    const textMessage = ref("");
    const nameMessage = ref("");
    const emailMessage = ref("");
    const canShow = ref(false);
    const disableButton = ref(true);
    const successMessage = ref('');
    const errorMessage = ref('');
    const showGuide = ref(false);
    const currentGuideStage = ref(1);
    const workflowId = ref();
    const canShowGuide = ref(localStorage.getItem('can-show-guide'));
    const canShowTeamsGuide = ref(localStorage.getItem('can-show-teams-guide'));
    const canShowTasksGuide = ref(localStorage.getItem('can-show-tasks-guide'));
    const canShowAssignTaskTourGuide = ref(localStorage.getItem('can-show-assign-task-guide'));
    const canDeleteTemplate =  ref(false);
    const canEditNodeTemplate = ref(false);
    provide('currentGuideStage', currentGuideStage);
    provide('canShowGuide',canShowGuide);
    provide('canShowTeamsGuide',canShowTeamsGuide);
    provide('canShowTasksGuide',canShowTasksGuide);
    provide('canShowAssignTaskTourGuide',canShowAssignTaskTourGuide);
    provide('workflowId',workflowId);
    provide('canDeleteTemplate',canDeleteTemplate);
    provide('canEditNodeTemplate',canEditNodeTemplate);

    provide("user", userFromStorage);
    let username = ref(userFromStorage.name);

    let showFeedBackModal = ref(false)
    let logoutAferFeedBack = ref(false)
    let current_card = ref(1);
    let totalCards   = ref(6)
    const $loading = useLoading();

    let easyToUse        = ref();
    let easyToUseReason  = ref('');
    let meetExpectations        = ref();
    let meetExpectationsReason  = ref('');
    let doneBetter  = ref('');
    let otherSuggestions  = ref('');
    let explainSliceloop  = ref('');
    let surveyAnswers = ref([

      {'Question': "How do you currently manage your content creation process and content operations? Which Apps do you use, if any?", 'Answer': ""}, 
      {'Question': "Why doesn't sliceloop meet your expectations? What's missing in the App?", 'Answer': ""}, 
      {'Question': "Did you find sliceloop easy to use?", 'Answer': ""},
      {'Question': "Did the App tour guides help you?", 'Answer': ""}, 
      {'Question': "Which parts of the app felt unnecessarily complicated?", 'Answer': ""}, 
      {'Question': "Which features did not work as expected?", 'Answer': ""},
      {'Question': "Which features were least useful?", 'Answer': ""}, 
      {'Question': "Please describe what was most confusing when you tried using sliceloop?", 'Answer': ""}, 
      {'Question': "What could we have improved on/done better?", 'Answer': ""},
      {'Question': "What features would you want added to sliceloop?", 'Answer': ""}, 
      {'Question': "Do you have some other suggestions on how we could improve sliceloop?", 'Answer': ""}, 
      {'Question': "How would you describe sliceloop to a friend, family member or colleague?", 'Answer': ""},
      {'Question': "Would you recommend sliceloop to a friend?", 'Answer': ""}, 
      {'Question': "Would you use sliceloop again?", 'Answer': ""}, 
      {'Question': "How much are you ready to pay for using sliceloop?", 'Answer': ""},

    ]);
    

    const tours = inject('tours');
    const steps = [
          {
            target: '#nav-step-0', 
            content: `<span class="text-sm pb-2">Manage your projects and tasks here</span>`,
            params: {
              placement: 'bottom-end',
            } 
          },
          {
            target: '#nav-step-1',
            content: '<span class="text-sm pb-2">Add team members and manage teams</span>',
            params: {
              placement: 'bottom-end',
            } 
          },
          {
            target: '#nav-step-2',
            content: '<span class="text-sm pb-2">View Tasks you have been assigned</span>',
             params: {
              placement: 'bottom-end',
            } 
          },
          {
            target: '#nav-step-3',
            content: '<span class="text-sm pb-2">Define and Manage your project workflows from here</span>',
             params: {
              placement: 'bottom-end',
            } 
          },
          {
            target: '#nav-step-4',
            content: '<span class="text-sm pb-2">Create and Manage templates for your content</span>',
            params: {
              placement: 'bottom-end',
            } 
          },
          {
            target: '#nav-step-5',
            content: '<span class="text-sm pb-2">View all content tasks in a calendar format for easy planning</span>',
            params: {
              placement: 'bottom-end',
            } 
          },
          {
            target: '#nav-step-6',
            content: '<span class="text-sm pb-2">View reports about performance of team members, projects and tasks</span>',
            params: {
              placement: 'bottom-end',
            } 
          },
          {
            target: '#nav-step-7',
            content: '<span class="text-sm pb-2">Get all payment information here</span>',
            params: {
              placement: 'bottom-end',
            } 
          },
          {
            target: '#nav-step-8',
            content: '<span class="text-sm pb-2">Click here to contact support</span>',
            params: {
              placement: 'bottom-end',
            } 
          },
          {
            target: '#nav-step-9',
            content: '<span class="text-sm pb-2">Make feature requests here</span>',
            params: {
              placement: 'bottom-end',
            } 
          },
          {
            target: '#nav-step-10',
            content: '<span class="text-sm pb-2">Click here to take our feedback survey</span>',
            params: {
              placement: 'bottom-end',
            } 
          }
        ];
    const  myOptions= {
          useKeyboardNavigation: false,
          labels: {
            buttonSkip: 'Skip tour',
            buttonPrevious: 'Previous',
            buttonNext: 'Next',
            buttonStop: 'Finish'
          },
          highlight: true
        };
    const myCallbacks = {
        onSkip:   myCustomSkipStepCallback,
        onFinish: myCustomFinishStepCallback
      }

     function  myCustomSkipStepCallback (currentStep) {

        //
       
     }
     function myCustomFinishStepCallback (currentStep) {

       //
        
     }

     function startNavigationTutorial(){

       resetHelpGuideValues();
       tours['navTour'].start();

     }

     const my_project_tour_tutorial = ref(0)
     const task_management_tour_tutorial = ref(null)

     function update_my_project_tour_tutorial(val) {

        my_project_tour_tutorial.value = val;

     }

     function update_task_management_tour_tutorial(val) {

        task_management_tour_tutorial.value = val;

     }

     provide('my_project_tour_tutorial',{my_project_tour_tutorial, update_my_project_tour_tutorial});
     provide('task_management_tour_tutorial',{task_management_tour_tutorial, update_task_management_tour_tutorial});

     function startMyProjectsTutorial(){
      
       resetHelpGuideValues();
       my_project_tour_tutorial.value = 1;
       router.push({ path: '/dashboard/home' })

     }

     function startTaskManagementTutorial(){

       resetHelpGuideValues();
       task_management_tour_tutorial.value = !task_management_tour_tutorial.value;
      
      // router.push({ path: '/dashboard/home', params:{'task_tutorial'} })
      router.push({name: 'dashboardHome'});
      
     }

     function resetHelpGuideValues(){

       canShowGuide.value = 1;
       canShowAssignTaskTourGuide.value = 0
       canShowTasksGuide.value  = 1;


     }

    function updateUserProfile() {
      userFromStorage = JSON.parse(localStorage.getItem("user"));

      username.value = userFromStorage.name;
    }

    const getNameInitials = () => {
      initials.value = username.value[0];
    };

   
   function  next () {


      if(current_card.value == totalCards.value){

        current_card.value = 1;

      }else{

       current_card.value = current_card.value + 1;

      }


   }

   function prev () {

 
      if(current_card.value > 1){

        current_card.value = current_card.value - 1;

        

      }else{

       current_card.value = 3;

      }
      
   }

   async function sendFeedback(){

    showFeedBackModal.value = false;

     const loader = $loading.show({
        color: "#2581ef",
        opacity: 1,
     });
     
     let feedbackobj = {
      'name' : name.value,
      'email' : email.value,
      'surveyAnswers' : surveyAnswers.value,
     
     }
     try {

        const res = await Api().post("/sendfeedback", feedbackobj)

        if (res.data.status === "success") {
          
          dontShowFeedBackPopUpModal();
          dontShowFeedBackModal();

          if(logoutAferFeedBack.value){

            await logout();

          }else{

             surveyAnswers.value = [
              {'Question': "How do you currently manage your content creation process and content operations? Which Apps do you use, if any?", 'Answer': ""}, 
              {'Question': "Why doesn't sliceloop meet your expectations? What's missing in the App?", 'Answer': ""}, 
              {'Question': "Did you find sliceloop easy to use?", 'Answer': ""},
              {'Question': "Did the App tour guides help you?", 'Answer': ""}, 
              {'Question': "Which parts of the app felt unnecessarily complicated?", 'Answer': ""}, 
              {'Question': "Which features did not work as expected?", 'Answer': ""},
              {'Question': "Which features were least useful?", 'Answer': ""}, 
              {'Question': "Please describe what was most confusing when you tried using sliceloop?", 'Answer': ""}, 
              {'Question': "What could we have improved on/done better?", 'Answer': ""},
              {'Question': "What features would you want added to sliceloop?", 'Answer': ""}, 
              {'Question': "Do you have some other suggestions on how we could improve sliceloop?", 'Answer': ""}, 
              {'Question': "How would you describe sliceloop to a friend, family member or colleague?", 'Answer': ""},
              {'Question': "Would you recommend sliceloop to a friend?", 'Answer': ""}, 
              {'Question': "Would you use sliceloop again?", 'Answer': ""}, 
              {'Question': "How much are you ready to pay for using sliceloop?", 'Answer': ""},

            ];
            current_card.value = 1;

            successMessage.value = 'Feedback sent successfully.'
            loader.hide();
             
          }
          

        } else {

          errorMessage.value = res.data.message;
          loader.hide();

        }

      } catch (err) {

          if (err.response) {

            errorMessage.value = 'Error! Please try again'

          } else if (err.request) {


            errorMessage.value = 'Error with Request';


          } else {

              errorMessage.value = 'An Error has Occured';

          }
        loader.hide();
      }


   }

    async function logout() {

      const loader = $loading.show({
        color: "#2581ef",
        opacity: 1,
      });


      try {

        const res = await Api().post("/logout")

        if (res.data.status === "success") {

          localStorage.removeItem("token");
          localStorage.removeItem("user");
          window.location.href = '/?nocache=' + (new Date()).getTime();

        } else {

          errorMessage.value = res.data.message;
          loader.hide();

        }

      } catch (err) {

          if (err.response) {

            errorMessage.value = 'Error! Please try again'

          } else if (err.request) {


            errorMessage.value = 'Error with Request';


          } else {

              errorMessage.value = 'An Error has Occured';

          }
        loader.hide();
      }

    }
   const  checkForSavedMessage = () => {
        let message =  localStorage.getItem('successMessage');
        successMessage.value =  message;
        localStorage.removeItem('successMessage');
   }


    onMounted(() => {
      checkForSavedMessage();
      getNameInitials();
      setTimeout(()=>{
        showGuide.value = true;

      },1000)
    });

    const disableContactButton = computed(() => {
      if (
        name.value.trim().length > 0 &&
        email.value.trim().length > 0 &&
        message.value.trim().length > 0
      ) {
        return false;
      }

      return true;
    });

    const submitMessage = () => {
      canShow.value = true;
      const msg = {
        name: name.value,
        email: email.value,
        message: message.value,
        subject: subject.value,
      };

      Api()
        .post("/support", msg)

        .then((response) => {
          //let message = response.data.message;
          let status = response.data.status;

          if(status === "success"){

            successMessage.value = "success";
            disableButton.value = false;
            setTimeout(() => {
              document.getElementById("support-form").reset();
              message.value = "";
            });

          }
          
        })
        .catch((error) => {
          if (error.response.status === 422) {
            nameMessage.value = error.response.data.errors.name;
            emailMessage.value = error.response.data.errors.email;
            textMessage.value = error.response.data.errors.message;
          }
          
          disableButton.value = false;
        })
        .finally(() => {
          canShow.value = false;
        });
    };


    const showGuideAgain = () => {
      localStorage.removeItem('can-show-guide');
      localStorage.removeItem('can-show-teams-guide');
      localStorage.removeItem('can-show-tasks-guide');
      localStorage.removeItem('can-show-assign-task-guide');
      location.href= '/dashboard/home';
    }

    setTimeout(function() {

       let can_show_popup_feedback_modal = localStorage.getItem("can-show-popup-feedback-modal");

        if(can_show_popup_feedback_modal == null || can_show_popup_feedback_modal == 1){

          showFeedBackModal.value = true;
          localStorage.setItem('can-show-popup-feedback-modal',0);

        }

    }, 180000);

    function dontShowFeedBackPopUpModal(){

      localStorage.setItem('can-show-popup-feedback-modal',0);
      

    }

    function  dontShowFeedBackModal(){

      localStorage.setItem('can-show-feedback-modal',0);

    }

    function canShowFeedbackModal(){

      let canShowFeedbackSurvey =  localStorage.getItem('can-show-feedback-modal');

      if(canShowFeedbackSurvey == null || canShowFeedbackSurvey == 1){

        return true;

      }else{

          return false;

      }

    }

    function signout(){ 

      if(canShowFeedbackModal()){

        showFeedBackModal.value = true; 
        logoutAferFeedBack.value = true;
        dontShowFeedBackModal();
        dontShowFeedBackPopUpModal();

      }else{
    
         logout();

      }

    }

    return {
      navigation,
      userNavigation,
      sidebarOpen,
      logout,
      updateUserProfile,
      initials,
      support,
      showContactForm,
      name,
      email,
      message,
      nameMessage,
      emailMessage,
      textMessage,
      canShow,
      disableContactButton,
      disableButton,
      submitMessage,
      successMessage,
      userFromStorage,
      subject,
      showGuide,
      canShowGuide,
      showGuideAgain,
      showFeedBackModal,
      next,prev,current_card, totalCards, sendFeedback,errorMessage,
      easyToUse, easyToUseReason, meetExpectations , meetExpectationsReason, 
      doneBetter, otherSuggestions, explainSliceloop,
      steps, myOptions, myCallbacks, startNavigationTutorial, startMyProjectsTutorial, startTaskManagementTutorial,
      logoutAferFeedBack, surveyAnswers, dontShowFeedBackPopUpModal, dontShowFeedBackModal, canShowFeedbackModal, signout
    };
  },
};
</script>
<style>
 .v-tour.navtourclass .v-step__buttons{
  display:  inline-block !important;
  padding-top: 0.5rem !important;;
  /* font-size: 0.75rem !important;
  line-height: 1rem !important; */
}

 .v-tour.navtourclass .v-step__arrow{
  border-color: #3b82f6 !important;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  /* border-bottom-color: transparent !important; */
}

</style>
