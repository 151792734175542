<template>
   <div class="py-16 bg-white overflow-hidden">
    <div class="px-4 sm:px-6 lg:px-8">
      <div class="text-lg max-w-prose mx-auto">
        <h1>
          <span class="block text-2xl text-center text-indigo-600 font-semibold tracking-wide uppercase">How to create a Project</span>
          <span class="mt-2 block text-xs text-center tracking-tight text-gray-900">A project is grouping of similar or related content tasks. You can organize it in any way you want</span>
        </h1>
      </div>
      <div class="mt-6 w-1/2 prose-indigo prose-lg text-black mx-auto">

           <div class="text-base my-6">
               <p class="py-3"><span class="font-medium text-lg">1.</span> Click on <span class="italic font-bold">My Projects</span> on the left navigation menu</p>
                <!-- <figure class="border rounded-lg border-1">
                    <img class="w-full rounded-lg" src="/images/4_create_a_workflow.png" alt="" width="1310" height="873" />
                </figure> -->
           </div>
           <div class="text-base my-6">
               <p class="py-3"><span class="font-medium text-lg">2.</span> Click on <span class="italic text-blue-500"> Add Project </span></p>
                 <figure class="border rounded-lg border-1">
                    <img class="w-full rounded-lg" src="/images/6_add_a_project_1.png" alt="" width="1310" height="873" />
                    <!-- <figcaption>Sagittis scelerisque nulla cursus in enim consectetur quam.</figcaption> -->
                </figure>
           </div>

           <div class="text-base my-6">
               <p class="py-3"><span class="font-medium text-lg">3.</span>  Enter a project name eg. <span class="italic text-blue-500"> Project 1 </span>,  Assign it a workflow and click
               <button disabled class="ml-1 flex-shrink-0 px-4 py-2 border border-transparent text-xs font-normal rounded-md shadow-sm text-white
                bg-blue-600"> Create Project</button></p>
               <p class="text-xs italic font-normal py-3">You can find out how to create a workflow <a href="/how-to-create-a-workflow"
          target="_blank" class="text-blue-500 underline"> here </a> </p>

                 <figure class="border rounded-lg border-1 mt-3">
                    <img class="w-full rounded-lg" src="/images/6_add_a_project_2.png" alt="" width="1310" height="873" />
                </figure>
           </div>
        <p>You can now use this project to start assigning tasks to team members. Find out how to <a href="/how-to-create-a-team"
          target="_blank" class="text-blue-500 underline"> create a team </a> or  <a href="/how-to-assign-a-task"
          target="_blank" class="text-blue-500 underline"> assign a content task </a>

        </p>
      </div>
    </div>
   </div>
</template>

<script>
export default {

}
</script>

<style>

</style>