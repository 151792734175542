<template>
  <div>
    <AlertSuccess
      v-if="successMessage"
      :message="successMessage"
      @hide="successMessage = null"
    />
    <AlertError
      v-if="errorMessage"
      :message="[errorMessage]"
      @hide="errorMessage = null"
    />

    <AlertWithDescription
      v-if="!teamsList"
      header="You have not been added to any teams!"
      body="When you get invitations to join other teams we will notify you"
    />

    <div class="flex flex-col mt-10">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div
            class="
              shadow
              overflow-hidden
              border-b border-gray-200
              sm:rounded-lg
            "
          >
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                  >
                    Team Name
                  </th>
                  <th
                    scope="col"
                    class="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                  >
                    Invitation Sent On
                  </th>
                  <th
                    scope="col"
                    class="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                  >
                    Status
                  </th>
                  <th scope="col" class="relative px-6 py-3">
                    <span class="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="team in teamsList" :key="team.teamId">
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="ml-4">
                        <div class="text-sm font-medium text-gray-900">
                          {{ team.teamName }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="text-sm text-gray-900">
                      {{ formatDate(team.invitation_sent_on) }}
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <p
                      v-if="team.invitationAccepted"
                      class="
                        text-xs
                        capitalize
                        bg-green-100
                        text-green-800
                        inline-block
                        px-5
                        py-1
                        rounded-xl
                      "
                    >
                      accepted
                    </p>
                    <p
                      v-else
                      class="
                        text-xs
                        capitalize
                        bg-yellow-100
                        text-yellow-800
                        inline-block
                        px-5
                        py-1
                        rounded-xl
                      "
                    >
                      Pending
                    </p>
                  </td>

                  <td
                    class="
                      px-6
                      py-4
                      whitespace-nowrap
                      text-right text-sm
                      font-medium
                    "
                  >
                    <button
                      v-if="team.invitationAccepted"
                      type="button"
                      id="leaveTeamId"
                      @click="
                        showDeleteModal = true;
                        activeDelete = team;
                      "
                      :disabled="team.isLeavingTeam"
                      class="
                        inline-flex
                        items-center
                        py-2
                        px-3
                        text-red-500
                        hover:underline
                      "
                    >
                      Leave Team
                    </button>

                    <button
                      v-else
                      type="button"
                      id="acceptToJoin"
                      @click="acceptToJoin(team)"
                      :disabled="team.isAcceptingToJoinTeam"
                      class="
                        inline-flex
                        items-center
                        py-2
                        px-3
                        text-blue-500
                        hover:underline
                      "
                    >
                      Accept to Join
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <DeleteAlert
      :show="showDeleteModal"
      header="Do you want to leave team?"
      body="This action cannot be undone and you will lose access to all tasks assigned to this team. "
      @delete="deleteTeam"
      @cancel="showDeleteModal = false"
    />
    <v-tour v-if="(canShowTeamsGuide == 1)" class="text-gray-500 bg-white" name="myTour" :steps="steps"></v-tour>
    <GuideModal  v-if="showGuide" :is-open="showGuide"  @close="closeGuide" />
  </div>
</template>

<script>
import { ref, inject, onMounted } from "vue";
import Api from "@/api/Api.js";
import AlertWithDescription from "./AlertWithDescription";
import AlertError from "./AlertError";
import AlertSuccess from "./AlertSuccess";
import { useLoading } from "vue-loading-overlay";
import DeleteAlert from "./DeleteAlert";
import GuideModal from "./GuideModal";
export default {
  name: "TeamsYouHaveBeenAddedTo",
  components: { AlertWithDescription, AlertSuccess, AlertError, DeleteAlert, GuideModal},
  setup() {
    let teamsList = ref(null);
    const $loading = useLoading();
    let userFromStorage = JSON.parse(localStorage.getItem("user"));
    let userid = userFromStorage.id;
    const showDeleteModal = ref(false);
    let successMessage = ref("");
    let errorMessage = ref("");
    const activeDelete = ref();
    const canShowTeamsGuide = inject('canShowTeamsGuide');
    const tours = inject('tours');
    const  steps = [
      {
        target: '#acceptToJoin',
        content: `Click here to accept to join team`,
        params: {
          placement: 'bottom'
        }
      },
    ]
    const guideStage =  inject('currentGuideStage');
    const showGuide = ref(false);

    const deleteTeam = () => {
      leaveTeam(activeDelete.value);
      showDeleteModal.value = false;
    };

    function loadTeamsIhaveBeenAddedTo(allowGuide = false) {
      const loader = $loading.show({
        color: "#2581ef",
        opacity: 1,
      });
      Api()
        .get(`/teams/show-Teams-I-have-Been-Added-To/${userid}`)
        .then((response) => {
          if (response.data.status === "success") {
            response.data.teams.forEach(function (row) {
              row.isLeavingTeam = false;
              row.isAcceptingToJoinTeam = false;
            });

            teamsList.value = response.data.teams;
          } else {
            teamsList.value = null;
          }
        })
        .catch((error) => {})
        .finally(() => {
          loader.hide();

          if(document.getElementById("acceptToJoin") && (canShowTeamsGuide.value == 1) && allowGuide){
            
            tours['myTour'].start();

          }
          
        });
    }
    function formatDate(date) {
      var d1 = new Date(date);
      var ms = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      return d1.getDate() + " " + ms[d1.getMonth()] + " " + d1.getFullYear();
    }

    function acceptToJoin(teamObject) {

      if(canShowTeamsGuide.value == 1){

          tours['myTour'].stop();
          guideStage.value = 6.3;
    
      }

      const loader = $loading.show({
        color: "#2581ef",
        opacity: 1,
      });
      successMessage.value = null;
      errorMessage.value = null;

      let tempTeamMemberId = teamObject.teamMemberRowId;

      // disable button
      teamObject.isAcceptingToJoinTeam = true;

      let tempTeamMember = {
        invitationAccepted: 1,
      };

      Api()
        .patch(
          `/teams/update-invitation-accept-status/${tempTeamMemberId}`,
          tempTeamMember
        )
        .then((response) => {
          let status = response.data.status;
          if (status === "success") {
            
              if(canShowTeamsGuide.value == 1){

                  // tours['myTour'].stop();
                  // guideStage.value = 6.3;
                  showGuide.value = true;

              }else{

                successMessage.value = "You have successfully joined team: " + teamObject.teamName;

              }
            
          }
        })
        .catch((error) => {
          
          errorMessage.value = "An error has occurred! Please try again.";
        })
        .finally(() => {
          loadTeamsIhaveBeenAddedTo();
          loader.hide();
        });
    }

    function leaveTeam(teamObject) {
      successMessage.value = null;
      errorMessage.value = null;
      const loader = $loading.show({
        color: "#2581ef",
        opacity: 1,
      });
      let tempTeamMemberId = teamObject.teamMemberRowId;

      //disable leave button

      teamObject.isLeavingTeam = true;

      let tempTeamMember = {
        leaveTeamStatus: 1,
      };

      Api()
        .patch(
          `/teams/update-leave-team-status/${tempTeamMemberId}`,
          tempTeamMember
        )
        .then((response) => {
          let status = response.data.status;
          if (status === "success") {
            successMessage.value =
              "You have successfully left team: " + teamObject.teamName;
            
          }
        })
        .catch((error) => {
          
          errorMessage.value = "An error has occurred! Please try again.";
        })
        .finally(() => {
          loadTeamsIhaveBeenAddedTo();
          loader.hide();
        });
    }

    function closeGuide(){

      showGuide.value = false;
      canShowTeamsGuide.value = 2;
      localStorage.setItem('can-show-teams-guide',0);

    }
    
    loadTeamsIhaveBeenAddedTo(true);

    
    
    

    return {
      teamsList,
      formatDate,
      acceptToJoin,
      successMessage,
      errorMessage,
      showDeleteModal,
      deleteTeam,
      activeDelete,
      steps,
      showGuide,
      closeGuide,
      canShowTeamsGuide
    };
  },
};
</script>

<style></style>
