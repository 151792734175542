<template>
  <main class="relative z-10 flex-auto flex items-center justify-center text-sm text-center  pt-16 px-4 sm:px-6 lg:px-8 md:mt-36">
    <div class="w-full max-w-sm">
      <div class="pl-4 my-6 "><a class="toggleColour no-underline hover:no-underline font-bold text-2xl lg:text-4xl text-center" href="/"> Sliceloop </a></div>
      <h1 class="sr-only">Log in to your account</h1>

      <form action="/login" method="POST">
        <div class="relative">
          <label  for="name"  class="sr-only">Name:</label>
          <input type="text" placeholder="Name" v-model="name" class="text-gray-900 ring-gray-900 ring-opacity-5 placeholder-gray-400 appearance-none bg-white rounded-md block w-full px-3 py-2 border border-transparent shadow ring-1 sm:text-sm mb-4 focus:border-blue-500 focus:ring-blue-500 focus:outline-none" id="name" />
          <p
            v-for="(errorMessage, index) in nameMessage"
            :key="index"
            style="font-size: 0.8rem !important"
            class="text-red-500 mb-2"
          >
            {{ errorMessage }}
          </p>
        </div>
        <div v-if="invitationID == 0" class="relative">
          <label  for="email" class="sr-only">Email address:</label>
          <input type="email" placeholder="Email address" v-model="email" class="text-gray-900 ring-gray-900 ring-opacity-5 placeholder-gray-400 appearance-none bg-white rounded-md block w-full px-3 py-2 border border-transparent shadow ring-1 sm:text-sm mb-4 focus:border-blue-500 focus:ring-blue-500 focus:outline-none" id="email" />
          <p
            v-for="(errorMessage, index) in emailMessage"
            :key="index"
            style="font-size: 0.8rem !important"
            class="text-red-500 mb-2"
          >
            {{ errorMessage }}
          </p>
        </div>
        <div class="relative">
          <label  for="password" class="sr-only">Password:</label>
          <input
            type="password"
            v-model="password"
            placeholder="Password"
            class="text-gray-900 ring-gray-900 ring-opacity-5 placeholder-gray-400 appearance-none bg-white rounded-md block w-full px-3 py-2 border border-transparent shadow ring-1 sm:text-sm mb-4 focus:border-blue-500 focus:ring-blue-500 focus:outline-none"
            id="password"
          />

          <p
            v-for="(errorMessage, index) in passwordMessage"
            :key="index"
            style="font-size: 0.8rem !important"
            class="text-red-500 mb-2"
          >
            {{ errorMessage }}
          </p>
        </div>
        <div class="relative mb-3">
          <label  for="password_confirmation" class="sr-only"
            >Confirm Password:</label
          >
          <input
            type="password"
            v-model="password_confirmation"
            placeholder="Confirm Password"
            class="text-gray-900 ring-gray-900 ring-opacity-5 placeholder-gray-400 appearance-none bg-white rounded-md block w-full px-3 py-2 border border-transparent shadow ring-1 sm:text-sm mb-4 focus:border-blue-500 focus:ring-blue-500 focus:outline-none"
            id="password_confirmation"
          />
        </div>
        <button
          type="submit"
          @click.prevent="register"
          :disabled="disableRegisterButton"
          v-bind:class="{ disabled: disableButton }"
          class=" flex justify-center items-center  block w-full py-2 px-3 border border-transparent rounded-md text-white font-medium bg-blue-500
        shadow-sm sm:text-sm mb-10 hover:bg-blue-600 focus:outline-none focus-visible:ring-2
        focus-visible:ring-gray-700 focus-visible:ring-offset-2 focus-visible:ring-offset-gray-50"
        >
          <InlineLoader :h="25" :w="25" :show="canShow" />
          <span v-if="subscriptionPackage">
           Continue
          </span>
          <span v-else>
            Create Account
          </span>
        </button>
      </form>
    </div>
  </main>
  <footer class="relative z-10 flex-none text-sm text-center py-4 px-4 sm:px-6 lg:px-8">
    <div class="text-gray-900 sm:flex sm:items-center sm:justify-center space-y-4 sm:space-y-0 sm:space-x-4">
      <p>Already have an account?</p>
      <router-link
          :to="{ name: 'Login' }"
          class="rounded-md border border-gray-300 hover:border-gray-400 py-2 px-10 font-medium flex items-center justify-center"
      >
        Login
        <svg aria-hidden="true" width="11" height="10" fill="none" class="flex-none ml-1.5">
          <path d="M5.977 9.639L10.616 5 5.977.362l-.895.89L8.19 4.353H.384v1.292H8.19L5.082 8.754l.895.885z" fill="currentColor"></path>
        </svg>
      </router-link
      >
    </div>
  </footer>
</template>

<script>
import { ref,inject } from "vue";
import { computed,onMounted } from "vue";
import Api from "@/api/Api.js";
import { useRouter,useRoute } from "vue-router";
import InlineLoader from "../components/InlineLoader";

export default {
  components:{InlineLoader},
  setup() {

    const route = useRoute();
    const router = useRouter();
    const canShow = ref(false);
    let name = ref("");
    let email = ref("");
    let password = ref("");
    let password_confirmation = ref("");
    const subscriptionPackage = inject('subscriptionPackage');
    const subscriptionPeriod = inject('subscriptionPeriod');
    const client_secret = inject('client_secret');
    let nameMessage = ref(null);
    let emailMessage = ref(null);
    let passwordMessage = ref(null);
    let disableButton = ref(false);

    // const invitationID = Number.parseInt(route.params.invitationID,10);
    const invitationID = route.params.invitationID == null ? 0 : route.params.invitationID;

    if (invitationID != 0) {

       Api()
        .get(`/teams/invitation-details/${invitationID}`)
        .then((response) => {

            if(response.data.status === "success"){

              
              email.value = response.data.email;
              
            }else{

               router.push({name:'pricing'})

            }
             

        })
        .catch((error) => {
          
        });

    }

    const disableRegisterButton = computed(() => {
      if (
        name.value.trim().length > 0 &&
        email.value.trim().length > 0 &&
        password.value.trim().length > 0 &&
        password_confirmation.value.trim().length > 0
      ) {
        return false;
      }

      return true;
    });

    function register() {
     
     disableButton.value = true;
      canShow.value = true;
      nameMessage.value = [];
      emailMessage.value = [];
      passwordMessage.value = [];

      let user = {
        'name': name.value,
        'email': email.value,
        'password': password.value,
        'password_confirmation': password_confirmation.value,
        'invitationID': invitationID != 0 ? invitationID : 0,
        'subscription_type': subscriptionPackage.value? subscriptionPackage.value.name.toLocaleLowerCase() : 'free'
      };

      Api()
        .post("/register", user)

        .then((response) => {
          let message = response.data.message;
          let status = response.data.status;

          if (status === "success"){
            let token = response.data.token;
            let user = response.data.user;
            localStorage.setItem("token", token);
            localStorage.setItem("user", JSON.stringify(user));
            localStorage.removeItem('can-show-guide');
            localStorage.removeItem('can-show-teams-guide');
            localStorage.removeItem('can-show-tasks-guide');

            //only go here if register was successfull
            if (subscriptionPackage.value && (subscriptionPackage.value.name.toLowerCase() !== 'free')){
              client_secret.value =  response.data.intent.client_secret;
              router.push({name:'payments'})
            }else{

              if (!invitationID) {

                router.push({ name: "dashboardHome" });

              }else{

                router.push({ name: "teams",  params: { 'invite' : '1' }});

              }

              

            }
          }else{
            nameMessage.value = [message + "\n"];
          }
          disableButton.value = false;
        })
        .catch((error) => {
          if (error.response.status === 422) {
            nameMessage.value = error.response.data.errors.name;
            emailMessage.value = error.response.data.errors.email;
            passwordMessage.value = error.response.data.errors.password;
          }
          
          disableButton.value = false;
        }).finally(()=>{
          canShow.value = false;
      });
    }


    onMounted(()=>{
      if (!subscriptionPackage.value){

        if(!route.params.invitationID){
         router.push({ name: "pricing" });
        }

      }

    })

    return {
      name,
      email,
      password,
      password_confirmation,
      register,
      nameMessage,
      emailMessage,
      passwordMessage,
      disableRegisterButton,
      disableButton,
      canShow,
      invitationID,
      subscriptionPackage
    };
  },
};
</script>

<style>
</style>