import { Node, mergeAttributes } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-3'
import NodeComponent from './NodeComponent.vue'

export default Node.create({
  name: 'vueComponent',

  group: 'block',

// allow blocks within node
  content: 'block*',

// make node draggable
  draggable: true,

//keep cursor within node
  isolating: true, 

  // keeps node from being selectable and thus not deletable
  selectable: false, 

  // defining: false,
  addAttributes() {
    return {
      title: {
        default: 'text block',
      },

      isTemplate: {
        default: false,
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'vue-component',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['vue-component', mergeAttributes(HTMLAttributes), 0]
  },

  addNodeView() {
    return VueNodeViewRenderer(NodeComponent)
  },
  addKeyboardShortcuts(){
    return {

       Delete: () => { 

        
       
       
        // const { $from, $to } = this.editor.tr;
         //$to.node.type.name
        //  const $pos = this.editor.state.tr.selection.$head;
        //  for (let depth = $pos.depth; depth>0; depth-=1){
        //    const node = $pos.node(depth)
           
       

          //  if(node.type.name === "paragraph"){
          
          //     return true;
          //  }else{
          
          //     return false;
          //  }

           
       //  }
        

      },
      'Mod-Delete': () =>  {return true},

    }

  },
  addCommands() {
    return {
      setMyNode:
        (vueComponent) =>
        ({ commands, chain }) =>{

        // return commands.insertContent({type: this.name})
         return chain().focus().insertContent({type: this.name}).run();

        }
    };
  },
  
})