<template>
<div>
   <div class="isolate bg-white">
    <div class="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]">
      <svg class="relative left-[calc(50%-11rem)] -z-10 h-[21.1875rem] max-w-none -translate-x-1/2 rotate-[30deg] sm:left-[calc(50%-30rem)] sm:h-[42.375rem]" viewBox="0 0 1155 678" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill="url(#45de2b6b-92d5-4d68-a6a0-9b9b2abad533)" fill-opacity=".3" d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z" />
        <defs>
          <linearGradient id="45de2b6b-92d5-4d68-a6a0-9b9b2abad533" x1="1155.49" x2="-78.208" y1=".177" y2="474.645" gradientUnits="userSpaceOnUse">
            <stop stop-color="#2564eb" />
            <stop offset="1" stop-color="#bfdbfe" />
          </linearGradient>
        </defs>
      </svg>
    </div>
    <div class="px-6 pt-6 lg:px-8">
      <div>
        <nav class="flex h-9 items-center justify-between" aria-label="Global">
          <div class="flex lg:min-w-0 lg:flex-1" aria-label="Global">
            <a href="#" class="-m-1.5 p-1.5">
              <div class="flex relative">
                <img src="/images/logo.png" alt="SliceLoop" style="width: 200px" />
                <!-- <sup
                class="text-blue-500 text-xs uppercase"
                style="position: absolute; top: 10px; right: 0px"
                >Beta</sup> -->
              </div>
            </a>
          </div>
          <div class="flex lg:hidden">
            <button type="button" class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700" @click="mobileMenuOpen = true">
              <span class="sr-only">Open main menu</span>
              <MenuIcon class="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div class="hidden lg:flex lg:min-w-0 lg:flex-1 lg:justify-center lg:gap-x-12 text-lg">
            <router-link :to="{ name: 'pricing' }" class="font-semibold text-gray-900 hover:text-blue-600 hover:underline "> 
                Pricing 
            </router-link>
            <a v-for="item in navigation" :key="item.name" :href="item.href" class="hover:underline font-semibold text-gray-900 hover:text-blue-600">
                {{ item.name }}
            </a>
          </div>
          <div class="hidden lg:flex lg:min-w-0 lg:flex-1 lg:justify-end">
            <router-link :to="{ name: 'Login' }" class=" mx-auto lg:mx-0 hover:underline border border-blue-600 text-blue-600 inline-block font-bold rounded-full my-2 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition  hover:scale-105  duration-300  ease-in-out">
                Login
            </router-link>
          </div>
        </nav>
        <Dialog as="div" @close="mobileMenuOpen = false" :open="mobileMenuOpen">
          <DialogPanel focus="true" class="fixed inset-0 z-10 overflow-y-auto bg-white px-6 py-6 lg:hidden">
            <div class="flex h-9 items-center justify-between">
              <div class="flex">
                <a href="#" class="-m-1.5 p-1.5">
                  <span class="sr-only">Your Company</span>
                  <img class="h-8" src="/images/favicon32.png" alt="" />
                </a>
              </div>
              <div class="flex">
                <button type="button" class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700" @click="mobileMenuOpen = false">
                  <span class="sr-only">Close menu</span>
                  <XIcon class="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
            <div class="mt-6 flow-root">
              <div class="-my-6 divide-y divide-gray-500/10">
                <div class="space-y-2 py-6">
                    <router-link :to="{ name: 'pricing' }" class="font-semibold text-gray-900 hover:text-blue-600"> 
                        Pricing 
                    </router-link>
                  <a v-for="item in navigation" :key="item.name" :href="item.href" class="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-400/10">
                  {{ item.name }}
                  </a>
                </div>
                <div class="py-6">
                    <router-link :to="{ name: 'Login' }" class=" mx-auto lg:mx-0 hover:underline bg-blue-600 inline-block text-white font-bold rounded-full my-2 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition  hover:scale-105  duration-300  ease-in-out">
                      login
                    </router-link>
                </div>
              </div>
            </div>
          </DialogPanel>
        </Dialog>
      </div>
    </div>
    <main>
      <div class="relative px-6 lg:px-8 pb-10">
        <div :class="showMessage ? 'pt-20 sm:pt-44 pb-20 sm:pb-28' :'pt-48 pb-20 sm:pb-44'" class="mx-auto max-w-3xl">
          <div>
                <div>
                    <h1 class="text-4xl font-bold tracking-normal text-center sm:text-5xl">Managing content being written by a team is hard</h1>
                    <p class="mt-6 text-xl leading-8 text-gray-600 text-center py-5">With Sliceloop, you can easily manage how content is written and moved between different members of your writing team.</p>
                    <div class="mt-8 grid grid-cols-1 text-center gap-y-2 sm:flex gap-x-4 justify-center py-5">
                      <div>
                        <a href="/pricing" class="inline-block rounded-lg bg-blue-600 px-4 py-1.5 text-base font-semibold leading-7 text-white shadow-sm ring-1 ring-blue-600 hover:bg-blue-700 hover:ring-blue-700">
                        Get started
                        <span class="text-indigo-200" aria-hidden="true">&rarr;</span>
                        </a>
                      </div>
                      <div>
                        <a  @click="showContactForm = true" class=" cursor-pointer inline-block rounded-lg px-4 py-1.5 text-base font-semibold leading-7 text-gray-900 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                        Schedule a demo
                        <span class="text-gray-400" aria-hidden="true">&rarr;</span>
                        </a>
                      </div>
                    </div>
                </div>
                <div class="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]">
                    <svg class="relative left-[calc(50%+3rem)] h-[21.1875rem] max-w-none -translate-x-1/2 sm:left-[calc(50%+36rem)] sm:h-[42.375rem]" viewBox="0 0 1155 678" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill="url(#ecb5b0c9-546c-4772-8c71-4d3f06d544bc)" fill-opacity=".3" d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z" />
                        <defs>
                        <linearGradient id="ecb5b0c9-546c-4772-8c71-4d3f06d544bc" x1="1155.49" x2="-78.208" y1=".177" y2="474.645" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#2564eb" />
                            <stop offset="1" stop-color="#bfdbfe" />
                        </linearGradient>
                        </defs>
                    </svg>
                </div>
          </div>
        </div>
      </div>
    </main>
   </div>
    <div >
        <div class=" mx-auto sm:px-2 lg:px-4">
            <div class="w-full mx-auto px-4 lg:max-w-none">
                <div class="flex mx-auto md:w-3/4 justify-center items-center flex-col text-center">
                    <h2 class="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl" id="how-it-works">How it works</h2>
                    <p class="mt-2 text-gray-500">Sliceloop is built to allow you always have a bird eyes view of content being developed across any number of teams while equipping your writers with the tools necessary for them to get the job done</p>
                </div>
                <div class="space-y-16 pt-5 mt-8 sm:pt-10 sm:mt-5">
                    <div class="lg:grid lg:grid-cols-12 lg:gap-x-8 lg:items-center">
                        <div class="flex flex-col space-y-5 lg:col-span-5 xl:col-span-4 p-4">
                            <div v-for="(feature) in features" :key="feature.name" @click="switchFeatureImage(feature)" :class="clicked_feature == feature.id? 'bg-gray-100 border': ''" class="mt-6 lg:mt-0 lg:col-span-5 xl:col-span-4 border border-gray-200 rounded-lg shadow-md p-6 hover:bg-gray-100 cursor-pointer">
                                <h3  :class="clicked_feature == feature.id? 'text-blue-600': 'text-gray-900'" class="text-lg font-medium ">{{ feature.name }}</h3>
                                <p   :class="clicked_feature == feature.id? 'text-blue-600': 'text-gray-500'" class="mt-2 text-sm ">{{ feature.description }}</p>
                            </div>
                        </div>
                        <div class="lg:col-span-7 xl:col-span-8 lg:-mt-20">
                           <div class="flex space-x-3 justify-center items-center my-3 mb-10">
                                <button type="button" :class="clicked_feature == 0? 'bg-blue-600': 'bg-gray-300'"  @click="switchFeatureImage(features[0])" class="w-5 h-5 rounded-full"></button>
                                <button type="button" :class="clicked_feature == 1? 'bg-blue-600': 'bg-gray-300'"  @click="switchFeatureImage(features[1])" class="w-5 h-5 rounded-full"></button>
                                <button type="button" :class="clicked_feature == 2? 'bg-blue-600': 'bg-gray-300'"  @click="switchFeatureImage(features[2])" class="w-5 h-5 rounded-full"></button>
                                <button type="button" :class="clicked_feature == 3? 'bg-blue-600': 'bg-gray-300'"  @click="switchFeatureImage(features[3])" class="w-5 h-5 rounded-full"></button>
                                <button type="button" :class="clicked_feature == 4? 'bg-blue-600': 'bg-gray-300'"  @click="switchFeatureImage(features[4])" class="w-5 h-5 rounded-full"></button>
                            </div>
                            <div v-show="clicked_feature == 0" class="aspect-w-5 aspect-h-2 rounded-lg border-l border-r border-b bg-gray-100 overflow-hidden">
                                <img :src="features[0].imageSrc" :alt="features[0].imageAlt" class="object-center object-contain h-fit" />
                            </div>
                            <div v-show="clicked_feature == 1" class="aspect-w-5 aspect-h-2 rounded-lg border-l border-r border-b bg-gray-100 overflow-hidden">
                                <img :src="features[1].imageSrc" :alt="features[1].imageAlt" class="object-center object-contain h-fit" />
                            </div>
                            <div v-show="clicked_feature == 2" class="aspect-w-5 aspect-h-2 rounded-lg border-l border-r border-b bg-gray-100 overflow-hidden">
                                <img :src="features[2].imageSrc" :alt="features[2].imageAlt" class="object-center object-contain h-fit" />
                            </div>
                            <div v-show="clicked_feature == 3" class="aspect-w-5 aspect-h-2 rounded-lg border-l border-r border-b bg-gray-100 overflow-hidden">
                                <img :src="features[3].imageSrc" :alt="features[3].imageAlt" class="object-center object-contain h-fit" />
                            </div>
                            <div v-show="clicked_feature == 4" class="aspect-w-5 aspect-h-2 rounded-lg border-l border-r border-b bg-gray-100 overflow-hidden">
                                <img :src="features[4].imageSrc" :alt="features[4].imageAlt" class="object-center object-contain h-fit" />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="mt-20 sm:mt-10 pt-10">
        <div class=" mx-auto sm:px-2 lg:px-4">
            <div class="w-full mx-auto px-4 lg:max-w-none">
                <div class="flex mx-auto sm:w-1/2 justify-center items-center flex-col text-center">
                    <h2 class="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl" id="features">Features</h2>
                </div>
                <div class="space-y-16 pt-5 mt-5 sm:pt-5 sm:mt-5">
                    <div  class="grid grid-cols-1 w-full gap-x-10 sm:grid-cols-2 md:grid-cols-3">
                        <div v-for="feature in features1" :key="feature.name" class="w-full my-3 mt-6 lg:mt-0  border border-gray-200 rounded-lg shadow-md p-6 hover:bg-gray-100">
                            <h3 class="text-lg font-medium text-blue-600">{{ feature.name }}</h3>
                            <p class="mt-2 text-sm text-gray-500">{{ feature.description }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <footer class="bg-white mt-20 border-t border-t-gray-400 mx-16">
        <div class="container mx-auto px-8">
            <div class="w-full flex flex-col md:flex-row py-6">
                <div class="flex-1 mb-6 text-black">
                    <a  class=" no-underline hover:no-underline font-bold text-2xl lg:text-4xl text-blue-600" href="#">
                        Sliceloop
                    </a>
                </div>
                <div class="flex-1">
                <p class="uppercase font-medium text-gray-900 md:mb-6">Legal</p>
                <ul class="list-reset mb-6 pl-0 text-gray-500 ml-2">
                    <li class="mt-2 inline-block mr-2 md:block md:mr-0">
                        <router-link
                            :to="{ name: 'terms' }"
                            class="
                            no-underline
                            hover:underline
                            text-gray-800
                            hover:text-blue-500
                            "
                            >Terms
                        </router-link>
                    </li>
                    <li class="mt-2 inline-block mr-2 md:block md:mr-0">
                    <router-link
                        :to="{ name: 'policy' }"
                        class="
                        no-underline
                        hover:underline
                        text-gray-800
                        hover:text-blue-500
                        "
                        >Privacy</router-link
                    >
                    </li>

                    <li class="mt-2 inline-block mr-2 md:block md:mr-0">
                    <router-link
                        :to="{ name: 'faq' }"
                        class="
                        no-underline
                        hover:underline
                        text-gray-800
                        hover:text-blue-500
                        "
                        >FAQ</router-link
                    >
                    </li>
                </ul>
                </div>
                <div class="flex-1">
                <p class="uppercase font-medium text-gray-900 md:mb-6 ">Social</p>
                <ul class="list-reset mb-6 pl-0 ml-2">
                    <li class="mt-2 inline-block mr-2 md:block md:mr-0">
                        <a
                            href="https://twitter.com/sliceloop"
                            class="
                            no-underline
                            hover:underline
                            text-gray-800
                            hover:text-blue-500
                            "
                            >Twitter</a>
                    </li>
                </ul>
                </div>
            </div>
        </div>
        <p class="text-center pb-5">© 2022 Sliceloop All Rights Reserved</p>
    </footer>
   <div class="fixed sm:flex sm:justify-center sm:items-center sm:flex-wrap inset-x-0 bottom-0" v-if="showMessage">
    <div class=" bg-white border border-blue-600 sm:w-1/2">
      <div class="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
        <div class="flex flex-row-reverse sm:flex-row items-center justify-between flex-wrap">
          <div class="w-0 flex-1 flex items-center">
            <p class="ml-3 font-medium text-gray-600">
              <span>
                By continuing to use this site you agree to our
                <b>cookie policy</b> and <b>privacy policy</b>
              </span>
            </p>
          </div>
          <div class=" order-3 border border-blue-600  mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto" >
            <router-link
              :to="{ name: 'policy' }"
              class="flex items-center justify-center px-4 py-2 border sm:border sm:border-transparent rounded-md
                shadow-sm text-sm font-medium text-gray-600 bg-white hover:bg-blue-50">
              Learn more
            </router-link>
          </div>
          <div class="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
            <button
              type="button"
              class=" -mr-1 flex p-2 rounded-md bg-blue-600 hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2 "
              @click="closeMessage">
              <span class="sr-only">Dismiss</span>
              <XIcon class="h-6 w-6 text-white" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
   </div>
   <Contact
        :show-contact-form="showContactForm"
        @dismiss="showContactForm = false"
        subject="Needs to know more"
    />
</div>
</template>

<script>
import { ref, inject } from 'vue'
import { Dialog, DialogPanel } from '@headlessui/vue'
import { MenuIcon, XIcon } from "@heroicons/vue/outline";
import Footer from "../components/Footer";
import Contact from "../components/Contact";

export default {
  name: "Home2",
  components: {  Dialog, DialogPanel, MenuIcon, XIcon, Footer, Contact  },
  setup() {

        const navigation = [
        { name: 'Features', href: '#features' },
        // { name: 'Pricing', href: '#' },
        // { name: 'Contact Us', href: '/#contact' }
        ]
        const showContactForm = ref(false);
        const mobileMenuOpen = ref(false);
        const showMessage = ref(false);
        const consent = localStorage.getItem("agree");
        if (!consent) {
           showMessage.value = true;
        }

        const closeMessage = () => {
           showMessage.value = false;
           localStorage.setItem("agree", 1);
        };

        const minimize = inject('minimize');
        const onLoad = inject('onLoad');


        onLoad(() => {
          minimize();
        });

        const features = [
            {
                name: 'Create custom workflows',
                description:
                'You can streamline and define what steps/stages your content should go through',
                imageSrc: '/images/features_workflow_stages.jpg',
                imageAlt: 'photo of workflow stages',
                id : '0'
            },
            {
                name: 'Create a team and assign roles',
                description:
                "Using this team, you can then assign them roles on a task by attaching them to stage within the workflow for that particular task",
                imageSrc: '/images/features_assign_to_teams.jpg',
                imageAlt: 'Assigning team members to task',
                id : '1'
            },
            {
                name: 'Create a project',
                description:
                'When you create a project, attach a workflow to it and start adding similar/related tasks to it. You can also keep track of tasks at different stages of the workflow',
                imageSrc: '/images/features_projects_dashboard.jpg',
                imageAlt: 'projects dashboard',
                id : '2'
            },
            {
                name: 'Create a content brief',
                description:
                'Describe content priorities plus guidelines on how content should look, feel and sound',
                imageSrc: '/images/features_content_briefs.jpg',
                imageAlt: 'content brief',
                id : '3'
            },
            {
                name: 'Team members can start working on tasks',
                description:
                'Using our robust editor, team members can get to working on a writing task, viewing comments other team members have shared, view content brief to ensure they are doing it right',
                imageSrc: '/images/features_editor.jpg',
                imageAlt: 'task dashboard',
                id : '4'
            },
        ]

         const features1 = [
            {
                name: 'Content Project Management',
                description:
                "Coordinate all content creation in one place, assign content writing tasks, set deadlines, track time against individual tasks to ensure that team members aren't taking too long",
            },
            {
                name: 'Content Workflow Management',
                description:
                "Smoothen out how content is transitioned throughout your team. Clearly define and automate what stages content should go through and who is responsible for each workflow stage",
            },
            {
                name: 'Content Planning',
                description:
                'Determine which content tasks to execute and set clear expectations of how it should be executed and when.',
            },
            {
                name: 'Content Governance',
                description:
                'Using Content Briefs and Content Templates describe content priorities plus guidelines on how content should look, feel and sound',
            },
            {
                name: 'Content Team Management',
                description:
                'Organize your content staff into easily manageable content teams that you can use across any number of projects and content tasks',
            },
            {
                name: 'Content Editor',
                description:
                'Use our robust content editor that can accommodate all your content creation needs with familiar editor controls that make it easy for you to get started',
            },
            {
                name: 'Content Briefs',
                description:
                'Communicate to your writers exactly what you expect from the content they are creating and keep your content style and brand voice consistent throughout all the content that is being created.',
            },
            {
                name: 'Content Templates',
                description:
                'Ensure consistent content formatting across large volumes of content. Structure your content easily using your own custom templates and easily reproduce the same content at scale using the same standard structure',
            },
            {
                name: 'Content Calendar',
                description:
                'Visualize all your due dates, current assignments and content task statuses using the content calendar',
            },
        ]

        let features_image = ref(features[0]);
        let clicked_feature = ref('0');

        function switchFeatureImage(feature){

            //features_image.value  = feature;
            clicked_feature.value = feature.id;

        }

  return { 
           mobileMenuOpen, 
           navigation,showMessage, closeMessage, 
           features, features_image, switchFeatureImage,clicked_feature, showContactForm,
           features1
        };
  },
};
</script>