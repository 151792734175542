<template>
  <Modal :show="showContactForm" @dismiss="$emit('dismiss')">
    <template v-slot:header>
      <h5 class="modal-title text-danger capitalize">Contact us</h5>
      <br />
      <AlertSuccess
        v-if="successMessage"
        message="You message has been received our team will contact you shortly "
        @hide="successMessage = null"
      />
    </template>

    <template v-slot:body>
      <form id="support-form" action="post" @submit.prevent="submitMessage">
        <input type="hidden" placeholder="Name" v-model="name" required />

        <input type="hidden" placeholder="Name" v-model="email" required />

        <div class="relative">
          <label for="name" class="sr-only">Name:</label>
          <input
            type="text"
            placeholder="Name"
            v-model="name"
            required
            class="
              text-gray-900
              ring-gray-900 ring-opacity-5
              placeholder-gray-400
              appearance-none
              bg-white
              rounded-md
              block
              w-full
              px-3
              py-2
              border border-transparent
              shadow
              ring-1
              sm:text-sm
              mb-4
              focus:border-blue-500 focus:ring-blue-500 focus:outline-none
            "
            id="name"
          />
          <p
            v-for="(errorMessage, index) in nameMessage"
            :key="index"
            style="font-size: 0.8rem !important"
            class="text-red-500 mb-2"
          >
            {{ errorMessage }}
          </p>
        </div>
        <div class="relative">
          <label for="email" class="sr-only">Email address:</label>
          <input
            type="email"
            placeholder="Email address"
            v-model="email"
            required
            class="
              text-gray-900
              ring-gray-900 ring-opacity-5
              placeholder-gray-400
              appearance-none
              bg-white
              rounded-md
              block
              w-full
              px-3
              py-2
              border border-transparent
              shadow
              ring-1
              sm:text-sm
              mb-4
              focus:border-blue-500 focus:ring-blue-500 focus:outline-none
            "
            id="email"
          />
          <p
            v-for="(errorMessage, index) in emailMessage"
            :key="index"
            style="font-size: 0.8rem !important"
            class="text-red-500 mb-2"
          >
            {{ errorMessage }}
          </p>
        </div>

        <div class="relative">
          <label for="message" class="sr-only">Message:</label>
          <textarea
            type="text"
            placeholder="Your message"
            v-model="message"
            required
            class="
              text-gray-900
              ring-gray-900 ring-opacity-5
              placeholder-gray-400
              appearance-none
              bg-white
              rounded-md
              block
              w-full
              px-3
              py-2
              border border-transparent
              shadow
              ring-1
              sm:text-sm
              mb-4
              focus:border-blue-500 focus:ring-blue-500 focus:outline-none
            "
            cols="30"
            rows="10"
            id="message"
          ></textarea>
          <p
            v-for="(errorMessage, index) in textMessage"
            :key="index"
            style="font-size: 0.8rem !important"
            class="text-red-500 mb-2"
          >
            {{ errorMessage }}
          </p>
        </div>

        <button
          type="submit"
          :disabled="disableContactButton"
          v-bind:class="{ disabled: disableButton }"
          class="
            flex
            justify-center
            items-center
            block
            w-full
            py-2
            px-3
            border border-transparent
            rounded-md
            text-white
            font-medium
            bg-blue-500
            shadow-sm
            sm:text-sm
            mb-10
            disabled:bg-blue-200
            hover:bg-blue-600
            focus:outline-none
            focus-visible:ring-2
            focus-visible:ring-gray-700
            focus-visible:ring-offset-2
            focus-visible:ring-offset-gray-50
          "
        >
          <InlineLoader :h="25" :w="25" :show="canShow" />
          Submit Message
        </button>
      </form>
    </template>
  </Modal>
</template>

<script>
import Modal from "./Modal";
import { ref, computed } from "vue";
import AlertSuccess from "./AlertSuccess";
import InlineLoader from "./InlineLoader";
import Api from "../api/Api";
export default {
  props: ["showContactForm", "subject"],
  name: "Contact",
  components: { Modal, AlertSuccess, InlineLoader },
  setup(props) {
    const name = ref("");
    const email = ref("");
    const subject = ref("");
    const message = ref("");
    const textMessage = ref("");
    const nameMessage = ref("");
    const emailMessage = ref("");
    const canShow = ref(false);
    const disableButton = ref(true);
    const successMessage = ref();

    const disableContactButton = computed(() => {
      if (
        name.value.trim().length > 0 &&
        email.value.trim().length > 0 &&
        message.value.trim().length > 0
      ) {
        return false;
      }

      return true;
    });

    const submitMessage = () => {
      canShow.value = true;
      const msg = {
        name: name.value,
        email: email.value,
        message: message.value,
        subject: props.subject,
      };

      Api()
        .post("/contact-us", msg)

        .then((response) => {
          successMessage.value = "success";
          disableButton.value = false;
          setTimeout(() => {
            document.getElementById("support-form").reset();
            email.value = "";
            subject.value = "";
            message.value = "";
          });
        })
        .catch((error) => {
          if (error.response.status === 422) {
            nameMessage.value = error.response.data.errors.name;
            emailMessage.value = error.response.data.errors.email;
            textMessage.value = error.response.data.errors.message;
          }
          
          disableButton.value = false;
        })
        .finally(() => {
          canShow.value = false;
        });
    };

    return {
      name,
      email,
      message,
      nameMessage,
      emailMessage,
      textMessage,
      canShow,
      disableContactButton,
      disableButton,
      submitMessage,
      successMessage,
      subject,
    };
  },
};
</script>

<style scoped></style>
