<template>
  <Navbar :no-links="true" />
  <div class="bg-white">
    <div class="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-20 lg:px-8">
      <div class="lg:grid lg:grid-cols-3 lg:gap-8">
        <div>
          <h2 class="text-3xl font-extrabold text-blue-600">
            Frequently asked questions
          </h2>
          <p class="mt-4 text-lg text-gray-500">
            Can’t find the answer you’re looking for? Reach out to our
            <a
              @click="showContactForm = true"
              class="font-medium text-blue-600 hover:text-blue-500 cursor-pointer"
              >customer support</a
            >
            team.
          </p>
        </div>
        <div class="mt-12 lg:mt-0 lg:col-span-2">
          <dl class="space-y-12">
            <div v-for="faq in faqs" :key="faq.question">
              <dt class="text-lg leading-6 font-medium text-gray-900">
                {{ faq.question }}
              </dt>
              <dd class="mt-2 text-base text-gray-500" v-html="faq.answer"></dd>
            </div>
            <div>
              <dt class="text-lg leading-6 font-medium text-gray-900">
                How can I contact sliceloop?
              </dt>
              <dd class="mt-2 text-base text-gray-500">
                <p class="c1">
                  <span>You can contact us using this form: </span>
                  <span class="c11">
                    <a class="font-medium text-blue-600 hover:text-blue-500 cursor-pointer" @click="showContactForm = true">
                      contact us
                      </a>
                  </span><span class="c2">&nbsp;</span></p>
              </dd>
            </div>
            
          </dl>
        </div>
      </div>
    </div>
  </div>
  <Contact
    :show-contact-form="showContactForm"
    @dismiss="showContactForm = false"
    subject="Needs Support"
  />
  <Footer />
</template>

<script>
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { ref } from "vue";
import Contact from "../components/Contact";

const faqs = [
  {
    question: "What is sliceloop?",
    answer:
      "Sliceloop is a content operations management platform that helps you produce text-based content much faster. With sliceloop, you can manage your writing projects at scale while maintaining the quality and structure of your text.",
  },

  {
    question: "What is content operations?",
    answer: `Content operations is the set of processes, people, and technologies needed for the effective planning, production, management, and analysis of all content types used in an enterprise or project. \n

Simply put, it is the management of all activities related to the production of content. \n

Good content operations management ensures that high quality content is produced using an efficient scalable content pipeline that adheres to enterprise content guidelines.
`,
  },

  {
    question: "How can sliceloop help me?",
    answer: `
    <p class="c1"><span class="c2">Sliceloop can help you in many ways. </span></p>
<ul class="c8 lst-kix_4ojicnosyg19-0 start list"><li class="c1 c4 li-bullet-0"><span class="c2">You can create a content workflow for your projects that your content team will have to follow. This ensures you do not miss any step in your content management process</span></li><li class="c1 c4 li-bullet-0"><span class="c2">You can apply project management to your content tasks and know who is doing what and whether they are meeting deadlines</span></li><li class="c1 c4 li-bullet-0"><span class="c2">You can define what structure your content should have using our content template feature. This will ensure your writers never stray away from your desired content structure </span></li><li class="c1 c4 li-bullet-0"><span class="c2">You can group people into teams and use that team across any number of projects. This makes it easy for you to assign tasks to different teams.</span></li></ul>
 There are many other ways sliceloop can help and we do encourage you to try it out and find out more
<p class="c1"><span class="c2">There are many other ways sliceloop can help and we do encourage you to try it out and find out more</span></p>
    `,
  },

  {
    question: "Who is sliceloop meant for? Who can use sliceloop?",
    answer:
      "Sliceloop is meant for anyone who has content they would like to create, manage and distribute quickly. This includes writers, content teams, legal teams, freelancers, agencies and organizations that want to gain control of their content operations.",
  },

  // {
  //   question: "Is sliceloop still in beta?",
  //   answer:
  //     "Yes, sliceloop is still in beta and we are continuously engaging any early adopters to help us fine tune and build a product that can further help them manage their content operations efficiently.",
  // },

  // {
  //   question: "How can I join your beta program?",
  //   answer:
  //     "Currently, all you have to do is sign up and get started. That's it.",
  // },

  // {
  //   question: "What kind of beta testers or users are you looking for?",
  //   answer: `We are looking for writers, bloggers, content teams, documenters, legal teams, content marketers, content design specialists etc that want to gain control of the content or text that they produce. \n
  //      We are looking for anyone that wants to manage the entire process of content operations management efficiently including those who want to do it at scale.
  //      `,
  // },

  {
    question: "What kind of content can I manage with sliceloop?",
    answer: `
    <p class="c1"><span class="c2">With sliceloop, you can manage;</span></p>
    <ul class="c8 lst-kix_7r6dq5luoagl-0 start list"><li class="c1 c4 li-bullet-0"><span class="c2">RFQs</span></li><li class="c1 c4 li-bullet-0"><span class="c2">Contracts,</span></li><li class="c1 c4 li-bullet-0"><span class="c2">Product Specifications</span></li><li class="c1 c4 li-bullet-0"><span class="c2">Blog Content</span></li><li class="c1 c4 li-bullet-0"><span class="c2">Speeches</span></li><li class="c1 c4 li-bullet-0"><span class="c2">Requirement Specifications</span></li><li class="c1 c4 li-bullet-0"><span class="c2">Design Documents</span></li><li class="c1 c4 li-bullet-0"><span class="c2">Legal Documents</span></li><li class="c1 c4 li-bullet-0"><span class="c2">White papers</span></li><li class="c1 c4 li-bullet-0"><span class="c2">Product manuals</span></li><li class="c1 c4 li-bullet-0"><span class="c2">Menus</span></li><li class="c1 c4 li-bullet-0"><span class="c2">etc</span></li></ul>
    `,
  },
  {
    question: "How can I get started?",
    answer:
      "Just signup and work with us as we build the future of efficient content operations management",
  },
  {
    question:
      "How can I reach out to sliceloop for feature requests, bug reports or support?\n",
    answer:
      "Signup or login into sliceloop and click on the request a feature / support button to contact us",
  }
];

export default {
  components: { Navbar, Footer, Contact },
  name: "Faq",
  setup() {
    const showContactForm = ref(false);
    return { faqs, showContactForm };
  },
};
</script>

<style>
ul.list {
  list-style-type: disc;
  margin-left: 2rem;
}
ul li {
  margin: 0.5rem 0;
}
</style>
