<template>
  <div class="min-h-screen">
    <div class="flex justify-center mb-4">
      <div class="flex mr-10 items-center">
        <label
          for="myTasks"
          class="
            h-8
            w-8
            mr-2
            my-tasks
            justify-center
            items-center
            flex
            cursor-pointer
          "
        >
          <input
            v-model="myTasks"
            id="myTasks"
            type="checkbox"
            class="h-4 w-4 cursor-pointer"
          />
        </label>
        <p>My Tasks</p>
      </div>
      <div class="flex items-center">
        <label
          for="otherTasks"
          class="
            h-8
            w-8
            mr-2
            other-tasks
            flex
            justify-center
            items-center
            cursor-pointer
          "
        >
          <input
            v-model="otherTasks"
            id="otherTasks"
            type="checkbox"
            class="h-4 w-4 cursor-pointer"
          />
        </label>
        <p>Tasks Assigned to me</p>
      </div>
    </div>
    <calendar-view
      :show-date="showDate"
      class="
        theme-default
        holiday-us-traditional holiday-us-official
        capitalize
      "
      @click-item="itemClicked"
      :items="items"
    >
      <template #header="{ headerProps }">
        <calendar-view-header
          :header-props="headerProps"
          @input="setShowDate"
        />
      </template>
    </calendar-view>
    <Modal :show="showItem" @dismiss="showItem = false">
      <template v-slot:header>
        <h5 class="capitalize">{{ taskObj.title }}</h5>
      </template>

      <template v-slot:body>
        <table class="min-w-full divide-gray-200">
          <thead :class="taskObj.classes[0]">
            <tr>
              <th
                scope="col"
                class="
                  px-6
                  py-3
                  text-center text-xs
                  font-medium
                  text-gray-500
                  uppercase
                  tracking-wider
                "
              >
                Stage
              </th>

              <th
                scope="col"
                class="
                  px-6
                  py-3
                  text-center text-xs
                  font-medium
                  text-gray-500
                  uppercase
                  tracking-wider
                "
              >
                Status
              </th>
              <th
                scope="col"
                class="
                  px-6
                  py-3
                  text-center text-xs
                  font-medium
                  text-gray-500
                  uppercase
                  tracking-wider
                "
              >
                Assignee Name
              </th>

              <th
                scope="col"
                class="
                  px-6
                  py-3
                  text-center text-xs
                  font-medium
                  text-gray-500
                  uppercase
                  tracking-wider
                "
              >
                Assigned On
              </th>

              <th
                scope="col"
                class="
                  px-6
                  py-3
                  text-center text-xs
                  font-medium
                  text-gray-500
                  uppercase
                  tracking-wider
                "
              >
                Due Date
              </th>
            </tr>
          </thead>
          <tbody class="bg-white divide-gray-200 capitalize">
            <tr>
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {{ taskObj.originalItem.stageName }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                <span
                  class="
                    px-2
                    inline-flex
                    text-xs
                    leading-5
                    font-semibold
                    rounded-full
                    bg-yellow-100
                    text-yellow-800
                  "
                  v-if="
                    taskObj.originalItem.statusName.toLowerCase() === 'pending'
                  "
                >
                  {{ taskObj.originalItem.statusName }}
                </span>

                <span
                  class="
                    px-2
                    inline-flex
                    text-xs
                    leading-5
                    font-semibold
                    rounded-full
                    bg-green-100
                    text-green-800
                  "
                  v-else-if="
                    taskObj.originalItem.statusName.toLowerCase() === 'done'
                  "
                >
                  {{ taskObj.originalItem.statusName }}
                </span>

                <span
                  class="
                    px-2
                    inline-flex
                    text-xs
                    leading-5
                    font-semibold
                    rounded-full
                    bg-blue-100
                    text-blue-800
                  "
                  v-else-if="
                    taskObj.originalItem.statusName.toLowerCase() ===
                    'in progress'
                  "
                >
                  {{ taskObj.originalItem.statusName }}
                </span>

                <span v-else>
                  {{ taskObj.originalItem.statusName }}
                </span>
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {{ taskObj.originalItem.name }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {{ taskObj.originalItem.assignedOn }}
              </td>

              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {{ taskObj.originalItem.startDate }}
              </td>
            </tr>
          </tbody>
        </table>
      </template>
    </Modal>
  </div>
</template>

<script>
import { CalendarView, CalendarViewHeader } from "vue-simple-calendar";
import "../../node_modules/vue-simple-calendar/dist/style.css"
import { inject, ref, watch } from "vue";
import Api from "@/api/Api.js";
import Modal from "./Modal";
export default {
  name: "Calender",
  components: { CalendarView, CalendarViewHeader, Modal },
  setup() {
    const showDate = ref(new Date());
    const showItem = ref(false);
    const taskObj = ref({});
    const user = inject("user");
    const items = ref([]);
    const myTasks = ref(true);
    const otherTasks = ref(true);
    const tasksAssignedByMe = ref([]);
    const tasksAssignedToMe = ref([]);
    const setShowDate = (d) => {
      showDate.value = d;
    };

    const itemClicked = (calendarItem) => {
      taskObj.value = calendarItem;
      showItem.value = true;
    };

    const getUserCalendar = () => {
      Api()
        .get(`/tasks/show-calendar/${user.id}`)
        .then((res) => {
          for (const resKey in res.data.tasksCreatedByMe) {
            const t = {
              id: res.data.tasksCreatedByMe[resKey].teamId,
              startDate: res.data.tasksCreatedByMe[resKey].dueDate,
              title: res.data.tasksCreatedByMe[resKey].taskTitle,
              assignedOn: res.data.tasksCreatedByMe[resKey].assignedOn,
              name: res.data.tasksCreatedByMe[resKey].name,
              statusName: res.data.tasksCreatedByMe[resKey].statusName,
              stageName: res.data.tasksCreatedByMe[resKey].stageName,
              classes: ["my-tasks"],
            };
            items.value.push(t);
            tasksAssignedByMe.value.push(t);
          }

          for (const resKey in res.data.tasksAssignedToMe) {
            const t = {
              id: res.data.tasksAssignedToMe[resKey].teamId,
              startDate: res.data.tasksAssignedToMe[resKey].dueDate,
              title: res.data.tasksAssignedToMe[resKey].taskTitle,
              assignedOn: res.data.tasksAssignedToMe[resKey].assignedOn,
              name: res.data.tasksAssignedToMe[resKey].name,
              statusName: res.data.tasksAssignedToMe[resKey].statusName,
              stageName: res.data.tasksAssignedToMe[resKey].stageName,

              classes: [
                "other-tasks",
                res.data.tasksAssignedToMe[resKey].statusName,
              ],
            };

            items.value.push(t);
            tasksAssignedToMe.value.push(t);
          }
        });
    };

    watch([myTasks, otherTasks], ([t1New, t2New]) => {
      if (!t1New && t2New) {
        items.value = tasksAssignedToMe.value;
      }

      if (t1New && !t2New) {
        items.value = tasksAssignedByMe.value;
      }

      if (t1New && t2New) {
        items.value = [].concat(
          tasksAssignedByMe.value,
          tasksAssignedToMe.value
        );
      }

      if (!t1New && !t2New) {
        items.value = [];
      }
    });

    getUserCalendar();
    return {
      showDate,
      setShowDate,
      itemClicked,
      showItem,
      taskObj,
      items,
      myTasks,
      otherTasks,
    };
  },
};
</script>

<style>
.custom-calendar.vc-container {
  --day-border: 1px solid #b8c2cc;
  --day-border-highlight: 1px solid #b8c2cc;
  --day-width: 90px;
  --day-height: 90px;
  --weekday-bg: #f8fafc;
  --weekday-border: 1px solid #eaeaea;
  border-radius: 0;
  width: 100%;
}

.vc-header {
  background-color: #f1f5f8;
  padding: 10px 0;
}
.vc-weeks {
  padding: 0;
}
.vc-weekday {
  background-color: #f8fafc;
  border-bottom: 1px solid #eaeaea;
  border-top: 1px solid #eaeaea;
  padding: 5px 0;
}
.vc-day {
  padding: 0 5px 3px 5px;
  text-align: left;
  height: 90px;
  min-width: 90px;
  background-color: white;
}
.weekday-1,
.weekday-7 {
  background-color: #eff8ff;
}
.custom-calendar :not(.on-bottom) {
  border-bottom: 1px solid #b8c2cc;
}
.custom-calendar :not(.on-bottom) .weekday-1 {
  border-bottom: 1px solid #b8c2cc;
}
.custom-calendar :not(.on-right) {
  border-right: 1px solid #b8c2cc;
}

.vc-day-dots {
  margin-bottom: 5px;
}
.cv-wrapper {
  height: 80vh;
}

.cv-header-days {
  background-color: #3b82f6;
  color: #ffffff;
}

.cv-item {
  border-width: 0 !important;
  font-size: 0.9rem !important;
  cursor: pointer;
}

.cv-item.my-tasks,
.my-tasks {
  background-color: #eef6ff;
  color: #3b82f6;
}

.my-tasks.done::after,
.other-tasks.done::after {
  content: "✓";
  float: right;
  margin-right: 0.5rem;
}

.cv-item.other-tasks,
.other-tasks {
  background-color: #fff8c0;
  color: #8a5e25;
}

.yt {
  background-color: #fff8c0;
}

.gn {
  background-color: #dbfce6;
}
</style>
