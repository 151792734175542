<template>
<Navbar v-if="!params.t"/>
  <div class="bg-white">
    <div class="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
      <div class="col-sm-12" v-if="params.t">
        <button class="capitalize flex text-blue-500 hover:underline" @click="$router.back()">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true" class="mr-2 h-6 w-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M10 19l-7-7m0 0l7-7m-7 7h18"></path>
            </svg> 
            Back To Billing  
        </button>
      </div>
      <div class="sm:flex sm:flex-col sm:align-center">
        <h1 class="text-5xl font-extrabold text-blue-600 sm:text-center">Pricing Plans</h1>
        <AlertError
                v-if="errorMessage"
                :message="[errorMessage,projectErrorMessage,taskErrorMessage,templateErrorMessage]"
                @hide="errorMessage = null"
         />
        <div class="relative self-center mt-6 bg-gray-100 rounded-lg p-0.5 flex sm:mt-8">
          <button type="button" @click="period='m'" :class="period=='m'? 'bg-white':''" class="relative w-1/2 border-gray-300 rounded-md shadow-sm py-2 text-sm font-medium text-gray-900 whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-blue-600 focus:z-10 sm:w-auto sm:px-8">Monthly billing</button>
          <button type="button" @click="period='y'" :class="period=='y'? 'bg-white':''" class="ml-0.5 relative w-1/2 border border-transparent rounded-md py-2 text-sm font-medium text-gray-700 whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-blue-600 focus:z-10 sm:w-auto sm:px-8">Yearly billing</button>
        </div>
        <div class="text-center mt-10 text-xs bg-blue-100">
            <div class="revin-checkout-notice"></div>
        </div>
      </div>
      <div class="mt-10 space-y-4 sm:mt-14 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-4">
        <div v-for="tier in tiers" :key="tier.name" class="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200">
          <div class="p-6">
            <h2 class="text-lg leading-6 font-medium text-gray-900">{{ tier.name }}</h2>
            <p class="mt-4 text-sm text-gray-500">{{ tier.description }}</p>
            <p class="mt-8"  v-if="period === 'm'">
              <span class="text-4xl font-extrabold text-gray-900">${{ tier.priceMonthly }}</span>
              {{ ' ' }}
              <span class="text-base font-medium text-gray-500">/mo</span>
            </p>
            <p class="mt-8" v-else>
              <span class="text-4xl font-extrabold text-gray-900">${{ tier.priceYearly }}</span>
               {{ ' ' }}
              <span class="text-base font-medium text-gray-500">/yr</span>
            </p>
            <template v-if="params.t">
              <button  v-if="tier.name !== 'Free' &&  tier.name.toLowerCase() !== params.c "  @click="subscribeUser(tier,true)" class="mt-8 block w-full bg-blue-600 border border-blue-600 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-blue-500">Subscribe</button>
            </template>
            <template  v-else>
              <button  @click="subscribeUser(tier)" class="mt-8 block w-full bg-blue-600 border border-blue-600 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-blue-500">Subscribe</button>
            </template>
            <!-- <a :href="tier.href" class="mt-8 block w-full bg-gray-800 border border-gray-800 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-900">Buy {{ tier.name }}</a> -->
          </div>
          <div class="pt-6 pb-8 px-6">
            <h3 class="text-xs font-medium text-gray-900 tracking-wide uppercase">What's included</h3>
            <ul role="list" class="mt-6 space-y-4">
              <li v-for="feature in tier.includedFeatures" :key="feature" class="flex space-x-3">
                <CheckIcon class="flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" />
                <span class="text-sm text-gray-500">{{ feature }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CheckIcon } from '@heroicons/vue/solid'
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import {ref,inject,onMounted} from 'vue';
import {useRouter,useRoute} from "vue-router";
import Api from "../api/Api";
import { useLoading } from "vue-loading-overlay";
import AlertError from "../components/AlertError";


export default {
  name: "Pricing",
  
  components: {
    CheckIcon,
    Navbar,
    Footer,
    AlertError
  },
  
  setup() {

    const tiers = [
    {
        name: 'Free',
        href: '#',
        description: '',
        includedFeatures: [
        '1 Project incl. archived', 
        '5 content tasks per project',
        '1 Template',
        'Unlimited Users',
        'Unlimited Workflows',
        'Comments',
        'Editorial calendar',
        'Reports',
        'Revision history',
        'Archive projects',
        ],
        PriceIdMonthly: '#',
        PriceIdYearly: '#',
        priceMonthly: 0,
        priceYearly: 0,
    },
    {
        name: 'Starter',
        href: '#',
        description: '',
        includedFeatures: [
        'Everything in Free',
        '5 Projects incl. archived', 
        '60 content tasks per project',
        '3 Templates',
        ],
        PriceIdMonthly: 'price_1LRtqUEIoIK7zESbnVodX9D1', 
        PriceIdYearly:  'price_1LRtqUEIoIK7zESbdKhZQvaD', 
        priceMonthly: 5,
        priceYearly: 60,
    },
    {
        name: 'Standard',
        href: '#',
        description: '',
        includedFeatures: [
        'Everything in Free',
        '15 Projects incl. archived', 
        '120 content tasks per project',
        '10 Templates',
        ],
        PriceIdMonthly: 'price_1LRty8EIoIK7zESbQQZsk6ts', 
        PriceIdYearly:  'price_1LRty8EIoIK7zESbDY9jvdTF', 
        priceMonthly: 17,
        priceYearly: 204,
    },
    {
        name: 'Pro',
        href: '#',
        description: '',
        includedFeatures: [
        'Everything in Free',
        '60 Projects incl. archived', 
        '360 content tasks per project',
        '30 Templates',
        ],
        PriceIdMonthly: 'price_1LRtyYEIoIK7zESbz9LEOxdt', 
        PriceIdYearly:  'price_1LRtyYEIoIK7zESbmjYVhynE', 
        priceMonthly: 59,
        priceYearly: 708,
    },
    ]
    const period = ref('m');
    const route = useRoute();
    const subscriptionPackage = inject('subscriptionPackage');
    const subscriptionPeriod = inject('subscriptionPeriod');
    const client_secret = inject('client_secret');
    const errorMessage = ref();
    let projectErrorMessage = ref(null);
    let templateErrorMessage = ref(null);
    let taskErrorMessage = ref(null);
    const $loading = useLoading();
    const router = useRouter();
    const params = ref({t:null,c:null});
    onMounted(()=>{

      params.value = route.query
    })
    const subscribeUser = (subscription,update=false) => {
        subscriptionPackage.value = subscription;
        subscriptionPeriod.value = period.value;
      if (update){
        const user =  JSON.parse(localStorage.getItem("user"));
        const loader = $loading.show({
          color: "#2581ef",
          opacity: 1,
        });

        projectErrorMessage.value = null;
        taskErrorMessage.value = null;
        templateErrorMessage.value = null;

        Api().patch(`/subscription/check-user-tier-usage/${user.id}`,{
          priceId: (period.value === 'm')? subscriptionPackage.value.PriceIdMonthly : subscriptionPackage.value.PriceIdYearly
        }).then(res => {
          if (res.data.status === 'success'){
            Api().get(`/subscription/get-setup-intent/${user.id}`).then((response)=>{
              client_secret.value =  response.data.intent.client_secret;
              router.push({name:'payments'})
                 }).finally(()=>{
                   loader.hide();
            });
          }else{
            
            errorMessage.value = res.data.message;

            if(res.data.project_message){
               projectErrorMessage.value = ">> "+res.data.project_message
            }

            if(res.data.task_message){
              taskErrorMessage.value = "";
              for(var i = 0; i < res.data.task_message.length; i++){
                
                taskErrorMessage.value = taskErrorMessage.value + "\t- "+ res.data.task_message[i] + "\n"

              }
            }

            if(res.data.template_message){
              templateErrorMessage.value = '>> '+ res.data.template_message
            }

            loader.hide();
          }
        }).catch (error => {
          loader.hide();
        })

      }else{
        router.push({name:'register'})
      }

      return;
    }




    return {
      tiers,
      period,
      subscribeUser,
      params,
      errorMessage,
      projectErrorMessage,
      taskErrorMessage,
      templateErrorMessage
    }
  },
}
</script>