<template>
  <div
    class="
      d-flex
      flex-column
      min-vh-100
      justify-content-center
      align-items-center
    "
  >
    <!-- <h1>Verify Account</h1> -->
    <p :class="error ? 'text-danger' : 'blink'">{{ loadingMessage }}</p>
    <p v-if="msg">{{ msg }}</p>
    <!-- <button class="btn btn-primary">Get Started</button> -->
  </div>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import { ref } from "vue";
import Api from "@/api/Api.js";

export default {
  setup() {
    const route = useRoute();
    const router = useRouter();

    let loadingMessage = ref("Verifying Email Address....");
    let error  = ref(false);
    let msg  = ref(null);

    const verification_code = route.params.verification_code;

    function verifyAccount(verification_code) {
      Api()
        .get(`/verify/${verification_code}`)
        .then((response) => {
          
          let status = response.data.status;
          
          if(status === "success"){
              loadingMessage.value = "Your email has been succesfully verified!"
              msg.value = "Redirecting to login page..."
              router.push({ name: "Login" });
          }else{
              error.value = true;
              loadingMessage.value = "An Error Occurred! Please contact Support for further assistance"
          }
          
        })
        .catch((error) => {

            

        });

    }

    verifyAccount(verification_code);

    return {
        loadingMessage,
        error,
        msg
    };
  },
};
</script>

<style>
.blink {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
</style>