<template>
  <router-view />
</template>
<script>
import {provide,ref} from 'vue';
export default {
  setup(){
    const subscriptionPackage = ref();
    const subscriptionPeriod = ref();
    const client_secret = ref();
    provide('subscriptionPackage',subscriptionPackage);
    provide('subscriptionPeriod',subscriptionPeriod);
    provide('client_secret',client_secret);

  }
}

</script>
<style>
.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}

.ProseMirror {
  min-height: 60vh;
  min-width: 100%;
}
.v-tour .v-step{
  background:#3b82f6 !important;
  border-radius: 1rem !important;
}
.v-step__content{
  margin-bottom: 0 !important;
}
.v-tour .v-step__buttons{
  display:  none !important;
}
.v-tour .v-step__arrow{
  border-color: #3b82f6 !important;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
}
</style>
