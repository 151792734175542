<template>
  <div>
    <div class="px-4 sm:px-6 md:px-8 mb-10">
      <button
          class="capitalize flex text-blue-500 hover:underline float-left -ml-4 mr-10 items-center"
          @click="$router.back()"
      >
        <ArrowLeftIcon class="mr-2 h-6 w-6" />
        back
      </button>
      <h1 class="text-2xl font-semibold text-gray-700">Your Report</h1>
    </div>
  </div>

  <div class="mt-12 flex justify-center">
    <div class="flex flex-col w-full mr-2">
      <h3 class="text-lg leading-6 font-medium text-gray-900  text-center">
        Team Member Details for <span class="text-blue-500">{{$route.params.name}}</span>
      </h3>
      <div class="my-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="shadow overflow-hidden border border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="capitalize">
              <tr>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">
                  Task Title
                </th>

                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Assigned On
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">
                  Days Before Due Date
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">
                  Days Taken To Complete Task
                </th>

                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">
                  Hours Before Due Date
                </th>

                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">
                  Task Due Date
                </th>

                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">
                  Time Taken To Complete Task
                </th>

                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">
                  Completed On
                </th>


                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">
                  Stage Name
                </th>


              </tr>
              </thead>
              <tbody class="bg-white">
              <tr v-for="details in memberDetails" :key="details.memberId">
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500  text-center">
                  {{ details.taskTitle }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500 ">
                  {{ formatDate(details.AssignedOnByProjectOwner) }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500  text-center">
                  {{ details.DaysBeforeDueDate }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500  text-center">

                    {{ details.DaysTakenToCompleteTask }}


                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500  text-center">

                    {{ details.HoursBeforeDueDate }}


                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500  text-center">
                 <span v-if="details.TaskDueDate">
                   {{ formatDate(details.TaskDueDate) }}
</span>


                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500  text-center">

                    {{ details.TimeTakenToCompleteTask }}


                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500  text-center">

                  <span v-if="details.completedOn">  {{ formatDate(details.completedOn) }}</span>



                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500  text-center">


                    {{ details.stageName }}


                </td>


              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>


</template>

<script>
import Api from "../api/Api";
import {useRoute} from "vue-router";
import {inject,ref} from "vue";
import {ArrowLeftIcon} from "@heroicons/vue/outline";
import {useLoading} from "vue-loading-overlay";


export default {
  name: "MemberDetails",
  components:{ArrowLeftIcon},
  setup() {
    const user  =  inject('user');
    const route = useRoute();
    const memberDetails= ref();
    const $loading = useLoading();
    const getReportMemberDetailSummary = () => {
      const loader = $loading.show({
        color: "#2581ef",
        opacity: 1,
      });
      Api()
          .get(`/reports/show-team-member-in-project-summary/${user.id}/${route.params.project}/${route.params.team}/${route.params.id}`)
          .then((response) => {
            if (response.data.status === "success") {
              memberDetails.value = response.data.report
            }
          })
          .catch((error) => {})
          .finally(() => {
            loader.hide();
          });
    }
    getReportMemberDetailSummary();

    function formatDate(date) {
      var d1 = new Date(date);
      var ms = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      return d1.getDate() + " " + ms[d1.getMonth()] + " " + d1.getFullYear();
    }

    return {
      memberDetails,
      formatDate
    }
  }
}
</script>

<style scoped>

</style>