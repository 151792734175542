import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import VCalendar from "v-calendar";
import VueLoading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import VueGtag from "vue-gtag";
import Vue3Tour from 'vue3-tour'
import 'vue3-tour/dist/vue3-tour.css'
import TawkMessengerVue from '@tawk.to/tawk-messenger-vue-3';
import './input.css'

const app = createApp(App)
  .use(router)
  .use(VCalendar, {})
  .use(Vue3Tour)
  .use(VueLoading)
  .use(
    VueGtag,
    {
      config: { id: "G-90ZPZ7HZSC" },
    },
    router
  )
  .use(TawkMessengerVue, {

    propertyId: '634d86bab0d6371309c9ff34',
    widgetId: '1gfjdcimq'

  });
 app.provide('tours', app.config.globalProperties.$tours);
app.mount("#app");
