<template>
  <div>
    <div class="px-4 sm:px-6 md:px-8 mb-10">
      <h1 class="text-2xl font-semibold text-gray-700">Your Report</h1>
    </div>
  </div>

  <div>
    <h3 class="text-lg leading-6 font-medium text-gray-900">
      Summary
    </h3>
    <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
      <div v-for="item in stats" :key="item.id" class="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden border">
        <dt>
          <div class="absolute bg-blue-500 rounded-md p-3">
            <component :is="item.icon" class="h-6 w-6 text-white" aria-hidden="true" />
          </div>
          <p class="ml-16 text-sm font-medium text-gray-500 truncate">{{ item.name }}</p>
        </dt>
        <dd class="ml-16 pb-6 flex items-baseline sm:pb-7">
          <p class="text-2xl font-semibold text-gray-900">
            {{ item.stat }}
          </p>
        </dd>
      </div>
    </dl>
  </div>

  <div class="mt-12 flex">
    <div class="flex flex-col">
      <h3 class="text-lg leading-6 font-medium text-gray-900 ">
        Your Projects
      </h3>
      <div class="my-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="shadow overflow-hidden border border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead>
              <tr>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Project Name
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Number Of Tasks
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Number Of Teams
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                </th>
              </tr>
              </thead>
              <tbody class="bg-white">
              <tr v-for="project in yourProjects" :key="project.project_id">
                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {{ project.ProjectName }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500  text-center">
                  {{ project.NumberOfTasks }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500  text-center">
                  {{ project.NumberOfTeams }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500  text-center">
                  <router-link class="text-blue-500 hover:text-blue-600 hover:underline" :to="{ name: 'project-report', params: { id: project.project_id,project: project.ProjectName.replaceAll(' ' ,'-') }}">
                    View Team
                  </router-link>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>


</template>

<script>
import Api from "../api/Api";
import {inject,ref} from 'vue';
import { UsersIcon,HomeIcon, ClipboardListIcon} from '@heroicons/vue/solid';
  import {useLoading} from "vue-loading-overlay";


export default {
  name: "MyReport",
  setup(){
    const user  =  inject('user');
    const $loading = useLoading();
    const stats =ref( [
      { name: 'Total Projects', stat: '0',icon:HomeIcon },
      { name: 'Total Tasks', stat: '0',icon:ClipboardListIcon },
    ]);
    const yourProjects  = ref();

    const people = [
      { name: 'Jane Cooper', title: 'Regional Paradigm Technician', role: 'Admin', email: 'jane.cooper@example.com' },
      // More people...
    ]

    const getReportSummary = () => {
      const loader = $loading.show({
        color: "#2581ef",
        opacity: 1,
      });
      Api()
          .get(`/reports/show-project-summary/${user.id}`)
          .then((response) => {
            if (response.data.status === "success") {
              const projects  = response.data.projects;
              yourProjects.value = projects;
              let tasks = 0;
              let teams = 0;
               const summary =[];
               summary.push({ name: 'Total Projects', stat: projects.length,icon:HomeIcon })

              projects.forEach(el=>{
                tasks+= el.NumberOfTasks;
                teams+=el.NumberOfTeams
              })

               summary.push({ name: 'Total Tasks', stat: tasks,icon:ClipboardListIcon })
               //summary.push({ name: 'Total Teams', stat: teams,icon:UsersIcon})

              stats.value = summary;
            } else {
            }
          })
          .catch((error) => {})
          .finally(() => {
            loader.hide();
          });
    }
    getReportSummary();

return {
  stats,
  people,
  yourProjects
}
  }
}
</script>

<style scoped>

</style>