<template>
   <div class="py-16 bg-white overflow-hidden">
    <div class="px-4 sm:px-6 lg:px-8">
      <div class="text-lg max-w-prose mx-auto">
        <h1>
          <span class="block text-2xl text-center text-indigo-600 font-semibold tracking-wide uppercase">How to create a Workflow</span>
          <span class="mt-2 block text-xs text-center tracking-tight text-gray-900">A workflow streamlines which stages project content tasks will go through</span>
        </h1>
        <!-- <p class="mt-8 text-xl text-gray-500 leading-8">Aliquet nec orci mattis amet quisque ullamcorper neque, nibh sem. At arcu, sit dui mi, nibh dui, diam eget aliquam. Quisque id at vitae feugiat egestas ac. Diam nulla orci at in viverra scelerisque eget. Eleifend egestas fringilla sapien.</p> -->
      </div>
      <div class="mt-6 w-1/2 prose-indigo prose-lg text-black mx-auto">

           <div class="text-base my-6">
               <p class="py-3"><span class="font-medium text-lg">1.</span> Click on <span class="italic font-bold">My Workflows</span> on the left navigation menu</p>
                <!-- <figure class="border rounded-lg border-1">
                    <img class="w-full rounded-lg" src="/images/4_create_a_workflow.png" alt="" width="1310" height="873" />
                </figure> -->
           </div>

           <div class="text-base my-6">
               <p class="py-3"><span class="font-medium text-lg">2.</span> Type Workflow Name eg. <span class="italic text-blue-500"> 4 step workflow </span> and Click 
               <button disabled class="ml-1 flex-shrink-0 px-4 py-2 border border-transparent text-xs font-normal rounded-md shadow-sm text-white
                bg-blue-600"> Add New Workflow </button></p>
                <figure class="border rounded-lg border-1">
                    <img class="w-full rounded-lg" src="/images/4_create_a_workflow.png" alt="" width="1310" height="873" />
                    <!-- <figcaption>Sagittis scelerisque nulla cursus in enim consectetur quam.</figcaption> -->
                </figure>
           </div>

           <div class="text-base my-6">
               <p class="pt-3"><span class="font-medium text-lg">3.</span> Enter a name for your workflow Stage eg. <span class="italic text-blue-500"> review </span> and Click 
               <button disabled class="ml-1 flex-shrink-0 px-4 py-2 border border-transparent text-xs font-normal rounded-md shadow-sm text-white
                bg-blue-600"> Add Stage</button></p>
                <p class="text-xs italic font-normal py-3">You can add any number of stages to your workflow, as long as they make sense to you </p>
                <p class="text-xs italic font-normal pb-3">You can also drag and reorder the workflow stages </p>
                <figure class="border rounded-lg border-1">
                    <img class="w-full rounded-lg" src="/images/5_add_stage_to_workflow.png" alt="" width="1310" height="873" />
                    <!-- <figcaption>Sagittis scelerisque nulla cursus in enim consectetur quam.</figcaption> -->
                </figure>
           </div>
        <p>You can now use this workflow in any project of your choice. Find out how to <a href="/how-to-create-a-project"
          target="_blank" class="text-blue-500 underline"> create a project </a>

        </p>
      </div>
    </div>
   </div>
</template>

<script>
export default {

}
</script>

<style>

</style>