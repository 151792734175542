<template>
  <nav id="header" class="w-full z-30 top-0 mb-5 py-5">
    <div
      class="
        w-full
        container
        mx-auto
        flex flex-wrap
        items-center
        justify-between
        mt-0
        py-2
      "
    >
      <div class="pl-4 flex items-center">
        <a
          class="
            toggleColour
            no-underline
            hover:no-underline
            font-bold
            text-2xl
            lg:text-4xl
          "
          href="/"
        >
          <div class="flex relative">
            <img src="/images/logo.png" alt="SliceLoop" style="width: 200px" />
            <!-- <sup
              class="text-blue-500 text-xs uppercase"
              style="position: absolute; top: 10px; right: 0px"
              >Beta</sup
            > -->
          </div>
        </a>
      </div>

      <div class="block lg:hidden pr-4">
        <button
          @click="toggleMenu"
          id="nav-toggle"
          class="
            flex
            items-center
            p-1
            text-blue-600
            hover:text-gray-900
            focus:outline-none focus:shadow-outline
            transform
            transition
            hover:scale-105
            duration-300
            ease-in-out
          "
        >
          <svg
            class="fill-current h-6 w-6"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
      </div>
      <div
        class="
          w-full
          flex-grow
          lg:flex lg:items-center lg:w-auto
          hidden
          mt-2
          lg:mt-0
          bg-white
          lg:bg-transparent
          text-black
          p-4
          lg:p-0
          z-20
        "
        id="nav-content"
      >
        <ul class="list-reset lg:flex justify-end flex-1 items-center">
          <li class="mr-3" v-if="!noLinks">
            <a
              class="
                inline-block
                py-2
                px-4
                text-black
                hover:underline
                no-underline
                hover:text-blue-500
                font-bold
              "

              href="/#features"
              >Features</a
            >
          </li>

          <li class="mr-3" v-if="!noLinks">
            <router-link
                :to="{ name: 'pricing' }"
                class="
                inline-block
                py-2
                px-4
                text-black
                hover:underline
                no-underline
                hover:text-blue-500
                font-bold
              "
            > Pricing </router-link>
          </li>
        </ul>

        <router-link
          :to="{ name: 'Login' }"
          class="
            mx-auto
            lg:mx-0
            hover:underline
            bg-blue-600
            inline-block
            text-white
            font-bold
            rounded-full
            my-2
            py-4
            px-8
            shadow-lg
            focus:outline-none focus:shadow-outline
            transform
            transition
            hover:scale-105
            duration-300
            ease-in-out
          "
        >
          Get Started
        </router-link>
      </div>
    </div>
    <hr class="border-b border-gray-100 opacity-25 my-0 py-0" />
  </nav>
</template>

<script>
export default {
  name: "Navbar",
  props: ["noLinks"],
  setup() {
    const check = (e) => {
      const target = (e && e.target) || (event && event.srcElement);

      //Nav Menu
      if (!checkParent(target, navMenuDiv)) {
        // click NOT on the menu
        if (checkParent(target, navMenu)) {
          // click on the link
          if (navMenuDiv.classList.contains("hidden")) {
            navMenuDiv.classList.remove("hidden");
          } else {
            navMenuDiv.classList.add("hidden");
          }
        } else {
          // click both outside link and outside menu, hide menu
          navMenuDiv.classList.add("hidden");
        }
      }
    };
    const checkParent = (t, elm) => {
      while (t.parentNode) {
        if (t == elm) {
          return true;
        }
        t = t.parentNode;
      }
      return false;
    };

    const toggleMenu = () => {
      const links = document.getElementById("nav-content");
      links.classList.toggle("hidden");
    };

    return { toggleMenu };
  },
};
</script>

<style scoped></style>
