<template>
  <div class="bg-white">
    <AlertError
        v-if="errorMessage"
        :message="[errorMessage,projectErrorMessage,taskErrorMessage,templateErrorMessage]"
        @hide="errorMessage = null"
    />
    <div class="max-w-7xl mx-auto  px-4 sm:px-6 lg:px-8">
      <div class="sm:flex sm:flex-col sm:align-center">
        <h1 class="text-5xl font-extrabold text-gray-900 sm:text-center">Billing</h1>
      </div>
    </div>
  </div>

  <div v-if="details" class="bg-white  overflow-hidden sm:rounded-lg">
    <div class="px-4 py-5 sm:px-6">
      <h3 class="text-lg leading-6 font-medium text-gray-900">
        Billing Information
      </h3>
      <p class="mt-1 max-w-2xl text-sm text-gray-500">
        Details about your account.
      </p>
    </div>
    <div class="px-4 py-5 sm:px-6 flex flex-col-reverse lg:flex-row">
      <dl class="grid grid-cols-1 gap-x-4 gap-y-8 mt-5 lg:mt-0 sm:grid-cols-2 flex-auto">
        <div class="sm:col-span-1">
          <dt class="text-sm font-medium text-gray-500">
           Current Subscription
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{details.currentSubscription}} <span v-if="details.currentSubscription.toLowerCase() !== 'free'" :class="details.subscriptionStatus === 'active'? 'text-green-600' : 'text-red-600' ">({{details.subscriptionStatus}})</span>
          </dd>
        </div>
        <div class="sm:col-span-1">
          <dt class="text-sm font-medium text-gray-500">
            Expires On
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
          {{details.expiresOn}}
          </dd>
        </div>
        <div class="sm:col-span-1">
          <dt class="text-sm font-medium text-gray-500">
            Started On
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
         {{details.startedOn}}
          </dd>
        </div>
        <div class="sm:col-span-1">
          <dt class="text-sm font-medium text-gray-500">
            Days Left
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{details.daysLeft}}
          </dd>
        </div>
        <div class="sm:col-span-2">
          <dt class="text-sm font-medium text-gray-500">
           Project Stats
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            <div>

              <dl class="mt-5">
                <div class="rounded-lg  border overflow-hidden sm:flex">
                  <div class="relative bg-white pt-5 pl-4 pb-5 sm:pt-6 flex-1">
                    <dt>
                      <div class="absolute bg-indigo-500 rounded-md p-3">
                        <svg   xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="h-6 w-6 text-white" viewBox="0 0 16 16">
                          <path d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5v-3zM2.5 2a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zM1 10.5A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3z"/>
                        </svg>

                      </div>
                      <p class="ml-16 text-sm font-medium text-gray-500 truncate">Active</p>
                    </dt>
                    <dd class="ml-16 pb-6 flex items-baseline sm:pb-7 justify-between">
                      <p class="text-2xl font-semibold text-gray-900">
                        {{ details.totalActiveProjects}}
                      </p>
                    </dd>

                  </div>


                  <div class="relative bg-white pt-5 pl-4 sm:pl-0 pr-4 pb-5 sm:pt-6  flex-1">
                    <dt>
                      <div class="absolute bg-indigo-500 rounded-md p-3">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="h-6 w-6 text-white" viewBox="0 0 16 16">
                          <path d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5v-3zm8 0A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5v-3zm-8 8A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5v-3zm8 0A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5v-3z"/>
                        </svg>
                      </div>
                      <p class="ml-16 text-sm font-medium text-gray-500 truncate">Archived</p>
                    </dt>
                    <dd class="ml-16 pb-6 flex items-baseline sm:pb-7 justify-between">
                      <p class="text-2xl font-semibold text-gray-900">
                        {{ details.totalArchivedProjects}}
                      </p>
                    </dd>

                  </div>

                  <div class="relative bg-white  pr-4 pb-5 sm:pt-6  flex-1">
                    <dt>
                      <p class="ml-5 sm:ml-16 text-sm font-medium text-gray-500 truncate">Projects Left </p>
                    </dt>
                    <dd class="ml-5 sm:ml-16 pb-6 flex items-baseline sm:pb-7 justify-between ">
                      <p :class=" ['text-red-600 mt-1', 'ml-2 items-baseline text-sm font-semibold flex justify-between bg-red-100 px-5 py-1 rounded-full']">
                        {{ details.totalProjectsLeft }}
                      </p>
                    </dd>

                  </div>
                </div>
              </dl>
            </div>
          </dd>
        </div>
        <div class="sm:col-span-2">
          <dt class="text-sm font-medium text-gray-500">
            Task Stats
          </dt>
          <dd class="mt-6 text-sm text-gray-900">
            <div class="flex flex-col">
              <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div class=" overflow-hidden border  sm:rounded-lg">
                    <table class="min-w-full divide-y divide-gray-200">
                      <thead class="bg-gray-50">
                      <tr>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 capitalize tracking-normal">
                          Project
                        </th>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 capitalize tracking-normal">
                          Tasks Active
                        </th>

                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 capitalize tracking-normal">
                          Tasks Archived
                        </th>

                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 capitalize tracking-normal">
                          Is Project Archived
                        </th>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 capitalize tracking-normal">
                          Tasks Left
                        </th>
                      </tr>
                      </thead>
                      <tbody class="bg-white divide-y divide-gray-200">
                      <tr v-for="task in details.taskStats" :key="task.projectName">
                        <td class="px-6 py-4 whitespace-nowrap text-xs font-medium text-gray-900">
                          {{ task.projectName }}
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-xs text-gray-500">
                          <p :class=" ['text-green-600', 'ml-2 items-baseline text-xs font-semibold inline-flex justify-between bg-green-100 px-4 py-2 rounded-full']">
                            {{ task.totalActiveTasks }}
                          </p>

                        </td>

                        <td class="px-6 py-4 whitespace-nowrap text-xs text-gray-500">
                          <p :class=" ['text-yellow-600', 'ml-2 items-baseline text-xs font-semibold inline-flex justify-between bg-yellow-100 px-4 py-2 rounded-full']">
                            {{ task.totalArchivedTasks }}
                          </p>

                        </td>

                        <td class="px-6 py-4 whitespace-nowrap text-xs text-gray-500">
                          <p :class=" ['text-yellow-600', 'ml-2 items-baseline text-xs font-semibold inline-flex justify-between  px-4 py-2 rounded-full']">
                            {{ task.isProjectArchived }}
                          </p>

                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-xs text-gray-500">
                          <p :class=" ['text-red-600', 'ml-2 items-baseline text-xs font-semibold inline-flex justify-between bg-red-100 px-4 py-2 rounded-full']">
                            {{ task.tasksLeft }}
                          </p>

                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </dd>
        </div>


        <div class="sm:col-span-2">
          <dt class="text-sm font-medium text-gray-500">
           Billing History
          </dt>
          <dd class="mt-6 text-sm text-gray-900">
            <div class="flex flex-col">
              <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div class=" overflow-hidden border  sm:rounded-lg">
                    <table class="min-w-full divide-y divide-gray-200">
                      <thead class="bg-gray-50">
                      <tr>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 capitalize tracking-normal">
                          Date
                        </th>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 capitalize tracking-normal">
                        Description
                        </th>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 capitalize tracking-normal">
                         Amount
                        </th>
                      </tr>
                      </thead>
                      <tbody class="bg-white divide-y divide-gray-200">
                      <tr v-for="payment in details.billingHistory" :key="payment.date">
                        <td class="px-6 py-4 whitespace-nowrap text-xs font-medium text-gray-900">
                          {{ payment.date }}
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-xs text-gray-500">
                          {{ payment.description }}
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-xs text-gray-500">
                          {{ payment.amount }}
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </dd>
        </div>
      </dl>
      <div class="lg:px-6">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          Estimated costs for this billing period
        </h3>
        <p class="text-5xl font-extrabold text-gray-900 mt-6 text-center">${{details.estimatedCostsForBillingPeriod}}</p>

        <div>
          <button class="block w-full bg-blue-500 border border-blue-500 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-blue-600 mt-6" @click="changeSubscription(details.currentSubscription.toLowerCase())">Change Subscription</button>
          <button class="block w-full bg-red-500 border border-bred-500 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-red-600 mt-6" @click="showCancelAlert = true">Cancel Subscription</button>
        </div>
        <div class="mt-10">
          <label class="text-base font-medium text-gray-900">Subscription Frequency</label>
          <p class="text-sm leading-5 text-gray-500">How frequently would you like to be billed?</p>
          <fieldset class="mt-4">
            <legend class="sr-only">Notification method</legend>
            <div class="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
              <div v-for="frequency in frequencies" :key="frequency.id" class="flex items-center">
                <input :id="frequency.id" @click.prevent="selectedFrequency=frequency.id;showFrequencyAlert=true" name="frequency" type="radio" :checked="frequency.id === details.billingFrequency" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300" />
                <label :for="frequency.id" class="ml-3 block text-sm font-medium text-gray-700 cursor-pointer">
                  {{ frequency.title }}
                </label>
              </div>
            </div>
          </fieldset>
        </div>

      </div>
    </div>
  </div>
  <DeleteAlert
      :show="showCancelAlert"
      header="Cancel subscription"
      body="Are you sure you want to cancel your subscription?"
      @delete="cancelSubscription"
      @cancel="showCancelAlert = false"
      action="Continue"
  />

  <DeleteAlert
      :show="showFrequencyAlert"
      header="Switch subscription Frequency"
      body="Are you sure you want to Switch your subscription Frequency?"
      @delete="changeFrequency(selectedFrequency)"
      @cancel="showFrequencyAlert = false"
      action="Continue"
  />



</template>

<script>
import { PaperClipIcon } from '@heroicons/vue/solid'
import Api from "../api/Api";
import {inject,ref,watch} from "vue";
import {useLoading} from "vue-loading-overlay";
import {useRouter} from 'vue-router'
import DeleteAlert from "./DeleteAlert";
import AlertError from "./AlertError";
const frequencies = [
  { id: 'monthly', title: 'Monthly' },
  { id: 'yearly', title: 'Yearly' },
]

export default {
  name: "Billing",
  components: {
    PaperClipIcon,
    DeleteAlert,
    AlertError
  },
  setup()
  {
    const router =  useRouter();
  const user =  inject('user');
  const details =  ref();
  const showCancelAlert = ref(false);
  const showFrequencyAlert = ref(false);
    let errorMessage = ref(null);
    let projectErrorMessage = ref(null);
    let templateErrorMessage = ref(null);
    let taskErrorMessage = ref(null);
  const selectedFrequency = ref();
    const $loading = useLoading();

    const setMessage = (message) => {
        localStorage.setItem('successMessage', message)
    }
    const getBillingDetails = () => {
      const loader = $loading.show({
        color: "#2581ef",
        opacity: 1,
      });
      Api()
          .get(`/subscription/get-billing-info/${user.id}`)
          .then((response) => {
            if (response.data.status === "success") {
              details.value = response.data.billingInfo;
              selectedFrequency.value = response.data.billingInfo.billingFrequency;
            }else {
              errorMessage.value = response.data.message
            }
          })
          .catch((error) => {})
          .finally(() => {
            loader.hide();
          });
    }
   getBillingDetails();

    const cancelSubscription = () => {
      const loader = $loading.show({
        color: "#2581ef",
        opacity: 1,
      });

      projectErrorMessage.value = null;
      taskErrorMessage.value = null;
      templateErrorMessage.value = null;

      Api()
          .patch(`/subscription/cancel/${user.id}`)
          .then((response) => {
            if (response.data.status === "success") {
              setMessage(response.data.message)
             window.location.reload();
            }else {
              errorMessage.value = response.data.message

              if(response.data.project_message){
               projectErrorMessage.value = ">> "+response.data.project_message
              }

              if(response.data.task_message){
               taskErrorMessage.value = "";
                for(var i = 0; i < response.data.task_message.length; i++){
                  
                  taskErrorMessage.value = taskErrorMessage.value + "\t- "+ response.data.task_message[i] + "\n"

                }
              }

              if(response.data.template_message){
               templateErrorMessage.value = '>> '+ response.data.template_message
              }

            }
          })
          .catch((error) => {})
          .finally(() => {
            loader.hide();
             showCancelAlert.value = false;
          });

    }

    const changeSubscription = (current) => {
       router.push(`/pricing?t=u&c=${current}`)
    }
    const changeFrequency = (frequency) => {
      const loader = $loading.show({
        color: "#2581ef",
        opacity: 1,
      });

      Api()
          .patch(`/subscription/toggle-billing-frequency/${user.id}`)
          .then((response) => {
            if (response.data.status === "success") {
              setMessage(response.data.message)
              window.location.reload();
            }else {
              errorMessage.value = response.data.message
            }
          })
          .catch((error) => {})
          .finally(() => {
            loader.hide();
            showFrequencyAlert.value = false;
          });
    }

    return {
      details,
      cancelSubscription,
      showCancelAlert,
      changeSubscription,
      frequencies,
      showFrequencyAlert,
      selectedFrequency,
      changeFrequency,
      errorMessage,
      projectErrorMessage,
      taskErrorMessage,
      templateErrorMessage
    }
  }
}
</script>

<style scoped>

</style>