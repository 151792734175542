<template>
  <div>
    <div class="mb-5">
      <div class="col-sm-12">
        <button
          class="capitalize flex text-blue-500 hover:underline"
          v-if="currentViewComponent"
          @click="
            changeComponent(null, null, null, null, null, null, null, null)
          "
        >
          <ArrowLeftIcon class="mr-2 h-6 w-6" />
          back to content tasks
        </button>
      </div>
    </div>
    <component
      :is="currentViewComponent"
      :assigned_to_user="prop_user_id"
      :contentBriefId="prop_contentBrief_Id"
      :task_id="prop_task_Id"
      :templateLoaded="prop_templateLoaded"
      :templateId="prop_templateId"
      :status_id="prop_status_id"
      @reloadTasks="loadStages"
      :task="activeTask"
      @backToTasks="
        changeComponent(null, null, null, null, null, null, null, null)
      "
    ></component>

    <div v-if="!currentViewComponent">
      <div class="flex flex-col">
        <div class="">
          <div class="py-2 align-middle inline-block min-w-full">
            <div
              class="
                shadow
                overflow-hidden
                border-b border-gray-200
                sm:rounded-lg
              "
            >
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="
                        px-6
                        py-3
                        text-left text-xs
                        font-medium
                        text-gray-500
                        uppercase
                        tracking-wider
                      "
                    >
                      Name
                      <Filter @filter="filterByName" />
                    </th>
                    <th
                      scope="col"
                      class="
                        px-6
                        py-3
                        text-left text-xs
                        font-medium
                        text-gray-500
                        uppercase
                        tracking-wider
                      "
                    >
                      Status
                      <Filter @filter="filterByStatus" />
                    </th>
                    <th
                      scope="col"
                      class="
                        px-6
                        py-3
                        text-left text-xs
                        font-medium
                        text-gray-500
                        uppercase
                        tracking-wider
                      "
                    >
                      Stage

                      <Filter @filter="filterByStage" />
                    </th>
                    <th
                      scope="col"
                      class="
                        px-6
                        py-3
                        text-left text-xs
                        font-medium
                        text-gray-500
                        uppercase
                        tracking-wider
                      "
                    >
                      Template
                    </th>

                    <th
                      scope="col"
                      class="
                        px-6
                        py-3
                        text-left text-xs
                        font-medium
                        text-gray-500
                        uppercase
                        tracking-wider
                      "
                    >
                      Assigned On
                    </th>

                    <th
                      scope="col"
                      class="
                        px-6
                        py-3
                        text-left text-xs
                        font-medium
                        text-gray-500
                        uppercase
                        tracking-wider
                      "
                    >
                      Due Date
                    </th>

                    <th
                      scope="col"
                      class="
                        px-6
                        py-3
                        text-left text-xs
                        font-medium
                        text-gray-500
                        uppercase
                        tracking-wider
                      "
                    >
                      Assignee
                    </th>

                    <th
                      scope="col"
                      class="
                        px-6
                        py-3
                        text-left text-xs
                        font-medium
                        text-gray-500
                        uppercase
                        tracking-wider
                      "
                    >
                      Content Brief
                    </th>

                    <th
                      scope="col"
                      class="
                        px-6
                        py-3
                        text-left text-xs
                        font-medium
                        text-gray-500
                        uppercase
                        tracking-wider
                      "
                    >
                      WorkFlow
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr v-for="task in getTasks" :key="task.task_id">
                    <td
                      class="
                        px-6
                        py-4
                        whitespace-nowrap
                        text-sm
                        font-medium
                        text-gray-900
                      "
                    >
                      <span
                        class="
                          capitalize
                          underline
                          cursor-pointer
                          hover:text-blue-500
                        "
                        @click="
                          activeTask = task;
                          changeComponent(
                            'ContentEditor',
                            task.stageAssignments,
                            task.workflow_stage,
                            task.contentBriefId,
                            task.task_id,
                            task.templateLoaded,
                            task.templateId,
                            task.status_id
                          );
                        "
                      >
                        {{ task.taskTitle }}
                      </span>
                    </td>
                    <td
                      class="
                        px-6
                        py-4
                        whitespace-nowrap
                        text-sm text-gray-500
                        capitalize
                      "
                    >
                      <span
                        class="
                          px-2
                          inline-flex
                          text-xs
                          leading-5
                          font-semibold
                          rounded-full
                          bg-yellow-100
                          text-yellow-800
                        "
                        v-if="task.statusName.toLowerCase() === 'pending'"
                      >
                        {{ task.statusName }}
                      </span>

                      <span
                        class="
                          px-2
                          inline-flex
                          text-xs
                          leading-5
                          font-semibold
                          rounded-full
                          bg-green-100
                          text-green-800
                        "
                        v-else-if="task.statusName.toLowerCase() === 'done'"
                      >
                        {{ task.statusName }}
                      </span>

                      <span
                        class="
                          px-2
                          inline-flex
                          text-xs
                          leading-5
                          font-semibold
                          rounded-full
                          bg-blue-100
                          text-blue-800
                        "
                        v-else-if="
                          task.statusName.toLowerCase() === 'in progress'
                        "
                      >
                        {{ task.statusName }}
                      </span>

                      <span v-else>
                        {{ task.statusName }}
                      </span>
                    </td>
                    <td
                      class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                    >
                      {{ task.stageName }}
                    </td>
                    <td
                      class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                    >
                      {{ task.templateName ? task.templateName : "---" }}
                    </td>

                    <td
                      class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                    >
                      {{
                        (cdate = getAssignedOnDateForStage(
                          task.workflow_stage,
                          task.stageAssignments
                        ))
                          ? cdate
                          : "---"
                      }}
                    </td>
                    <td
                      class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                    >
                      {{
                        (ddate = getDueOnDateForStage(
                          task.workflow_stage,
                          task.stageAssignments
                        ))
                          ? ddate
                          : "---"
                      }}
                    </td>
                    <td
                      class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                    >
                      {{
                        getCurrentAssignee(
                          task.assignedTo,
                          task.stageAssignments
                        )
                      }}
                    </td>
                    <td
                      class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                    >
                      <button
                        v-if="task.contentBriefId"
                        type="button"
                        class="
                          inline-flex
                          items-center
                          py-2
                          px-3
                          text-blue-500
                          hover:underline
                        "
                        @click="
                          activeTask = task;
                          showContentBriefModal(
                            task.contentBriefId,
                            task.task_id
                          );
                        "
                      >
                        View
                      </button>
                      <span v-else>None</span>
                    </td>

                    <td
                      class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                    >
                      <button
                        type="button"
                        class="
                          inline-flex
                          items-center
                          py-2
                          px-3
                          text-blue-500
                          hover:underline
                        "
                        @click="
                          activeTask = task;
                          showConfigureModal(task.stageAssignments);
                        "
                      >
                        View
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <Modal
        :open="true"
        v-if="showConfigureWorkflowModal"
        @dismiss="closeConfigureWorkflowModal"
      >
        <template v-slot:header>
          <h5 class="modal-title text-danger">
            <span class="text-blue-500">{{ activeTask.taskTitle }}</span> Workflow Assignments
          </h5>
          <br />
        </template>

        <template v-slot:body>
          <div class="flex flex-col modal-mh">
            <div class="">
              <div class="py-2 align-middle inline-block min-w-full">
                <div class="overflow-hidden">
                  <table class="min-w-full divide-gray-200">
                    <thead class="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          class="
                            px-6
                            py-3
                            text-center text-xs
                            font-medium
                            text-gray-500
                            uppercase
                            tracking-wider
                          "
                        >
                          Stage
                        </th>
                        <th
                          scope="col"
                          class="
                            px-6
                            py-3
                            text-center text-xs
                            font-medium
                            text-gray-500
                            uppercase
                            tracking-wider
                          "
                        >
                          Assignee Name
                        </th>
                      </tr>
                    </thead>
                    <tbody class="bg-white divide-gray-200">
                      <tr
                        v-for="(assignment, index) in assignments"
                        :key="index"
                      >
                        <td
                          class="
                            px-6
                            py-4
                            whitespace-nowrap
                            text-sm
                            font-medium
                            text-gray-900
                          "
                        >
                          {{ assignment.stageName }}
                        </td>
                        <td
                          class="
                            px-6
                            py-4
                            whitespace-nowrap
                            text-sm text-gray-500
                          "
                        >
                          {{
                            assignment.assigneeName
                              ? assignment.assigneeName
                              : "Not Assigned"
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </template>
      </Modal>
      <Modal
        :open="true"
        v-if="showContentBrief"
        @dismiss="closeContentBriefModal"
      >
        <template v-slot:header>
          <h5 class="modal-title text-danger">
            View <span class="text-blue-500">{{ activeTask.taskTitle }}</span> Content Brief
          </h5>
        </template>

        <template v-slot:body>
          <div class="modal-mh">
            <div
              class="flex pb-3"
              v-for="(section, counter) in contentBriefSections"
              v-bind:key="counter"
            >
              <div class="mr-2">
                <input
                  type="text"
                  placeholder="title"
                  v-model.lazy="section.itemTitle"
                  class="
                    text-gray-900
                    placeholder-gray-400
                    appearance-none
                    bg-white
                    rounded-md
                    block
                    w-full
                    px-3
                    py-2
                    sm:text-sm
                    mb-4
                    focus:border-blue-500 focus:ring-blue-500 focus:outline-none
                    font-bold
                  "
                  disabled="true"
                />
              </div>
              <div class="flex-1 mr-2">
                <textarea
                  class="
                    text-gray-900
                    flex-1
                    ring-gray-900 ring-opacity-5
                    placeholder-gray-400
                    appearance-none
                    bg-white
                    rounded-md
                    block
                    w-full
                    px-3
                    py-2
                    ring-1
                    sm:text-sm
                    mb-4
                    focus:border-blue-500 focus:ring-blue-500 focus:outline-none
                  "
                  placeholder="write a catchy that describes theme e.g.why we love"
                  v-model.lazy="section.itemDescription"
                  rows="3"
                  cols="5"
                  disabled="true"
                ></textarea>
              </div>
            </div>
          </div>
        </template>
      </Modal>
    </div>
  </div>
</template>

<script>
import Modal from "@/components/Modal.vue";
import { ref, computed } from "vue";
import ContentEditor from "@/components/ContentEditor.vue";
import Api from "@/api/Api.js";
import { ArrowLeftIcon } from "@heroicons/vue/outline";
import { useLoading } from "vue-loading-overlay";
import InlineLoader from "./InlineLoader";
import Filter from "./Filter";

export default {
  components: { Modal, ContentEditor, ArrowLeftIcon, InlineLoader, Filter },
  props: {
    project_id: {
      type: Number,
      required: false,
      default: false,
    },
    workflow_id: {
      type: Number,
      required: false,
      default: false,
    },
  },
  setup(props, _) {
    //varibale intializations
    let userFromStorage = JSON.parse(localStorage.getItem("user"));
    let userid = userFromStorage.id;
    const $loading = useLoading();
    const activeTask = ref();
    let contentBriefName = ref("");
    let contentBriefSections = ref([
      { itemID: "", itemTitle: "", itemDescription: "" },
    ]);

    let assignments = ref([]);

    let taskTemplates = ref([null]);
    let tasks = ref([]);
    let listOfStages = ref(null);
    let listOfAssignees = ref(null);
    let workflow_id = ref(props.workflow_id);
    let project_id = ref(props.project_id).value;
    let prop_contentBrief_Id = ref(null);
    let prop_task_Id = ref(null);
    let prop_user_id = ref(0);
    let prop_templateLoaded = ref(null);
    let prop_templateId = ref(null);
    let prop_status_id = ref(null);

    let currentViewComponent = ref(null);
    const getTasks = computed(() =>
      tasks.value.filter((task) => {
        return (
          task.taskTitle.includes(filterName.value) &&
          task.statusName.includes(filterStatus.value) &&
          task.stageName.includes(filterStage.value)
        );
      })
    );
    const filterName = ref("");
    const filterStatus = ref("");
    const filterStage = ref("");
    const filterAssignee = ref("");

    // Modal Intializations and necessary functions
    const showConfigureWorkflowModal = ref(false);

    const closeConfigureWorkflowModal = () => {
      showConfigureWorkflowModal.value = false;
    };

    function showConfigureModal(assignmentObject) {
      assignments.value = assignmentObject;

      showConfigureWorkflowModal.value = true;
    }

    const showContentBrief = ref(false);

    const closeContentBriefModal = () => {
      showContentBrief.value = false;
    };

    function showContentBriefModal(varContentBriefId, taskid) {
      loadContentBrief(taskid, varContentBriefId);
    }

    //functions
    function changeComponent(
      component,
      assignmentObject,
      currentStage,
      _contentBriefId,
      _task_id,
      _isTemplatedLoaded,
      _templateid,
      _status_id
    ) {
      if (assignmentObject) {
        prop_contentBrief_Id.value = _contentBriefId;
        prop_task_Id.value = _task_id;
        prop_templateLoaded.value = _isTemplatedLoaded;
        prop_templateId.value = _templateid;
        prop_status_id.value = _status_id;

        for (var i = 0; i < assignmentObject.length; i++) {
          if (currentStage === assignmentObject[i].stageid) {
            if (assignmentObject[i].user_id)
              prop_user_id.value = assignmentObject[i].user_id;
          }
        }
      }

      currentViewComponent.value = component;
      document.getElementById("initBack").classList.toggle("hidden");
    }

    function formatDate(date) {
      var d1 = new Date(date);
      var ms = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      return d1.getDate() + " " + ms[d1.getMonth()] + " " + d1.getFullYear();
    }

    function getAssignedOnDateForStage(currentStage, assignmentObject) {
      let currentDate = null;

      for (var i = 0; i < assignmentObject.length; i++) {
        if (currentStage === assignmentObject[i].stageid) {
          currentDate = assignmentObject[i].assignedOn;
        }
      }

      if (currentDate === null) {
        return null;
      } else {
        return formatDate(currentDate);
      }
    }

    function getDueOnDateForStage(currentStage, assignmentObject) {
      let currentDate = null;

      for (var i = 0; i < assignmentObject.length; i++) {
        if (currentStage === assignmentObject[i].stageid) {
          currentDate = assignmentObject[i].dueDate;
        }
      }

      if (currentDate === null) {
        return null;
      } else {
        return formatDate(currentDate);
      }
    }

    function getCurrentAssignee(currentlyAssignedTo, assignmentObject) {
      let currentName = "";

      for (var i = 0; i < assignmentObject.length; i++) {
        if (currentlyAssignedTo === assignmentObject[i].assignee) {
          currentName = assignmentObject[i].assigneeName;
        }
      }

      if (currentName === "") {
        return "";
      } else {
        return currentName;
      }
    }

    function getAssigneeInitials(str) {
      const firstLetters = str
        .split(" ")
        .splice(0, 2)
        .map((word) => word[0])
        .join("");

      return firstLetters;
    }

    function loadContentBrief(taskid, varContentBriefId) {
      const loader = $loading.show({
        color: "#2581ef",
        opacity: 1,
      });
      Api()
        .get(
          `/contentBrief/show-task-contentBrief/${taskid}/${varContentBriefId}`
        )
        .then((response) => {
          if (response.data.status === "success") {
            contentBriefName.value = response.data.contentBriefName;
            contentBriefSections.value = response.data.contentBriefSections;
          }

          showContentBrief.value = true;
        })
        .catch((error) => {})
        .finally(() => {
          loader.hide();
        });
    }
    function loadTasks() {
      const loader = $loading.show({
        color: "#2581ef",
        opacity: 1,
      });

      Api()
        .get(`/tasks/tasks-assigned-to-me/${userid}/${project_id}`)
        .then((response) => {
          if (response.data.status === "success") {
            for (var j = 0; j < response.data.tasks.length; j++) {
              response.data.tasks[j].stageAssignments = [];

              let taskid = response.data.tasks[j].task_id;

              taskTemplates.value[j] = response.data.tasks[j].templateId;

              for (var i = 0; i < listOfStages.value.length; i++) {
                let stageid = listOfStages.value[i].id;
                let stageName = listOfStages.value[i].stageName;

                response.data.tasks[j].stageAssignments.push({
                  task_id: taskid,
                  stageName: stageName,
                  stageid: stageid,
                  assignee: "Pick Assignee",
                  teamId: "Choose a Team",
                  assignedOn: null,
                  duedate: null,
                  assigneeName: "",
                  user_id: "",
                });
                for (
                  var x = 0;
                  x < response.data.workflowStageAssignmentsForTasks.length;
                  x++
                ) {
                  if (
                    response.data.workflowStageAssignmentsForTasks[x]
                      .workflowStageId != null &&
                    response.data.workflowStageAssignmentsForTasks[x].taskId !=
                      null &&
                    response.data.workflowStageAssignmentsForTasks[x]
                      .workflowStageId === stageid &&
                    response.data.workflowStageAssignmentsForTasks[x].taskId ===
                      taskid
                  ) {
                    response.data.tasks[j].stageAssignments[i].assignee =
                      response.data.workflowStageAssignmentsForTasks[
                        x
                      ].assignedToMemberId;

                    response.data.tasks[j].stageAssignments[i].assigneeName =
                      response.data.workflowStageAssignmentsForTasks[x].name;

                    response.data.tasks[j].stageAssignments[i].user_id =
                      response.data.workflowStageAssignmentsForTasks[x].user_id;

                    response.data.tasks[j].stageAssignments[i].teamId =
                      response.data.workflowStageAssignmentsForTasks[x].teamId;

                    response.data.tasks[j].stageAssignments[i].assignedOn =
                      response.data.workflowStageAssignmentsForTasks[
                        x
                      ].assignedOn;

                    response.data.tasks[j].stageAssignments[i].dueDate =
                      response.data.workflowStageAssignmentsForTasks[x].dueDate;
                  }
                }
              }
            }

            tasks.value = response.data.tasks;
          } else {
            tasks.value = [];
          }
        })
        .catch((error) => {})
        .finally(() => {
          loader.hide();
        });
    }

    function loadStages() {
      const loader = $loading.show({
        color: "#2581ef",
        opacity: 1,
      });
      Api()
        .get(`/workflows/show-all-stages-of-workflow/${workflow_id.value}`)
        .then((response) => {
          if (response.data.status === "success") {
            listOfStages.value = response.data.workflowStages;
          } else {
            listOfStages.value = null;
          }
          let message = response.data.message;
        })
        .catch((error) => {})
        .then((response) => {
          loadTasks();
          loader.hide();
        });
    }

    loadStages();

    const filterByName = (name) => {
      filterName.value = name;
    };

    const filterByStatus = (name) => {
      filterStatus.value = name;
    };

    const filterByStage = (name) => {
      filterStage.value = name;
    };

    const filterByAssignee = (name) => {
      filterAssignee.value = name;
    };

    return {
      showConfigureWorkflowModal,
      showConfigureModal,
      closeConfigureWorkflowModal,
      showContentBrief,
      closeContentBriefModal,
      showContentBriefModal,
      contentBriefSections,
      contentBriefName,
      changeComponent,
      currentViewComponent,
      taskTemplates,
      tasks,
      listOfStages,
      listOfAssignees,
      getAssignedOnDateForStage,
      getDueOnDateForStage,
      getAssigneeInitials,
      getCurrentAssignee,
      loadContentBrief,
      assignments,
      prop_contentBrief_Id,
      prop_task_Id,
      prop_user_id,
      prop_templateLoaded,
      prop_templateId,
      prop_status_id,
      loadStages,
      activeTask,
      filterByName,
      getTasks,
      filterByStatus,
      filterByAssignee,
      filterByStage,
    };
  },
};
</script>

<style></style>
