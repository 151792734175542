<template>
  <div class="mr-10 w-8/12 flex flex-col">
    <AlertSuccess
      v-if="successMessage"
      :message="successMessage"
      @hide="successMessage = null"
    />
    <AlertError
      v-if="errorMessage"
      :message="[errorMessage]"
      @hide="errorMessage = null"
    />
  </div>

  <div class="flex relative">
    <p v-if="showSavingIndicator" class="absolute w-full text-center w-1/2 animate-pulse  text-blue-500"> Saving...</p>
    <TextEditor
      v-if="!loading"
      :content="taskContent"
      :can-edit="canModify"
      :disable-save-button="disableSaveButton"
      :disable-claim-task-button="disableClaimTaskButton"
      :disable-submit-button="disableSubmitButton"
      @saveEditorContent="saveContent"
      @saveEditorContentSilently="saveContentSilently"
      :editable="editable"
      :task-status="task_status"
      @submitTask="submitTask"
      @claimTask="claimTask"
      :is-task="true"
      :taskTitle="task.taskTitle"
      :project-id="task.project_id"
      :task-id="task.task_id"
      :task-stage="task.stageName"
      :workflow_id="task.workflow_stage"
      @commentsLoaded="showComments"
      @changeActiveComment="setActiveComment"
      :can-comment="canModify"
      :content-brief-sections="contentBriefSections"
      @message="showMessage"
      :reloadDocumentVersions="reloadDocumentVersions"
    />
  </div>
</template>

<script>
import {ref, inject} from "vue";
import Api from "@/api/Api.js";
import TextEditor from "./TextEditor";
import { useLoading } from "vue-loading-overlay";
import format from "date-fns/format";
import AlertSuccess from "./AlertSuccess";
import AlertError from "./AlertError";

export default {
  components: {
    TextEditor,
    AlertSuccess,
    AlertError,
  },

  props: {
    assigned_to_user: {
      type: Number,
      required: false,
      default: false,
    },
    contentBriefId: {
      type: Number,
      required: false,
      default: false,
    },
    task_id: {
      type: Number,
      required: false,
      default: false,
    },
    templateLoaded: {
      type: Number,
      required: false,
      default: false,
    },
    templateId: {
      type: Number,
      required: false,
      default: false,
    },
    status_id: {
      type: Number,
      required: false,
      default: false,
    },
    canModify: {
      default: true,
      required: false,
      type: Boolean,
    },

    task: {
      required: true,
      type: Object,
    },
  },
  emits: ["backToTasks", "reloadTasks"],

  setup(props, context) {
    let successMessage = ref("");
    let errorMessage = ref("");
    let assigned_to_user = ref(props.assigned_to_user).value;
    let contentBriefId = ref(props.contentBriefId).value;
    let task_id = ref(props.task_id).value;
    let templateLoaded = ref(props.templateLoaded).value;
    let templateId = ref(props.templateId).value;
    const dateTimeFormat = "dd.MM.yyyy HH:mm";
    let task_status = ref(props.status_id);
    const activeTab = ref(1);
    const allComments = ref([]);
    const formatDate = (d) => (d ? format(new Date(d), dateTimeFormat) : null);
    const commentText = ref("");
    const showSavingIndicator = ref(false);
    const activeCommentsInstance = ref({});
    const tabs = [
      { name: "Brief", index: 1, current: true },
      { name: "Comments", index: 2, current: false },
    ];

    let taskContent = ref(
      JSON.parse(
        `{"type":"doc","content":[{"type":"paragraph","attrs":{"textAlign":"left"}}]}`
      )
    );
    const editable = ref(false);
    const $loading = useLoading();
    const canEditNodeTemplate = inject('canEditNodeTemplate');
    let userFromStorage = JSON.parse(localStorage.getItem("user"));
    let user_id = userFromStorage.id;

    let contentBriefSections = ref(null);
    let contentBriefName = ref(null);
    const loading = ref(true);

    let disableSaveButton = ref(true);
    let disableSubmitButton = ref(true);
    let disableClaimTaskButton = ref(true);

    let reloadDocumentVersions = ref(false);

    loadTaskContent(task_id);

    const saveContentSilently = (content) => {
      disableSaveButton.value = true;
      disableSubmitButton.value = true;
      showSavingIndicator.value = true;

      let task_ = {
        taskContent: content,
        templateLoaded: templateLoaded,
        templateId: templateId,
      };

      Api()
          .patch(`/tasks/update-task-content/${task_id}`, task_)
          .finally(() => {

            disableSaveButton.value = false;
            disableSubmitButton.value = false;
            showSavingIndicator.value = false;
            reloadDocumentVersions.value    = !reloadDocumentVersions.value;
          });
    }

    function saveContent(content) {
      const loader = $loading.show({
        color: "#2581ef",
        opacity: 1,
      });
      disableSaveButton.value = true;
      disableSubmitButton.value = true;

      let task_ = {
        taskContent: content,
        templateLoaded: templateLoaded,
        templateId: templateId,
      };

      Api()
        .patch(`/tasks/update-task-content/${task_id}`, task_)
        .then((response) => {
          let status = response.data.status;
          let responseMessage = response.data.message;
          if (status === "success") {
            // context.emit("reloadTasks");
            successMessage.value = responseMessage;
            reloadDocumentVersions.value    = !reloadDocumentVersions.value;
          } else {
            errorMessage.value = responseMessage;
          }
        })
        .catch((error) => {
          //errorMessage.value = error.response.data.errors.projectName;
        })
        .finally(() => {
          disableSaveButton.value = false;
          disableSubmitButton.value = false;
          loader.hide();
        });
    }

    function submitTask() {
      const loader = $loading.show({
        color: "#2581ef",
        opacity: 1,
      });
      disableSaveButton.value = true;
      disableSubmitButton.value = true;

      Api()
        .patch(`/tasks/submit-task/${task_id}`)
        .then((response) => {
          let status = response.data.status;
          if (status === "success") {
            context.emit("backToTasks");
            context.emit("reloadTasks");
          }
        })
        .catch((error) => {})
        .finally(() => {
          disableSaveButton.value = false;
          disableSubmitButton.value = false;
          loader.hide();
        });
    }

    function claimTask() {
      const loader = $loading.show({
        color: "#2581ef",
        opacity: 1,
      });
      let taskObject = {
        current_status: task_status.value,
      };

      Api()
        .patch(`/tasks/update-task-status/${task_id}`, taskObject)

        .then((response) => {
          if (response.data.status === "success") {
            task_status.value = response.data.current_status;
            editable.value = true;
            disableSaveButton.value = false;
            disableSubmitButton.value = false;
            context.emit("reloadTasks");
          }
        })
        .catch((error) => {})
        .finally(() => {
          canEditNodeTemplate.value = editable.value && props.canModify;;
          loader.hide();
        });
    }

    function loadTaskContent(_taskid) {
      const loader = $loading.show({
        color: "#2581ef",
        opacity: 1,
      });
      Api()
        .get(`/tasks/load-task-content/${_taskid}`)
        .then((response) => {
          if (response.data.status === "success") {
            let _taskContent = response.data.taskContent;
            let _templateId = response.data.templateId;
            let _isTemplatedLoaded = response.data.isTemplateLoaded;
            let _templateStructure = response.data.templateStructure;

            if (!_taskContent) {
              if (_templateId && !_isTemplatedLoaded) {
                taskContent.value = JSON.parse(_templateStructure);
              } else {
                taskContent.value = JSON.parse(
                  `{"type":"doc","content":[{"type":"paragraph","attrs":{"textAlign":"left"}}]}`
                );
              }
            } else {
              taskContent.value = JSON.parse(_taskContent);
            }

            loading.value = false;

            if (task_status.value === 2) {
              editable.value = true;
              disableSaveButton.value = false;
              disableSubmitButton.value = false;
            } else if (task_status.value === 1) {
              disableClaimTaskButton.value = false;
            }
          }
        })
        .catch((error) => {})
        .finally(() => {
          canEditNodeTemplate.value = editable.value && props.canModify;
          loader.hide();
        });
    }

    function loadContentBrief(taskid, varContentBriefId) {
      const loader = $loading.show({
        color: "#2581ef",
        opacity: 1,
      });
      Api()
        .get(
          `/contentBrief/show-task-contentBrief/${taskid}/${varContentBriefId}`
        )
        .then((response) => {
          if (response.data.status === "success") {
            contentBriefName.value = response.data.contentBriefName;
            contentBriefSections.value = response.data.contentBriefSections;
          }
        })
        .catch((error) => {})
        .finally(() => {
          loader.hide();
        });
    }

    if (contentBriefId) loadContentBrief(task_id, contentBriefId);

    const showComments = (comments) => {
      allComments.value = comments;
    };

    const setActiveComment = (comment) => {
      if (comment.comments) {
        activeCommentsInstance.value = comment;
        activeTab.value = 2;
      }
    };

    const setComment = () => {
      commentText.value = "";
    };

  const showMessage = (message) => {
    successMessage.value = message;
  }

    return {
      saveContent,
      disableSaveButton,
      disableSubmitButton,
      disableClaimTaskButton,
      assigned_to_user,
      user_id,
      contentBriefSections,
      contentBriefName,
      taskContent,
      task_status,
      submitTask,
      templateLoaded,
      templateId,
      claimTask,
      loading,
      editable,
      tabs,
      activeTab,
      showComments,
      allComments,
      commentText,
      setComment,
      activeCommentsInstance,
      setActiveComment,
      formatDate,
      successMessage,
      errorMessage,
      saveContentSilently,
      showSavingIndicator,
      showMessage,
      reloadDocumentVersions
    };
  },
};
</script>

<style scoped></style>
