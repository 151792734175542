<template>

  <main class="relative z-10 flex-auto flex items-center justify-center text-sm text-center  py-16 px-4 sm:px-6 lg:px-8 md:mt-36">
    <div class="w-full max-w-sm">

      <AlertSuccess v-if="successMessage" :message="successMessage" @hide="successMessage=null"/>
      <AlertError v-if="generalMessage" :message="[generalMessage]" @hide="generalMessage=null"/>


      <h1 class="font-bold my-5">Forgot Password</h1>
      <p class="mb-3">A reset link will be sent to this email address</p>

      <div>

        <div class="relative">
          <label  for="email" class="sr-only">Email address:</label>
          <input
              type="email"
              v-model="email"
              placeholder="Enter Your Email address"
              class="text-gray-900 ring-gray-900 ring-opacity-5 placeholder-gray-400 appearance-none bg-white rounded-md block w-full px-3 py-2 border border-transparent shadow ring-1 sm:text-sm mb-4 focus:border-blue-500 focus:ring-blue-500 focus:outline-none"
              id="email"
          />

          <p
              v-for="(errorMessage, index) in emailMessage"
              :key="index"
              style="font-size: 0.8rem !important"
              class="text-red-500 mb-2"
          >
            {{ errorMessage }}
          </p>
        </div>

        <button
            v-bind:class="{ disabled: disableButton }"
            :disabled="disableEmailMeButton"
            @click.prevent="sendEmailresetPassword"
            class=" flex justify-center items-center  w-full py-2 px-3 border border-transparent rounded-md text-white font-medium bg-blue-500
        shadow-sm sm:text-sm mb-10 hover:bg-blue-600 focus:outline-none focus-visible:ring-2
        focus-visible:ring-gray-700 focus-visible:ring-offset-2 focus-visible:ring-offset-gray-50"
        >
          <InlineLoader :h="25" :w="25" :show="canShow"/>
         Send Email
        </button>
      </div>
    </div>
  </main>

</template>

<script>
import { ref } from "vue";
import Api from "@/api/Api.js";
import { useRouter } from "vue-router";
import { computed } from "vue";
import AlertSuccess from "../components/AlertSuccess";
import AlertError from "../components/AlertError";
import InlineLoader from "../components/InlineLoader";

export default {
  components:{AlertSuccess,AlertError,InlineLoader},
  setup() {
    let email = ref("");
    let emailMessage = ref(null);
    let generalMessage = ref("");
    let successMessage = ref("");
    let disableButton = ref(false);
    const canShow = ref(false)

    const disableEmailMeButton = computed(() => {
      if (email.value.trim().length > 0 && validateEmail(email.value.trim())) {
         generalMessage.value = null;
        return false;
      }

      return true;
    });

    function sendEmailresetPassword() {
       
      
      generalMessage.value = null;
      successMessage.value = null;
      disableButton.value = true;

      let userEmail = {
        'email': email.value,
      
      };

      canShow.value = true;

      Api()
        .post("/forgotPassword", userEmail)

        .then((response) => {
          let status = response.data.status;

          if(status === "success"){

            successMessage.value = "A reset link has successfully been sent to your email address";
            email.value = "";
            disableButton.value = false;

          }
          
         
         // router.push({ name: "dashboardHome" });

        })
        .catch((error) => {
         
         if(!error.message.includes("Network")){
           if (error.response.status === 409) {
            generalMessage.value = error.response.data.error;
          } else if (error.response.status === 422) {
            emailMessage.value = error.response.data.errors.email;
          }
         }
          disableButton.value = false;
        }).finally(()=>{
        canShow.value = false;
      });;

    }

    function validateEmail(emailStr) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,25})+$/.test(emailStr)) {
       generalMessage.value = null;
       return true;
    } else {
      generalMessage.value = "Please enter a valid email address"
       return false;
    }
}

    return {
      sendEmailresetPassword,
      email,
      emailMessage,
      generalMessage,
      disableButton,
      disableEmailMeButton,
      validateEmail,
      successMessage,
      canShow
    };
  },
};
</script>

<style>
</style>