import axios from "axios";

let BaseApi = axios.create({
  baseURL: "https://sliceloop.com/api",
  // baseURL: "http://localhost:8000"
});

// Add a request interceptor
BaseApi.interceptors.request.use(
  function (config) {
    // NProgress.start();
    return config;
  },
  function (error) {
    // NProgress.done();
    return Promise.reject(error);
  }
);

// Add a response interceptor
BaseApi.interceptors.response.use(
  function (response) {
    // NProgress.done();
    return response;
  },
  function (error) {
    // NProgress.done();
    return Promise.reject(error);
  }
);

let Api = function () {
  let token = localStorage.getItem("token");

  // let  CSRFtoken =  localStorage.getItem("csrftoken");

  if (token) {
    BaseApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }

  // if (CSRFtoken) {
  //   //BaseApi.defaults.headers.common["X-Requested-With"] = 'XMLHttpRequest';
  //   BaseApi.defaults.headers.common["X-CSRF-TOKEN"] = `${CSRFtoken}`;
  // }

  return BaseApi;
};

export default Api;
