<template>
  <div class="py-16 bg-white overflow-hidden">
    <div class="px-4 sm:px-6 lg:px-8">
      <div class="text-lg max-w-prose mx-auto">
        <h1>
          <span class="block text-base text-center text-indigo-600 font-semibold tracking-wide uppercase">Beginners Tutorial</span>
          <span class="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">8 Quick Steps to Get Started with Sliceloop</span>
        </h1>
        <!-- <p class="mt-8 text-xl text-gray-500 leading-8">Aliquet nec orci mattis amet quisque ullamcorper neque, nibh sem. At arcu, sit dui mi, nibh dui, diam eget aliquam. Quisque id at vitae feugiat egestas ac. Diam nulla orci at in viverra scelerisque eget. Eleifend egestas fringilla sapien.</p> -->
      </div>
      <div class="mt-6 w-1/2 prose-indigo prose-lg text-black mx-auto">
        <!-- <p>Faucibus commodo massa rhoncus, volutpat. <strong>Dignissim</strong> sed <strong>eget risus enim</strong>. Mattis mauris semper sed amet vitae sed turpis id. Id dolor praesent donec est. Odio penatibus risus viverra tellus varius sit neque erat velit. Faucibus commodo massa rhoncus, volutpat. Dignissim sed eget risus enim. <a href="#">Mattis mauris semper</a> sed amet vitae sed turpis id.</p> -->
        <!-- <ol class="list-decimal" role="list"> -->

          <div class="text-base my-4">
            <p class="py-3"><span class="font-medium text-lg">1.</span> Create a team and then click on newly created team card</p>
            <figure class="border border-1 rounded-lg">
                <img class="w-full rounded-lg" src="/images/1_add_team.png" alt="" width="1310" height="873" />
                <!-- <figcaption>Sagittis scelerisque nulla cursus in enim consectetur quam.</figcaption> -->
            </figure>
          </div>

           <div class="text-base my-6">
               <p class="pt-3"><span class="font-medium text-lg">2.</span>  Add and Invite team member</p>
                <p class="text-xs italic font-normal pb-3">For this tutorial, you can use your own email address for demonstration purposes</p>
                <figure class="border border-1 rounded-lg">
                    <img class="w-full rounded-lg" src="/images/2_add_invite_team_member.png" alt="" width="1310" height="873" />
                    <!-- <figcaption>Sagittis scelerisque nulla cursus in enim consectetur quam.</figcaption> -->
                </figure>
           </div>

           <div class="text-base my-6">
               <p class="py-3"><span class="font-medium text-lg">3.</span> Team Member Accepts Invite</p>
                <figure class="border border-1 rounded-lg">
                    <img class="w-full rounded-lg" src="/images/3_team_member_accepts_invite.png" alt="" width="1310" height="873" />
                    <!-- <figcaption>Sagittis scelerisque nulla cursus in enim consectetur quam.</figcaption> -->
                </figure>
           </div>

           <div class="text-base my-6">
               <p class="py-3"><span class="font-medium text-lg">4.</span> Create a Workflow</p>
                <figure class="border rounded-lg border-1">
                    <img class="w-full rounded-lg" src="/images/4_create_a_workflow.png" alt="" width="1310" height="873" />
                    <!-- <figcaption>Sagittis scelerisque nulla cursus in enim consectetur quam.</figcaption> -->
                </figure>
           </div>

           <div class="text-base my-6">
               <p class="pt-3"><span class="font-medium text-lg">5.</span> Add Stages to the Workflow</p>
                <p class="text-xs italic font-normal pb-3">You can add any number of stages to your workflow, as long as they make sense to you</p>
                <figure class="border rounded-lg border-1">
                    <img class="w-full rounded-lg" src="/images/5_add_stage_to_workflow.png" alt="" width="1310" height="873" />
                    <!-- <figcaption>Sagittis scelerisque nulla cursus in enim consectetur quam.</figcaption> -->
                </figure>
           </div>

           <div class="text-base my-6">
               <p class="py-3"><span class="font-medium text-lg">6.</span> Go to My Projects, Add a project and assign a workflow to it</p>
                <figure class="border rounded-lg border-1">
                    <img class="w-full rounded-lg" src="/images/6_add_a_project_1.png" alt="" width="1310" height="873" />
                    <!-- <figcaption>Sagittis scelerisque nulla cursus in enim consectetur quam.</figcaption> -->
                </figure>

                 <figure class="border rounded-lg border-1 mt-3">
                    <img class="w-full rounded-lg" src="/images/6_add_a_project_2.png" alt="" width="1310" height="873" />
                    <!-- <figcaption>Sagittis scelerisque nulla cursus in enim consectetur quam.</figcaption> -->
                </figure>
           </div>

            <div class="text-base my-6">
               <p class="py-3"><span class="font-medium text-lg">7.</span> Click on Project card and create a content task</p>
                <figure class="border rounded-lg border-1">
                    <img class="w-full rounded-lg" src="/images/7_create_content_task_1.png" alt="" width="1310" height="873" />
                    <!-- <figcaption>Sagittis scelerisque nulla cursus in enim consectetur quam.</figcaption> -->
                </figure>

                 <figure class="border rounded-lg border-1 mt-3">
                    <img class="w-full rounded-lg" src="/images/7_create_content_task_2.png" alt="" width="1310" height="873" />
                    <!-- <figcaption>Sagittis scelerisque nulla cursus in enim consectetur quam.</figcaption> -->
                </figure>
           </div>

           <div class="text-base my-6">
               <p class="pt-3"><span class="font-medium text-lg">8.</span> Click on Manage and then Click on Assign Task</p>
               <p class="text-xs italic font-normal pb-3"> Select a team and then pick assignees and due dates for each workflow stage</p>
                <figure class="border rounded-lg border-1">
                    <img class="w-full rounded-lg" src="/images/8_click_on_manage_1.png" alt="" width="1310" height="873" />
                    <!-- <figcaption>Sagittis scelerisque nulla cursus in enim consectetur quam.</figcaption> -->
                </figure>

                 <figure class="border rounded-lg border-1 mt-3">
                    <img class="w-full rounded-lg" src="/images/8_click_on_manage_2.png" alt="" width="1310" height="873" />
                    <!-- <figcaption>Sagittis scelerisque nulla cursus in enim consectetur quam.</figcaption> -->
                </figure>

                 <figure class="border rounded-lg border-1 mt-3">
                    <img class="w-full rounded-lg" src="/images/8_select_team_and_assign_3.png" alt="" width="1310" height="873" />
                    <!-- <figcaption>Sagittis scelerisque nulla cursus in enim consectetur quam.</figcaption> -->
                </figure>
           </div>
        <!-- </ol> -->
        <p>Assignee is then notified by email about this assignment. You will also be notified each time the task is sent to another stage in your workflow</p>
      </div>
    </div>
  </div>
</template>
