<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <Menu as="div" class="relative inline-block text-left">
    <div>
      <MenuButton
        class="
          inline-flex
          justify-center
          w-full
          rounded-md
          border border-gray-300
          shadow-sm
          px-4
          py-2
          bg-white
          text-sm
          font-medium
          text-gray-700
          hover:bg-gray-50
          focus:outline-none
          focus:ring-2
          focus:ring-offset-2
          focus:ring-offset-gray-100
        "
      >
        <InlineLoader
          v-show="canShow"
          color="#2581ef"
          :h="25"
          :w="25"
          :show="true"
        />
        Download Content
        <ChevronDownIcon class="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
      </MenuButton>
    </div>

    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems
        class="
          origin-top-right
          absolute
          right-0
          mt-2
          w-56
          rounded-md
          shadow-lg
          bg-white
          ring-1 ring-black ring-opacity-5
          divide-y divide-gray-100
          focus:outline-none
          z-20
        "
      >
        <div class="py-1">
          <MenuItem v-slot="{ active }">
            <a
              href="#"
              @click="downloadPdf"
              :class="[
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'group flex items-center px-4 py-2 text-sm',
              ]"
            >
              <BIconFileEarmarkPdf
                class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                aria-hidden="true"
              />
              PDF
            </a>
          </MenuItem>
          <MenuItem v-slot="{ active }">
            <a
              href="#"
              @click="downloadWord"
              :class="[
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'group flex items-center px-4 py-2 text-sm',
              ]"
            >
              <BIconFileEarmarkWord
                class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                aria-hidden="true"
              />
              WORD
            </a>
          </MenuItem>
          <MenuItem v-slot="{ active }">
            <a
              href="#"
              @click="downloadHtml"
              :class="[
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'group flex items-center px-4 py-2 text-sm',
              ]"
            >
              <BIconFileEarmarkRichtext
                class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                aria-hidden="true"
              />
              HTML
            </a>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script>
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { BIconFileEarmarkPdf, BIconFileEarmarkWord, BIconFileEarmarkRichtext } from "bootstrap-icons-vue";
import { ChevronDownIcon } from "@heroicons/vue/solid";
import InlineLoader from "./InlineLoader";
import { ref } from "vue";
import Api from "../api/Api";

export default {
  name: "Download",
  props: ["name", "container", "editor"],
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ChevronDownIcon,
    BIconFileEarmarkPdf,
    BIconFileEarmarkWord,
    InlineLoader,
    BIconFileEarmarkRichtext
  },
  setup(props) {
    const canShow = ref(false);
    const downloadPdf = () => {
      canShow.value = true;
      let options = {
        responseType: "blob",
      };
      Api()
        .post(
          "/tasks/generatepdf",
          {
            pdfContent: props.editor.getHTML(),
          },
          options
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${props.name}.pdf`);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {})
        .finally(() => {
          canShow.value = false;
        });
    };

    const downloadWord = () => {
      canShow.value = true;
      setTimeout(() => {
        const header =
          "<html xmlns:o='urn:schemas-microsoft-com:office:office' " +
          "xmlns:w='urn:schemas-microsoft-com:office:word' " +
          "xmlns='http://www.w3.org/TR/REC-html40'>" +
          "<head><meta charset='utf-8'><title>Export HTML to Word Document with JavaScript</title></head><body>";
        const footer = "</body></html>";
        const sourceHTML =
          header + document.querySelector(props.container).innerHTML + footer;

        const source =
          "data:application/vnd.ms-word;charset=utf-8," +
          encodeURIComponent(sourceHTML);
        const fileDownload = document.createElement("a");
        document.body.appendChild(fileDownload);
        fileDownload.href = source;
        fileDownload.download = `${props.name}.doc`;
        fileDownload.click();
        document.body.removeChild(fileDownload);
        canShow.value = false;
      }, 10);
    };

    const downloadHtml = () => {

      canShow.value = true;

      setTimeout(() => {

        const url = window.URL.createObjectURL(new Blob([props.editor.getHTML()]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${props.name}.html`);
        document.body.appendChild(link);
        link.click(); 
        document.body.removeChild(link);

        canShow.value = false;

      }, 20);

    };

    return { downloadPdf, downloadWord, canShow, downloadHtml };
  },
};
</script>

<style scoped></style>
