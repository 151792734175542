<template>
  <div class="max-w-lg mx-auto">
    <div>
      <form action="#" class="my-6 flex">
        <label for="email" class="sr-only">email</label>
        <input
          type="email"
          name="email"
          v-model="email"
          id="email_id"
          class="
            shadow-sm
            focus:ring-blue-500 focus:border-blue-500
            block
            w-full
            sm:text-sm
            border-gray-300
            rounded-md
            border
            pl-2
          "
          placeholder="user@gmail.com"
        />
        <button
          type="button"
          :disabled="disableAddTeamMemberButton"
          @click="addTeamMemberToTeam"
          class="
            ml-4
            flex-shrink-0
            px-4
            py-2
            border border-transparent
            text-sm
            font-medium
            rounded-md
            shadow-sm
            text-white
            bg-blue-600
            hover:bg-blue-700
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-blue-500
          "
        >
          Add Team Member
        </button>
      </form>
    </div>

    <AlertSuccess
      v-if="successMessage"
      :message="successMessage"
      @hide="successMessage = null"
    />
    <AlertError
      v-if="errorMessage"
      :message="[errorMessage]"
      @hide="errorMessage = null"
    />
  </div>

  <AlertWithDescription
    v-if="!listOfTeamMembers"
    header="No team members!"
    body="Please add a team member to get started"
  />

  <div class="mt-20 max-w-3xl mx-auto" v-else>
    <h3
      class="
        text-xs
        font-semibold
        text-gray-500
        uppercase
        tracking-wide
        text-center
      "
    >
      Added Team Members
    </h3>
    <ul
      role="list"
      class="mt-4 border-t border-b border-gray-200 divide-y divide-gray-200"
    >
      <li
        v-for="teamMember in listOfTeamMembers"
        :key="teamMember.team_members_id"
        class="py-4 flex items-center justify-between space-x-3"
      >
        <div class="min-w-0 flex-1 flex items-center space-x-3">
          <span
            class="
              w-12
              h-12
              rounded-full
              border
              flex
              justify-center
              items-center
              font-bold
              text-xl
              hover:bg-gray-100
            "
            >{{ teamMember.name[0] }}</span
          >
          <div class="min-w-0 flex-1">
            <p class="text-sm font-medium text-gray-900 truncate">
              {{ teamMember.name }}
            </p>
            <p class="text-sm font-medium text-gray-500 truncate">
              {{ teamMember.email }}
            </p>
          </div>
        </div>

        <div class="flex-shrink-0 w-36">
          <div class="min-w-0 flex-1">
            <p class="text-xs font-medium text-gray-400 px-5 truncate">
              Status
            </p>

            <p
              v-if="teamMember.invitationAccepted"
              class="
                text-xs
                capitalize
                bg-green-100
                text-green-800
                inline-block
                px-5
                py-1
                rounded-xl
              "
            >
              accepted
            </p>
            <p
              v-else
              class="
                text-xs
                capitalize
                bg-yellow-100
                text-yellow-800
                inline-block
                px-5
                py-1
                rounded-xl
              "
            >
              Pending
            </p>
          </div>
        </div>

        <div class="flex-shrink-0">
          <button
            type="button"
            :disabled="teamMember.isInviting"
            @click="
              showDeleteModal = true;
              activeDelete = teamMember;
            "
            class="
              inline-flex
              items-center
              py-2
              px-3
              text-red-500
              hover:underline
            "
          >
            <MinusSmIcon class="-ml-1 mr-0.5 h-5 w-5" aria-hidden="true" />
            <span class="text-sm font-medium">
              Remove <span class="sr-only">{{ teamMember.name }}</span>
            </span>
          </button>
        </div>
      </li>
    </ul>
  </div>
  <v-tour  v-if="(canShowTeamsGuide == 1)" class="text-gray-500 bg-white" name="myTour" :steps="steps"></v-tour>
  <GuideModal  v-if="(canShowTeamsGuide == 1) && (guideStage == 6.2) " :is-open="showGuide"  @close="showGuide=false" @goToInvite="goToInvite" />
  <DeleteAlert
    :show="showDeleteModal"
    item="Team Member"
    @delete="deleteMember"
    @cancel="showDeleteModal = false"
  />
</template>

<script>
import { ref, inject } from "vue";
import Api from "@/api/Api.js";
import { computed } from "vue";
import AlertWithDescription from "./AlertWithDescription";
import { MinusSmIcon } from "@heroicons/vue/outline";
import DeleteAlert from "./DeleteAlert";
import { useLoading } from "vue-loading-overlay";
import AlertError from "./AlertError";
import AlertSuccess from "./AlertSuccess";
import GuideModal from "./GuideModal";

export default {
  name: "ViewOrAddTeamMembersComponent",
  components: {
    AlertWithDescription,
    MinusSmIcon,
    DeleteAlert,
    AlertSuccess,
    AlertError,
    GuideModal
  },
  props: {
    teamId: {
      type: Number,
      required: false,
      default: false,
    },
  },
  emits:['goToInvite'],
  setup(props,{emit}) {
    const showDeleteModal = ref(false);
    const activeDelete = ref();
    let propTeamId = ref(props.teamId).value;
    let listOfTeamMembers = ref(null);
    const $loading = useLoading();

    // let message = ref("");
    let msg = ref("");
    let email = ref("");
    let successMessage = ref("");
    let errorMessage = ref("");

    const guideStage =  inject('currentGuideStage');
    const canShowTeamsGuide = inject('canShowTeamsGuide');
    
    const showGuide = ref(false);
    const tours = inject('tours');
    const  steps = [
      {
        target: '#email_id',
        content: `Add team members email here <br/> For this guide, you can use your sliceloop email address`,
        params: {
          placement: 'top'
        }
      },
    ]
    let showTeamTour = ref(false);

    const deleteMember = () => {
      deleteTeamMember(activeDelete.value);
      showDeleteModal.value = false;
    };

    const disableAddTeamMemberButton = computed(() => {
      if (email.value.length > 0) return false;
      return true;
    });

    function loadTeamMembers(allowGuide = false) {
      const loader = $loading.show({
        color: "#2581ef",
        opacity: 1,
      });
      Api()
        .get(`/teams/show-all-members-of-team/${propTeamId}`)
        .then((response) => {
          //Code for handling the response
          //add attribute editTeamName,isDeleting and isInviting so that you can toggle showDetails row
          let status = response.data.status;

          if (status === "success") {
            response.data.teamMembers.forEach(function (row) {
              row.editTeamName = false;
              row.isDeleting = false;
              row.isInviting = false;
            });

            listOfTeamMembers.value = response.data.teamMembers;
            
          } else {
            listOfTeamMembers.value = null;
          }
        })
        .catch((error) => {
          
        })
        .finally(() => {
          loader.hide();
         
          if ((canShowTeamsGuide.value == 1) && allowGuide){
            
            tours['myTour'].start();
          }
        });
    }

    function formatDate(date) {
      var d1 = new Date(date);
      var ms = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      return d1.getDate() + " " + ms[d1.getMonth()] + " " + d1.getFullYear();
    }

    function deleteTeamMember(teamMemberObj) {
      successMessage.value = null;
      errorMessage.value = null;
      msg.value = null;
      let teamMemberName = teamMemberObj.name;
      let teamMembersId = teamMemberObj.team_members_id;

      //disable button
      teamMemberObj.isDeleting = true;
      teamMemberObj.isInviting = true;
      const loader = $loading.show({
        color: "#2581ef",
        opacity: 1,
      });

      Api()
        .delete(`/teams/delete-team-member/${teamMembersId}`)
        .then((response) => {

          let message = response.data.message;
          let status  = response.data.status;

          if(status === "success"){

            successMessage.value = message;

          }else{

            errorMessage.value = message;

          }

        })
        .catch((error) => {
          errorMessage.value = "An error has occurred! Please try again";
        })
        .finally(() => {
          loadTeamMembers();
          loader.hide();
        });
    }

    function inviteTeamMember(teamMemberObj) {
      successMessage.value = null;
      errorMessage.value = null;
      msg.value = null;
      let id = teamMemberObj.team_members_id;

      let teamMember = {
        invitationSent: "1",
        email: teamMemberObj.email,
      };

      teamMemberObj.isInviting = true;
      const loader = $loading.show({
        color: "#2581ef",
        opacity: 1,
      });
      Api()
        .patch(`/teams/update-invitation-sent/${id}`, teamMember)
        .then((response) => {

          let message = response.data.message;
          let status = response.data.status;

          if (status === "success"){

            successMessage.value = message;

          }else{

            errorMessage.value = message;

          }

        })
        .catch((error) => {
          errorMessage.value = "An error has occurred! Please try again";
        })
        .finally(() => {
          loadTeamMembers();
          loader.hide();
        });
    }

    function addTeamMemberToTeam() {
      let teamMemberEmail = email.value;

      if (validateEmail(teamMemberEmail)) {
        email.value = "";
        successMessage.value = null;
        errorMessage.value = null;
        msg.value = `Adding ${teamMemberEmail} ...`;

        let theTeamId = propTeamId;
        let teamMember = {
          email: teamMemberEmail,
          teamId: theTeamId,
        };

        if(canShowTeamsGuide.value == 1){

          tours['myTour'].stop();
          guideStage.value = 6.2;

        }

        const loader = $loading.show({
          color: "#2581ef",
          opacity: 1,
        });
        Api()
          .post("/teams/add-team-member", teamMember)
          .then((response) => {
            
            let message = response.data.message;
            let status  = response.data.status;

            if (status === "success") {

              

              if(canShowTeamsGuide.value == 1){

                //guideStage.value = 6.2;
                 setTimeout(()=>{

                  showGuide.value =  true;
                  
                 },500);
                //showGuide.value =  true;

              }else{

                  successMessage.value = message;

              }

            } else {

              errorMessage.value = message;

            }
            msg.value = "";
          })
          .catch((error) => {
            errorMessage.value = "An error occurred please try again";
          })
          .finally(() => {
            //reload team Members
            loadTeamMembers();
            loader.hide();
            
          });
      }
    }

    function validateEmail(emailStr) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailStr)) {
        errorMessage.value = null;
        return true;
      } else {
        errorMessage.value = "Please enter a valid email address";
        return false;
      }
    }

     const goToInvite = () => {
      showGuide.value = false;
      emit('goToInvite');
     // document.querySelectorAll('.teamname-link')[document.querySelectorAll('.teamname-link').length - 1].click();
    }    

    loadTeamMembers(true);

    return {
      propTeamId,
      listOfTeamMembers,
      formatDate,
      deleteTeamMember,
      inviteTeamMember,
      email,
      addTeamMemberToTeam,
      disableAddTeamMemberButton,
      msg,
      successMessage,
      errorMessage,
      showDeleteModal,
      activeDelete,
      deleteMember,
      steps,
      showGuide,
      guideStage,
      goToInvite,
      canShowTeamsGuide
    };
  },
};
</script>

<style></style>
