<template>
  <div>
    <div class="mb-5">
      <div class="col-sm-12">
        <button
          class="capitalize flex text-blue-500 hover:underline"
          v-if="currentComponent"
          @click="swapComponent(null, 0, '')"
        >
          <ArrowLeftIcon class="mr-2 h-6 w-6" />
          back
        </button>
      </div>
    </div>

    <div v-if="currentComponent" class="row text-center">
      <div class="col-sm-12">
        <h2 class="mt-2 text-lg font-medium text-gray-900 capitalize">
          {{ tempTeamName }} Team Members
        </h2>
      </div>
    </div>

    <component :is="currentComponent" :teamId="tempTeamId" @goToInvite="goToInvite" ></component>

    <div v-show="!currentComponent">
      <div class="max-w-lg mx-auto">
        <div>
          <div class="text-center">
            <svg
              class="mx-auto h-12 w-12 text-gray-400"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 48 48"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M34 40h10v-4a6 6 0 00-10.712-3.714M34 40H14m20 0v-4a9.971 9.971 0 00-.712-3.714M14 40H4v-4a6 6 0 0110.713-3.714M14 40v-4c0-1.313.253-2.566.713-3.714m0 0A10.003 10.003 0 0124 26c4.21 0 7.813 2.602 9.288 6.286M30 14a6 6 0 11-12 0 6 6 0 0112 0zm12 6a4 4 0 11-8 0 4 4 0 018 0zm-28 0a4 4 0 11-8 0 4 4 0 018 0z"
              />
            </svg>
            <h2 class="mt-2 text-lg font-medium text-gray-900">Create Team</h2>
            <p class="mt-1 text-sm text-gray-500">
              As the owner of this team, you can manage team member
              permissions.
            </p>
          </div>
          <form action="#" class="my-6 text-center sm:flex">
            <label for="teamName" class="sr-only">Enter New Team Name</label>
            <input
              type="text"
              name="teamName"
              v-model="inputTeamName"
              id="teamName"
              class="
                shadow-sm
                focus:ring-blue-500 focus:border-blue-500
                block
                w-full
                py-2
                sm:py-0
                sm:text-sm
                border-gray-300
                rounded-md
                border
                pl-2
              "
              placeholder="Enter New Team Name"
            />
            <button
              type="button"
              :disabled="disableAddTeamButton"
              @click="addTeam"
              class="
                ml-4
                flex-shrink-0
                px-4
                py-2
                mt-3
                sm:mt-0
                border border-transparent
                text-sm
                font-medium
                rounded-md
                shadow-sm
                text-white
                bg-blue-600
                hover:bg-blue-700
                focus:outline-none
                focus:ring-2
                focus:ring-offset-2
                focus:ring-blue-500
              "
            >
              Add New Team
            </button>
          </form>
        </div>
      </div>

      <AlertSuccess
        v-if="successMessage"
        :message="successMessage"
        @hide="successMessage = null"
      />
      <AlertError v-if="error" :message="[error]" @hide="error = null" />

      <div class="mx-auto mt-20">
        <div class="grid md:grid-cols-2 lg:grid-cols-4 gap-5">
          <div
            v-for="team in listOfTeams"
            :key="team.teamId"
            class="
              h-60
              bg-white
              border
              shadow
              rounded-xl
              flex flex-col
              justify-center
              items-center
            "
          >
            <div class="text-gray-500 text-left block w-full py-4 px-5">
              <div v-if="team.editTeamName" class="flex">
                <input
                  name="name"
                  :value="`${team.teamName}`"
                  v-on:keyup.enter="submitNewTeamName($event, team)"
                  class="w-full border px-3 py-1 text-gray-800 rounded-md"
                />
                <InlineLoader
                  v-if="saving"
                  :show="saving"
                  color="#ccc"
                  class="ml-4"
                />

                <XIcon
                  v-else
                  @click="team.editTeamName = false"
                  class="
                    ml-4
                    h-8
                    w-8
                    text-red-500
                    hover:text-red-600
                    cursor-pointer
                  "
                />
              </div>
              <div v-else class="font-bold text-gray-600 flex">
                <a
                  href="#"
                  class="hover:underline teamname-link"
                  @click="
                    swapComponent(
                      'ViewOrAddTeamMembersComponent',
                      team.teamId,
                      team.teamName
                    )
                  "
                  >{{ team.teamName }}</a
                >
                <PencilIcon
                  @click.stop="editTeamName(team)"
                  class="
                    mx-2
                    h-4
                    w-4
                    text-gray-500
                    hover:text-blue-500
                    cursor-pointer
                  "
                />
              </div>
              <div class="text-sm">
                Created on {{ formatDate(team.created_at) }}
              </div>
            </div>
            <div class="flex-1 w-full h-full cursor-pointer"  @click="swapComponent('ViewOrAddTeamMembersComponent',team.teamId, team.teamName)"></div>
            <div class="w-full flex justify-end px-2 py-2">
              <TrashIcon
                class="
                  -ml-1
                  mr-2
                  h-6
                  w-6
                  text-gray-400
                  hover:text-red-500
                  cursor-pointer
                "
                @click="
                  showDeleteModal = true;
                  activeDelete = team;
                "
              />
            </div>
          </div>
        </div>
        <DeleteAlert
          :show="showDeleteModal"
          item="team"
          @delete="deleteCreatedTeam"
          @cancel="showDeleteModal = false"
        />
      </div>
    </div>
    <v-tour v-if="(canShowTeamsGuide == 1) && showTeamTour" class="text-gray-500 bg-white" name="myTour" :steps="steps"></v-tour>
    <GuideModal v-if="((guideStage == 6) || (guideStage == 6.1) )&& (canShowTeamsGuide == 1)" :is-open="showGuide"  @close="closeGuide" @goToTeamMembers="goToTeamMembers" />
  </div>
</template>

<script>
import { ref, inject } from "vue";
import Api from "@/api/Api.js";
import { computed } from "vue";
import { XIcon, TrashIcon, ArrowLeftIcon } from "@heroicons/vue/outline";
import DeleteAlert from "./DeleteAlert";
import AlertSuccess from "./AlertSuccess";
import AlertError from "./AlertError";
import ViewOrAddTeamMembersComponent from "@/components/ViewOrAddTeamMembersComponent.vue";
import { PencilIcon } from "@heroicons/vue/solid";
import { useLoading } from "vue-loading-overlay";
import InlineLoader from "./InlineLoader";
import GuideModal from "./GuideModal";
export default {
  name: "TeamsYouCreated",
  components: {
    ViewOrAddTeamMembersComponent,
    ArrowLeftIcon,
    PencilIcon,
    TrashIcon,
    XIcon,
    DeleteAlert,
    AlertSuccess,
    AlertError,
    InlineLoader,
    GuideModal
  },

  setup(props,{emit}) {
    const showDeleteModal = ref(false);
    const activeDelete = ref();
    let userFromStorage = JSON.parse(localStorage.getItem("user"));
    let userid = userFromStorage.id;
    const $loading = useLoading();
    let listOfTeams = ref(null);
    let inputTeamName = ref("");
    let error = ref("");
    let alertAddingTeamName = ref(false);
    let alertTeamNameText = ref("");
    let currentComponent = ref(null);
    let tempTeamId = ref(0);
    let tempTeamName = ref("");
    let successMessage = ref("");
    const saving = ref(false);
    let showTeamTour = ref(false)

    const showGuide = ref(false)
    const guideStage =  inject('currentGuideStage');
    const canShowTeamsGuide =  inject('canShowTeamsGuide');
    const tours = inject('tours');
    const  steps = [
      {
        target: '#teamName',
        content: `Enter team name here and then click the add button`,
        params: {
          placement: 'top'
        }
      },
    ]

    const deleteCreatedTeam = () => {
      deleteTeam(activeDelete.value);
      showDeleteModal.value = false;
    };

    function swapComponent(component, varTempTeamId, varTempTeamName) {
      tempTeamId.value = varTempTeamId;
      tempTeamName.value = varTempTeamName;
      currentComponent.value = component;
      successMessage.value = null;
      error.value = null;
    }

    function loadListOfTeams(allowGuide = false) {
      const loader = $loading.show({
        color: "#2581ef",
        opacity: 1,
      });

      Api()
        .get(`/teams/show-my-teams/${userid}`)
        .then((response) => {
          //Code for handling the response
          //add attribute editTeamName so that you can toggle showDetails row
          let status = response.data.status;
          if (status === "success") {
            response.data.teams.forEach(function (row) {
              row.editTeamName = false;
              row.isDeletingTeam = false;
              row.isEditing = false;
              row.isViewing = false;
            });
            listOfTeams.value = response.data.teams;
          } else {
            listOfTeams.value = null;
            
          }
        })
        .catch((error) => {
          
        })
        .finally(() => {
          loader.hide();
          if(canShowTeamsGuide.value == 1 && allowGuide){
              showTeamTour.value =  true;
              showGuide.value=true;
          }
        });
    }

    function formatDate(date) {
      var d1 = new Date(date);
      var ms = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      return d1.getDate() + " " + ms[d1.getMonth()] + " " + d1.getFullYear();
    }

    function addTeam() {
     
     if(canShowTeamsGuide.value == 1){

        tours['myTour'].stop();
        showTeamTour.value = false;
        guideStage.value = 6.1;
      //  showGuide.value = true;

     }


      alertTeamNameText.value = inputTeamName.value;
      alertAddingTeamName.value = true;
      let tempInputTeamName = "";
      tempInputTeamName = inputTeamName.value;
      let team = {
        teamName: tempInputTeamName,
        teamCreator: userid,
      };

      inputTeamName.value = "";
      error.value = null;
      successMessage.value = null;
      const loader = $loading.show({
        color: "#2581ef",
        opacity: 1,
      });

      Api()
        .post("/teams/create-team", team)
        .then((response) => {
          let message = response.data.message;

          let status = response.data.status;

          if (status === "error") {
            inputTeamName.value = tempInputTeamName;
            error.value = message;
            
          } else if (status === "success") {
            
            if(canShowTeamsGuide.value == 1){

                // tours['myTour'].stop();
                // showTeamTour.value = false;
               // guideStage.value = 6.1;
                setTimeout(()=>{
                  showGuide.value = true;
                },1000);
              //  showGuide.value = true;

            }else{

              successMessage.value = message;

            }
            
          }
        })
        .catch((error) => {
          error.value = "An error has occured! Please try again";
          
          inputTeamName.value = tempInputTeamName;
        })
        .finally(() => {
          loadListOfTeams();

          alertAddingTeamName.value = false;

          alertTeamNameText.value = null;
          loader.hide();
        });
    }

    const disableAddTeamButton = computed(() => {
      if (inputTeamName.value.trim().length > 0) return false;

      return true;
    });

    function deleteTeam(teamObj) {
      successMessage.value = null;
      error.value = null;
      let teamName = teamObj.teamName;
      let teamId = teamObj.teamId;
      //disable delete button
      teamObj.isDeletingTeam = true;

      teamObj.isEditing = true;

      teamObj.isViewing = true;
      const loader = $loading.show({
        color: "#2581ef",
        opacity: 1,
      });

      Api()
        .delete(`/teams/delete-team/${teamId}`)
        .then((response) => {
          let status = response.data.status;
          let message = response.data.message;
          if (status === "success") {
            successMessage.value = message;
          } else if (status === "error") {
            error.value = message;
          }
        })
        .catch((error) => {
          error.value = "An error has occured! Please try again";
        })
        .finally(() => {
          //load tasks afterwards
          loadListOfTeams();
          loader.hide();
        });
    }

    function editTeamName(teamObj) {
      teamObj.editTeamName = !teamObj.editTeamName;

      teamObj.isDeletingTeam = teamObj.editTeamName;

      teamObj.isViewing = teamObj.editTeamName;
    }

    function submitNewTeamName(e, teamObj) {
      if (e.target.value.length > 0) {
        let newTeamName = e.target.value;
        let UpdateTeamId = teamObj.teamId;
        e.target.disabled = true;

        let team = {
          teamName: newTeamName,
        };

        saving.value = true;

        successMessage.value = null;
        error.value = null;

        Api()
          .patch(`/teams/update-team-name/${UpdateTeamId}`, team)
          .then((response) => {
            let status               = response.data.status;
            let message              = response.data.message;
            let responseTeamName     = response.data.teamName;
            if (status === "success") {

              successMessage.value = message;
              teamObj.teamName = responseTeamName;
              teamObj.editTeamName = false;
              
            } else {

              error.value = message;
              teamObj.teamName = newTeamName;
              teamObj.editTeamName = true;
              e.target.disabled = false;
            }

          })
          .catch((error) => {
            
          })
          .finally(() => {
            saving.value = false;
          });
      }
    }

    const goToTeamMembers = () => {
      showGuide.value = false;
      document.querySelectorAll('.teamname-link')[document.querySelectorAll('.teamname-link').length - 1].click();
    }    

    function goToInvite(){
     
      emit('goToInvite')
    }

    const closeGuide = () => {
      showGuide.value = false;
      tours['myTour'].start();
      
    }

    loadListOfTeams(true);

    return {
      listOfTeams,
      formatDate,
      inputTeamName,
      addTeam,
      disableAddTeamButton,
      error,
      deleteTeam,
      editTeamName,
      submitNewTeamName,
      alertAddingTeamName,
      alertTeamNameText,
      currentComponent,
      swapComponent,
      tempTeamId,
      tempTeamName,
      successMessage,
      showDeleteModal,
      activeDelete,
      deleteCreatedTeam,
      saving,
      showGuide,
      guideStage,
      goToTeamMembers,
      goToInvite,
      steps,
      closeGuide,
      canShowTeamsGuide,
      showTeamTour
    };
  },
};
</script>

<style></style>
