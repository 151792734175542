<template>
  <div class="max-w-2xl mx-auto mt-20">
    <AlertSuccess
      v-if="generalMessage"
      :message="generalMessage"
      @hide="generalMessage = null"
    />

    <h3 class="text-lg leading-6 font-medium text-gray-900">My Profile</h3>
  </div>
  <div class="mt-5 border-t border-gray-200 max-w-2xl mx-auto">
    <dl class="divide-y divide-gray-200">
      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
        <dt class="text-sm font-medium text-gray-500">
          Full name
          <p
            class="mt-1 max-w-2xl text-sm text-red-500"
            v-for="(errorMessage, index) in nameMessage"
            :key="index"
          >
            {{ errorMessage }}
          </p>
        </dt>
        <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <span v-if="!editName" class="flex-grow">{{ name }}</span>

          <div v-else class="flex flex-1">
            <input
              type="text"
              v-model="name"
              class="
                text-gray-900
                ring-gray-900 ring-opacity-5
                placeholder-gray-400
                appearance-none
                bg-white
                rounded-md
                block
                w-full
                px-3
                py-2
                border border-transparent
                shadow
                ring-1
                sm:text-sm
                focus:border-blue-500 focus:ring-blue-500 focus:outline-none
              "
            />
            <XIcon
              @click="editName = false"
              class="
                ml-4
                h-8
                w-8
                text-red-500
                hover:text-red-600
                cursor-pointer
              "
            />
          </div>

          <span
            class="ml-4 flex-shrink-0 w-20 flex justify-center align-center"
          >
            <button
              type="button"
              v-if="!editName"
              @click="editName = true"
              class="
                bg-white
                rounded-md
                font-medium
                text-blue-600
                hover:text-blue-500
                focus:outline-none
                hover:underline
              "
            >
              Edit
            </button>

            <button
              type="button"
              v-else
              @click="update"
              class="
                bg-white
                rounded-md
                font-medium
                text-blue-600
                hover:text-blue-500
                focus:outline-none
                hover:underline
              "
            >
              Update
            </button>
          </span>
        </dd>
      </div>

      <div class="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
        <dt class="text-sm font-medium text-gray-500">
          Email address
          <p
            class="mt-1 max-w-2xl text-sm text-red-500"
            v-for="(errorMessage, index) in emailMessage"
            :key="index"
          >
            {{ errorMessage }}
          </p>
        </dt>
        <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <span v-if="!editEmail" class="flex-grow"> {{ email }}</span>
          <div v-else class="flex flex-1">
            <input
              type="email"
              v-model="email"
              class="
                text-gray-900
                ring-gray-900 ring-opacity-5
                placeholder-gray-400
                appearance-none
                bg-white
                rounded-md
                block
                w-full
                px-3
                py-2
                border border-transparent
                shadow
                sm:text-sm
                focus:border-blue-500 focus:outline-none
              "
            />
            <XIcon
              @click="editEmail = false"
              class="
                ml-4
                h-8
                w-8
                text-red-500
                hover:text-red-600
                cursor-pointer
              "
            />
          </div>
          <span
            class="ml-4 flex-shrink-0 w-20 flex justify-center align-center"
          >
            <button
              v-if="!editEmail"
              type="button"
              @click="editEmail = true"
              class="
                bg-white
                rounded-md
                font-medium
                text-blue-600
                hover:text-blue-500
                focus:outline-none
                hover:underline
              "
            >
              Edit
            </button>

            <button
              v-else
              type="button"
              @click="update"
              class="
                bg-white
                rounded-md
                font-medium
                text-blue-600
                hover:text-blue-500
                focus:outline-none
                hover:underline
              "
            >
              Update
            </button>
          </span>
        </dd>
      </div>

      <div class="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
        <dt class="text-sm font-medium text-gray-500">Password</dt>
        <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <span class="flex-grow">*********</span>
          <span
            class="ml-4 flex-shrink-0 w-20 flex justify-center align-center"
          >
            <button
              type="button"
              @click="toggleChangePassword"
              class="
                bg-white
                rounded-md
                font-medium
                text-blue-600
                hover:text-blue-500
                focus:outline-none
                hover:underline
              "
            >
              Edit
            </button>
          </span>
        </dd>
      </div>
    </dl>
  </div>

  <Modal v-if="!changePassword" :open="true" @dismiss="cancel">
    <template v-slot:header>
      <h5>Change Password</h5>
    </template>
    <template v-slot:body>
      <div class="relative">
        <p
          v-for="(errorMessage, index) in passwordMessage"
          :key="index"
          style="font-size: 0.8rem !important"
          class="text-red-500 mb-2"
        >
          {{ errorMessage }}
        </p>

        <label for="password" class="sr-only">Password:</label>
        <input
          type="password"
          v-model="password"
          placeholder="Password"
          class="
            text-gray-900
            ring-gray-900 ring-opacity-5
            placeholder-gray-400
            appearance-none
            bg-white
            rounded-md
            block
            w-full
            px-3
            py-2
            border border-transparent
            shadow
            ring-1
            sm:text-sm
            mb-4
            focus:border-blue-500 focus:ring-blue-500 focus:outline-none
          "
          id="password"
        />
      </div>
      <div class="relative mb-3">
        <label for="password_confirmation" class="sr-only"
          >Confirm Password:</label
        >
        <input
          type="password"
          v-model="password_confirmation"
          placeholder="Confirm Password"
          class="
            text-gray-900
            ring-gray-900 ring-opacity-5
            placeholder-gray-400
            appearance-none
            bg-white
            rounded-md
            block
            w-full
            px-3
            py-2
            border border-transparent
            shadow
            ring-1
            sm:text-sm
            mb-4
            focus:border-blue-500 focus:ring-blue-500 focus:outline-none
          "
          id="password_confirmation"
        />
      </div>
      <button
        type="submit"
        @click="update"
        :disabled="disableSave"
        v-bind:class="{ disabled: enableSaveChangesButton }"
        class="
          block
          w-full
          py-2
          px-3
          border border-transparent
          rounded-md
          text-white
          font-medium
          bg-blue-500
          shadow-sm
          sm:text-sm
          mb-10
          hover:bg-blue-600
          focus:outline-none
          focus-visible:ring-2
          focus-visible:ring-gray-700
          focus-visible:ring-offset-2
          focus-visible:ring-offset-gray-50
        "
      >
        Save Changes
      </button>
    </template>
  </Modal>
</template>

<script>
import { ref, computed, inject } from "vue";
import Api from "@/api/Api.js";
import { XIcon } from "@heroicons/vue/outline";
import AlertSuccess from "./AlertSuccess";
import Modal from "./Modal";
import { useLoading } from "vue-loading-overlay";

export default {
  components: { AlertSuccess, XIcon, Modal },
  emits: ["user_profile_changed"],
  setup(_, context) {
    //Get userid and intialize variable userid
    let userFromStorage = JSON.parse(localStorage.getItem("user"));

    let userid = userFromStorage.id;
    let name = ref(userFromStorage.name);
    let email = ref(userFromStorage.email);
    let password = ref("");
    let password_confirmation = ref("");
    let changePassword = ref(true);
    let enableSaveChangesButton = ref(false);
    let errorFlag = ref(null);
    const editName = ref(false);
    const editEmail = ref(false);
    const $loading = useLoading();

    const disableSave = computed(() => {
      if (
        name.value.trim().length > 0 &&
        email.value.trim().length > 0 &&
        ((password.value.trim().length > 0 &&
          password_confirmation.value.trim().length > 0) ||
          changePassword.value)
      ) {
        generalMessage.value = null;
        return false;
      }
      errorFlag.value = true;
      generalMessage.value = "Please fill in all fields";
      return true;
    });

    let generalMessage = ref("");
    //error messages
    let nameMessage = ref(null);
    let emailMessage = ref(null);
    let passwordMessage = ref(null);

    function update() {
      errorFlag.value = null;

      let userPassword = {
        id: String(userid),
        name: name.value,
        email: email.value,
        changePassword: "1",
        password: password.value,
        password_confirmation: password_confirmation.value,
      };

      let userNoPassword = {
        id: String(userid),
        changePassword: "0",
        name: name.value,
        email: email.value,
      };

      //reset error messages
      nameMessage.value = [];
      emailMessage.value = [];
      passwordMessage.value = [];

      const loader = $loading.show({
        color: "#2581ef",
        opacity: 1,
      });

      enableSaveChangesButton.value = true;
      if (changePassword.value === false) {
        Api()
          .post("/user/edit", userPassword)
          .then((response) => {
            let user = response.data.user;
            let status = response.data.status;
            let statusCategory = response.data.status_category;
            let message = response.data.message;

            if (status === "success") {
              localStorage.setItem("user", JSON.stringify(user));
              generalMessage.value = message;
              changePassword.value = true;
              password.value = "";
              password_confirmation.value = "";
              editName.value = false;
              editEmail.value = false;
              changePassword.value = true;
              context.emit("user_profile_changed");
            } else if (status === "error" && statusCategory === "0") {
              emailMessage.value[0] = message;
              //email.value = userFromStorage.email;
            } else if (status === "error" && statusCategory == "2") {
              passwordMessage.value[0] = message;
            } else if (status === "error" && statusCategory == "1") {
              nameMessage.value[0] = message;
            }
          })
          .catch((error) => {
            if (error.response.status === 422) {
              nameMessage.value = error.response.data.errors.name;
              emailMessage.value = error.response.data.errors.email;
              passwordMessage.value = error.response.data.errors.password;
            }
          })
          .finally(() => {
            enableSaveChangesButton.value = false;
            loader.hide();
          });
      } else if (changePassword.value === true) {
        Api()
          .post("/user/edit", userNoPassword)
          .then((response) => {
            let user = response.data.user;
            let status = response.data.status;
            let message = response.data.message;
            let statusCategory = response.data.status_category;

            if (status === "success") {
              localStorage.setItem("user", JSON.stringify(user));
              context.emit("user_profile_changed");
              generalMessage.value = message;
              editName.value = false;
              editEmail.value = false;
              changePassword.value = true;
            } else if (status === "error" && statusCategory === "0") {
              emailMessage.value[0] = message;
              // email.value = userFromStorage.email;
            } else if (status === "error" && statusCategory == "1") {
              nameMessage.value[0] = message;
              //editName.value = true;
            }
          })
          .catch((error) => {
            if (error.response.status === 422) {
              nameMessage.value = error.response.data.errors.name;
              emailMessage.value = error.response.data.errors.email;
              //passwordMessage.value = error.response.data.errors.password;
            }
          })
          .finally(() => {
            enableSaveChangesButton.value = false;
            loader.hide();
          });
      }
    }

    function toggleChangePassword() {
      changePassword.value = !changePassword.value;
    }

    function cancel() {
      changePassword.value = true;
      password.value = "";
      password_confirmation.value = "";
      userFromStorage = JSON.parse(localStorage.getItem("user"));
      name.value = userFromStorage.name;
      email.value = userFromStorage.email;
      //reset error messages
      nameMessage.value = [];
      emailMessage.value = [];
      passwordMessage.value = [];

      generalMessage.value = "";
    }

    return {
      name,
      email,
      password,
      password_confirmation,
      update,
      changePassword,
      toggleChangePassword,
      cancel,
      enableSaveChangesButton,
      nameMessage,
      emailMessage,
      passwordMessage,
      generalMessage,
      disableSave,
      errorFlag,
      editName,
      editEmail,
    };
  },
};
</script>

<style></style>
