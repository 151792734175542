<template>
  <div>
    <div class="mb-5">
      <div class="col-sm-12">
        <button
          class="capitalize flex text-blue-500 hover:underline"
          v-if="currentViewComponent"
          @click="changeComponent(null, null, null, null, null, null, null,null)"
        >
          <ArrowLeftIcon class="mr-2 h-6 w-6" />
          back to content tasks
        </button>
      </div>
    </div>
    <component
      :is="currentViewComponent"
      :assigned_to_user="prop_user_id"
      :contentBriefId="prop_contentBrief_Id"
      :task_id="prop_task_Id"
      :templateLoaded="prop_templateLoaded"
      :status_id="prop_task_status_id"
      :canModify="false"
      :task="activeTask"
      :templateId="prop_template_id"
    >
    </component>
        <div>
          <h1 class="text-2xl font-semibold text-gray-700">{{project_name}} Tasks</h1>
        </div>
    <div class="text-center" v-if="!currentViewComponent">
      <SwitchGroup>
        <div class="sm:flex text-sm sm:items-center sm:justify-end">
          <SwitchLabel class="mr-4">Active  Tasks</SwitchLabel>
          <Switch
              v-model="archivedTask"
              :class='archivedTask ? "bg-blue-600" : "bg-gray-200"'
              class="relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none "
          >
        <span
            :class='archivedTask ? "translate-x-6" : "translate-x-1"'
            class="inline-block h-4 w-4 transform rounded-full bg-white transition-transform"
        />
          </Switch>
          <SwitchLabel class="ml-4">Archived Tasks</SwitchLabel>

        </div>
      </SwitchGroup>
      <div class="my-6">
        <button
          @click="showCreateTaskModal"
          type="button"
          class="
            inline-flex
            items-center
            px-4
            py-2
            border border-transparent
            shadow-sm
            text-sm
            font-medium
            rounded-md
            text-white
            bg-blue-600
            hover:bg-blue-700
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-blue-500
          "
        >
          <PlusIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
          Create New Content Task
        </button>

        <TransitionRoot as="template" :show="showTaskModal">
          <Dialog
            as="div"
            class="fixed z-10 inset-0 overflow-y-auto"
            @close="closeCreateTaskModal"
          >
            <div
              class="
                flex
                items-end
                justify-center
                min-h-screen
                pt-4
                px-4
                pb-20
                text-center
                sm:block sm:p-0
              "
            >
              <TransitionChild
                as="template"
                enter="ease-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in duration-200"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <DialogOverlay
                  class="
                    fixed
                    inset-0
                    bg-black bg-opacity-75
                    transition-opacity
                  "
                />
              </TransitionChild>

              <!-- This element is to trick the browser into centering the modal contents. -->
              <span
                class="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
                >&#8203;</span
              >
              <TransitionChild
                as="template"
                enter="ease-out duration-300"
                enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enter-to="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leave-from="opacity-100 translate-y-0 sm:scale-100"
                leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div
                  class="
                    inline-block
                    align-bottom
                    bg-white
                    rounded-lg
                    px-4
                    pt-5
                    pb-4
                    text-left
                    modal-mh
                    shadow-xl
                    transform
                    transition-all
                    sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6
                  "
                >
                  <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                    <button
                      type="button"
                      class="
                        bg-white
                        rounded-md
                        text-gray-400
                        hover:text-gray-500
                        focus:outline-none
                        focus:ring-2
                        focus:ring-offset-2
                        focus:ring-blue-500
                      "
                      @click="closeCreateTaskModal"
                    >
                      <span class="sr-only">Close</span>
                      <XIcon class="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div class="sm:flex sm:items-start">
                    <form @submit.prevent="onSaveTask" class="w-full">
                      <div class="mt-3 text-center sm:mt-0 sm:text-left w-full">
                        <DialogTitle
                          as="h3"
                          class="text-lg leading-6 font-medium text-gray-900"
                        >
                          Create Content Task
                        </DialogTitle>
                        <div class="mt-6">
                          <div class="mb-3">
                            <label for="inputTitle" class="sr-only"
                              >Enter Topic / Task Title</label
                            >
                            <input
                              type="text"
                              name="inputTitle"
                              v-model="inputTitle"
                              id="inputTitle"
                              class="
                                text-gray-900
                                ring-gray-900 ring-opacity-5
                                placeholder-gray-400
                                appearance-none
                                bg-white
                                rounded-md
                                block
                                w-full
                                px-3
                                py-2
                                border border-transparent
                                shadow
                                ring-1
                                sm:text-sm
                                mb-4
                                focus:border-blue-500
                                focus:ring-blue-500
                                focus:outline-none
                              "
                              placeholder="Enter Topic / Task Title"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="mt-5 sm:mt-6">
                        <button
                          type="submit"
                          v-bind:class="{ disabled: disableSaveButton }"
                          :disabled="disableSave"
                          name="inputSave"
                          class="
                            inline-flex
                            justify-center
                            w-full
                            rounded-md
                            border border-transparent
                            disabled:bg-blue-200
                            shadow-sm
                            px-4
                            py-2
                            bg-blue-600
                            text-base
                            font-medium
                            text-white
                            hover:bg-blue-700
                            focus:outline-none
                            focus:ring-2
                            focus:ring-offset-2
                            focus:ring-blue-500
                            sm:text-sm
                          "
                          @click="open = false"
                        >
                          save
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </TransitionChild>
            </div>
          </Dialog>
        </TransitionRoot>
      </div>
      <AlertSuccess
        v-if="successMessage"
        :message="successMessage"
        @hide="successMessage = null"
      ></AlertSuccess>
      <AlertError
        v-if="errorMessage"
        :message="[errorMessage]"
        @hide="errorMessage = null"
      >
      </AlertError>
    </div>

    <div class="flex flex-col" v-if="!currentViewComponent">
      <div>
        <div class="py-2 align-middle inline-block">
          <div class="shadow border-b border-gray-200 sm:rounded-lg">
            <table class=" divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                  >
                    Name
                    <Filter @filter="filterByName" />
                  </th>
                  <th
                    scope="col"
                    class="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                  >
                    Status
                    <Filter @filter="filterByStatus" />
                  </th>
                  <th
                    scope="col"
                    class="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                  >
                    Stage
                    
                    <Filter @filter="filterByStage" />
                  </th>
                  <th
                    scope="col"
                    class="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                  >
                    Content Template
                  </th>
                  <th
                    scope="col"
                    class="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                  >
                    Assigned On
                  </th>
                  <th
                    scope="col"
                    class="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                  >
                    Due Date
                  </th>
                  <th
                    scope="col"
                    class="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                  >
                    Assignee
                  </th>

                  <th scope="col" class="relative px-6 py-3">
                    <span class="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr
                  :id="'task' + task.task_id"
                  v-for="(task, taskIndex) in getTasks"
                  :key="task.task_id"
                >
                  <td
                    class="
                      px-6
                      py-4
                      whitespace-nowrap
                      text-sm
                      font-medium
                      text-gray-900
                    "
                  >
                    <span
                      class="
                        underline
                        cursor-pointer
                        capitalize
                        hover:text-blue-500
                      "
                      @click="
                        activeTask = task;
                        changeComponent(
                          'ContentEditor',
                          task.stageAssignments,
                          task.workflow_stage,
                          task.contentBriefId,
                          task.task_id,
                          task.templateLoaded,
                          task.status_id,
                          task.templateId
                        );
                      "
                      :id="taskIndex == 0? 'v-step-2' : ''"
                    >
                      {{ task.taskTitle }}
                    </span>
                  </td>
                  <td
                    class="
                      px-6
                      py-4
                      whitespace-nowrap
                      text-sm text-gray-500
                      capitalize
                    "
                  >
                    <span
                      class="
                        px-2
                        inline-flex
                        text-xs
                        leading-5
                        font-semibold
                        rounded-full
                        bg-yellow-100
                        text-yellow-800
                      "
                      v-if="task.statusName.toLowerCase() === 'pending'"
                    >
                      {{ task.statusName }}
                    </span>

                    <span
                      class="
                        px-2
                        inline-flex
                        text-xs
                        leading-5
                        font-semibold
                        rounded-full
                        bg-green-100
                        text-green-800
                      "
                      v-else-if="task.statusName.toLowerCase() === 'done'"
                    >
                      {{ task.statusName }}
                    </span>

                    <span
                      class="
                        px-2
                        inline-flex
                        text-xs
                        leading-5
                        font-semibold
                        rounded-full
                        bg-blue-100
                        text-blue-800
                      "
                      v-else-if="
                        task.statusName.toLowerCase() === 'in progress'
                      "
                    >
                      {{ task.statusName }}
                    </span>

                    <span v-else>
                      {{ task.statusName }}
                    </span>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ task.stageName }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    <select
                      style="width: 165px"
                      class="px-2 cursor-pointer"
                      name="templates"
                      @change="
                        task.status_id == 1
                          ? onChangeSelectedTemplate($event, task.task_id)
                          : ''
                      "
                      v-model="taskTemplates[taskIndex]"
                      :disabled="
                        task.status_id > 1 || task.assignedTo ? true : false
                      "
                    >
                      <option value="null" selected>pick a template</option>
                      <option
                        v-for="template in listOfTemplates"
                        :key="template.id"
                        v-bind:value="template.id"
                      >
                        {{ template.templateName }}
                      </option>
                    </select>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    <div
                      v-if="
                        (cdate = getAssignedOnDateForStage(
                          task.workflow_stage,
                          task.stageAssignments
                        ))
                      "
                    >
                      {{ cdate }}
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    <div
                      v-if="
                        (ddate = getDueOnDateForStage(
                          task.workflow_stage,
                          task.stageAssignments
                        ))
                      "
                    >
                      {{ ddate }}
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    <div
                      class="
                        w-40
                        text-ellipsis
                        whitespace-nowrap
                        overflow-hidden
                      "
                      :title="
                        getCurrentAssignee(
                          task.assignedTo,
                          task.stageAssignments
                        )
                      "
                    >
                      {{
                        getCurrentAssignee(
                          task.assignedTo,
                          task.stageAssignments
                        )
                      }}
                    </div>
                  </td>
                  <td
                    class="
                      px-6
                      py-4
                      whitespace-nowrap
                      text-right text-sm
                      font-medium
                    "
                  >
                    <Menu as="div" class="relative inline-block text-left">
                      <div>
                        <MenuButton
                          class="
                            inline-flex
                            justify-center
                            w-full
                            rounded-md
                            border-gray-300
                            px-4
                            bg-white
                            text-sm
                            font-medium
                            text-blue-500
                            hover:underline
                            focus:outline-none
                          "
                          :id="taskIndex == 0? 'v-step-0' : ''"
                        >
                          Manage
                          <ChevronDownIcon
                            class="-mr-1 ml-2 h-5 w-5"
                            aria-hidden="true"
                          />
                        </MenuButton>
                      </div>

                      <transition
                        enter-active-class="transition ease-out duration-100"
                        enter-from-class="transform opacity-0 scale-95"
                        enter-to-class="transform opacity-100 scale-100"
                        leave-active-class="transition ease-in duration-75"
                        leave-from-class="transform opacity-100 scale-100"
                        leave-to-class="transform opacity-0 scale-95"
                      >
                        <MenuItems
                          class="
                            origin-top-right
                            absolute
                            right-0
                            mt-2
                            w-56
                            rounded-md
                            shadow-lg
                            bg-white
                            ring-1 ring-black ring-opacity-5
                            focus:outline-none
                            z-20
                          "
                        >
                          <div class="py-1">
                            <MenuItem v-if="task.isDeleted == 0" id="task-mgt-step-1" v-slot="{ active }">
                              <a
                                href="#"
                                @click="
                                  activeTask = task;
                                  showContentBriefModal(
                                    task.contentBriefId,
                                    task.task_id
                                  );
                                "
                                :class="[
                                  active
                                    ? 'bg-gray-100 text-gray-900'
                                    : 'text-gray-700',
                                  'block px-4 py-2 text-sm',
                                ]"
                                >Content Briefs</a
                              >
                            </MenuItem>
                            <MenuItem v-if="task.isDeleted == 0" id="v-step-1" v-slot="{ active }">
                              <a
                                href="#"
                                @click="
                                  activeTask = task;
                                  showConfigureModal(
                                    task.stageAssignments,
                                    task.status_id,
                                    task.stageName
                                  );
                                "
                                :class="[
                                  active
                                    ? 'bg-gray-100 text-gray-900'
                                    : 'text-gray-700',
                                  'block px-4 py-2 text-sm',
                                ]"
                                >Assign Task</a
                              >
                            </MenuItem>

                            <MenuItem v-if="task.isDeleted == 0" id="task-mgt-step-3" v-slot="{ active }">
                              <a
                                href="#"
                                @click="
                                  activeTask = task;
                                  showEditNameModal = true;
                                "
                                :class="[
                                  active
                                    ? 'bg-gray-100 text-gray-900'
                                    : 'text-gray-700',
                                  'block px-4 py-2 text-sm',
                                ]"
                                >Edit Task Name</a
                              >
                            </MenuItem>

                            <MenuItem v-if="task.isDeleted == 0" id="task-mgt-step-4" v-slot="{ active }">
                              <a
                                  href="#"
                                  @click="activeTask = task; showChangeTaskStagePrompt=true;"
                                  :class="[
                                  active
                                    ? 'bg-gray-100 text-gray-900'
                                    : 'text-gray-700',
                                  'block px-4 py-2 text-sm',
                                ]"
                              >Change Stage</a>
                            </MenuItem>


                            <MenuItem v-if="!archivedTask" id="task-mgt-step-5" v-slot="{ active }">
                              <a
                                  href="#"
                                  @click="
                                  archiveTask(task.task_id)
                              "
                                  :class="[
                                  active
                                    ? 'bg-gray-100 text-yellow-900'
                                    : 'text-yellow-700',
                                  'block px-4 py-2 text-sm',
                                ]"
                              > Archive Task</a
                              >
                            </MenuItem>
                            <MenuItem v-else v-slot="{ active }">
                              <a
                                  href="#"
                                  @click="
                                  unArchiveTask(task.task_id)
                              "
                                  :class="[
                                  active
                           ? 'bg-gray-100 text-yellow-900'
                                    : 'text-yellow-700',
                                  'block px-4 py-2 text-sm',
                                ]"
                              >Un-Archive Task</a
                              >
                            </MenuItem>


                            <MenuItem v-if="task.isDeleted == 0" id="task-mgt-step-6" v-slot="{ active }">
                              <a
                                href="#"
                                @click="
                                  activeTask = task;
                                  showDeleteModal = true;
                                "
                                :class="[
                                  active ? 'bg-gray-100' : '',
                                  'block px-4 py-2 text-sm text-red-500',
                                ]"
                                >Delete Task</a
                              >
                            </MenuItem>
                          </div>
                        </MenuItems>
                      </transition>
                    </Menu>
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="flex justify-center my-4" v-if="showInlineLoader">
              <InlineLoader color="#2581ef" :show="true" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!currentViewComponent">
      <Modal
        v-if="showConfigureWorkflowModal"
        @dismiss="closeConfigureWorkflowModal"
        :open="showEditModal"
      >
        <template v-slot:header>
          <h5 class="modal-title text-danger capitalize">
            Configure <span class="text-blue-500">{{ activeTask.taskTitle }}</span>  Workflow Assignments
          </h5>
          <br />
        </template>

        <template v-slot:body>
          <div class="mb-4">
            <label for="inputTeam" class="form-label"
              >Select a Team to use</label
            >

            <select
              id="inputTeam"
              name="inputTeam"
              v-model="inputTeam"
              :disabled="currentTaskStatus != 1 ? true : false"
              class="
                mt-1
                block
                w-full
                pl-3
                pr-10
                py-2
                border
                text-base
                border-gray-300
                focus:outline-none focus:ring-blue-500 focus:border-blue-500
                sm:text-sm
                rounded-md
              "
              @change="
                onChange(
                  $event,
                  null,
                  listOfStages,
                  activeTask.stageAssignments
                )
              "
            >
              <option value="Choose a Team" selected disabled>
                Choose a Team
              </option>
              <option
                v-for="team in listOfTeams"
                :key="team.teamId"
                v-bind:value="team.teamId"
              >
                {{ team.teamName }}
              </option>
            </select>
          </div>
          <form @submit.prevent="saveConfigureWorkflow" class="mt-10">
            <!-- <div v-for="(stage,index) in listOfStages" :key="stage.id" class="row pb-3"> -->

            <div class="flex flex-col">
              <div>
                <div class="py-2 align-middle inline-block min-w-full">
                  <div class="modal-mh">
                    <table class="min-w-full divide-gray-200">
                      <thead class="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            class="
                              px-6
                              py-3
                              text-center text-xs
                              font-medium
                              text-gray-500
                              uppercase
                              tracking-wider
                            "
                          >
                            Stage
                          </th>
                          <th
                            scope="col"
                            class="
                              px-6
                              py-3
                              text-center text-xs
                              font-medium
                              text-gray-500
                              uppercase
                              tracking-wider
                            "
                          >
                            Assignee Name
                          </th>

                          <th
                            scope="col"
                            class="
                              px-6
                              py-3
                              text-center text-xs
                              font-medium
                              text-gray-500
                              uppercase
                              tracking-wider
                            "
                          >
                            Due Date
                          </th>
                        </tr>
                      </thead>
                      <tbody class="bg-white divide-gray-200">
                        <tr
                          v-for="(stage, index) in listOfStages"
                          :key="stage.id"
                        >
                          <td
                            class="
                              px-6
                              py-4
                              whitespace-nowrap
                              text-sm
                              font-medium
                              text-gray-900
                            "
                          >
                            {{ stage.stageName }}
                          </td>
                          <td
                            class="
                              px-6
                              py-4
                              whitespace-nowrap
                              text-sm text-gray-500
                            "
                          >
                            <select
                              name="inputAssignTo"
                              class="
                                text-gray-900
                                ring-gray-900 ring-opacity-5
                                placeholder-gray-400
                                appearance-none
                                bg-white
                                rounded-md
                                block
                                w-full
                                px-3
                                py-2
                                border border-transparent
                                shadow
                                ring-1
                                sm:text-sm
                                mb-4
                                focus:border-blue-500
                                focus:ring-blue-500
                                focus:outline-none
                              "
                              v-model="inputAssignTo[index]"
                              :disabled="
                                stage.stageName == currentTaskStageName &&
                                currentTaskStatus != 1
                                  ? true
                                  : false
                              "
                            >
                              <option
                                :title="inputAssignTo[index]"
                                :stage="currentTaskStageName"
                                :st="stage.stageName"
                                :team="inputTeam"
                                :originalTeam="originalTeam"
                                :selected="inputTeam !== originalTeam"
                                value="Pick Assignee"
                              >
                                Pick Assignee
                              </option>

                              <template
                                v-for="(assignee, index2) in listOfAssignees"
                                :key="index2"
                              >
                                <option
                                  :title="inputAssignTo[index]"
                                  v-bind:value="assignee.team_members_id"
                                >
                                  {{ assignee.name }}
                                </option>
                              </template>
                            </select>
                          </td>
                          <td>
                            <input
                              name="dueDate"
                              type="date"
                              class="
                                text-gray-900
                                ring-gray-900 ring-opacity-5
                                placeholder-gray-400
                                appearance-none
                                bg-white
                                rounded-md
                                block
                                w-full
                                px-3
                                py-2
                                border border-transparent
                                shadow
                                ring-1
                                sm:text-sm
                                mb-4
                                focus:border-blue-500
                                focus:ring-blue-500
                                focus:outline-none
                              "
                              :min="currentDate"
                              v-model="dueDate[index]"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-5 sm:mt-6">
              <button
                name="inputSave"
                class="
                  inline-flex
                  justify-center
                  w-full
                  rounded-md
                  border border-transparent
                  disabled:bg-blue-200
                  shadow-sm
                  px-4
                  py-2
                  bg-blue-600
                  text-base
                  font-medium
                  text-white
                  hover:bg-blue-700
                  focus:outline-none
                  focus:ring-2
                  focus:ring-offset-2
                  focus:ring-blue-500
                  sm:text-sm
                "
                :disabled="currentTaskStatus == 3"
              >
                <InlineLoader :show="saving" class="ml-4" />
                Save
              </button>
            </div>
          </form>
        </template>
      </Modal>

      <Modal
        v-if="showContentBrief"
        @dismiss="closeContentBriefModal"
        :open="showEditModal"
      >
        <template v-slot:header>
          <button v-if="activeContentBrief" class="flex text-blue-500 hover:underline float-left align-center text-xs"
                  @click="showBriefEditor=false;activeContentBrief=null;">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left mr-1" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
            </svg>
            Back
          </button>

          <button v-if="showBriefEditor && !activeContentBrief" class="flex text-blue-500 hover:underline float-left align-center text-xs"
                  @click="showBriefEditor=false;activeContentBrief=null;">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
            </svg>
            More Briefs
          </button>
          <h5 class="capitalize"> <span class="text-blue-500">{{ activeTask.taskTitle }}</span> Content Brief</h5>
        </template>

        <template v-slot:body>
          <div v-if="showBriefEditor">
          <div class="row m-1">
            <div class="col-12 m-2">
              <input
                type="text"
                placeholder="content brief name"
                v-model="contentBriefName"
                class="
                  text-gray-900
                  ring-gray-900 ring-opacity-5
                  placeholder-gray-400
                  appearance-none
                  bg-white
                  rounded-md
                  block
                  w-full
                  px-3
                  py-2
                  border border-transparent
                  shadow
                  ring-1
                  sm:text-sm
                  mb-4
                  focus:border-blue-500 focus:ring-blue-500 focus:outline-none
                "
              />
            </div>
          </div>
          <form class="mx-2" @submit.prevent="saveContentBrief">
            <div class="modal-mh">
              <div
                v-for="(section, counter) in contentBriefSections"
                v-bind:key="counter"
              >
                <div class="flex pb-3" :id="'row-' + counter">
                  <div class="mr-2">
                    <input
                      type="text"
                      placeholder="title"
                      v-model.lazy="section.itemTitle"
                      class="
                        text-gray-900
                        ring-gray-900 ring-opacity-5
                        placeholder-gray-400
                        appearance-none
                        bg-white
                        rounded-md
                        block
                        w-full
                        px-3
                        py-2
                        border border-transparent
                        shadow
                        ring-1
                        sm:text-sm
                        mb-4
                        focus:border-blue-500
                        focus:ring-blue-500
                        focus:outline-none
                      "
                    />
                  </div>
                  <div class="flex-1 mr-2">
                    <textarea
                      class="
                        text-gray-900
                        flex-1
                        ring-gray-900 ring-opacity-5
                        placeholder-gray-400
                        appearance-none
                        bg-white
                        rounded-md
                        block
                        w-full
                        px-3
                        py-2
                        border border-transparent
                        shadow
                        ring-1
                        sm:text-sm
                        mb-4
                        focus:border-blue-500
                        focus:ring-blue-500
                        focus:outline-none
                      "
                      placeholder="Describe item here"
                      v-model.lazy="section.itemDescription"
                      rows="3"
                      cols="5"
                    ></textarea>
                  </div>
                  <div class="col-1">
                    <span @click="deleteRow(counter)" title="Delete Row">
                      <XIcon
                        class="
                          h-5
                          w-5
                          cursor-pointer
                          hover:text-red-500
                          text-gray-500
                        "
                      />
                    </span>
                  </div>
                </div>
              </div>

              <div class="p-2">
                <button
                  type="button"
                  class="
                    text-sm text-blue-500
                    hover:underline
                    cursor-pointer
                    flex
                    justify-center
                    mx-auto
                    disabled:bg-blue-200
                  "
                  :disabled="disableAddRow"
                  @click="addRow"
                >
                  <PlusIcon class="w-5 h-5 mr-2" />
                  Add Row
                </button>
              </div>
            </div>

            <div class="mt-5 sm:mt-6">
              <button
                name="inputSave"
                v-bind:class="{ disabled: disableContentBriefSaveButton }"
                :disabled="disableContentBriefSave || currentTaskStatus == 3"
                class="
                  inline-flex
                  justify-center
                  w-full
                  rounded-md
                  border border-transparent
                  disabled:bg-blue-200
                  shadow-sm
                  px-4
                  py-2
                  bg-blue-600
                  text-base
                  font-medium
                  text-white
                  hover:bg-blue-700
                  focus:outline-none
                  focus:ring-2
                  focus:ring-offset-2
                  focus:ring-blue-500
                  sm:text-sm
                "
              >
                <InlineLoader :show="saving" class="ml-4" />
                Save
              </button>
            </div>
          </form>
          </div>
          <div class="flex justify-between p-3 " v-else>
             <div class="flex-1 px-5">
               <h3 class="text-center text-lg leading-6 font-medium text-gray-900">Existing Content Briefs</h3>
               <select v-model="selectedBrief" class=" w-full mt-4   px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                 <option value="select">Select Brief</option>
                 <option v-for="(brief,index) in contentBriefs" :key="brief.contentBriefName" :value="index">{{brief.contentBriefName}}</option>
               </select>
             </div>
            <div class="bg-gray-100  w-0.5 mx-5">
            </div>
             <div class="flex-1">
               <h3 class="text-center text-lg leading-6 font-medium text-gray-900">New Content Brief</h3>
               <button @click="showBriefEditor=true;" type="button" class="mt-4 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                 <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="-ml-1 mr-2 h-5 w-5">
                   <path fill-rule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clip-rule="evenodd"></path>
                 </svg> Create Content Brief </button>
             </div>
          </div>
        </template>
      </Modal>

      <DeleteAlert
        :show="showDeleteModal"
        item="Task"
        @cancel="showDeleteModal = false"
        @delete="deleteTask"
      ></DeleteAlert>

      <Modal @dismiss="showEditNameModal = false" :open="showEditNameModal">
        <template v-slot:header>
          <h5 class="modal-title text-danger capitalize">Edit Task Name</h5>
          <br />
        </template>

        <template v-slot:body>
          <div class="relative">
            <label for="edit-task-name" class="sr-only">Name:</label>
            <input
              id="edit-task-name"
              type="text"
              placeholder="Name"
              :value="activeTask.taskTitle"
              class="
                text-gray-900
                ring-gray-900 ring-opacity-5
                placeholder-gray-400
                appearance-none
                bg-white
                rounded-md
                block
                w-full
                px-3
                py-2
                border border-transparent
                shadow
                ring-1
                sm:text-sm
                mb-4
                focus:border-blue-500 focus:ring-blue-500 focus:outline-none
              "
            />
          </div>

          <button
            type="submit"
            @click.prevent="updateTaskName"
            class="
              flex
              justify-center
              items-center
              w-full
              py-2
              px-3
              border border-transparent
              rounded-md
              text-white
              font-medium
              bg-blue-500
              shadow-sm
              sm:text-sm
              mb-10
              hover:bg-blue-600
              focus:outline-none
              focus-visible:ring-2
              focus-visible:ring-gray-700
              focus-visible:ring-offset-2
              focus-visible:ring-offset-gray-50
            "
          >
            Save
          </button>

          <br />
        </template>
      </Modal>

      <Modal @dismiss="showTaskPrompt = false" :open="showTaskPrompt">
        <template v-slot:header>
          <h5 class="modal-title text-danger capitalize">Un-Archive Task</h5>
        </template>
        <template v-slot:body>
          <p class="text-gray-500">You need to un-archive project before you can see its content</p>
        </template>
      </Modal>

      <Modal @dismiss="showChangeTaskStagePrompt = false" :open="showChangeTaskStagePrompt">
        <template v-slot:header>
          <h5 class="modal-title text-danger capitalize">Workflow Stages</h5>
        </template>
        <template v-slot:body>
          <form id="change-stage">
            <fieldset class="px-10">
              <div class="mt-4 border-t border-b border-gray-200 divide-y divide-gray-200">

                <div v-for="(stage, sideIdx) in listOfStages" :key="sideIdx" class="relative flex items-start py-4">
                  <div class="min-w-0 flex-1 text-sm text-left">
                    <label :for="`side-${stage.id}`" class="font-medium text-gray-700 select-none cursor-pointer block">{{ stage.stageName }}</label>
                  </div>
                  <div class="ml-3 flex items-center h-5">
                    <input :id="`side-${stage.id}`" name="plan" type="radio" v-model="taskStage" :value="stage.id" :checked="stage.id === activeTask.workflow_stage" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300" />
                  </div>
                </div>
              </div>
            </fieldset>
            <div class="mt-3">
              <button @click="updateTaskStage" type="button" class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Save Task Stage</button>
            </div>
          </form>
        </template>
      </Modal>
    </div>
    <div>
      <v-tour class="taskclass" name="myTour" :steps="steps" :callbacks="myCallbacks" :options="myOptions"></v-tour>
    </div>
    <div>
      <v-tour class="taskclass" name="taskManagementTour" :steps="taskManagementToursteps" :callbacks="myCallbacksTaskManagementTour" :options="myOptionsTaskManagementTour"></v-tour>
    </div>
  </div>
  <GuideModal v-if="canShowTasksGuide == 0 || canShowTasksGuide  == null" :is-open="showGuide"  @close="closeGuide"/>
</template>

<script>
import Modal from "@/components/Modal.vue";
import {ref, computed, onMounted, watch, inject} from "vue";
import ContentEditor from "@/components/ContentEditor.vue";
import Api from "@/api/Api.js";
import { PlusIcon, XIcon, ChevronDownIcon } from "@heroicons/vue/solid";
import { ArrowLeftIcon } from "@heroicons/vue/outline";
import { useLoading } from "vue-loading-overlay";
import Filter from "./Filter";
import GuideModal from "./GuideModal";
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems, Switch, SwitchGroup, SwitchLabel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import DeleteAlert from "./DeleteAlert";
import AlertSuccess from "./AlertSuccess";
import InlineLoader from "./InlineLoader";
import AlertError from "./AlertError";
import inlineLoader from "./InlineLoader";

export default {
  components: {
    Modal,
    ContentEditor,
    PlusIcon,
    Dialog,
    DialogOverlay,
    DialogTitle,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
    XIcon,
    ChevronDownIcon,
    ArrowLeftIcon,
    InlineLoader,
    Filter,
    DeleteAlert,
    AlertSuccess,
    AlertError,
    inlineLoader,
    Switch, SwitchGroup, SwitchLabel, GuideModal
  },
  props: {
    project_id: {
      type: Number,
      required: false,
      default: false,
    },
    project_name: {
      type: String,
      required: false,
      default: false,
    },
    workflow_id: {
      type: Number,
      required: false,
      default: false,
    },
  },
  setup(props, _) {
    //varibale intializations
    let userFromStorage = JSON.parse(localStorage.getItem("user"));
    const showEditModal = ref(true);
    let userid = userFromStorage.id;
    let project_id = ref(props.project_id).value;
    let project_name = ref(props.project_name);
    let workflow_id = ref(props.workflow_id);
    let disableSaveButton = ref(false);
    let disableAddRow = ref(false);
    let disableContentBriefSaveButton = ref(false);
    let tasks = ref([]);
    let currentViewComponent = ref(null);
    let inputTitle = ref("");
    let listOfTeams = ref(null);
    let inputTeam = ref("Choose a Team");
    let listOfAssignees = ref(null);
    let listOfStages = ref(null);
    let inputAssignTo = ref(["Pick Assignee"]);
    let configTaskId = ref(0);
    let listOfTemplates = ref(null);
    let taskTemplates = ref([null]);
    let contentBriefName = ref("");
    let contentBriefTaskId = ref("");
    let contentBriefId = ref("");
    const saving = ref(false);
    let dueDate = ref([]);
    const currentDate = ref();
    const showInlineLoader = ref(true);
    const activeTask = ref();
    let currentTaskStatus = ref(null);
    let currentTaskStageName = ref(null);
    const $loading = useLoading();
    const originalTeam = ref();
    const showDeleteModal = ref(false);
    const showEditNameModal = ref(false);
    const successMessage = ref();
    const errorMessage = ref();
    const archivedTask = ref(false)
    const isArchiving = ref(false);
    const isArchivingTask = ref(0);
    const showTaskPrompt = ref(false);
    const showChangeTaskStagePrompt = ref(false);
    const contentBriefs = ref();
    const selectedBrief = ref('select');
    const taskStage = ref();
    const activeContentBrief = ref();
    const showBriefEditor = ref();
    const canShowTasksGuide =  inject('canShowTasksGuide');
    const showGuide = ref(false)
    const guideStage =  inject('currentGuideStage');
    let hasNoTasks  =  true;
    const getTasks = computed(() =>
      tasks.value.filter((task) => {
        return (
          task.taskTitle.includes(filterName.value) &&
          task.statusName.includes(filterStatus.value) &&
          task.stageName.includes(filterStage.value)
        );
      })
    );
    const filterName = ref("");
    const filterStatus = ref("");
    const filterStage = ref("");
    const filterAssignee = ref("");

    let prop_contentBrief_Id = ref(null);
    let prop_task_Id = ref(null);
    let prop_templateLoaded = ref(null);
    let prop_task_status_id = ref(null);
    const prop_template_id = ref(null);

    let prop_user_id = ref(0);

   const steps = [
          {
            target: '#v-step-0',  // We're using document.querySelector() under the hood
            // header: {
            //   title: 'Get Started',
            // },
            content: `Click <strong>Manage</strong> to start managing your task`
          },
          {
            target: '#v-step-1',
            content: 'Click here to <strong>choose a team</strong> and assign a task to an <strong>assignee</strong> in that team',
            before: type => new Promise((resolve, reject) => {
                // Time-consuming UI/async operation here
                document.querySelectorAll('#v-step-0')[document.querySelectorAll('#v-step-0').length - 1].click();
                resolve()
            })
          },
          {
            target: '#v-step-2',
            content: 'Click here to see what assignees are doing. <br/>You can ' + 
                      'also assign yourself to a stage in the workflow and then engage with the content at that stage by clicking <strong>My Tasks</strong> in the side menu',
          }
    ];
    const tours = inject('tours');
    const  myOptions= {
          useKeyboardNavigation: false,
          labels: {
            buttonSkip: 'Skip tour',
            buttonPrevious: 'Previous',
            buttonNext: 'Next',
            buttonStop: 'Finish'
          },
          highlight: true
        };
    const canShowAssignTaskTourGuide =  inject('canShowAssignTaskTourGuide');
    const myCallbacks = {
        onSkip:   myCustomSkipStepCallback,
        onFinish: myCustomFinishStepCallback
      }

     function  myCustomSkipStepCallback (currentStep) {

      canShowAssignTaskTourGuide.value = 0;
      localStorage.setItem('can-show-assign-task-guide',0);
       
     }
     function myCustomFinishStepCallback (currentStep) {

      canShowAssignTaskTourGuide.value = 0;
      localStorage.setItem('can-show-assign-task-guide',0);
        
     }

   const  {task_management_tour_tutorial, update_task_management_tour_tutorial}  = inject('task_management_tour_tutorial')
  //  name="taskManagementTour" :steps="taskManagementToursteps" :callbacks="myCallbacksTaskManagementTour" :options="myOptionsTaskManagementTour"
    const taskManagementToursteps = [
          {
            target: '#v-step-0',  // We're using document.querySelector() under the hood
            // header: {
            //   title: 'Get Started',
            // },
            content: `<span class="text-sm pb-2">Click <strong>Manage</strong> to start managing your task</span>`,
            // before: type => new Promise((resolve, reject) => {
            //     // Time-consuming UI/async operation here
            //     document.querySelectorAll('#v-step-0')[document.querySelectorAll('#v-step-0').length - 1].click();
            //     resolve()
            // })
          },
          {
            target: '#task-mgt-step-1',
            content: '<span class="text-sm pb-2">Click here to create briefs that describe what you want in your content</span>',
            before: type => new Promise((resolve, reject) => {
                // Time-consuming UI/async operation here
                document.querySelectorAll('#v-step-0')[document.querySelectorAll('#v-step-0').length - 1].click();
                resolve()
            })
          },
          {
            target: '#v-step-1',
            content: '<span class="text-sm pb-2">Click here to <strong>choose a team</strong> and assign a task to an <strong>assignee</strong> in that team</span>',
            before: type => new Promise((resolve, reject) => {
                // Time-consuming UI/async operation here
                document.querySelectorAll('#v-step-0')[document.querySelectorAll('#v-step-0').length - 1].click();
                resolve()
            })
        
          },
          {
            target: '#task-mgt-step-3',
            content: '<span class="text-sm pb-2">You can edit your task name here</span>',
            before: type => new Promise((resolve, reject) => {
                // Time-consuming UI/async operation here
                document.querySelectorAll('#v-step-0')[document.querySelectorAll('#v-step-0').length - 1].click();
                resolve()
            })
          },
          {
            target: '#task-mgt-step-4',
            content: '<span class="text-sm pb-2">Use this action to change a task\'s stage</span>',
            before: type => new Promise((resolve, reject) => {
                // Time-consuming UI/async operation here
                document.querySelectorAll('#v-step-0')[document.querySelectorAll('#v-step-0').length - 1].click();
                resolve()
            })
          },
          {
            target: '#task-mgt-step-5',
            content: '<span class="text-sm pb-2">Use this action to archive a task</span>',
            before: type => new Promise((resolve, reject) => {
                // Time-consuming UI/async operation here
                document.querySelectorAll('#v-step-0')[document.querySelectorAll('#v-step-0').length - 1].click();
                resolve()
            })
          },
          {
            target: '#task-mgt-step-6',
            content: '<span class="text-sm pb-2">Use this action to delete a task</span>',
            before: type => new Promise((resolve, reject) => {
                // Time-consuming UI/async operation here
                document.querySelectorAll('#v-step-0')[document.querySelectorAll('#v-step-0').length - 1].click();
                resolve()
            })
          },
           {
            target: '#v-step-2',
            content: '<span class="text-sm pb-2">Click here to view a tasks content and comments</span>',
          }
    ];
    const  myOptionsTaskManagementTour= {
          useKeyboardNavigation: false,
          labels: {
            buttonSkip: 'Skip tour',
            buttonPrevious: 'Previous',
            buttonNext: 'Next',
            buttonStop: 'Finish'
          },
          highlight: true
        };
    const myCallbacksTaskManagementTour = {
        onSkip:   myCustomSkipStepCallbackTaskManagementTour,
        onFinish: myCustomFinishStepCallbackTaskManagementTour
      }

     function  myCustomSkipStepCallbackTaskManagementTour (currentStep) {

               update_task_management_tour_tutorial(null)
       
      }
     function myCustomFinishStepCallbackTaskManagementTour (currentStep) {

       update_task_management_tour_tutorial(null)
        
     }

    let contentBriefSections = ref([
      { itemID: "", itemTitle: "", itemDescription: "" },
    ]);

    const disableSave = computed(() => {
      if (inputTitle.value.trim().length > 0) return false;
      return true;
    });

    const disableContentBriefSave = computed(() => {
      if (contentBriefName.value.trim().length > 0) return false;
      return true;
    });

    const setCurrentDate = () => {
      const date = new Date();

      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();

      if (month < 10) month = "0" + month;
      if (day < 10) day = "0" + day;

      currentDate.value = year + "-" + month + "-" + day;
    };

    // Modal Intializations and necessary functions
    const showConfigureWorkflowModal = ref(false);

    const showTaskModal = ref(false);

    const closeConfigureWorkflowModal = () => {
      showConfigureWorkflowModal.value = false;
      inputTeam.value = "Choose a Team";
    };

    const closeCreateTaskModal = () => {
      showTaskModal.value = false;
    };

    function showConfigureModal(
      assignmentObject,
      current_status,
      current_stage_name
    ) {
      showEditModal.value = true;
      let teamID = inputTeam.value;

      currentTaskStatus.value = current_status;
      currentTaskStageName.value = current_stage_name;

      //inputTeam.value = assignmentObject[0].teamId;

      configTaskId.value = assignmentObject[0].task_id;

      for (var i = 0; i < assignmentObject.length; i++) {
        inputAssignTo.value[i] = assignmentObject[i].assignee;
        dueDate.value[i] = assignmentObject[i].dueDate;

        if (assignmentObject[i].teamId != "Choose a Team") {
          inputTeam.value = assignmentObject[i].teamId;
          originalTeam.value = assignmentObject[i].teamId;
        }
      }

      if (teamID != inputTeam.value) onChange(null, inputTeam.value);

      //  if(!inputTeam.value)
      //     inputTeam.value = "Choose a Team";

      showConfigureWorkflowModal.value = true;
    }

    const showContentBrief = ref(false);

    const closeContentBriefModal = () => {
      showContentBrief.value = false;
      showEditModal.value = false;
      activeContentBrief.value=null;
      showBriefEditor.value=null;
    };

    function showContentBriefModal(varContentBriefId, taskid) {
      showEditModal.value = true;
      contentBriefId.value = varContentBriefId;
      contentBriefTaskId.value = taskid;

      if (varContentBriefId) {
        loadContentBrief(taskid, varContentBriefId);
        showBriefEditor.value = true;
        activeContentBrief.value = null;
      } else {
        contentBriefName.value = "";
        contentBriefSections.value = [];
        contentBriefSections.value.push({
          itemID: "",
          itemTitle: "",
          itemDescription: "",
        });
        showContentBrief.value = true;
        showBriefEditor.value = null;
      }
    }

    const showCreateTaskModal = () => {
      showTaskModal.value = true;
    };

    //functions
    function changeComponent(
      component,
      assignmentObject,
      currentStage,
      _contentBriefId,
      _task_id,
      _isTemplatedLoaded,
      _status_id,
      _template_id
    ) {
      if (assignmentObject) {
        prop_contentBrief_Id.value = _contentBriefId;
        prop_task_Id.value = _task_id;

        prop_templateLoaded.value = _isTemplatedLoaded;
        prop_task_status_id.value = _status_id;
        prop_template_id.value = _template_id;

        for (var i = 0; i < assignmentObject.length; i++) {
          if (currentStage === assignmentObject[i].stageid) {
            if (assignmentObject[i].user_id)
              prop_user_id.value = assignmentObject[i].user_id;
          }
        }
      }
      currentViewComponent.value = component;
    }

    function saveConfigureWorkflow() {
      saving.value = true;
      let stageAssignments = [];

      for (var i = 0; i < inputAssignTo.value.length; i++) {
        stageAssignments.push({
          stage: listOfStages.value[i].id,
          assignedTo: inputAssignTo.value[i],
          dueDate: dueDate.value[i],
        });
      }

      let workflowstageConfiguration = {
        taskId: configTaskId.value,
        assignedBy: userid,
        stageAssignments: stageAssignments,
      };

      Api()
        .post("/workflows/assign-workflow", workflowstageConfiguration)
        .then((response) => {
          if (response.data.status === "success") {
            loadStages();
            showConfigureWorkflowModal.value = false;
          } else {
          }
        })
        .catch((error) => {})
        .finally(() => {
          saving.value = false;
        });
    }

    function formatDate(date) {
      var d1 = new Date(date);
      var ms = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      return d1.getDate() + " " + ms[d1.getMonth()] + " " + d1.getFullYear();
    }

    function getAssignedOnDateForStage(currentStage, assignmentObject) {
      let currentDate = null;

      for (var i = 0; i < assignmentObject.length; i++) {
        if (currentStage === assignmentObject[i].stageid) {
          currentDate = assignmentObject[i].assignedOn;
        }
      }

      if (currentDate === null) {
        return null;
      } else {
        return formatDate(currentDate);
      }
    }

    function getDueOnDateForStage(currentStage, assignmentObject) {
      let currentDate = null;

      for (var i = 0; i < assignmentObject.length; i++) {
        if (currentStage === assignmentObject[i].stageid) {
          currentDate = assignmentObject[i].dueDate;
        }
      }

      if (currentDate === null) {
        return null;
      } else {
        return formatDate(currentDate);
      }
    }

    function getCurrentAssignee(currentlyAssignedTo, assignmentObject) {
      let currentName = "";

      for (var i = 0; i < assignmentObject.length; i++) {
        if (currentlyAssignedTo === assignmentObject[i].assignee) {
          currentName = assignmentObject[i].assigneeName;
        }
      }

      if (currentName === "") {
        return "";
      } else {
        return currentName;
      }
    }

    function getAssigneeInitials(str) {
      if (!str) str = "";

      const firstLetters = str
        .split(" ")
        .splice(0, 2)
        .map((word) => word[0])
        .join("");

      return firstLetters;
    }

    function loadTemplates() {
      //  /templates/show-my-templates
      //  listOfTemplates
      Api()
        .get(`/templates/show-my-templates/${userid}`)
        .then((response) => {
          //Code for handling the response
          //add attribute editTeamName so that you can toggle showDetails row
          if (response.data.status === "success") {
            listOfTemplates.value = response.data.templates;
          } else {
            listOfTemplates.value = null;
          }
        })
        .catch((error) => {})
        .finally(() => {});
    }

    //functions for content brief modal
    function addRow() {
      contentBriefSections.value.push({
        itemID: "",
        itemTitle: "",
        itemDescription: "",
      });
    }

    function deleteRow(counter) {
      let itemID = contentBriefSections.value[counter].itemID;
      if (itemID) {
        Api()
          .delete(`/contentBrief/delete-contentbrief-detail/${itemID}`)
          .then((response) => {
            let status = response.data.status;

            if (status == "success") {
              contentBriefSections.value.splice(counter, 1);
             // document.getElementById("row-" + counter).remove();
            }
          })
          .catch((error) => {})
          .finally(() => {});
      } else {
        contentBriefSections.value.splice(counter, 1);
       // document.getElementById("row-" + counter).remove();
      }
    }

    function saveContentBrief() {
      let contentBriefObject = {
        contentBriefName: contentBriefName.value,
        createdBy: userid,
        contentbriefDetails: contentBriefSections.value,
        taskId: contentBriefTaskId.value,
        contentBriefId: contentBriefId.value,
      };
      disableContentBriefSaveButton.value = true;
      disableAddRow.value = true;
      saving.value = true;

      Api()
        .post("/contentBrief/create-contentBrief", contentBriefObject)
        .then((response) => {
          if (response.data.status === "success") {
            contentBriefId.value = response.data.contentBriefId;
            loadStages();
            loadContentBrief(
              contentBriefTaskId.value,
              contentBriefId.value,
              false
            );
            disableContentBriefSaveButton.value = false;
            disableAddRow.value = false;
          } else {
          }
        })
        .catch((error) => {})
        .finally(() => {
          saving.value = false;
          showContentBrief.value = false;
        });
    }

    function loadContentBrief(taskid, varContentBriefId, showModal = true) {
      Api()
        .get(
          `/contentBrief/show-task-contentBrief/${taskid}/${varContentBriefId}`
        )
        .then((response) => {
          if (response.data.status === "success") {
            contentBriefName.value = response.data.contentBriefName;
            contentBriefSections.value = response.data.contentBriefSections;
            if (showModal) {
              showContentBrief.value = true;
            }
          }
        })
        .catch((error) => {})
        .finally(() => {});
    }

    //functions for task operations
    function onSaveTask() {
      const loader = $loading.show({
        color: "#2581ef",
        opacity: 1,
      });

      disableSaveButton.value = true;
      //build form object
      let taskObject = {
        taskTitle: inputTitle.value,
        createdBy: userid,
        project_id: project_id,
      };

      Api()
        .post("/tasks/create-task", taskObject)
        .then((response) => {
          if (response.data.status === "success") {
            if (hasNoTasks){
              guideStage.value = 7;
              showGuide.value = true;
            }
            //successMessage.value = "Task Added Successfully";
          } else {
            errorMessage.value =  response.data.message
          }
        })
        .catch((error) => {})
        .finally(() => {
          //close modal afterwards
          loadStages();
          disableSaveButton.value = false;
          closeCreateTaskModal();

          loader.hide();
        });
    }

    function loadTasks(hasLoader = false) {
      if(hasLoader){
        showInlineLoader.value = true;
      }
      Api()
        .get(`/tasks/tasks-created-by-me/${userid}/${project_id}`)
        .then((response) => {
          if (response.data.status === "success") {
             if (response.data.tasks.length > 0){
               hasNoTasks = false;
             }
            for (var j = 0; j < response.data.tasks.length; j++) {
              response.data.tasks[j].stageAssignments = [];

              let taskid = response.data.tasks[j].task_id;

              taskTemplates.value[j] = response.data.tasks[j].templateId;

              for (var i = 0; i < listOfStages.value.length; i++) {
                let stageid = listOfStages.value[i].id;
                let stageName = listOfStages.value[i].stageName;

                // inputAssignTo.value[i] = "Pick Assignee";

                response.data.tasks[j].stageAssignments.push({
                  task_id: taskid,
                  stageName: stageName,
                  stageid: stageid,
                  assignee: "Pick Assignee",
                  teamId: "Choose a Team",
                  assignedOn: null,
                  dueDate: null,
                  assigneeName: "",
                  user_id: "",
                });
                for (
                  var x = 0;
                  x < response.data.workflowStageAssignmentsForTasks.length;
                  x++
                ) {
                  if (
                    response.data.workflowStageAssignmentsForTasks[x]
                      .workflowStageId != null &&
                    response.data.workflowStageAssignmentsForTasks[x].taskId !=
                      null &&
                    response.data.workflowStageAssignmentsForTasks[x]
                      .workflowStageId === stageid &&
                    response.data.workflowStageAssignmentsForTasks[x].taskId ===
                      taskid
                  ) {
                    if (
                      response.data.workflowStageAssignmentsForTasks[x]
                        .assignedToMemberId
                    ) {
                      response.data.tasks[j].stageAssignments[i].assignee =
                        response.data.workflowStageAssignmentsForTasks[
                          x
                        ].assignedToMemberId;
                    }

                    response.data.tasks[j].stageAssignments[i].assigneeName =
                      response.data.workflowStageAssignmentsForTasks[x].name;

                    response.data.tasks[j].stageAssignments[i].user_id =
                      response.data.workflowStageAssignmentsForTasks[x].user_id;

                    response.data.tasks[j].stageAssignments[i].teamId =
                      response.data.workflowStageAssignmentsForTasks[x].teamId;

                    response.data.tasks[j].stageAssignments[i].assignedOn =
                      response.data.workflowStageAssignmentsForTasks[
                        x
                      ].assignedOn;

                    response.data.tasks[j].stageAssignments[i].dueDate =
                      response.data.workflowStageAssignmentsForTasks[x].dueDate;
                    // inputAssignTo.value[i] =
                    //   response.data.workflowStageAssignmentsForTasks[x].assignedToMemberId;
                  }
                }
              }
            }

            tasks.value = response.data.tasks;
            inputTitle.value = "";
    
          } else {
            tasks.value = [];
          }

          if(hasNoTasks && (canShowTasksGuide.value == 0 || canShowTasksGuide.value == null)){

            guideStage.value = 7;
            showGuide.value = true;

          }

          if(!hasNoTasks &&  (canShowAssignTaskTourGuide.value == null || canShowAssignTaskTourGuide.value == 1) && (canShowTasksGuide.value == 1)){

               tours['myTour'].start();
               canShowAssignTaskTourGuide.value = 1;

          }

          if(task_management_tour_tutorial.value != null && !((canShowAssignTaskTourGuide.value == null || canShowAssignTaskTourGuide.value == 1) && (canShowTasksGuide.value == 1))){

            if(hasNoTasks){

              errorMessage.value = "Tour cannot start before you create a task. Click on Create Content Task to create and try again";

            }else{

              tours['taskManagementTour'].start();
              
            }


          }

          
        })
        .catch((error) => {})
        .finally(() => {
          showInlineLoader.value = false;
        });
    }

    function loadTeams() {
      Api()
        .get(`/teams/show-my-teams/${userid}`)
        .then((response) => {
          // Code for handling the response
          listOfTeams.value = response.data.teams;
        })
        .catch((error) => {})
        .finally(() => {});
    }

    function onChange(event, team_Id, listOfStages = [], assignmentObject) {
      if (event != null && team_Id === null) {
        let teamid = event.target.value;

        // we make api call to get all members of a team
        Api()
          .get(`/teams/show-all-team-members/${teamid}`)
          .then((response) => {
            // Code for handling the response
            if (response.data.status === "success") {
              listOfAssignees.value = response.data.teamMembers;
            } else {
              listOfAssignees.value = null;
            }
          })
          .catch((error) => {})
          .finally(() => {
            if (inputTeam.value !== originalTeam.value) {
              for (let i = 0; i < listOfStages.length; i++) {
                inputAssignTo.value[i] = "Pick Assignee";
              }
            } else {
              configTaskId.value = assignmentObject[0].task_id;
              for (var i = 0; i < assignmentObject.length; i++) {
                inputAssignTo.value[i] = assignmentObject[i].assignee;
                dueDate.value[i] = assignmentObject[i].dueDate;
              }
            }
          });
      } else {
        // we make api call to get all members of a team

        Api()
          .get(`/teams/show-all-team-members/${team_Id}`)
          .then((response) => {
            // Code for handling the response
            if (response.data.status === "success") {
              listOfAssignees.value = response.data.teamMembers;
            } else {
              listOfAssignees.value = null;
            }
          })
          .catch((error) => {})
          .finally(() => {});
      }
    }

    function onChangeSelectedTemplate(event, task_id) {
      let templateId = event.target.value;
      let templateObject = {
        templateId: templateId,
      };

      Api()
        .put(`/tasks/update-task-template/${task_id}`, templateObject)
        .then((response) => {
          if (response.data.status === "success") {
          }
        })
        .catch((error) => {})
        .finally(() => {});
    }

    function loadStages(withTasks = true) {
      Api()
        .get(`/workflows/show-all-stages-of-workflow/${workflow_id.value}`)
        .then((response) => {

          if (response.data.status === "success") {

            listOfStages.value = response.data.workflowStages;

          } else {

            listOfStages.value = null;

          }
          let message = response.data.message;
        })
        .catch((error) => {})
        .then((response) => {
          if (withTasks){
            loadTasks();
          }
        });
    }

    const filterByName = (name) => {
      filterName.value = name;
    };

    const filterByStatus = (name) => {
      filterStatus.value = name;
    };

    const filterByStage = (name) => {
      filterStage.value = name;
    };

    const filterByAssignee = (name) => {
      filterAssignee.value = name;
    };

    loadStages();
    loadTeams();
    loadTemplates();

    onMounted(() => {
      setCurrentDate();
    });

    const deleteTask = () => {

      const loader = $loading.show({
        color: "#2581ef",
        opacity: 1,
      });

      Api()
        .delete(`/tasks/delete-task/${activeTask.value.task_id}`)
        .then((response) => {
          let message = response.data.message;
          if (response.data.status === "error") {
            errorMessage.value = message;
          } else {
            document.getElementById("task" + activeTask.value.task_id).remove();
            successMessage.value = message;
          }
        })
        .catch((error) => {})
        .finally(() => {
          showDeleteModal.value = false;
          loader.hide();
        });
    };

    const updateTaskName = () => {
      let task = {
        taskName: document.getElementById("edit-task-name").value,
      };
      Api()
        .patch(`/tasks/update-task-name/${activeTask.value.task_id}`, task)
        .then((response) => {
          let message = response.data.message;

          if (response.data.status === "error") {
            errorMessage.value = message;
          } else {
            successMessage.value = message;
            loadStages();
          }
        })
        .catch((error) => {
          errorMessage.value = error.response.data.errors.projectName;
        })
        .finally(() => {
          showEditNameModal.value = false;
        });
    };


    const archiveTask = (taskId) => {
      isArchivingTask.value = taskId;
      isArchiving.value = true;
      Api().patch(`/tasks/archive-task/${taskId}`)
          .then((response) => {
            let message = response.data.message;
            if (response.data.status === "error") {
              errorMessage.value = [message];
            } else {
              document.getElementById('task'+taskId).remove();
            }
          }).finally(() => {
            loadStages();
        isArchivingTask.value = 0;
        isArchiving.value = false;
      })
    }


    const unArchiveTask = (taskId) => {
      isArchivingTask.value = taskId;
      isArchiving.value = true;
      Api().patch(`/tasks/restore-archived-task/${taskId}`)
          .then((response) => {
            let message = response.data.message;
            if (response.data.status === "error") {
              errorMessage.value = message;
            } else {
              document.getElementById('task'+taskId).remove();
            }
          }).finally(() => {
            loadArchivedTask();
        isArchivingTask.value = 0;
        isArchiving.value = false;
      })
    }

    const loadArchivedTask = () => {
      showInlineLoader.value = true;
      Api()
          .get(`/tasks/get-archived-tasks/${userid}/${project_id}`)
          .then((response) => {
            if (response.data.status === "success") {
              for (var j = 0; j < response.data.tasks.length; j++) {
                response.data.tasks[j].stageAssignments = [];

                let taskid = response.data.tasks[j].task_id;

                taskTemplates.value[j] = response.data.tasks[j].templateId;

                for (var i = 0; i < listOfStages.value.length; i++) {
                  let stageid = listOfStages.value[i].id;
                  let stageName = listOfStages.value[i].stageName;

                  // inputAssignTo.value[i] = "Pick Assignee";

                  response.data.tasks[j].stageAssignments.push({
                    task_id: taskid,
                    stageName: stageName,
                    stageid: stageid,
                    assignee: "Pick Assignee",
                    teamId: "Choose a Team",
                    assignedOn: null,
                    dueDate: null,
                    assigneeName: "",
                    user_id: "",
                  });
                  for (
                      var x = 0;
                      x < response.data.workflowStageAssignmentsForTasks.length;
                      x++
                  ) {
                    if (
                        response.data.workflowStageAssignmentsForTasks[x]
                            .workflowStageId != null &&
                        response.data.workflowStageAssignmentsForTasks[x].taskId !=
                        null &&
                        response.data.workflowStageAssignmentsForTasks[x]
                            .workflowStageId === stageid &&
                        response.data.workflowStageAssignmentsForTasks[x].taskId ===
                        taskid
                    ) {
                      if (
                          response.data.workflowStageAssignmentsForTasks[x]
                              .assignedToMemberId
                      ) {
                        response.data.tasks[j].stageAssignments[i].assignee =
                            response.data.workflowStageAssignmentsForTasks[
                                x
                                ].assignedToMemberId;
                      }

                      response.data.tasks[j].stageAssignments[i].assigneeName =
                          response.data.workflowStageAssignmentsForTasks[x].name;

                      response.data.tasks[j].stageAssignments[i].user_id =
                          response.data.workflowStageAssignmentsForTasks[x].user_id;

                      response.data.tasks[j].stageAssignments[i].teamId =
                          response.data.workflowStageAssignmentsForTasks[x].teamId;

                      response.data.tasks[j].stageAssignments[i].assignedOn =
                          response.data.workflowStageAssignmentsForTasks[
                              x
                              ].assignedOn;

                      response.data.tasks[j].stageAssignments[i].dueDate =
                          response.data.workflowStageAssignmentsForTasks[x].dueDate;
                      // inputAssignTo.value[i] =
                      //   response.data.workflowStageAssignmentsForTasks[x].assignedToMemberId;
                    }
                  }
                }
              }

              tasks.value = response.data.tasks;
              inputTitle.value = "";
            } else {
              tasks.value = [];
            }
          })
          .catch((error) => {})
          .finally(() => {
            showInlineLoader.value = false;
          });

    }

    watch(archivedTask,(newVal)=>{
      const loader = $loading.show({
        color: "#2581ef",
        opacity: 1,
      });

      if (!newVal){
        loadTasks(true);
        loadStages();
        loadTeams();
        loadTemplates();
        setTimeout(()=>{
          loader.hide();
        },1000);
        return;
      }
      loadStages(false);
      loadArchivedTask();
      loadTeams();
      loadTemplates();
      setTimeout(()=>{
        loader.hide();
      },1000);
    });

    const changeStage = () => {
      showChangeTaskStagePrompt.value = true;
    }

    const updateTaskStage = () => {
          Api().patch(`/tasks/revert-task-stage/${userid}/${activeTask.value.task_id}/${taskStage.value}`)
              .then((response) => {
                showChangeTaskStagePrompt.value = false;
                loadStages();
    });
    }

    const loadContentBriefs  = () => {
      Api().get(`/contentBrief/my-contentbriefs/${userid}`)
          .then((response) => {
            if (response.data.contentBriefs && response.data.contentBriefs.length){
               contentBriefs.value = response.data.contentBriefs
            }
          });
    }

    loadContentBriefs();
    watch(selectedBrief, (newVal) => {
      if (newVal ==='select') return;
      activeContentBrief.value = contentBriefs.value[newVal]
      contentBriefName.value = contentBriefs.value[newVal].contentBriefName;
      contentBriefId.value    = contentBriefs.value[newVal].contentBriefId;
      if (contentBriefs.value[newVal].contentBriefSections.length > 0){
        contentBriefSections.value = contentBriefs.value[newVal].contentBriefSections
      }
      showBriefEditor.value =true;
    })

    const closeGuide = () => {
      canShowTasksGuide.value = 1;
      localStorage.setItem('can-show-tasks-guide',1);
      showGuide.value = false;
    }

    return {
      showConfigureWorkflowModal,
      showConfigureModal,
      closeConfigureWorkflowModal,
      showContentBrief,
      closeContentBriefModal,
      showContentBriefModal,
      contentBriefSections,
      addRow,
      deleteRow,
      changeComponent,
      currentViewComponent,
      showCreateTaskModal,
      showTaskModal,
      closeCreateTaskModal,
      inputTitle,
      onSaveTask,
      userid,
      disableSaveButton,
      disableSave,
      disableContentBriefSaveButton,
      disableContentBriefSave,
      tasks,
      loadTasks,
      listOfTeams,
      inputTeam,
      onChange,
      listOfAssignees,
      loadStages,
      listOfStages,
      inputAssignTo,
      saveConfigureWorkflow,
      getAssignedOnDateForStage,
      getDueOnDateForStage,
      getAssigneeInitials,
      getCurrentAssignee,
      loadTemplates,
      listOfTemplates,
      taskTemplates,
      onChangeSelectedTemplate,
      contentBriefName,
      contentBriefTaskId,
      saveContentBrief,
      contentBriefId,
      loadContentBrief,
      disableAddRow,
      prop_user_id,
      prop_contentBrief_Id,
      prop_templateLoaded,
      prop_task_status_id,
      prop_task_Id,
      prop_template_id,
      currentTaskStatus,
      currentTaskStageName,
      showEditModal,
      saving,
      currentDate,
      dueDate,
      showInlineLoader,
      activeTask,
      filterByName,
      getTasks,
      filterByStatus,
      filterByAssignee,
      filterByStage,
      originalTeam,
      showEditNameModal,
      showDeleteModal,
      deleteTask,
      successMessage,
      errorMessage,
      updateTaskName,
      archiveTask,
      isArchivingTask,
      isArchiving,
      archivedTask,
      unArchiveTask,
      showTaskPrompt,
      changeStage,
      showChangeTaskStagePrompt,
      taskStage,
      updateTaskStage,
      contentBriefs,
      selectedBrief,
      activeContentBrief,
      showBriefEditor,
      canShowTasksGuide,
      showGuide,
      guideStage,
      closeGuide,
      steps,
      myOptions,
      myCallbacks,
      taskManagementToursteps, myOptionsTaskManagementTour, myCallbacksTaskManagementTour
    };
  },
};
</script>

<style>
 .v-tour.taskclass .v-step__buttons{
  display:  inline-block !important;
}

 .v-tour.taskclass .v-step__arrow{
  border-color: #3b82f6 !important;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  /* border-bottom-color: transparent !important; */
}

</style>
