<template>
   <div class="py-16 bg-white overflow-hidden">
    <div class="px-4 sm:px-6 lg:px-8">
      <div class="text-lg max-w-prose mx-auto">
        <h1>
          <span class="block text-2xl text-center text-indigo-600 font-semibold tracking-wide uppercase">How to Assign a Content Task</span>
          <span class="mt-2 block text-xs text-center tracking-tight text-gray-900">A content task is an piece of content you want created. It can be a blog post, user manual or any kind of text content</span>
        </h1>
      </div>
      <div class="mt-6 w-1/2 prose-indigo prose-lg text-black mx-auto">

           <div class="text-base my-6">
               <p class="py-3"><span class="font-medium text-lg">1.</span> Click on <span class="italic font-bold text-blue-500">My Projects</span> on the left navigation menu</p>
           </div>

           <div class="text-base my-6">
               <p class="py-3"><span class="font-medium text-lg">2.</span> Click on the Project Card you want to use e.g <span class="italic font-medium text-blue-500"> project 1</span> </p>
                 <figure class="border rounded-lg border-1">
                    <img class="w-full rounded-lg" src="/images/6_add_a_project_1.png" alt="" width="1310" height="873" />
                    <!-- <figcaption>Sagittis scelerisque nulla cursus in enim consectetur quam.</figcaption> -->
                </figure>
           </div>

            <div class="text-base my-6">
               <p class="py-3"><span class="font-medium text-lg">3.</span> Click <button disabled class="ml-1 flex-shrink-0 px-2 py-1 border border-transparent text-xs font-normal rounded-md shadow-sm text-white
                bg-blue-600"> Create New Content Task</button> </p>
                <figure class="border rounded-lg border-1">
                    <img class="w-full rounded-lg" src="/images/7_create_content_task_1.png" alt="" width="1310" height="873" />
                    <!-- <figcaption>Sagittis scelerisque nulla cursus in enim consectetur quam.</figcaption> -->
                </figure>
           </div>
          <div class="text-base my-6">
               <p class="py-3"><span class="font-medium text-lg">4.</span> Type Content Task Name e.g.  <span class="italic text-blue-500"> Task 4 </span> and Click <button disabled class="ml-1 flex-shrink-0 px-2 py-1 border border-transparent text-xs font-normal rounded-md shadow-sm text-white
                bg-blue-600"> save</button> </p>
                 <figure class="border rounded-lg border-1">
                    <img class="w-full rounded-lg" src="/images/7_create_content_task_2.png" alt="" width="1310" height="873" />
                    <!-- <figcaption>Sagittis scelerisque nulla cursus in enim consectetur quam.</figcaption> -->
                </figure>
           </div>

           <div class="text-base my-6">
               <p class="pt-3"><span class="font-medium text-lg">5.</span> Click on <span  class="italic text-blue-500">Manage</span> and then Click on Assign Task</p>
               <!-- <p class="text-xs italic font-normal pb-3"> Select a team and then pick assignees and due dates for each workflow stage</p> -->
                <figure class="border rounded-lg border-1">
                    <img class="w-full rounded-lg" src="/images/8_click_on_manage_1.png" alt="" width="1310" height="873" />
                    <!-- <figcaption>Sagittis scelerisque nulla cursus in enim consectetur quam.</figcaption> -->
                </figure>
           </div>
            <div class="text-base my-6">
               <p class="py-3"><span class="font-medium text-lg">4.</span> Select a team, pick assignees and due dates for each workflow stage and Click <button disabled class="ml-1 flex-shrink-0 px-2 py-1 border border-transparent text-xs font-normal rounded-md shadow-sm text-white
                bg-blue-600"> save</button> </p>
                <figure class="border rounded-lg border-1 mt-3">
                    <img class="w-full rounded-lg" src="/images/8_click_on_manage_2.png" alt="" width="1310" height="873" />
                    <!-- <figcaption>Sagittis scelerisque nulla cursus in enim consectetur quam.</figcaption> -->
                </figure>

                 <figure class="border rounded-lg border-1 mt-3">
                    <img class="w-full rounded-lg" src="/images/8_select_team_and_assign_3.png" alt="" width="1310" height="873" />
                    <!-- <figcaption>Sagittis scelerisque nulla cursus in enim consectetur quam.</figcaption> -->
                </figure>
           </div>
        <p>The Assignees will be notified via email that you have just assigned them a task</p>
        <br/>
        <p> You can follow task progress by going to > My Projects > clicking on the Project card > then view current task statuses for each project task  </p>
      </div>
    </div>
   </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
