<template>
  <main class="relative z-10 flex-auto flex items-center justify-center text-sm text-center  pt-16 px-4 sm:px-6 lg:px-8 md:mt-10">
    <div class="w-full max-w-lg">
      <div class="pl-4 my-6 "><a class="toggleColour no-underline hover:no-underline font-bold text-2xl lg:text-4xl text-center" href="/"> Checkout </a></div>
      <h1 class="sr-only">Make payment</h1>
  <form action="https://sliceloop.com/subscription/subscribe"  method="post" id="payment-form" class="mt-10">
    <input type="hidden" name="priceId" value="#">
    <input type="hidden" name="tier" value="Free">
    <input type="hidden" name="userId" value="0">
    <div id="payment-element">
      <!-- Elements will create form elements here -->
    </div>
    <AlertError
        v-if="errorMessage"
        :message="[errorMessage]"
        @hide="errorMessage = null"
    />

    <div id="error-message" class="text-red-600">
      <!-- Display error message to your customers here -->
    </div>
    <button
        type="submit"
        @click.prevent="makePayment"
        class=" flex justify-center items-center mt-5  block w-full py-2 px-3 border border-transparent rounded-md text-white font-medium bg-blue-500
        shadow-sm sm:text-sm mb-5 hover:bg-blue-600 focus:outline-none focus-visible:ring-2
        focus-visible:ring-gray-700 focus-visible:ring-offset-2 focus-visible:ring-offset-gray-50"
    >
      <InlineLoader :h="25" :w="25" :show="canShow" />
          <span>
            Create Account
          </span>
    </button>
    <div class="revin-checkout-notice"></div>

    <div class="rounded-md bg-blue-50 p-4" v-if="errorMessage">
      <div class="flex">
        <div class="flex-shrink-0">
          <InformationCircleIcon class="h-5 w-5 text-blue-400" aria-hidden="true" />
        </div>
        <div class="ml-3 flex-1 md:flex md:justify-between">
          <p class="text-sm text-blue-700">
            Would you like to continue and pay later?
          </p>
          <p class="mt-3 text-sm md:mt-0 md:ml-6">
            <router-link :to="{name: 'dashboardHome'}" href="#" class="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600"> Continue <span aria-hidden="true">&rarr;</span></router-link>
          </p>
        </div>
      </div>
    </div>

  </form>
    </div>
  </main>
</template>

<script>
import { InformationCircleIcon } from '@heroicons/vue/solid'
import {onMounted,ref,onBeforeMount,inject} from 'vue';
import {useLoading} from "vue-loading-overlay";
import Api from "@/api/Api.js";
import { useRouter } from "vue-router";
import InlineLoader from "../components/InlineLoader";
import AlertError from "./AlertError";
export default {
  name: "Payment",
  components:{InlineLoader,AlertError,InformationCircleIcon},
  setup(){
    const router = useRouter();
    const canShow = ref(false);
    const user =  JSON.parse(localStorage.getItem("user"));
    const subscriptionPackage = inject('subscriptionPackage');
    const subscriptionPeriod = inject('subscriptionPeriod');
    let stripe = null;
    const $loading =  useLoading();
    let elements = null;
    let loader = null;
    const client_secret = inject('client_secret');
    const errorMessage = ref();
    onBeforeMount(() => {
       loader = $loading.show({
        color: "#2581ef",
        opacity: 1,
      });
    })
    onMounted(async ()=>{
     setupPayment();
    });

    const setupPayment  = () => {
      const options = {
        clientSecret: client_secret.value,
        // Fully customizable with appearance API.
        appearance: {},
      };

      stripe =  Stripe('pk_live_51L32qWEIoIK7zESbuoxGTdbPzjXszU9RCruOfI6tnoKeRK9bZnxvTDKYPEPQljYTdhFOo4yA08ZeXYtj3G7QGb4500gSzn1A8s');
      elements = stripe.elements(options);
      // Create and mount the Payment Element
      const paymentElement = elements.create('payment');
      paymentElement.mount('#payment-element');
      setTimeout(() => {
        loader.hide();
      },2000)

    }

    const setMessage = (message) => {
      localStorage.setItem('successMessage', message)
    }

    const makePayment = async () => {
      document.querySelector('#error-message').textContent = '';
      const {error, setupIntent} = await stripe.confirmSetup({
        //`Elements` instance that was used to create the Payment Element
        elements:elements,
        confirmParams: {
          return_url: 'https://sliceloop.com/dashboard/home',
        },
        redirect:'if_required'
      });

      if (error) {
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Show error to your customer (for example, payment
        // details incomplete)
        const messageContainer = document.querySelector('#error-message');
        messageContainer.textContent = error.message;
      } else {
        const paymentObject =  {
          priceId :subscriptionPeriod.value === 'm' ?  subscriptionPackage.value.PriceIdMonthly :  subscriptionPackage.value.PriceIdYearly,
          tier:subscriptionPackage.value.name,
          userId:user.id,
          paymentMethod:setupIntent.payment_method
        }
        submitForm(paymentObject);
      }
    }

    const submitForm = (paymentObject) => {
      canShow.value = true;
      Api().post('/subscription/subscribe',paymentObject).then(response => {
        if (response.data.status === "success") {
          setMessage(response.data.message)
          router.push({ name: "dashboardHome" });
        }else{
          client_secret.value =  response.data.intent.client_secret;
          setupPayment();
          errorMessage.value = response.data.message;
        }
      }).finally(()=>{
        canShow.value = false;
      })
    }



    return{
      makePayment,
      canShow,
      errorMessage
    }
  }
}
</script>

<style scoped>

</style>