<template>
   <div class="py-16 bg-white overflow-hidden">
    <div class="px-4 sm:px-6 lg:px-8">
      <div class="text-lg max-w-prose mx-auto">
        <h1>
          <span class="block text-2xl text-center text-indigo-600 font-semibold tracking-wide uppercase">How to Get Started On a Content Task</span>
          <span class="mt-2 block text-xs text-center tracking-tight text-gray-900">A content task is an piece of content that you have been assigned to create. It can be a blog post, user manual or any kind of text content</span>
        </h1>
      </div>
      <div class="mt-6 w-1/2 prose-indigo prose-lg text-black mx-auto">

           <div class="text-base my-6">
               <p class="py-3"><span class="font-medium text-lg">1.</span> Click on <span class="italic font-bold text-blue-500">My Tasks</span> on the left navigation menu</p>
           </div>

           <div class="text-base my-6">
               <p class="py-3"><span class="font-medium text-lg">2.</span> Select on the Project you want to view by clicking <span class="italic font-medium text-blue-500"> view</span> </p>
                 <figure class="border rounded-lg border-1">
                    <img class="w-full rounded-lg" src="/images/2.0_projects_assigned_to_me.png" alt="" width="1310" height="873" />
                    <!-- <figcaption>Sagittis scelerisque nulla cursus in enim consectetur quam.</figcaption> -->
                </figure>
           </div>

            <div class="text-base my-6">
               <p class="py-3"><span class="font-medium text-lg">3.</span> Click on the task name e.g. <span class="underline"> Hero Section </span></p>
                <figure class="border rounded-lg border-1">
                    <img class="w-full rounded-lg" src="/images/2.1_tasks_assigned_to_me.png" alt="" width="1310" height="873" />
                </figure>
           </div>
          <div class="text-base my-6">
               <p class="py-3"><span class="font-medium text-lg">4.</span> Click <button disabled class="ml-1 flex-shrink-0 px-2 py-1 border border-transparent text-xs font-normal rounded-md shadow-sm text-white
                bg-yellow-600"> Claim Task</button> to start working on the task </p>
                 <figure class="border rounded-lg border-1">
                    <img class="w-full rounded-lg" src="/images/2.2_task_assigned_to_me.png" alt="" width="1310" height="873" />
                    <!-- <figcaption>Sagittis scelerisque nulla cursus in enim consectetur quam.</figcaption> -->
                </figure>
           </div>

           <div class="text-base my-6">
               <p class="pt-3 pb-3"><span class="font-medium text-lg">5.</span> Use the editor to start on the task, Click on <button disabled class=" flex-shrink-0 px-2 py-1 border border-transparent text-xs font-normal rounded-md shadow-sm text-white
                bg-blue-600"> save</button> to save or <button disabled class=" flex-shrink-0 px-2 py-1 border border-transparent text-xs font-normal rounded-md shadow-sm text-white
                bg-blue-600"> submit task</button> to submit task to the next stage</p>
               <!-- <p class="text-xs italic font-normal pb-3"> Select a team and then pick assignees and due dates for each workflow stage</p> -->
                <figure class="border rounded-lg border-1 pt-3">
                    <img class="w-full rounded-lg" src="/images/2.3_task_assigned_to_me.png" alt="" width="1310" height="873" />
                    <!-- <figcaption>Sagittis scelerisque nulla cursus in enim consectetur quam.</figcaption> -->
                </figure>
           </div>
            
        <p>The Task Assigner will be notified via email that you have completed and submitted your task</p>
        <br/>
      </div>
    </div>
   </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
