<template>
  <footer class="bg-white">
    <div class="container mx-auto px-8">
      <div class="w-full flex flex-col md:flex-row py-6">
        <div class="flex-1 mb-6 text-blue-600">
          <a
            class="
              no-underline
              hover:no-underline
              font-bold
              text-2xl
              lg:text-4xl
            "
            href="#"
          >
            Sliceloop
          </a>
        </div>
        <div class="flex-1">
          <p class="uppercase text-gray-500 md:mb-6">Legal</p>
          <ul class="list-reset mb-6 pl-0">
            <li class="mt-2 inline-block mr-2 md:block md:mr-0">
              <router-link
                :to="{ name: 'terms' }"
                class="
                  no-underline
                  hover:underline
                  text-gray-800
                  hover:text-blue-500
                "
                >Terms
              </router-link>
            </li>
            <li class="mt-2 inline-block mr-2 md:block md:mr-0">
              <router-link
                :to="{ name: 'policy' }"
                class="
                  no-underline
                  hover:underline
                  text-gray-800
                  hover:text-blue-500
                "
                >Privacy</router-link
              >
            </li>

            <li class="mt-2 inline-block mr-2 md:block md:mr-0">
              <router-link
                :to="{ name: 'faq' }"
                class="
                  no-underline
                  hover:underline
                  text-gray-800
                  hover:text-blue-500
                "
                >FAQ</router-link
              >
            </li>
          </ul>
        </div>
        <div class="flex-1">
          <p class="uppercase text-gray-500 md:mb-6">Social</p>
          <ul class="list-reset mb-6 pl-0">
            
            <li class="mt-2 inline-block mr-2 md:block md:mr-0">
              <a
                href="https://twitter.com/sliceloop"
                class="
                  no-underline
                  hover:underline
                  text-gray-800
                  hover:text-blue-500
                "
                >Twitter</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
    <p class="text-center pb-5">© 2022 Sliceloop All Rights Reserved</p>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped></style>
