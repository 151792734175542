<template>
<!-- <div  v-if="isTemplate" class="flex flex-col">
   <button
            class="menu-button"
            @click="editor.chain().focus().setMyNode().run()"
          >
            <RiArrowGoForwardFill class="h-5 w-5" />
          </button>
</div> -->
  <Modal @dismiss="showCropperModal = false" :open="showCropperModal">
    <template v-slot:header>
      crop and Image Size
    </template>
    <template v-slot:body>
  <vue-cropper
      ref="cropper"
      :src="img"
      alt="Source Image"
  >
  </vue-cropper>
      <div class="flex justify-end mt-5">
        <button
            type="button"
            @click="cropImage"
            class="
          ml-4
          mr-10
          flex-shrink-0
          px-4
          py-2
          border border-transparent
          text-sm
          font-medium
          rounded-md
          shadow-sm
          text-white
          bg-blue-600
          hover:bg-blue-700
          focus:outline-none
          focus:ring-2
          focus:ring-offset-2
          focus:ring-blue-500
        "
        >
        crop & save
        </button>
      </div>
    </template>
  </Modal>
  <div
    class="flex flex-col"
    :class="!isTemplate && !fullEditor ? 'mr-10 w-8/12' : 'flex-1'"
  >
    <div class="my-6 relative flex justify-between">
      <button
        v-if="editable && canEdit && !isTemplate"
        type="button"
        :disabled="disableSubmitButton"
        @click="submitTask"
        class="
          ml-4
          mr-10
          flex-shrink-0
          px-4
          py-2
          border border-transparent
          text-sm
          font-medium
          rounded-md
          shadow-sm
          text-white
          bg-blue-600
          hover:bg-blue-700
          focus:outline-none
          focus:ring-2
          focus:ring-offset-2
          focus:ring-blue-500
          ml-auto
        "
      >
        Submit Task
      </button>
      <h1 v-if="!isTemplate" class="capitalize flex items-center ml-28">
        <span class="font-extrabold mr-2">Current Stage:</span> {{ taskStage }}
      </h1>

      <Download
        v-if="!isTemplate"
        :editor="editor"
        class="ml-auto"
        :name="taskTitle"
        container=".ProseMirror"
      />

      <button
        v-if="!isTemplate"
        title="Toggle Editor size"
        @click="fullEditor = !fullEditor"
        class="
          h-10
          w-10
          rounded-full
          ml-5
          flex
          items-center
          justify-center
          bg-gray-50
          hover:bg-gray-200
        "
      >
        <span v-if="!isTemplate && !fullEditor"
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="currentColor"
            class="bi bi-arrow-bar-right"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M6 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L12.293 7.5H6.5A.5.5 0 0 0 6 8zm-2.5 7a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5z"
            /></svg
        ></span>

        <span v-else>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="currentColor"
            class="bi bi-arrow-bar-left"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M12.5 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5zM10 8a.5.5 0 0 1-.5.5H3.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L3.707 7.5H9.5a.5.5 0 0 1 .5.5z"
            />
          </svg>
        </span>
      </button>
    </div>

    <div
      class="
        w-full
        border-2
        rounded
        border-gray-200
        flex-1
        max-vh-70
        flex flex-col
      "
      id="editorContainer"
    >
      <div class="p-3 border-b-2 border-gray-200" v-if="editable && canEdit">
        <div class="flex-wrap flex" v-if="editor">
          <button
            class="menu-button"
            @click="editor.chain().focus().toggleBold().run()"
            :class="{ 'is-active': editor.isActive('bold') }"
          >
            <BIconTypeBold class="h-5 w-5" />
          </button>
          <button
            class="menu-button"
            @click="editor.chain().focus().toggleItalic().run()"
            :class="{ 'is-active': editor.isActive('italic') }"
          >
            <BIconTypeItalic class="h-5 w-5" />
          </button>
          <button
            class="menu-button"
            @click="editor.chain().focus().toggleStrike().run()"
            :class="{ 'is-active': editor.isActive('strike') }"
          >
            <BIconTypeStrikethrough class="h-5 w-5" />
          </button>
          <button
            class="menu-button"
            @click="editor.chain().focus().toggleCode().run()"
            :class="{ 'is-active': editor.isActive('code') }"
          >
            <BIconCodeSlash class="h-5 w-5" />
          </button>

          <button
            class="menu-button"
            @click="editor.chain().focus().setParagraph().run()"
            :class="{ 'is-active': editor.isActive('paragraph') }"
          >
            <BIconParagraph class="h-5 w-5" />
          </button>
          <button
            class="menu-button"
            @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
            :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
          >
            h1
          </button>
          <button
            class="menu-button"
            @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
            :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
          >
            h2
          </button>
          <button
            class="menu-button"
            @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
            :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }"
          >
            h3
          </button>
          <button
            class="menu-button"
            @click="editor.chain().focus().toggleHeading({ level: 4 }).run()"
            :class="{ 'is-active': editor.isActive('heading', { level: 4 }) }"
          >
            h4
          </button>
          <button
            class="menu-button"
            @click="editor.chain().focus().toggleHeading({ level: 5 }).run()"
            :class="{ 'is-active': editor.isActive('heading', { level: 5 }) }"
          >
            h5
          </button>
          <button
            class="menu-button"
            @click="editor.chain().focus().toggleHeading({ level: 6 }).run()"
            :class="{ 'is-active': editor.isActive('heading', { level: 6 }) }"
          >
            h6
          </button>
          <button
            class="menu-button"
            @click="editor.chain().focus().toggleBulletList().run()"
            :class="{ 'is-active': editor.isActive('bulletList') }"
          >
            <BIconListUl class="h-5 w-5" />
          </button>
          <button
            class="menu-button"
            @click="editor.chain().focus().toggleOrderedList().run()"
            :class="{ 'is-active': editor.isActive('orderedList') }"
          >
            <BIconListOl class="h-5 w-5" />
          </button>
          <button
            class="menu-button"
            @click="editor.chain().focus().toggleCodeBlock().run()"
            :class="{ 'is-active': editor.isActive('codeBlock') }"
          >
            <BIconCodeSquare class="h-5 w-5" />
          </button>
          <button
            class="menu-button"
            @click="editor.chain().focus().toggleBlockquote().run()"
            :class="{ 'is-active': editor.isActive('blockquote') }"
          >
            <BIconBlockquoteLeft class="h-5 w-5" />
          </button>
          <button
            class="menu-button"
            @click="editor.chain().focus().toggleUnderline().run()"
          >
            <BIconTypeUnderline class="h-5 w-5" />
          </button>

          <button
            class="menu-button"
            @click="editor.chain().focus().setTextAlign('left').run()"
            :class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }"
          >
            <BIconTextLeft class="h-5 w-5" />
          </button>
          <button
            class="menu-button"
            @click="editor.chain().focus().setTextAlign('center').run()"
            :class="{ 'is-active': editor.isActive({ textAlign: 'center' }) }"
          >
            <BIconTextCenter class="h-5 w-5" />
          </button>
          <button
            class="menu-button"
            @click="editor.chain().focus().setTextAlign('right').run()"
            :class="{ 'is-active': editor.isActive({ textAlign: 'right' }) }"
          >
            <BIconTextRight class="h-5 w-5" />
          </button>
          <button
            class="menu-button"
            @click="editor.chain().focus().setTextAlign('justify').run()"
            :class="{ 'is-active': editor.isActive({ textAlign: 'justify' }) }"
          >
            <BIconJustify class="h-5 w-5" />
          </button>

          <button
            class="menu-button"
            @click="editor.chain().focus().toggleHighlight().run()"
            :class="{ 'is-active': editor.isActive('highlight') }"
          >
            <BIconPaletteFill class="h-5 w-5" />
          </button>
          <button
            class="menu-button"
            @click="
              editor
                .chain()
                .focus()
                .insertTable({ rows: 3, cols: 3, withHeaderRow: true })
                .run()
            "
          >
            <BIconTable class="h-5 w-5" />
          </button>

          <button
            class="menu-button"
            @click="editor.chain().focus().addColumnBefore().run()"
            :disabled="!editor.can().addColumnBefore()"
          >
            <RiInsertColumnLeft class="h-5 w-5" />
          </button>
          <button
            class="menu-button"
            @click="editor.chain().focus().addColumnAfter().run()"
            :disabled="!editor.can().addColumnAfter()"
          >
            <RiInsertColumnRight class="h-5 w-5" />
          </button>
          <button
            class="menu-button"
            @click="editor.chain().focus().deleteColumn().run()"
            :disabled="!editor.can().deleteColumn()"
          >
            <RiDeleteColumn class="h-5 w-5" />
          </button>
          <button
            class="menu-button"
            @click="editor.chain().focus().addRowBefore().run()"
            :disabled="!editor.can().addRowBefore()"
          >
            <RiInsertRowTop class="h-5 w-5" />
          </button>
          <button
            class="menu-button"
            @click="editor.chain().focus().addRowAfter().run()"
            :disabled="!editor.can().addRowAfter()"
          >
            <RiInsertRowBottom class="h-5 w-5" />
          </button>
          <button
            class="menu-button"
            @click="editor.chain().focus().deleteRow().run()"
            :disabled="!editor.can().deleteRow()"
          >
            <RiDeleteRow class="h-5 w-5" />
          </button>
          <button
            class="menu-button"
            @click="editor.chain().focus().mergeCells().run()"
            :disabled="!editor.can().mergeCells()"
          >
            <RiMergeCellsHorizontal class="h-5 w-5" />
          </button>
          <button
            class="menu-button"
            @click="editor.chain().focus().splitCell().run()"
            :disabled="!editor.can().splitCell()"
          >
            <RiSplitCellsHorizontal class="h-5 w-5" />
          </button>

          <button
            class="menu-button"
            @click="editor.chain().focus().undo().run()"
          >
            <RiArrowGoBackFill class="h-5 w-5" />
          </button>
          <button
            class="menu-button"
            @click="editor.chain().focus().redo().run()"
          >
            <RiArrowGoForwardFill class="h-5 w-5" />
          </button>

          <button class="menu-button" @click="triggerInput">
            <BIconImage class="h-5 w-5" />
          </button>
          <input
            type="file"
            name="image"
            id="editor-image"
            class="invisible absolute w-0"
            @change="addImage"
          />

          <button class="menu-button" @click="searchAndReplace = !searchAndReplace">
            <RiFindReplaceLine class="h-5 w-5" />
          </button>
        </div>
        
        
        <div class="flex items-center w-full" v-if="searchAndReplace">
<!--          <h3>Find and Replace</h3>-->
          <div class="flex items-center flex-1">
            <input type="text" v-model="searchTerm"             class="
                              text-gray-900
                              ring-gray-900 ring-opacity-5
                              placeholder-gray-400
                              appearance-none
                              bg-white
                              rounded-md
                              block
                              w-full
                              px-3
                              py-2
                              mr-4
                              border border-transparent

                              ring-1
                              sm:text-sm

                              focus:border-blue-500
                              focus:ring-blue-500
                              focus:outline-none
                            " placeholder="Search Term">
            <input v-model="replaceTerm"             class="
                              text-gray-900
                              ring-gray-900 ring-opacity-5
                              placeholder-gray-400
                              appearance-none
                              bg-white
                              rounded-md
                              block
                              w-full
                              px-3
                              py-2
                              border border-transparent

                              ring-1
                              sm:text-sm
                              mr-4
                              focus:border-blue-500
                              focus:ring-blue-500
                              focus:outline-none
                            " type="text" placeholder="Replace Term">
          </div>

          <div>


            <button
                @click="replace"
                type="button"
                class="
            inline-flex
            items-center
            px-4
            py-2
            border border-transparent

            text-sm

          text-blue-600
          hover:underline
          "
            > Replace </button>

            <button
                @click="replaceAll"
                type="button"
                class="
            inline-flex
            items-center
            px-4
            py-2
            border border-transparent

            text-sm

          text-blue-600
          hover:underline
          "
            > Replace All </button>

            <button
                @click="clear"
                type="button"
                class="
            inline-flex
            items-center
            px-4
            py-2
            border border-transparent

            text-sm

          text-blue-600
          hover:underline

          "
            > Clear </button>
          </div>
        </div>

      </div>

      <editor-content
        class="w-full bg-gray-10 flex-1 overflow-auto"
        :editor="editor"
        @click="handleFocusEvent($event)"
        @keyup="startTypingTimer"
        @keydown="stopTypingTimer"
      />

      <BubbleMenu
        v-if="editor && isTextSelected"
        :tippy-options="{ duration: 100, placement: 'bottom' }"
        :editor="editor"
        class="bubble-menu"
        :shouldShow="
          () =>
            isCommentModeOn && isTextSelected && !activeCommentsInstance.uuid
        "
      >
        <section class="comment-adder-section bg-white shadow-lg text-sm">
          <textarea
            v-model="commentText"
            @keypress.enter.stop.prevent="setComment"
            cols="30"
            rows="4"
            placeholder="Add comment..."
            class="border outline-none p-3"
          />

          <section class="flex flex-row w-full gap-1">
            <button
              class="
                bg-transparent
                hover:bg-red-500
                text-red-700
                font-semibold
                hover:text-white
                py-2
                px-4
                border border-red-500
                hover:border-transparent
                rounded
                flex-1
              "
              @click="() => (commentText = '')"
            >
              Clear
            </button>

            <button
              class="
                bg-transparent
                hover:bg-blue-500
                text-blue-700
                font-semibold
                hover:text-white
                py-2
                px-4
                border border-blue-500
                hover:border-transparent
                rounded
                flex-1
              "
              @click="setComment"
            >
              + Comment
            </button>
          </section>
        </section>
      </BubbleMenu>

      <div
        v-if="canEdit && taskStatus !== 3"
        class="border-t-2 border-gray-200 px-5 py-2 flex justify-end relative"
      >
        <button
          v-if="taskStatus == 1"
          type="button"
          class="
            ml-4
            flex-shrink-0
            px-4
            py-2
            border border-transparent
            text-sm
            font-medium
            rounded-md
            shadow-sm
            text-white
            bg-yellow-600
            hover:bg-yellow-700
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-yellow-500
          "
          :disabled="disableClaimTaskButton"
          @click="claimTask"
        >
          Claim Task
        </button>

        <button
          v-if="taskStatus == 2"
          class="
            ml-4
            flex-shrink-0
            px-4
            py-2
            border border-transparent
            text-sm
            font-medium
            rounded-md
            shadow-sm
            text-white
            bg-blue-600
            hover:bg-blue-700
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-blue-500
          "
          :disabled="disableSaveButton"
          @click="setContent()"
        >
          Save Content
        </button>

          <button v-if="isTemplate" @click="addSection" type="button" style="position: absolute;left: 43%;bottom: 100px;" class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="-ml-1 mr-2 h-5 w-5"><path fill-rule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clip-rule="evenodd"></path></svg>
            Add New Section
          </button>

      </div>
    </div>
  </div>

  <div
    v-if="!isTemplate && !fullEditor"
    class="flex-1 border rounded-lg pt-5 px-3"
  >
    <div class="row border-bottom">
      <h2 class="text-center text-lg font-medium text-gray-900 capitalize">
        {{ taskTitle }}
      </h2>
      <nav
        class="
          relative
          z-0
          rounded-lg
          shadow
          flex
          divide-x divide-gray-200
          mt-2
        "
        aria-label="Tabs"
      >
        <button
          v-for="tab in tabs"
          :key="tab.name"
          @click="activeTab = tab.index"
          :class="[
            activeTab === tab.index
              ? 'text-gray-900'
              : 'text-gray-500 hover:text-gray-700',

            'group relative min-w-0 flex-1 overflow-hidden bg-white py-3 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10',
          ]"
          :aria-current="tab.current ? 'page' : undefined"
        >
          <span>{{ tab.name }}</span>
          <span
            aria-hidden="true"
            :class="[
              activeTab === tab.index ? 'bg-blue-500' : 'bg-transparent',
              'absolute inset-x-0 bottom-0 h-0.5',
            ]"
          />
        </button>
      </nav>
    </div>
    <div style="height: 60vh;" class="overflow-auto max-vh-70">
      <template v-if="activeTab == 1">
        <div
          v-for="(section, counter) in contentBriefSections"
          v-bind:key="counter"
          class="bg-white py-5 my-5 border rounded-xl border-gray-200 sm:px-6"
        >
          <div class="-ml-4 -mt-4 px-4 items-center flex-wrap sm:flex-nowrap">
            <div class="mt-4">
              <h3 class="leading-6 font-medium text-gray-900">
                {{ section.itemTitle }}
              </h3>
              <p class="mt-1 text-sm text-gray-500">
                {{ section.itemDescription }}
              </p>
            </div>
          </div>
        </div>

        <div v-if="!contentBriefSections" class="text-center mt-20">

          <svg xmlns="http://www.w3.org/2000/svg"    width="100"
               height="100" fill="currentColor"    class="text-gray-200 mx-auto" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3.854 2.146a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 3.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 7.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z"/>
          </svg>
          <p class="mt-1 text-sm text-gray-500">You do not have any content briefs</p>
        </div>

      </template>

      <template v-if="activeTab == 2">
        <div v-if="allComments.length == 0" class="text-center mt-20">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            width="100"
            height="100"
            viewBox="0 0 17 17"
            class="text-gray-200 mx-auto"
            fill="currentColor"
          >
            <g></g>
            <path
              d="M17 1.5v7.969c0 0.827-0.673 1.5-1.5 1.5h-0.508v-1h0.508c0.276 0 0.5-0.225 0.5-0.5v-7.969c0-0.275-0.224-0.5-0.5-0.5h-14c-0.276 0-0.5 0.225-0.5 0.5v7.969c0 0.275 0.224 0.5 0.5 0.5h1.5v2.892l1.779-1.569 0.661 0.75-3.44 3.035v-4.108h-0.5c-0.827 0-1.5-0.673-1.5-1.5v-7.969c0-0.827 0.673-1.5 1.5-1.5h14c0.827 0 1.5 0.673 1.5 1.5zM14.020 9.54v3.043c0 0.827-0.673 1.5-1.5 1.5h-0.5v3.064l-3.667-3.064h-0.853c-0.827 0-1.5-0.673-1.5-1.5v-3.043c0-0.827 0.673-1.5 1.5-1.5h5.020c0.827 0 1.5 0.673 1.5 1.5zM13.020 9.54c0-0.275-0.224-0.5-0.5-0.5h-5.020c-0.276 0-0.5 0.225-0.5 0.5v3.043c0 0.275 0.224 0.5 0.5 0.5h1.217l2.303 1.926v-1.926h1.5c0.276 0 0.5-0.225 0.5-0.5v-3.043z"
            />
          </svg>
          <p class="mt-1 text-sm text-gray-500">You do not have any comments</p>
        </div>

        <article
          class="
            bg-gray-50
            text-sm
            py-5
            my-5
            border
            rounded-xl
            border-gray-200
            sm:px-6
          "
          v-for="(comment, i) in allComments"
          :key="i + 'external_comment'"
        >
          <button  v-if="comment.jsonComments.uuid === activeCommentsInstance.uuid"
                   class="text-blue-500 hover:underline flex items-center ml-auto" @click="markAsResolved()">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-check2 mr-2" viewBox="0 0 16 16">
              <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
            </svg>
            Mark As Resolved
          </button>
          <article
            v-for="(jsonComment, j) in comment.jsonComments.comments"
            :key="`${j}_${Math.random()}`"
            class="external-comment p-3"
          >
            <div class="comment-details">
              <strong class="capitalize">{{ jsonComment.userName }}</strong>

              <span class="ml-1 date-time text-xs">{{
                formatDate(jsonComment.time)
              }}</span>
            </div>

            <span>{{ jsonComment.content }}</span>
          </article>

          <section
            :data-label="comment.jsonComments.uuid"
            :data-value="activeCommentsInstance.uuid"
            v-if="comment.jsonComments.uuid === activeCommentsInstance.uuid"
            class="flex flex-col w-full gap-1"
            :id="comment.jsonComments.uuid"
          >
            <textarea
              v-if="canEdit"
              v-model="commentText"
              @keypress.enter.stop.prevent="setComment"
              cols="30"
              rows="3"
              placeholder="Add comment..."
              class="p-3 border outline-none bg-white"
            />

            <section class="flex flex-row w-full gap-1">
              <button
                class="
                  bg-transparent
                  hover:bg-red-500
                  text-red-700
                  font-semibold
                  hover:text-white
                  py-2
                  px-4
                  border border-red-500
                  hover:border-transparent
                  rounded
                  text-sm
                  flex-1
                "
                @click="() => (commentText = '')"
              >
                Clear
              </button>

              <button
                class="
                  bg-transparent
                  hover:bg-blue-500
                  text-blue-700
                  font-semibold
                  hover:text-white
                  py-2
                  px-4
                  border border-blue-500
                  hover:border-transparent
                  rounded
                  text-sm
                  flex-1
                "
                @click="setComment"
              >
                + Comment
              </button>
            </section>
          </section>
        </article>
      </template>

      <template v-if="activeTab == 3 && canEdit">

        <div role="list" class="my-5"  v-for="(entry,index) in versions" :key="index">
          <div class="flex justify-between mt-10 mb-5 ml-5 items-center">
            <h2 class=" font-bold">{{entry.date}}</h2>
           <button @click="toggleCollapse(index)" class="text-blue-500 hover:underline border p-2 rounded hover:text-white hover:bg-blue-500 "><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrows-collapse" viewBox="0 0 16 16">
             <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13A.5.5 0 0 1 1 8zm7-8a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 4.293V.5A.5.5 0 0 1 8 0zm-.5 11.707-1.146 1.147a.5.5 0 0 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 11.707V15.5a.5.5 0 0 1-1 0v-3.793z"/>
           </svg></button>
          </div>
          <ul :id="'v'+index" class="overflow-hidden" >
          <li class="py-4 px-6 rounded-xl bg-gray-50 mt-3" v-for="version in entry.versions" :key="version.id">
            <div class="flex items-center">
              <div class="flex-1 min-w-0">
                <div class="flex justify-between">
                <button @click="activeVersion =version; versionName =version.versionName; versionDescription = version.description; showVersionModal = true " class="text-sm font-bold text-gray-900 truncate capitalize flex hover:text-blue-500 items-center">
                <span :id="'version'+version.id">{{ version.versionName || 'Untitled' }}</span>
                             <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil ml-2 " viewBox="0 0 16 16">

               <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                             </svg>
                </button>

                  <button @click="restoreDocumentVersion(version.id)" type="button" class="inline-flex items-center py-2 px-3 text-blue-500 hover:underline"> Restore
                  </button>
                </div>
                <div style="max-width: 100%">
                  <p :id="'vd'+version.id" class="text-sm text-gray-600 mt-2" >{{version.description || ''}}</p>
                </div>
                <p class="text-sm text-gray-500 mt-2">{{new Date(version.created_at).toString()}}</p></div>
              <div>
              </div>
            </div>
          </li>

          </ul>
        </div>

        <Modal @dismiss="showVersionModal = false" :open="showVersionModal">
          <template v-slot:header>
            <h5 class="modal-title text-danger capitalize">Edit Version Details</h5>
            <br />
          </template>

          <template v-slot:body>
           <div class="mt-6">
             <div class="mb-3">
               <label for="inputProjectName" class="sr-only"
               >Enter Version Name</label
               >
               <input
                   type="text"
                   name="inputProjectName"
                   v-model="versionName"
                   id="inputProjectName"
                   class="py-3 px-4 block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 border border-gray-300 rounded-md
                            "
                   placeholder="Enter Version Name"
               />
             </div>
             <div class="sm:col-span-2">
               <label for="description" class="sr-only">Version Description</label>
               <div class="mt-1">
                 <textarea id="description" name="description" v-model="versionDescription" placeholder="Enter Version description" rows="4" class="py-3 px-4 block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 border border-gray-300 rounded-md" />
               </div>
             </div>

             <div class="mt-5 sm:mt-6">
               <button
                   type="submit"
                   v-bind:class="{ disabled: !versionName }"
                   :disabled="!versionName"
                   name="inputSave"
                   class="
                          inline-flex
                          justify-center
                          w-full
                          rounded-md
                          border border-transparent
                          disabled:bg-blue-200
                          shadow-sm
                          px-4
                          py-2
                          bg-blue-600
                          text-base
                          font-medium
                          text-white
                          hover:bg-blue-700
                          focus:outline-none
                          focus:ring-2
                          focus:ring-offset-2
                          focus:ring-blue-500
                          sm:text-sm
                        "
                   @click=" updateVersionDetails(); showVersionModal = false"
               >
                 Submit Version Details
               </button>
             </div>
           </div>
          </template>
        </Modal>

        <div v-if="!versions" class="text-center mt-20">

          <svg xmlns="http://www.w3.org/2000/svg"    width="100"
               height="100" fill="currentColor"    class="text-gray-200 mx-auto" viewBox="0 0 16 16">
            <path d="M14 4.5V9h-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v7H2V2a2 2 0 0 1 2-2h5.5L14 4.5zM13 12h1v2a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-2h1v2a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-2zM.5 10a.5.5 0 0 0 0 1h15a.5.5 0 0 0 0-1H.5z"/>
          </svg>

          <p class="mt-1 text-sm text-gray-500">You do not have any document versions saved</p>
        </div>
      </template>
    </div>
  </div>

  <TransitionRoot v-if="isTemplate" as="template" :show="showCreateModal">
    <Dialog
        as="div"
        class="fixed z-10 inset-0 overflow-y-auto"
        @close="showCreateModal = false"
    >
      <div
          class="
              flex
              items-end
              justify-center
              min-h-screen
              pt-4
              px-4
              pb-20
              text-center
              sm:block sm:p-0
            "
      >
        <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="ease-in duration-200"
            leave-from="opacity-100"
            leave-to="opacity-0"
        >
          <DialogOverlay
              class="fixed inset-0 bg-black bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
            class="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
        >&#8203;</span
        >
        <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
              class="
                  inline-block
                  align-bottom
                  bg-white
                  rounded-lg
                  px-4
                  pt-5
                  pb-4
                  text-left
                  overflow-hidden
                  shadow-xl
                  transform
                  transition-all
                  sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6
                "
          >
            <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
              <button
                  type="button"
                  class="
                      bg-white
                      rounded-md
                      text-gray-400
                      hover:text-gray-500
                      focus:outline-none
                      focus:ring-2
                      focus:ring-offset-2
                      focus:ring-blue-500
                    "
                  @click="showCreateModal = false"
              >
                <span class="sr-only">Close</span>
                <XIcon class="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div class="sm:flex sm:items-start  flex-col">
                <div class="mt-3 text-center sm:mt-0 sm:text-left w-full">
                  <DialogTitle
                      as="h3"
                      class="text-lg leading-6 font-medium text-gray-900"
                  >
                    Create Block Section
                  </DialogTitle>

                </div>
              <div class="w-full justify-center">
                <div class="mt-5 sm:mt-6">
                  <div class="mb-3">
                    <label for="inputProjectName" class="sr-only"
                    >Enter Project Name</label
                    >
                    <input
                        type="text"
                        name="inputProjectName"
                        v-model="sectionName"
                        id="inputProjectName"
                        class="
                              text-gray-900
                              ring-gray-900 ring-opacity-5
                              placeholder-gray-400
                              appearance-none
                              bg-white
                              rounded-md
                              block
                              w-full
                              px-3
                              py-2
                              border border-transparent
                              shadow
                              ring-1
                              sm:text-sm
                              mb-4
                              focus:border-blue-500
                              focus:ring-blue-500
                              focus:outline-none
                            "
                        placeholder="Enter Section Title"
                    />
                  </div>

                  <button
                      type="submit"
                      name="inputSave"
                      class="
                          inline-flex
                          justify-center
                          w-full
                          rounded-md
                          border border-transparent
                          disabled:bg-blue-200
                          shadow-sm
                          px-4
                          py-2
                          bg-blue-600
                          text-base
                          font-medium
                          text-white
                          hover:bg-blue-700
                          focus:outline-none
                          focus:ring-2
                          focus:ring-offset-2
                          focus:ring-blue-500
                          sm:text-sm
                        "
                      @click="createSectionBlock();  showCreateModal = false"
                  >
                    Create Block
                  </button>
                </div>
              </div>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>

</template>

<script>
import Api from "../api/Api";
import {
  ExclamationIcon,
  XIcon,
  TrashIcon,
  ArrowLeftIcon,
} from "@heroicons/vue/outline";
import {
  BIconTypeBold,
  BIconTypeItalic,
  BIconTypeStrikethrough,
  BIconCodeSquare,
  BIconParagraph,
  BIconListOl,
  BIconListUl,
  BIconCodeSlash,
  BIconBlockquoteLeft,
  BIconTypeUnderline,
  BIconTextRight,
  BIconTextLeft,
  BIconTextCenter,
  BIconJustify,
  BIconPaletteFill,
  BIconTable,
  BIconImage,
} from "bootstrap-icons-vue";

import {  Dialog,
  DialogOverlay,
  DialogTitle,  TransitionChild,
  TransitionRoot,} from "@headlessui/vue";

import {

  RiArrowGoBackFill,
  RiArrowGoForwardFill,
  RiInsertColumnLeft,
  RiInsertColumnRight,
  RiDeleteColumn,
  RiInsertRowBottom,
  RiInsertRowTop,
  RiDeleteRow,
  RiMergeCellsHorizontal,
  RiSplitCellsHorizontal,
  RiTextWrap,
  RiDownloadLine,
    RiFindReplaceLine
} from "vue-remix-icons";
import { useEditor, EditorContent, BubbleMenu } from "@tiptap/vue-3";
import StarterKit from "@tiptap/starter-kit";
import TextAlign from "@tiptap/extension-text-align";
import Highlight from "@tiptap/extension-highlight";
import Table from "@tiptap/extension-table";
import TableRow from "@tiptap/extension-table-row";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import Image from "@tiptap/extension-image";
import Underline from '@tiptap/extension-underline'
import {onMounted, ref, onBeforeUnmount, inject,watch} from "vue";
import Download from "./Download";
import format from "date-fns/format";
import { v4 as uuidv4 } from "uuid";
import Comment from "@/extensions/comment.js";
import VueComponent from "@/extensions/NodeExtension.js"
import SearchNReplace from "@/extensions/search.js"
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import Modal from "./Modal";
import {useLoading} from "vue-loading-overlay";
const CustomTableCell = TableCell.extend({
  addAttributes() {
    return {
      // extend the existing attributes …
      ...this.parent?.(),

      // and add a new one …
      backgroundColor: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-background-color"),
        renderHTML: (attributes) => {
          return {
            "data-background-color": attributes.backgroundColor,
            style: `background-color: ${attributes.backgroundColor}`,
          };
        },
      },
    };
  },
});

export default {
  props: [
    "editor",
    "disableSaveButton",
    "content",
    "disableClaimTaskButton",
    "disableSubmitButton",
    "canEdit",
    "editable",
    "taskStatus",
    "isTask",
    "taskTitle",
    "projectId",
    "taskId",
    "isTemplate",
    "taskStage",
    "canComment",
    "contentBriefSections",
    "workflow_id",
    "reloadDocumentVersions"
  ],
  name: "TextEditor",
  emits: [
    "saveEditorContent",
    "claimTask",
    "submitTask",
    "commentsLoaded",
    "changeActiveComment",
      "saveEditorContentSilently",
    "message"
  ],
  components: {
    XIcon,
    TransitionChild,
    TransitionRoot,
    Dialog,
    DialogOverlay,
    DialogTitle,
    BubbleMenu,
    EditorContent,
    BIconTypeBold,
    BIconTypeItalic,
    BIconTypeStrikethrough,
    BIconCodeSquare,
    BIconParagraph,
    BIconListOl,
    BIconListUl,
    BIconCodeSlash,
    BIconBlockquoteLeft,
    BIconTypeUnderline,
    BIconTextRight,
    BIconTextLeft,
    BIconTextCenter,
    BIconJustify,
    BIconPaletteFill,
    BIconTable,
    RiArrowGoBackFill,
    RiArrowGoForwardFill,
    RiInsertColumnLeft,
    RiInsertColumnRight,
    RiDeleteColumn,
    RiInsertRowBottom,
    RiInsertRowTop,
    RiDeleteRow,
    RiMergeCellsHorizontal,
    RiSplitCellsHorizontal,
    RiTextWrap,
    RiDownloadLine,
    Download,
    BIconImage,
    Modal,
    RiFindReplaceLine,
    VueCropper
  },
  setup(props, { emit }) {
    const user = inject("user");
    const dateTimeFormat = "dd.MM.yyyy HH:mm";

    const formatDate = (d) => (d ? format(new Date(d), dateTimeFormat) : null);

    const currentUserName = ref(user.name);

    const commentText = ref("");

    const showCommentMenu = ref(false);
    const sectionName = ref();

    const isCommentModeOn = ref(props.canComment);
    const showVersionModal = ref(false);
    const $loading = useLoading();
    const versionName = ref();
    const versionDescription = ref();
    const activeVersion = ref();
    const showCropperModal =  ref(false);
    const img =  ref();
    const cropper = ref();

    const searchTerm = ref("");
    const replaceTerm = ref("");
    const searchAndReplace = ref(false);
    const isTextSelected = ref(false);
    const activeTab = ref(1);
    const showAddCommentSection = ref(true);
    const showCreateModal = ref(false);

    const activeCommentsInstance = ref({});
    const fullEditor = ref(false);
    // let timer;
    let typingTimer;                //timer identifier
    const doneTypingInterval = 5000;  //time in ms, 5 seconds for example

    const allComments = ref([]);
    const versions = ref();
    const tabs = [
      { name: "Brief", index: 1, current: true },
      { name: "Comments", index: 2, current: false },
    ];

    const findCommentsAndStoreValues = (editor) => {
      const tempComments = [];

      const { doc } = editor.state;

      doc.descendants((node, pos) => {
        const { marks } = node;

        marks.forEach((mark) => {
          if (mark.type.name === "comment") {
            const markComments = mark.attrs.comment;

            const jsonComments = markComments ? JSON.parse(markComments) : null;

            if (jsonComments !== null) {
              tempComments.push({
                node,
                jsonComments,
                pos,
              });
            }
          }
        });
      });

      if (!tempComments.length) {
        allComments.value = [];
        return;
      }

      allComments.value = tempComments;
    };

    const { log } = console;

    const setCurrentComment = (editor) => {
      const newVal = editor.isActive("comment");

      if (newVal) {
        setTimeout(() => (showCommentMenu.value = newVal), 50);

        showAddCommentSection.value = !editor.state.selection.empty;

        const parsedComment = JSON.parse(
          editor.getAttributes("comment").comment
        );

        parsedComment.comment =
          typeof parsedComment.comments === "string"
            ? JSON.parse(parsedComment.comments)
            : parsedComment.comments;

        activeCommentsInstance.value = parsedComment;
      } else {
        activeCommentsInstance.value = {};
      }
      activeTab.value = 2;
    };

    const editor = useEditor({
      editorProps: {
        attributes: {
          class:
            "prose prose-sm sm:prose lg:prose-lg " +
            " focus:outline-none bg-gray-50 p-5 text-xs min-h-full",
        },
      },
      autofocus: false,
      content: props.content,

      extensions: [
        StarterKit.configure({
            gapcursor: true,
        }),
        Comment,
        TextAlign.configure({
          types: ["heading", "paragraph"],
          alignments: ["left", "center", "right", "justify"],
        }),
        Highlight,
        Table.configure({
          resizable: true,
        }),
        TableRow,
        TableHeader,
        Underline,
        // Default TableCell
        // TableCell,
        // Custom TableCell with backgroundColor attribute
        CustomTableCell,
        Image,
        VueComponent,
          SearchNReplace
      ],

      onUpdate({ editor }) {
        findCommentsAndStoreValues(editor);
        setCurrentComment(editor);
      },

      onSelectionUpdate({ editor }) {
        if (!!editor.state.selection.content().size) {
          setCurrentComment(editor);
        } else {
          activeCommentsInstance.value = {};
        }
        isTextSelected.value = !!editor.state.selection.content().size;
      },

      onCreate({ editor }) {
        findCommentsAndStoreValues(editor);
      },

      editable: false,
      // autofocus: "start",
    });
    onMounted(() => {
      setTimeout(updateSearchReplace)
      editor.value.setEditable(props.editable && props.canEdit);
      if (props.editable && props.canEdit){
        tabs.push({ name: "Versions", index: 3, current: false })
      }
      const backBtn = document.getElementById("backBtn");
      if (backBtn) {
        backBtn.classList.add("hidden");
      }
      if (editor.value.options.editable){
       editor.value.commands.focus('end',true);
      }
    });

    onBeforeUnmount(() => {
      const backBtn = document.getElementById("backBtn");
      if (backBtn) {
        backBtn.classList.remove("hidden");
      }
    });

    const setContent = () => {
      emit("saveEditorContent", editor.value.getJSON());

    };

    const saveContentWithoutReloading = () => {
      emit("saveEditorContentSilently", editor.value.getJSON());
    }

    const submitTask = () => {
       
       saveContentWithoutReloading();
       emit("submitTask");
    };

    const claimTask = () => {
      
      setTimeout(() => {
        emit("claimTask");
      }, 1000);
      editor.value.options.editable = true;
      editor.value.commands.focus('end',true);
    };

    const triggerInput = () => {
      document.getElementById("editor-image").click();
    };

    const addImage = (evn) => {
      const [file] = evn.target.files;
      const fr=new FileReader();
      fr.readAsDataURL(file);
      fr.onload =() => { img.value = fr.result;    showCropperModal.value = true;};
    };

    const setComment = () => {
      if (!commentText.value.trim().length) return;

      const activeCommentInstance = JSON.parse(
        JSON.stringify(activeCommentsInstance.value)
      );

      const commentsArray =
        typeof activeCommentInstance.comments === "string"
          ? JSON.parse(activeCommentInstance.comments)
          : activeCommentInstance.comments;

      if (commentsArray) {
        commentsArray.push({
          userName: currentUserName.value,
          time: Date.now(),
          content: commentText.value,
        });

        const commentWithUuid = JSON.stringify({
          uuid: activeCommentsInstance.value.uuid || uuidv4(),
          comments: commentsArray,
        });

        // eslint-disable-next-line no-unused-expressions
        editor.value.chain().setComment(commentWithUuid).run();
      } else {
        const commentWithUuid = JSON.stringify({
          uuid: uuidv4(),
          comments: [
            {
              userName: currentUserName.value,
              time: Date.now(),
              content: commentText.value,
            },
          ],
        });

        // eslint-disable-next-line no-unused-expressions
        editor.value.chain().setComment(commentWithUuid).run();
      }
      
      setTimeout(() => {commentText.value = "";isTextSelected.value = false; saveContentWithoutReloading()}, 50);
    };

    const addCommentFromSidebar = (comment) => {
      commentText.value = comment;
      setComment();
    };

    const markAsResolved = () => {
       
      editor.value.chain().unsetComment().run();
      saveContentWithoutReloading();
    }

    const getDocumentVersions = () => {
      Api()
          .get(`/tasks/get-all-versions/${props.taskId}/${props.workflow_id}`)
          .then((response) => {
            if (response.data.status === "success") {
              versions.value = response.data.allVersions;
            }
          })
          .catch((error) => {})
          .finally(() => {

          });

    }

    getDocumentVersions();

    const toggleCollapse = (index) => {
      document.getElementById('v'+index).classList.toggle('h-0');
    }


    const restoreDocumentVersion = (id) => {
      const loader = $loading.show({
        color: "#2581ef",
        opacity: 1,
      });
      Api()
          .patch(`/tasks/restore-version/${id}/${props.taskId}`)
          .then((response) => {
            let responseMessage = response.data.message;
            Api()
                .get(`/tasks/load-task-content/${props.taskId}`)
                .then((response) => {
                  let _taskContent = response.data.taskContent;
                  editor.value.commands.setContent(JSON.parse(_taskContent));
                }).finally(()=>{
              emit('message',responseMessage)
            })

          })
          .catch((error) => {})
          .finally(() => {
            loader.hide()
          });
    }

    const updateVersionDetails = () => {
      Api()
          .patch(`/tasks/edit-version-name/${activeVersion.value.id}/${props.taskId}`,{'versionName':versionName.value})
          .then((response) => {
            if (response.data.status == 'success'){
               document.getElementById('version'+activeVersion.value.id).innerText = versionName.value;
            }
          })
          .finally(() => {
            Api()
                .patch(`/tasks/edit-version-description/${activeVersion.value.id}/${props.taskId}`,{'versionDescription':versionDescription.value})
                .then((response) => {
                  if (response.data.status == 'success'){
                    document.getElementById('vd'+activeVersion.value.id).innerText = versionDescription.value;
                  }
                })
          });
    }


    const updateSearchReplace = () => {
      if (!editor.value) return;
      editor.value.commands.setSearchTerm(searchTerm.value);
      editor.value.commands.setReplaceTerm(replaceTerm.value);
    };


    watch(
        () => searchTerm.value.trim(),
        (val, oldVal) => {
          if (!val) clear();
          if (val !== oldVal) updateSearchReplace()
        }
    );
    watch(
        () => replaceTerm.value.trim(),
        (val, oldVal) => (val === oldVal ? null : updateSearchReplace())
    );
    const replace = () => editor.value?.commands.replace();
    const clear = () => (searchTerm.value = replaceTerm.value = "");
    const replaceAll = () => editor.value?.commands.replaceAll();

    const cropImage = () => {
     const canvas = cropper.value.getCroppedCanvas({
           width:1000,
      })
      canvas.toBlob((blob)=>{
          const formData = new FormData();
          formData.append("image", blob,'test.jpg');
          formData.append("projectId", props.projectId);
          formData.append("taskId", props.taskId);
          const options = {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          };
          Api()
              .post("/tasks/upload", formData, options)
              .then((response) => {
                editor.value
                    .chain()
                    .focus()
                    .setImage({
                      src: `https://sliceloop.com/api/tasks/get-image/${props.projectId}/${props.taskId}/${response.data.name}`,
                    })
                    .run();
              })
              .catch((error) => {
              })
              .finally(() => {
                showCropperModal.value = false;
              });
      })
    }

    const addSection = () => {
      showCreateModal.value = true;

    }

    const createSectionBlock = () => {
      if (sectionName.value &&  sectionName.value.length !== 0){
        const html =  editor.value.getHTML();
        editor.value.commands.setContent(html + `
            <vue-component  title="${sectionName.value}"></vue-component>
        `);
      }
      sectionName.value = null;
    }

    const handleFocusEvent = (event) => {
      // if (event.target.classList.contains('content')  ||  event.target.parentElement.classList.contains('content')){
      //    editor.value.setEditable(props.editable && props.canEdit);
      //     return;
      // }
      // editor.value.setEditable(false);
    }

   const startTypingTimer =  () => {
     clearTimeout(typingTimer);
     typingTimer = setTimeout(saveContentWithoutReloading, doneTypingInterval);
    }

    const stopTypingTimer =  () => {
      clearTimeout(typingTimer);
    }

    watch(() => props.reloadDocumentVersions, (first, second) => {
          getDocumentVersions();
    });

    return {
      editor,
      setContent,
      submitTask,
      claimTask,
      addImage,
      triggerInput,
      allComments,
      activeCommentsInstance,
      showAddCommentSection,
      isTextSelected,
      isCommentModeOn,
      showCommentMenu,
      commentText,
      currentUserName,
      formatDate,
      setComment,
      activeTab,
      tabs,
      fullEditor,
      markAsResolved,
      versions,
      showVersionModal,
      toggleCollapse,
      restoreDocumentVersion,
      versionName,
      versionDescription,
      updateVersionDetails,
      activeVersion,
      searchTerm,
      replaceTerm,
      updateSearchReplace,
      replace,
      clear,
      replaceAll,
      searchAndReplace,
      img,
      showCropperModal,
      cropper,
      cropImage,
      addSection,
      showCreateModal,
      sectionName,
      createSectionBlock,
      handleFocusEvent,
      startTypingTimer,
      stopTypingTimer
    };
  },
};
</script>

<style>
.ProseMirror {
  margin-top: 0;
  overflow: auto;
  padding: 1.5rem;
}

ul,
ol {
  padding: 0 1rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.1;
}

code {
  background-color: rgba(#616161, 0.1);
  color: #616161;
}

.ProseMirror pre {
  background: #0d0d0d;
  color: #fff;
  font-family: JetBrainsMono, monospace;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
  white-space: pre-wrap;
  min-height: 50vh;
}
.ProseMirror pre code {
  color: inherit;
  padding: 0;
  background: none;
  font-size: 0.8rem;
}

img {
  max-width: 100%;
  height: auto;
}

blockquote {
  padding-left: 1rem;
  border-left: 2px solid rgba(#0d0d0d, 0.1);
}

hr {
  border: none;
  border-top: 2px solid rgba(#0d0d0d, 0.1);
  margin: 2rem 0;
}
.menu-button {
  font-size: inherit;
  font-family: inherit;
  margin: 0.2rem;
  border-radius: 0.3rem;
  padding: 0.1rem 0.4rem;
  background: white;
  accent-color: black;
  min-height: 35px;
  min-width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.is-active,
.menu-button:hover {
  background: rgb(59 130 246 / 1);
  border-color: #ffffff;
  color: #fff;
  cursor: pointer;
  fill: #fff;
}

.is-active,
.menu-button:hover svg {
}
mark {
  background-color: #faf594;
}

.ProseMirror table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  margin: 0;
  overflow: hidden;
}
.ProseMirror table td,
.ProseMirror table th {
  min-width: 1em;
  border: 2px solid #ced4da;
  padding: 3px 5px;
  vertical-align: top;
  box-sizing: border-box;
  position: relative;
  margin-bottom: 0;
}

.ProseMirror table th {
  font-weight: bold;
  text-align: left;
  background-color: #f1f3f5;
}

.ProseMirror table .selectedCell:after {
  z-index: 2;
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(200, 200, 255, 0.4);
  pointer-events: none;
}

.column-resize-handle {
  position: absolute;
  right: -2px;
  top: 0;
  bottom: -2px;
  width: 4px;
  background-color: #adf;
  pointer-events: none;
}

.ProseMirror table p {
  margin: 0;
}

.tableWrapper {
  overflow-x: auto;
}

.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}

.ProseMirror {
  outline: none !important;
}
.ProseMirror:focus {
  outline: none !important;
}
.ProseMirror span[data-comment] {
  background: rgba(172, 255, 47, 0.5);
}
.ProseMirror span[data-comment]::after {
  content: "";
  user-select: text;
}

.search-result{
  background-color:rgb(255 217 0 / 50%) ;
}

.lg\:prose-lg :where(p):not(:where([class~="not-prose"] *)){
  margin-top: 0;
  margin-bottom: 1rem;
}

.content{
  min-height: 250px;
}
</style>
