<template>
  <div>
    <div class="mb-5">
      <div class="col-sm-12">
        <button
          class="capitalize flex text-blue-500 hover:underline"
          v-if="currentComponent"
          @click="swapComponent(null, false, null)"
        >
          <ArrowLeftIcon class="mr-2 h-6 w-6" />
          back
        </button>
      </div>
    </div>

    <component
      :is="currentComponent"
      :templateObject="prop_template"
      @refreshTemplates="loadTemplates"
    ></component>

    <div v-if="!currentComponent">
      <div class="px-4 sm:px-6 md:px-8 mb-10">
        <h1 class="text-2xl font-semibold text-gray-700">Your Templates</h1>
      </div>
      <div class="max-w-lg mx-auto">
        <div>
          <div class="text-center">
            <TemplateIcon class="mx-auto h-12 w-12 text-gray-400" />
            <h2 class="mt-2 text-lg font-medium text-gray-900">
              Create A Template
            </h2>
            <p class="mt-1 text-sm text-gray-500">
              Help you team deliver content based on a given structure
            </p>
          </div>
          <form action="#" class="my-6 text-center sm:flex">
            <label for="email" class="sr-only">Enter Template Name</label>
            <input
              type="text"
              name="email"
              v-model="inputTemplateName"
              id="email"
              class="
                shadow-sm
                focus:ring-blue-500 focus:border-blue-500
                block
                w-full
                py-2
                sm:py-0
                sm:text-sm
                border-gray-300
                rounded-md
                border
                pl-2
              "
              placeholder="Enter Template Name"
            />
            <button
              type="button"
              v-bind:class="{ disabled: disableSaveButton }"
              :disabled="disableSave"
              @click="addNewTemplate"
              class="
                ml-4
                flex-shrink-0
                px-4
                py-2
                border border-transparent
                text-sm
                font-medium
                rounded-md
                shadow-sm
                mt-3
                sm:mt-0
                text-white
                bg-blue-600
                hover:bg-blue-700
                focus:outline-none
                focus:ring-2
                focus:ring-offset-2
                focus:ring-blue-500
              "
            >
              Add New Template
            </button>
          </form>
        </div>
      </div>

      <AlertSuccess
        v-if="successMessage"
        :message="successMessage"
        @hide="successMessage = null"
      />
      <AlertError
        v-if="errorMessage"
        :message="[errorMessage]"
        @hide="errorMessage = null"
      />

      <div class="mx-auto mt-20">
        <div class="grid md:grid-cols-2 lg:grid-cols-4 gap-5">
          <div
            v-for="template in listOfTemplates"
            :key="template.id"
            class="
              h-60
              bg-white
              border
              shadow
              rounded-xl
              flex flex-col
              justify-center
              items-center
            "
          >
            <div class="text-gray-500 text-left block w-full py-4 px-5">
              <div v-if="template.editTemplateName" class="flex">
                <input
                  name="name"
                  :disabled="!template.editTemplateName"
                  :value="`${template.templateName}`"
                  v-on:keyup.enter="submitNewTemplateName($event, template)"
                  class="w-full border px-3 py-1 text-gray-800 rounded-md"
                />
                <InlineLoader
                  v-if="saving"
                  :show="saving"
                  color="#ccc"
                  class="ml-4"
                />

                <XIcon
                  v-else
                  @click="template.editTemplateName = false"
                  class="
                    ml-4
                    h-8
                    w-8
                    text-red-500
                    hover:text-red-600
                    cursor-pointer
                  "
                />
              </div>
              <div v-else class="font-bold text-gray-600 flex">
                <a
                  href="#"
                  class="hover:underline"
                  @click="
                    swapComponent(
                      'ViewTemplate',
                      template.isDeletingTemplate,
                      template
                    )
                  "
                >
                  {{ template.templateName }}</a
                >

                <PencilIcon
                  @click.stop="editTemplateName(template)"
                  class="
                    mx-2
                    h-4
                    w-4
                    text-gray-500
                    hover:text-blue-500
                    cursor-pointer
                  "
                />
              </div>
              <div class="text-sm">
                Created on {{ formatDate(template.created_at) }}
              </div>
            </div>
            <div class="flex-1 w-full h-full cursor-pointer" 
                 @click="swapComponent('ViewTemplate',template.isDeletingTemplate, template)">
            </div>
            <div class="w-full flex justify-end px-2 py-2">
              <TrashIcon
                class="
                  -ml-1
                  mr-2
                  h-6
                  w-6
                  text-gray-400
                  hover:text-red-500
                  cursor-pointer
                "
                @click="
                  showDeleteModal = true;
                  activeDelete = template;
                "
              />
            </div>
          </div>
        </div>
        <DeleteAlert
          :show="showDeleteModal"
          item="Template"
          body="All task content  associated with the template will be deleted "
          @delete="deleteCreatedTemplate"
          @cancel="showDeleteModal = false"
        />
      </div>

      <AlertWithDescription
        v-if="!listOfTemplates"
        header="You do not have any templates"
        body="Add a Template and show your team how your content should be structured"
      />
    </div>
  </div>
</template>

<script>
import ViewTemplate from "@/components/ViewTemplate.vue";
import Modal from "@/components/Modal.vue";
import { ref, computed,onMounted,onBeforeUnmount,inject} from "vue";
import Api from "@/api/Api.js";
import AlertWithDescription from "./AlertWithDescription";
import AlertError from "./AlertError";
import AlertSuccess from "./AlertSuccess";
import DeleteAlert from "./DeleteAlert";
import { XIcon, TrashIcon, ArrowLeftIcon } from "@heroicons/vue/outline";
import { PencilIcon, TemplateIcon } from "@heroicons/vue/solid";
import { useLoading } from "vue-loading-overlay";
import InlineLoader from "./InlineLoader";

export default {
  components: {
    ViewTemplate,
    Modal,
    AlertSuccess,
    AlertError,
    AlertWithDescription,
    DeleteAlert,
    XIcon,
    TrashIcon,
    ArrowLeftIcon,
    PencilIcon,
    TemplateIcon,
    InlineLoader,
  },

  setup() {
    const showDeleteModal = ref(false);
    const activeDelete = ref();
    const $loading = useLoading();
    //Get userid and intialize variable userid
    let userFromStorage = JSON.parse(localStorage.getItem("user"));
    let userid = userFromStorage.id;
    const saving = ref(false);

    let currentComponent = ref(null);
    let inputTemplateName = ref("");
    const showModal = ref(false);
    let disableSaveButton = ref(null);
    let listOfTemplates = ref(null);
    let prop_template = ref(null);
    let successMessage = ref("");
    let errorMessage = ref("");
    const canDeleteTemplate = inject('canDeleteTemplate');

    const deleteCreatedTemplate = () => {
      deleteTemplate(activeDelete.value);
      showDeleteModal.value = false;
    };

    const disableSave = computed(() => {
      if (inputTemplateName.value.trim().length > 0) return false;
      return true;
    });

    const show = () => {
      showModal.value = true;
    };

    const closeModal = () => {
      showModal.value = false;
      disableSaveButton.value = false;
      inputTemplateName.value = "";
    };

    function reloadTemplates() {
      //  loadTemplates();
    }

    function swapComponent(component, isDeleting, templateObj) {
      successMessage.value = null;
      errorMessage.value = null;
      let _templateObj = {
        id: templateObj ? templateObj.id : "",
        templateName: templateObj ? templateObj.templateName : "",
      };

      if (!isDeleting) {
        currentComponent.value = component;
        prop_template.value = _templateObj;
      }
    }

    function loadTemplates() {
      const loader = $loading.show({
        color: "#2581ef",
        opacity: 1,
      });
      //  /templates/show-my-templates
      //  listOfTemplates
      Api()
        .get(`/templates/show-my-templates/${userid}`)
        .then((response) => {
          //Code for handling the response
          //add attribute editTeamName so that you can toggle showDetails row
          if (response.data.status === "success") {
            response.data.templates.forEach(function (row) {
              row.editTemplateName = false;
              row.isDeletingTemplate = false;
              row.isEditing = false;
              row.isViewing = false;
            });

            listOfTemplates.value = response.data.templates;
          } else {
            listOfTemplates.value = null;
          }
        })
        .catch((error) => {
          
        })
        .finally(() => {
          loader.hide();
        });
    }

    function addNewTemplate() {
      const loader = $loading.show({
        color: "#2581ef",
        opacity: 1,
      });

      if (inputTemplateName.value.trim().length <= 0) return;

      // build the form object
      let templateObject = {
        templateName: inputTemplateName.value,
        templateCreator: userid,
      };

      disableSaveButton.value = true;
      //
      Api()
        .post("/templates/create-template", templateObject)
        .then((response) => {
          if (response.data.status === "error") {
            errorMessage.value = response.data.message + "\n";
          } else if (response.data.status === "success") {
            successMessage.value = response.data.message + "\n";
          }

          inputTemplateName.value = "";
        })
        .catch((error) => {
          errorMessage.value = error.response.data.errors.projectName;
          // errorModalMessage.value = error.response.data.errors.projectName;
        })
        .finally(() => {
          loadTemplates();
          disableSaveButton.value = false;
          loader.hide();
        });
    }

    function formatDate(date) {
      var d1 = new Date(date);
      var ms = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      return d1.getDate() + " " + ms[d1.getMonth()] + " " + d1.getFullYear();
    }

    function editTemplateName(templateObj) {
      templateObj.editTemplateName = !templateObj.editTemplateName;

      templateObj.isDeletingTemplate = templateObj.editTemplateName;

      templateObj.isViewing = templateObj.editTemplateName;
    }

    function submitNewTemplateName(event, templateObj) {
      if (event.target.value.length > 0) {
        saving.value = true;
        let newTemplateName = event.target.value;

        let updateTemplateId = templateObj.id;

        event.target.disabled = true;

        let template = {
          templateName: newTemplateName,
        };

        // successMessage.value = "";
        // errorMessage.value = null;

        Api()
          .patch(
            `/templates/update-template-name/${updateTemplateId}`,
            template
          )
          .then((response) => {
            let message = response.data.message;
            let responseTemplateName = response.data.templateName;
            
            // successMessage.value = message + "\n";
            if(response.data.status === "success"){
              successMessage.value = message + "\n";
              templateObj.templateName =  responseTemplateName;
            }else{
              errorMessage.value = message + "\n";
              //templateObj.editTemplateName = true;
            }
          })
          .catch((error) => {
            if(error.response.data.errors.templateName){
            errorMessage.value = error.response.data.errors.templateName;
            }
          })
          .finally(() => {
            editTemplateName(templateObj)
            saving.value = false;
          });
      }
    }

    function deleteTemplate(templateObj) {
      let templateName = templateObj.templateName;
      let templateId = templateObj.id;
      const loader = $loading.show({
        color: "#2581ef",
        opacity: 1,
      });
      // successMessage.value = "";
      // errorModalMessage.value = null;
      // errorMessage.value = null;

      //disable delete button
      templateObj.isDeletingTemplate = true;

      templateObj.isEditing = true;

      templateObj.isViewing = true;

      Api()
        .delete(`/templates/delete-template/${templateId}`)
        .then((response) => {

          let responseMessage = response.data.message;

          if (response.data.status === "success") {
            successMessage.value = responseMessage;
          }else{
            errorMessage.value   = responseMessage;
          }

        })
        .catch((error) => {
          
        })
        .finally(() => {
          //load templates afterwards
          loadTemplates();
          loader.hide();
        });
    }

    onMounted(()=>{
      canDeleteTemplate.value = true;
    });
    onBeforeUnmount(()=>{
      canDeleteTemplate.value = false;
    })

    loadTemplates();

    return {
      currentComponent,
      swapComponent,
      inputTemplateName,
      showModal,
      show,
      closeModal,
      disableSaveButton,
      disableSave,
      listOfTemplates,
      formatDate,
      submitNewTemplateName,
      deleteTemplate,
      editTemplateName,
      prop_template,
      reloadTemplates,
      loadTemplates,
      deleteCreatedTemplate,
      showDeleteModal,
      activeDelete,
      successMessage,
      addNewTemplate,
      errorMessage,
      saving,
    };
  },
};
</script>

<style></style>
