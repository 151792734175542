<template>
  <main class="relative z-10 flex-auto flex items-center justify-center text-sm text-center  py-16 px-4 sm:px-6 lg:px-8 md:mt-36">
    <div class="w-full max-w-sm">
      <div class="pl-4 my-6 "><a class="toggleColour no-underline hover:no-underline font-bold text-2xl lg:text-4xl text-center" href="/"> Sliceloop </a></div>
      <h1 class="sr-only">Log in to your account</h1>

      <form action="/login" method="POST">

        <div class="relative">
          <p class="text-red-500 mb-2"   v-if="generalMessage">
            {{ generalMessage }}
          </p>
          <label for="email" class="sr-only">Email address</label>
          <input type="email"   placeholder="Email address" v-model="email" class="text-gray-900 ring-gray-900 ring-opacity-5 placeholder-gray-400 appearance-none bg-white rounded-md block w-full px-3 py-2 border border-transparent shadow ring-1 sm:text-sm mb-4 focus:border-blue-500 focus:ring-blue-500 focus:outline-none" id="email" />

        </div>
        <div class="relative">
          <label for="password" class="sr-only">Password</label>
          <input x-ref="password" id="password" v-model="password" name="password" type="password" required="" class="text-gray-900 ring-gray-900 ring-opacity-5 placeholder-gray-400 appearance-none bg-white rounded-md block w-full px-3 py-2 border border-transparent shadow ring-1 sm:text-sm mb-6 focus:border-blue-500 focus:ring-blue-500 focus:outline-none" placeholder="Password">
        </div>

        <button
            v-bind:class="{ disabled: disableButton }"
            :disabled="disableLoginButton"
            @click.prevent="login"
            type="button" class=" flex justify-center items-center block w-full py-2 px-3 border border-transparent rounded-md text-white font-medium bg-blue-500
        shadow-sm sm:text-sm mb-10 hover:bg-blue-600 focus:outline-none focus-visible:ring-2
        focus-visible:ring-gray-700 focus-visible:ring-offset-2 focus-visible:ring-offset-gray-50">
        <InlineLoader :h="25" :w="25" :show="canShow"/>
          Sign in to account
        </button>

        <p class="text-center">
          <router-link
              :to="{ name: 'ForgotPassword' }"
              class="text-sm underline hover:text-gray-900"
          >Forgot Password?</router-link
          >
        </p>
      </form>
    </div>
  </main>
  <footer class="relative z-10 flex-none text-sm text-center py-4 px-4 sm:px-6 lg:px-8">
    <div class="text-gray-900 sm:flex sm:items-center sm:justify-center space-y-4 sm:space-y-0 sm:space-x-4">
      <p>Don't have an account?</p>
      <router-link
          :to="{ name: 'pricing' }"
          class="rounded-md border border-gray-300 hover:border-gray-400 py-2 px-10 font-medium flex items-center justify-center"
      >
        Sign up
        <svg aria-hidden="true" width="11" height="10" fill="none" class="flex-none ml-1.5">
          <path d="M5.977 9.639L10.616 5 5.977.362l-.895.89L8.19 4.353H.384v1.292H8.19L5.082 8.754l.895.885z" fill="currentColor"></path>
        </svg>
      </router-link
      >
    </div>
  </footer>
</template>

<script>
import { ref } from "vue";
import Api from "@/api/Api.js";
import { useRouter } from "vue-router";
import { computed } from "vue";
import InlineLoader from "../components/InlineLoader";


export default {
  name:"Login",
  components:{InlineLoader},
  setup() {
    const router = useRouter();
    let email = ref("");
    let password = ref("");
    let emailMessage = ref(null);
    let passwordMessage = ref(null);
    let generalMessage = ref("");
    let disableButton = ref(false);
    const canShow = ref(false)

    const disableLoginButton = computed(() => {
      if (email.value.trim().length > 0 && password.value.trim().length > 0) {
        return false;
      }

      return true;
    });



    function login() {
      emailMessage.value = [];
      passwordMessage.value = [];
      generalMessage.value = "";
      disableButton.value = true;

      let user = {
        email: email.value,
        password: password.value,
      };

      canShow.value = true;

      Api()
          .post("/login", user)

          .then((response) => {
            let message = response.data.message;

            let token = response.data.token;

            let user = response.data.user;

            localStorage.setItem("token", token);
            localStorage.setItem("user", JSON.stringify(user));

            router.push({ name: "dashboardHome" });
          })
          .catch((error) => {
            if(error.response.data.message){
                generalMessage.value = error.response.data.message;
            }else{
                generalMessage.value = "An error has occurred! Please try again";
            }
                disableButton.value = false;
          }).finally(()=>{
            canShow.value = false;
      });
    }
    return {
      email,
      password,
      login,
      disableLoginButton,
      emailMessage,
      passwordMessage,
      generalMessage,
      disableButton,
      canShow
    };
  },
};
</script>


<style scoped>

</style>