<template>
  <div>
    <div class="mb-5" id="initBack">
      <div class="col-sm-12">
        <button
          class="capitalize flex text-blue-500 hover:underline"
          v-if="currentComponent === 'ViewContentTasksAssignedToMe'"
          @click="swapComponent(null, 0, 0)"
        >
          <ArrowLeftIcon class="mr-2 h-6 w-6" />
          back
        </button>
      </div>
    </div>

    <component
      :is="currentComponent"
      :project_id="current_project_id"
      :workflow_id="prop_workflow_id"
    >
    </component>

    <div class="" v-if="!currentComponent">
      <div class="px-4 sm:px-6 md:px-8 mb-10">
        <h1 class="text-2xl font-semibold text-gray-700 capitalize">
          Tasks Assigned to You
        </h1>
      </div>

      <div class="flow-root mt-10 max-w-3xl mx-auto">
        <AlertWithDescription
          v-if="!listOfProjects"
          header="No tasks at the moment!"
          body="You do not have any tasks assigned to you at this time, please check again later"
        />

        <ul role="list" class="-my-5">
          <li
            v-for="project in listOfProjects"
            :key="project.projectId"
            class="py-4 px-6 rounded-xl bg-gray-50 mt-3"
          >
            <div class="flex items-center space-x-4 cursor-pointer" 
                 @click="project.workflow_id? swapComponent('ViewContentTasksAssignedToMe',project.projectId, project.workflow_id ): ''">
              <div class="flex-1 min-w-0">
                <p class="text-sm font-bold text-gray-900 truncate capitalize">
                  {{ project.projectName }}
                </p>
                <p class="text-sm text-gray-500 truncate mt-2">
                  {{ "Created On " + formatDate(project.created_at) }}
                </p>
              </div>
              <div>
                <button
                  type="button"
                  class="
                    inline-flex
                    items-center
                    py-2
                    px-3
                    text-blue-500
                    hover:underline
                  "
                  @click="
                    project.workflow_id
                      ? swapComponent(
                          'ViewContentTasksAssignedToMe',
                          project.projectId,
                          project.workflow_id
                        )
                      : ''
                  "
                >
                  View
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/api/Api.js";
import ViewContentTasksAssignedToMe from "@/components/ViewContentTasksAssignedToMe.vue";
import { onMounted, ref } from "vue";
import { ArrowLeftIcon } from "@heroicons/vue/outline";
import { useLoading } from "vue-loading-overlay";
import AlertWithDescription from "./AlertWithDescription";

export default {
  components: {
    ViewContentTasksAssignedToMe,
    ArrowLeftIcon,
    AlertWithDescription,
  },
  setup() {
    //Get userid and intialize variable userid
    let userFromStorage = JSON.parse(localStorage.getItem("user"));
    let userid = userFromStorage.id;
    const $loading = useLoading();
    let listOfProjects = ref(null);
    let currentComponent = ref(null);
    let current_project_id = ref(0);
    let prop_workflow_id = ref("");
    let prop_project_name = ref("");

    function loadProjects() {
      const loader = $loading.show({
        color: "#2581ef",
        opacity: 1,
      });
      Api()
        .get(`/projects/show-my-task-projects/${userid}`)
        .then((response) => {
          if (response.data.status === "success") {
            listOfProjects.value = response.data.projects;
          } else {
            listOfProjects.value = null;
          }
        })
        .catch((error) => {
          
        })
        .finally(() => {
          loader.hide();
        });
    }

    function formatDate(date) {
      var d1 = new Date(date);
      var ms = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      return d1.getDate() + " " + ms[d1.getMonth()] + " " + d1.getFullYear();
    }

    function swapComponent(component, temp_project_id, varworkflow_id) {
      current_project_id.value = temp_project_id;
      prop_workflow_id.value = varworkflow_id;
      currentComponent.value = component;
      loadProjects();
    }

    loadProjects();

    return {
      listOfProjects,
      formatDate,
      currentComponent,
      swapComponent,
      current_project_id,
      prop_project_name,
      prop_workflow_id,
    };
  },
};
</script>

<style></style>
