<template>
  <Popover class="relative inline-block text-left ml-4">
    <PopoverButton
      class="
        hover:bg-gray-100
        rounded-full
        flex
        items-center
        text-gray-400
        hover:text-gray-600
        focus:outline-none
        absolute
      "
      style="top: -13px"
    >
      <span class="sr-only">Open options</span>
      <BIconFilter class="h-5 w-5" aria-hidden="true" />
    </PopoverButton>

    <transition
      enter-active-class="transition duration-200 ease-out"
      enter-from-class="translate-y-1 opacity-0"
      enter-to-class="translate-y-0 opacity-100"
      leave-active-class="transition duration-150 ease-in"
      leave-from-class="translate-y-0 opacity-100"
      leave-to-class="translate-y-1 opacity-0"
    >
    <div>
      <PopoverPanel
        class="
          absolute
          w-56
          py-1
          mt-2
          bg-white
          rounded-md
          shadow-lg
          ring-1 ring-black ring-opacity-5
          focus:outline-none
          z-20
        "
      >
        <input
          @keyup="$emit('filter', text)"
          type="text"
          placeholder="Filter"
          class="
            text-gray-900
            ring-gray-900 ring-opacity-5
            placeholder-gray-400
            appearance-none
            bg-white
            rounded-md
            block
            w-full
            px-3
            py-2
            sm:text-sm
          "
          v-model="text"
          id="name"
        />
      </PopoverPanel>
    </div>
    </transition>
  </Popover>
</template>

<script>
import { BIconFilter } from "bootstrap-icons-vue";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";
import { ref } from "vue";
export default {
  name: "Filter",
  components: {
    BIconFilter,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    Popover,
    PopoverButton,
    PopoverPanel,
  },
  emits: ["filter"],
  setup() {
    const text = ref("");

    return { text };
  },
};
</script>

<style scoped></style>
