<template>
  <div>
    <div class="mb-5">
      <div class="col-sm-12">
        <button
          class="capitalize flex text-blue-500 hover:underline"
          id="backBtn"
          v-if="currentComponent"
          @click="swapComponent(null, 0, '', '')"
        >
          <ArrowLeftIcon class="mr-2 h-6 w-6" />
          back to projects
        </button>
      </div>
    </div>

    <component
      :is="currentComponent"
      :project_id="current_project_id"
      :project_name="prop_project_name"
      :workflow_id="prop_workflow_id"
    ></component>

    <div v-if="!currentComponent">
      <AlertSuccess
        v-if="successMessage"
        :message="successMessage"
        @hide="successMessage = null"
      />

      <AlertError
        v-if="errorMessage"
        :message="errorMessage"
        @hide="errorMessage = null"
      />

      <div class="text-center" v-if="!listOfProjects && !archived">
        <svg
          class="mx-auto h-12 w-12 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            vector-effect="non-scaling-stroke"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
          />
        </svg>
        <h3 class="mt-2 text-sm font-medium text-gray-900">No projects</h3>
        <p class="mt-1 text-sm text-gray-500">
          Get started by creating a new project.
        </p>
        <div class="mt-6">
          <button
            @click="show"
            type="button"
            class="
              inline-flex
              items-center
              px-4
              py-2
              border border-transparent
              shadow-sm
              text-sm
              font-medium
              rounded-md
              text-white
              bg-blue-600
              hover:bg-blue-700
              focus:outline-none
              focus:ring-2
              focus:ring-offset-2
              focus:ring-blue-500
            "
          >
            <PlusIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
            New Project
          </button>
        </div>
      </div>

      <div v-else class="mx-auto mt-20">
        <div class="px-4 sm:px-6 md:px-8 mb-10 sm:flex sm:justify-between sm:flex-wrap">
          <div>
          <h1 class="text-2xl font-semibold text-gray-700">Your Projects</h1>
          <div class="text-xs mt-2 sm:flex">
            <span class="">Stage Status:</span>
            <div class="flex flex-none items-center ml-4">
              <span class="indicator"></span> <span>No-tasks</span>
            </div>
            <div class="flex flex-none items-center ml-4">
              <span class="indicator status-1"></span> <span>Pending</span>
            </div>
            <div class="flex flex-none items-center ml-4">
              <span class="indicator status-2"></span> <span>In Progress</span>
            </div>
            <div class="flex flex-none items-center ml-4">
              <span class="indicator status-3"></span> <span>Done</span>
            </div>
          </div>
          </div>
          <SwitchGroup>
            <div id="my-projects-step-5" class="lg:flex lg:items-center">
              <SwitchLabel class="mr-4 text-xs">Active  Projects</SwitchLabel>
              <Switch
                  v-model="archived"
                  :class='archived ? "bg-blue-600" : "bg-gray-200"'
                  class="relative block sm:inline-flex h-5 sm:h-6 w-11 items-center rounded-full transition-colors focus:outline-none "
              >
                <span
                    :class='archived ? "translate-x-3 sm:translate-x-6" : "-translate-x-3 sm:translate-x-1"'
                    class="inline-block h-4 w-4 transform rounded-full bg-white transition-transform"
                />
              </Switch>
              <SwitchLabel class="ml-0 sm:ml-4 text-xs">Archived Projects</SwitchLabel>

            </div>
          </SwitchGroup>
        </div>
        <div class="grid md:grid-cols-2 lg:grid-cols-4 gap-5">
          <a
             id="my-projects-step-0"
              v-if="!archived"
            href="#"
            @click="my_project_tour_tutorial != 1 ? show() : ''"
            class="
              h-80
              bg-white
              border
              shadow
              rounded-xl
              flex flex-col
              justify-center
              items-center
              text-blue-500
              hover:bg-gray-50
            "
          >
            <PlusIcon class="-ml-1 mr-2 h-8 w-8" />
            Add Project
          </a>

          <div
            v-for="(project,index) in listOfProjects"
            :key="project.projectId"
            class="
              h-80
              bg-white
              border
              shadow
              rounded-xl
              flex flex-col
              justify-center
              items-center
            "
            :id="'project-'+project.projectId"
          >
            <div class="text-gray-500 text-left block w-full py-4 px-5">
              <div v-if="project.editProjectName" class="flex align-center">
                <input
                  name="name"
                  :value="project.projectName"
                  v-on:keyup.enter="submitNewProjectName($event, project)"
                  class="w-full border px-3 py-1 text-gray-800 rounded-md"
                />
                <InlineLoader
                  v-if="saving"
                  :show="saving"
                  color="#ccc"
                  class="ml-4"
                />
                <XIcon
                  v-else
                  @click="project.editProjectName = false"
                  class="
                    ml-4
                    h-8
                    w-8
                    text-red-500
                    hover:text-red-600
                    cursor-pointer
                  "
                />
              </div>
              <div v-else class="font-bold text-blue-500 flex">
                <a
                  href="#"
                  class="hover:underline"
                  @click="
                    project.workflow_id
                      ? swapComponent(
                          'ViewAllContentTasks',
                          project.projectId,
                          project.projectName,
                          project.workflow_id
                        )
                      : ''
                  "
                  >{{ project.projectName }}</a
                >
                <div  :id="index == 0 ? 'my-projects-step-1': ''">
                <PencilIcon
                  @click.stop="my_project_tour_tutorial != 1 ? editProjectName(project) : ''"
                  class="
                    mx-2
                    h-4
                    w-4
                    text-gray-500
                    hover:text-blue-500
                    cursor-pointer
                  "
                />
                </div>
              </div>
              <div class="text-xs">
                Last updated {{ formatDate(project.updated_at) }}
              </div>

              <div class="text-xs capitalize">
                {{ project.workFlowName }}
              </div>
            </div>
            <div :id="index == 0 ? 'my-projects-step-2': ''"  
               class="flex-1 w-full px-2 capitalize cursor-pointer" 
               @click="
                    project.workflow_id && (my_project_tour_tutorial == 0)
                      ? swapComponent(
                          'ViewAllContentTasks',
                          project.projectId,
                          project.projectName,
                          project.workflow_id
                        )
                      : ''
                  ">
              <div
                class="space-y-1 stages"
                @scroll="handleScroll"
                aria-label="Sidebar"
              >
                <div
                  v-for="item in project.project_summary"
                  :key="item.stageName"
                  class="
                    text-gray-600
                    group
                    flex
                    items-center
                    px-3
                    pt-2
                    font-medium
                    rounded-md
                    text-xs
                  "
                  :aria-current="item.current ? 'page' : undefined"
                >
                  <span :class="'indicator status-' + item.statusID"></span>
                  <span class="truncate">
                    {{ item.stageName }}
                  </span>
                  <span
                    class="
                      bg-blue-500
                      text-white
                      ml-auto
                      inline-block
                      py-0.5
                      px-3
                      text-xs
                      rounded-full
                    "
                  >
                    {{ item.total }}
                  </span>
                </div>
              </div>
              <div
                class="flex justify-center mt-2"
                v-if="project.project_summary.length > 5"
              >
                <button
                  class="flex justify-center text-center w-20 up hidden"
                  @click="scrollUpParent"
                >
                  <ChevronUpIcon
                    class="w-6 h-6 text-blue-400 hover:text-blue-500"
                  />
                </button>

                <button
                  class="flex justify-center text-center w-20 down"
                  @click="scrollParent"
                >
                  <ChevronDownIcon
                    class="w-6 h-6 text-blue-400 hover:text-blue-500"
                  />
                </button>
              </div>
            </div>
            <div  class="w-full flex justify-between px-2 py-2">
              <button :id="index == 0 ? 'my-projects-step-3': ''" v-if="!archived" class="hover:text-yellow-500     text-gray-400 flex items-center" @click="my_project_tour_tutorial != 1 ? archiveProject(project.projectId) : ''">
                <svg v-if="isArchivingProject !== project.projectId"  title="archive Project" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                     class="
                  ml-2
                  mr-2
                  h-5
                  w-5

                  hover:text-yellow-500
                  cursor-pointer,
                  archiveProject
                " viewBox="0 0 16 16">
                  <path d="M0 2a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1v7.5a2.5 2.5 0 0 1-2.5 2.5h-9A2.5 2.5 0 0 1 1 12.5V5a1 1 0 0 1-1-1V2zm2 3v7.5A1.5 1.5 0 0 0 3.5 14h9a1.5 1.5 0 0 0 1.5-1.5V5H2zm13-3H1v2h14V2zM5 7.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z"/>
                </svg>

                <InlineLoader v-else :show="true" color="#2581ef" />
                <span class="text-xs">Archive</span>
              </button>
              <button :id="index == 0 ? 'my-projects-step-3': ''" v-else class="hover:text-yellow-500     text-gray-400 flex items-center" @click="my_project_tour_tutorial != 1 ? unArchiveProject(project.projectId) : ''">
                <svg  v-if="isArchivingProject !== project.projectId"  xmlns="http://www.w3.org/2000/svg"         class="
                  ml-2
                  mr-2
                  h-5
                  w-5

                  hover:text-yellow-500
                  cursor-pointer,
                  archiveProject
                " fill="currentColor" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z"/>
                  <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z"/>
                </svg>

                <InlineLoader v-else :show="true" color="#2581ef" />
                <span class="text-xs">Un-Archive</span>
              </button>

              <div  :id="index == 0 ? 'my-projects-step-4': ''" >
                  <TrashIcon
                    class="
                      -ml-1
                      mr-2
                      h-6
                      w-6
                      text-gray-400
                      hover:text-red-500
                      cursor-pointer
                    "
                    @click="my_project_tour_tutorial != 1 ? (showDeleteModal = true, activeDelete = project) : ''"
                  />
              </div>
            </div>
          </div>
        </div>
        <DeleteAlert
          :show="showDeleteModal"
          item="project"
          @delete="deleteActiveProject"
          @cancel="showDeleteModal = false"
        />
      </div>

      <TransitionRoot as="template" :show="showModal">
        <Dialog
          as="div"
          class="fixed z-10 inset-0 overflow-y-auto"
          @close="open = false"
        >
          <div
            class="
              flex
              items-end
              justify-center
              min-h-screen
              pt-4
              px-4
              pb-20
              text-center
              sm:block sm:p-0
            "
          >
            <TransitionChild
              as="template"
              enter="ease-out duration-300"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="ease-in duration-200"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <DialogOverlay
                class="fixed inset-0 bg-black bg-opacity-75 transition-opacity"
              />
            </TransitionChild>

            <!-- This element is to trick the browser into centering the modal contents. -->
            <span
              class="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
              >&#8203;</span
            >
            <TransitionChild
              as="template"
              enter="ease-out duration-300"
              enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enter-to="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leave-from="opacity-100 translate-y-0 sm:scale-100"
              leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div
                class="
                  inline-block
                  align-bottom
                  bg-white
                  rounded-lg
                  px-4
                  pt-5
                  pb-4
                  text-left
                  overflow-hidden
                  shadow-xl
                  transform
                  transition-all
                  sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6
                "
              >
                <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    class="
                      bg-white
                      rounded-md
                      text-gray-400
                      hover:text-gray-500
                      focus:outline-none
                      focus:ring-2
                      focus:ring-offset-2
                      focus:ring-blue-500
                    "
                    @click="showModal = false"
                  >
                    <span class="sr-only">Close</span>
                    <XIcon class="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div class="sm:flex sm:items-start">
                  <form @submit.prevent="onSubmit" class="w-full">
                    <div class="mt-3 text-center sm:mt-0 sm:text-left w-full">
                      <DialogTitle
                        as="h3"
                        class="text-lg leading-6 font-medium text-gray-900"
                      >
                        Create A Project
                      </DialogTitle>
                      <div class="mt-6">
                        <div class="mb-3">
                          <label for="inputProjectName" class="sr-only"
                            >Enter Project Name</label
                          >
                          <input
                            type="text"
                            name="inputProjectName"
                            v-model="inputProjectName"
                            id="inputProjectName"
                            class="
                              text-gray-900
                              ring-gray-900 ring-opacity-5
                              placeholder-gray-400
                              appearance-none
                              bg-white
                              rounded-md
                              block
                              w-full
                              px-3
                              py-2
                              border border-transparent
                              shadow
                              ring-1
                              sm:text-sm
                              mb-4
                              focus:border-blue-500
                              focus:ring-blue-500
                              focus:outline-none
                            "
                            placeholder="Enter Project Name"
                          />
                        </div>

                        <div class="form-check mb-3 hidden">
                          <input
                            class="form-check-input"
                            id="flexCheckDefault"
                            type="checkbox"
                            v-model="checked"
                            disabled
                          />
                          <label for="flexCheckDefault"> Use a Workflow </label>
                        </div>
                        <p class="text-red-500 mb-2 text-sm">
                          {{ workflowErrorMessage }}
                        </p>
                        <div v-if="checked" class="mb-3">
                          <label for="inputWorkflow" class="sr-only"
                            >Choose a Workflow</label
                          >
                          <select
                            name="inputWorkflow"
                            v-model="inputWorkflow"
                            class="
                              text-gray-900
                              ring-gray-900 ring-opacity-5
                              placeholder-gray-400
                              appearance-none
                              bg-white
                              rounded-md
                              block
                              w-full
                              px-3
                              py-2
                              border border-transparent
                              shadow
                              ring-1
                              sm:text-sm
                              mb-4
                              focus:border-blue-500
                              focus:ring-blue-500
                              focus:outline-none
                            "
                            id="inputWorkflow"
                          >
                            <option value="Choose a Workflow" selected>
                              Choose a Workflow
                            </option>
                            <option
                              v-for="workflow in listOfWorkflows"
                              :key="workflow.workFlowId"
                              v-bind:value="workflow.workFlowId"
                            >
                              {{ workflow.workFlowName }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="mt-5 sm:mt-6">
                      <button
                        type="submit"
                        v-bind:class="{ disabled: disableSaveButton }"
                        :disabled="disableSave"
                        name="inputSave"
                        class="
                          inline-flex
                          justify-center
                          w-full
                          rounded-md
                          border border-transparent
                          disabled:bg-blue-200
                          shadow-sm
                          px-4
                          py-2
                          bg-blue-600
                          text-base
                          font-medium
                          text-white
                          hover:bg-blue-700
                          focus:outline-none
                          focus:ring-2
                          focus:ring-offset-2
                          focus:ring-blue-500
                          sm:text-sm
                        "
                        @click="open = false"
                      >
                        Create Project
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </TransitionChild>
          </div>
        </Dialog>
      </TransitionRoot>
    </div>
  </div>
  <GuideModal v-if="guideStage == 5 && !canShowGuide" :is-open="showGuide"  @close="showGuide=false; showClickProjectPrompt();"/>
  <div>
    <v-tour class="myprojectsTourclass" name="myprojectsTour" :steps="steps" :callbacks="myCallbacks" :options="myOptions"></v-tour>
  </div>
  <div>
    <v-tour class="clickProjectTourclass" name="clickProjectTour" :steps="steps_1" :options="myOptions_1"></v-tour>
  </div>
  <Modal :open="taskManagementModal" @close="taskManagementModal = false">
    <template v-slot:header>
      <h5 class="modal-title text-danger capitalize">Alert</h5>
    </template>

    <template v-slot:body>
      <div class="flex flex-col space-y-5 justify-center items-center -mt-3">
      <div> Please select a project to get started with the tutorial </div>

        <button @click="taskManagementTutorial();"
          class=" flex justify-center items-center w-1/2 py-2 px-3 border border-transparent rounded-md
            text-white font-medium bg-blue-500 shadow-sm sm:text-sm mb-10 disabled:bg-blue-200
            hover:bg-blue-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-700
            focus-visible:ring-offset-2 focus-visible:ring-offset-gray-50
          "
        >
        close
        </button>
      </div>
    </template>
  </Modal>
</template>

<script>
import Api from "@/api/Api.js";
import Modal from "@/components/Modal.vue";
import ViewAllContentTasks from "@/components/ViewAllContentTasks.vue";
import ContentEditor from "@/components/ContentEditor.vue";
import {ref, computed, inject, watch, onMounted, onUnmounted} from "vue";
import GuideModal from "./GuideModal";
import inlineLoader from "./InlineLoader";
import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue'
import {
  PlusIcon,
  CogIcon,
  ChevronDownIcon,
  PencilIcon,
  ChevronUpIcon,
} from "@heroicons/vue/solid";
import InlineLoader from "./InlineLoader";

import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import {
  ExclamationIcon,
  XIcon,
  TrashIcon,
  ArrowLeftIcon,
} from "@heroicons/vue/outline";
import DeleteAlert from "./DeleteAlert";
import AlertSuccess from "./AlertSuccess";
import AlertError from "./AlertError";
import { useLoading } from "vue-loading-overlay";
export default {
  name: "ViewProjects",
  components: {
    AlertError,
    Modal,
    ViewAllContentTasks,
    ContentEditor,
    PlusIcon,
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    ExclamationIcon,
    XIcon,
    DeleteAlert,
    CogIcon,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ChevronDownIcon,
    PencilIcon,
    TrashIcon,
    AlertSuccess,
    ArrowLeftIcon,
    InlineLoader,
    ChevronUpIcon,
    GuideModal,
    inlineLoader,
    Switch, SwitchGroup, SwitchLabel
  },
  setup() {
    const showDeleteModal = ref(false);
    const activeDelete = ref();
    //Get userid and intialize variable userid
    let userFromStorage = JSON.parse(localStorage.getItem("user"));
    let userid = userFromStorage.id;
    const showModal = ref(false);
    const $loading = useLoading();
    const saving = ref(false);
    const showGuide = ref(false)
    const guideStage =  inject('currentGuideStage');
    const canShowGuide = inject('canShowGuide');
    const workflowId = inject('workflowId');
    const isArchiving = ref(false);
    const isArchivingProject = ref(0);
    const archived = ref(false)
    const canShowTasksGuide =  inject('canShowTasksGuide');

    onMounted(() => {


      setTimeout(()=>{
        
        if(task_management_tour_tutorial.value != null){

          update_task_management_tour_tutorial(null)
          taskManagementModal.value = true;

        }

      },1000)
      
    });
    
     onUnmounted(() => {

       //update_task_management_tour_tutorial(null)
       unwatch();
      
    });

    const show = () => {
      showModal.value = true;
    };
    const closeModal = () => {
      showModal.value = false;
      disableSaveButton.value = false;
      inputProjectName.value = "";
      checked.value = true;
      inputWorkflow.value = "Choose a Workflow";
      errorModalMessage.value = null;
      workflowErrorMessage.value = "";
    };

    let inputProjectName = ref("");
    let checked = ref(true);
    let inputWorkflow = ref(workflowId.value && !canShowGuide.value ? workflowId.value  :"Choose a Workflow");
    let listOfWorkflows = ref(null);
    let disableSaveButton = ref(null);
    const disableSave = computed(() => {
      if (inputProjectName.value.trim().length > 0) return false;
      return true;
    });

    let listOfProjects = ref(null);
    let currentComponent = ref(null);
    let current_project_id = ref(0);
    let prop_project_name = ref("");
    let prop_workflow_id = ref("");

    let successMessage = ref("");
    let errorMessage = ref(null);
    let errorModalMessage = ref(null);
    let workflowErrorMessage = ref("");

    const { my_project_tour_tutorial, update_my_project_tour_tutorial } = inject('my_project_tour_tutorial')
    const  {task_management_tour_tutorial, update_task_management_tour_tutorial}  = inject('task_management_tour_tutorial')
    const tours = inject('tours');
    const steps = [
          {
            target: '#my-projects-step-0', 
            content: `<span class="text-sm pb-2">Click here to add project</span>`,
            params: {
              placement: 'bottom-end',
            } 
          },
          {
            target: '#my-projects-step-1',
            content: '<span class="text-sm pb-2">Click here to edit project name</span>',
            params: {
              placement: 'bottom-end',
            } 
          },
          {
            target: '#my-projects-step-2',
            content: '<span class="text-sm pb-2">Click here to view project tasks </span> <br/><small class="italic"> A summary of task statuses per workflow stage also appears here</small>',
             params: {
              placement: 'bottom-end',
            } 
          },
          {
            target: '#my-projects-step-3',
            content: '<span class="text-sm pb-2">Archive / Unarchive Project</span>',
             params: {
              placement: 'bottom-end',
            } 
          },
          {
            target: '#my-projects-step-4',
            content: '<span class="text-sm pb-2">Delete Project</span>',
             params: {
              placement: 'bottom-end',
            } 
          },
          {
            target: '#my-projects-step-5',
            content: '<span class="text-sm pb-2">Toggle between Active and Archived projects</span>',
             params: {
              placement: 'bottom-end',
            } 
          }
        ];
     const steps_1 = [
         
          {
            target: '#my-projects-step-2',
            content: '<span class="text-sm pb-2">Click here to add a task to this project </span>',
             params: {
              placement: 'top-end',
            } 
          }
     ];
     const  myOptions_1= {
          // useKeyboardNavigation: false,
          labels: {
            buttonStop: 'OK'
          },
          // highlight: true

     };
    const  myOptions= {
          useKeyboardNavigation: false,
          labels: {
            buttonSkip: 'Skip tour',
            buttonPrevious: 'Previous',
            buttonNext: 'Next',
            buttonStop: 'Finish'
          },
          highlight: true
    };
    const myCallbacks = {
        onSkip:   myCustomSkipStepCallback,
        onFinish: myCustomFinishStepCallback
    }
    let taskManagementModal = ref(false)

     function  myCustomSkipStepCallback (currentStep) {

        update_my_project_tour_tutorial(0);
       
     }
     function myCustomFinishStepCallback (currentStep) {

       update_my_project_tour_tutorial(0);
      
        
     }

    function onSubmit() {
      workflowErrorMessage.value = "";
      successMessage.value = "";
      errorMessage.value = null;
      errorModalMessage.value = null;

      if (inputProjectName.value.trim().length <= 0) return;

      let workflow_id = inputWorkflow.value;

      //for now enforce workflow
      checked.value = true;

      if (checked.value && workflow_id === "Choose a Workflow") {
        workflowErrorMessage.value =
          "You need to create a workflow first to be able to proceed";
        return;
      }
      if (workflow_id === "Choose a Workflow") {
        workflow_id = null;
      }

      // build the form object
      let projectObject = {
        projectName: inputProjectName.value,
        projectCreator: userid,
        workflow_id: workflow_id,
      };

      disableSaveButton.value = true;
      //
      Api()
        .post("/projects/create-project", projectObject)
        .then((response) => {

          if (response.data.status === "error") {
            errorMessage.value = [response.data.message + "\n"];
          } else if (response.data.status === "success") {
            successMessage.value = response.data.message + "\n";
            guideStage.value = 5;
          } 

          closeModal();
        })
        .catch((error) => {
          errorMessage.value = error.response.data.errors.projectName;
          errorModalMessage.value = error.response.data.errors.projectName;
        })
        .finally(() => {
          //close modal afterwards
          loadProjects();
          disableSaveButton.value = false;
          // closeModal();
        });
    }

    function loadWorkflows() {
      const loader = $loading.show({
        color: "#2581ef",
        opacity: 1,
      });
      Api()
        .get(`/workflows/show-my-workflows/${userid}`)
        .then((response) => {
          // Code for handling the response
          if (response.data.status === "success") {
            listOfWorkflows.value = response.data.workflows;
          } else {
            listOfWorkflows.value = null;
          }
        })
        .catch((error) => {})
        .finally(() => {
          loader.hide();
        });
    }

    function loadProjects() {
      const loader = $loading.show({
        color: "#2581ef",
        opacity: 1,
      });

      Api()
        .get(`/projects/show-my-projects/${userid}`)
        .then((response) => {
          //Code for handling the response
          //add attribute editTeamName so that you can toggle showDetails row
          if (response.data.status === "success") {
            response.data.projects.forEach(function (row) {
              row.editProjectName = false;
              row.isDeletingProject = false;
              row.isEditing = false;
              row.isViewing = false;
            });

            listOfProjects.value = response.data.projects;
             loader.hide();

              if(listOfProjects.value != null && listOfProjects.value.length > 0 && listOfProjects.value.length < 2 && !currentComponent.value && canShowGuide.value ){

                  if(canShowTasksGuide.value == 0 || canShowTasksGuide.value == null){
                     showClickProjectPrompt();
                  }

              }

             if(my_project_tour_tutorial.value == 1 ){

               if(task_management_tour_tutorial.value != null){

                 update_task_management_tour_tutorial(null);

               }

                if(listOfProjects.value != null && listOfProjects.value.length > 0 ){

                    closeClickProjectPrompt();
                    tours['myprojectsTour'].start();

                }else{

                  errorMessage.value = ["Tour cannot start before you create a project. Click on Add Project to create one and try again"]


                }
             }
            
            
          } else {
            listOfProjects.value = null;
            closeClickProjectPrompt();
             loader.hide();
          }
        })
        .catch((error) => {

           loader.hide();
          
        })
        .finally(() => {
          showGuide.value = true;
        });
    }

    function formatDate(date) {
      var d1 = new Date(date);
      var ms = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      return d1.getDate() + " " + ms[d1.getMonth()] + " " + d1.getFullYear();
    }

    function editProjectName(projectObj) {
      projectObj.editProjectName = !projectObj.editProjectName;

      projectObj.isDeletingProject = projectObj.editProjectName;

      projectObj.isViewing = projectObj.editProjectName;
    }

    function submitNewProjectName(event, projectObj) {
      if (event.target.value.length > 0) {
        let newProjectName = event.target.value;

        let UpdateProjectId = projectObj.projectId;

        // event.target.disabled = true;

        let project = {
          projectName: newProjectName,
        };

        successMessage.value = "";
        errorMessage.value = null;
        saving.value = true;

        Api()
          .patch(`/projects/update-project-name/${UpdateProjectId}`, project)
          .then((response) => {
            let message = response.data.message;
            if (response.data.status === "error") {
              errorMessage.value = [message];
            } else {
              successMessage.value = message + "\n";
              projectObj.editProjectName = false;
              projectObj.projectName = newProjectName;
            }
          })
          .catch((error) => {
            errorMessage.value = error.response.data.errors.projectName;
          })
          .finally(() => {
            saving.value = false;
          });
      }
    }

    function deleteProject(projectObj) {
      let projectId = projectObj.projectId;

      successMessage.value = "";
      errorModalMessage.value = null;
      errorMessage.value = null;

      //disable delete button
      projectObj.isDeletingProject = true;

      projectObj.isEditing = true;

      projectObj.isViewing = true;
      const loader = $loading.show({
        color: "#2581ef",
        opacity: 1,
      });
      Api()
        .delete(`/projects/delete-project/${projectId}`)
        .then((response) => {
          let message = response.data.message;
          let status = response.data.status;

          if(status === "success"){
          successMessage.value = message;
          }else{
            errorMessage.value =  [message];
          }

        })
        .catch((error) => {})
        .finally(() => {
          //load projects afterwards
          loadProjects();
          loader.hide();
        });
    }

    const deleteActiveProject = () => {
      deleteProject(activeDelete.value);
      showDeleteModal.value = false;
    };

    function swapComponent(
      component,
      temp_project_id,
      varTempProjectName,
      varworkflow_id
    ) {
      current_project_id.value = temp_project_id;
      prop_project_name.value = varTempProjectName;
      prop_workflow_id.value = varworkflow_id;
      currentComponent.value = component;
      
      closeClickProjectPrompt();

      if (archived.value){
        loadWorkflows();
        loadArchivedProjects();
        return;
      }

      loadWorkflows();
      loadProjects();
    }

    loadWorkflows();

    loadProjects();

    const scrollParent = (env) => {
      env.currentTarget.parentElement.parentElement.querySelector(
        ".stages"
      ).scrollTop += 60;
    };

    const handleScroll = (env) => {
      const target = env.currentTarget;
      if (target.scrollTop == 0) {
        target.nextElementSibling.querySelector(".up").classList.add("hidden");
      } else if (
        target.scrollTop ===
        target.scrollHeight - target.offsetHeight
      ) {
        target.nextElementSibling
          .querySelector(".down")
          .classList.add("hidden");
      } else {
        target.nextElementSibling
          .querySelector(".up")
          .classList.remove("hidden");
        target.nextElementSibling
          .querySelector(".down")
          .classList.remove("hidden");
      }
    };

    const scrollUpParent = (env) => {
      env.currentTarget.parentElement.parentElement.querySelector(
        ".stages"
      ).scrollTop -= 60;
    };

    const archiveProject = (projectId) => {
      isArchivingProject.value = projectId;
      isArchiving.value = true;
      Api().patch(`/projects/archive-project/${projectId}`)
          .then((response) => {
            let message = response.data.message;
            if (response.data.status === "error") {
              errorMessage.value = [message];
            } else {
               document.getElementById('project-'+projectId).remove();
            }
          }).finally(() => {
        isArchivingProject.value = 0;
        isArchiving.value = false;
        loadProjects();
        loadWorkflows();
      })
    }


    const unArchiveProject = (projectId) => {
      isArchivingProject.value = projectId;
      isArchiving.value = true;
      Api().patch(`/projects/restore-archived-project/${projectId}`)
          .then((response) => {
            let message = response.data.message;
            if (response.data.status === "error") {
              errorMessage.value = [message];
            } else {
              document.getElementById('project-'+projectId).remove();
            }
          }).finally(() => {
        isArchivingProject.value = 0;
        isArchiving.value = false;
        loadArchivedProjects();
      })
    }

  const loadArchivedProjects = () => {
    const loader = $loading.show({
      color: "#2581ef",
      opacity: 1,
    });

    Api()
        .get(`/projects/get-archived-projects/${userid}`)
        .then((response) => {
          //Code for handling the response
          //add attribute editTeamName so that you can toggle showDetails row
          if (response.data.status === "success") {
            response.data.projects.forEach(function (row) {
              row.editProjectName = false;
              row.isDeletingProject = false;
              row.isEditing = false;
              row.isViewing = false;
            });

            listOfProjects.value = response.data.projects;
          } else {
            listOfProjects.value = null;
          }
        })
        .catch((error) => {
          
        })
        .finally(() => {
          loader.hide();
          showGuide.value = true;
        });
  }

    watch(archived,(newVal)=>{
      if (!newVal){
        loadWorkflows();
        loadProjects();
        return;
      }
      closeClickProjectPrompt();
      loadArchivedProjects();
      loadWorkflows();
    });

    watch(my_project_tour_tutorial,(newVal)=>{

      if (newVal == 1){

        if(!archived.value){

          if(!currentComponent.value){

            if(listOfProjects.value != null && listOfProjects.value.length > 0 ){

                closeClickProjectPrompt();
                tours['myprojectsTour'].start();

            }else{

                errorMessage.value = ["Tour cannot start before you create a project. Click on Add Project to create one and try again"]

            }

          }else{

             setTimeout(()=>{
            
               swapComponent(null, 0, '', '')
            
             },500)

            
          }

        }else{

           setTimeout(()=>{

             archived.value =  false;
            
           },500)
          
        }
        
      }
     
    });
   let count = ref(0)
  const unwatch =  watch(task_management_tour_tutorial,(newVal,oldVal) => {

    
          if(newVal != null){

            if(currentComponent.value){

                

                setTimeout(()=>{
                  
                    swapComponent(null, 0, '', '')

                    setTimeout(()=>{

                       taskManagementModal.value = true;

                    },1000)
                  
                },300)

               

            }else{

                taskManagementModal.value = true;

            }
           
          }
                                
   });

    function taskManagementTutorial(){

      taskManagementModal.value = false; 

    }

    function showClickProjectPrompt(){

      //if(canShowTasksGuide.value == 0 || canShowTasksGuide.value == null){

       tours['clickProjectTour'].start();

      //}

    }
    function closeClickProjectPrompt(){

     tours['clickProjectTour'].stop();

    }
    return {
      showModal,
      show,
      closeModal,
      onSubmit,
      disableSave,
      disableSaveButton,
      inputWorkflow,
      listOfWorkflows,
      checked,
      inputProjectName,
      listOfProjects,
      formatDate,
      editProjectName,
      submitNewProjectName,
      deleteProject,
      currentComponent,
      swapComponent,
      current_project_id,
      prop_project_name,
      prop_workflow_id,
      successMessage,
      errorMessage,
      errorModalMessage,
      workflowErrorMessage,
      showDeleteModal,
      activeDelete,
      deleteActiveProject,
      saving,
      scrollParent,
      handleScroll,
      scrollUpParent,
      showGuide,
      guideStage,
      canShowGuide,
      archiveProject,
      isArchivingProject,
      isArchiving,
      archived,
      unArchiveProject,
      steps, myOptions, myCallbacks, my_project_tour_tutorial,
      taskManagementModal, taskManagementTutorial,
      steps_1, myOptions_1, showClickProjectPrompt, closeClickProjectPrompt
    };
  },
};
</script>

<style>
 .v-tour.myprojectsTourclass .v-step__buttons{
  display:  inline-block !important;
  padding-top: 0.5rem !important;;
  /* font-size: 0.75rem !important;
  line-height: 1rem !important; */
}

 .v-tour.myprojectsTourclass .v-step__arrow{
  border-color: #3b82f6 !important;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  /* border-bottom-color: transparent !important; */
}

.v-tour.clickProjectTourclass .v-step__arrow{
  border-color: #3b82f6 !important;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  /* border-bottom-color: transparent !important; */
}

.v-tour.clickProjectTourclass .v-step__buttons{
  display:  inline-block !important;
  padding-top: 0.5rem !important;;
  /* font-size: 0.75rem !important;
  line-height: 1rem !important; */
}

.v-tour.clickProjectTourclass .v-step{
  z-index: 10 !important;
 
}

</style>
