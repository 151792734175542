<template>
   <div class="py-16 bg-white overflow-hidden">
    <div class="px-4 sm:px-6 lg:px-8">
      <div class="text-lg max-w-prose mx-auto">
        <h1>
          <span class="block text-2xl text-center text-indigo-600 font-semibold tracking-wide uppercase">How to accept a Team Invite</span>
          <span class="mt-2 block text-xs text-center tracking-tight text-gray-900">A team invite is a request you receive to join a team on sliceloop. It is sent via email</span>
        </h1>
      </div>
      <div class="mt-6 w-1/2 prose-indigo prose-lg text-black mx-auto">

           <div class="text-base my-6">
               <p class="py-3"><span class="font-medium text-lg">1.</span> Click on <span class="italic font-bold">Teams</span> on the left navigation menu</p>
                <!-- <figure class="border rounded-lg border-1">
                    <img class="w-full rounded-lg" src="/images/4_create_a_workflow.png" alt="" width="1310" height="873" />
                </figure> -->
           </div>

           <div class="text-base my-6">
               <p class="py-3"><span class="font-medium text-lg">2.</span> Click on the <span class="italic font-medium"> Teams You Have Been Added To </span> Tab</p>
                <!-- <figure class="border rounded-lg border-1">
                    <img class="w-full rounded-lg" src="/images/4_create_a_workflow.png" alt="" width="1310" height="873" />
                </figure> -->
           </div>
           <div class="text-base my-6">
               <p class="py-3"><span class="font-medium text-lg">3.</span> Under that Tab, find the team name and click <span class="italic text-blue-400"> Accept to Join</span></p>
                <figure class="border border-1 rounded-lg">
                  <img class="w-full rounded-lg" src="/images/3_team_member_accepts_invite.png" alt="" width="1310" height="873" />
                  <!-- <figcaption>Sagittis scelerisque nulla cursus in enim consectetur quam.</figcaption> -->
                </figure>
           </div>
        <p>The team creator will be notified via email that you have accepted to join their team</p>
        <br/>
        <p>After you accept the invite, you can start viewing tasks you have been assigned. Find out how to <a href="/how-to-get-started-on-a-task"
          target="_blank" class="text-blue-500 underline">get started on a content task</a>

        </p>
      </div>
    </div>
   </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
