<template>
  <div>
    <div class="mb-5">
      <div class="col-sm-12">
        <button
          class="capitalize flex text-blue-500 hover:underline"
          v-if="currentComponent"
          @click="swapComponent(null, 0, '')"
        >
          <ArrowLeftIcon class="mr-2 h-6 w-6" />
          back
        </button>
      </div>
    </div>

    <div v-if="currentComponent" class="row text-center">
      <div class="col-sm-12">
        <h2 class="mt-2 text-lg font-medium text-gray-900 capitalize">
          {{ tempWorkFlowName }} Stages
        </h2>
      </div>
    </div>

    <component :is="currentComponent" :workflowId="tempWorkFlowId"></component>

    <div v-if="!currentComponent">
      <div class="px-4 sm:px-6 md:px-8 mb-10">
        <h1 class="text-2xl font-semibold text-gray-700">Your Workflows</h1>
      </div>
      <div class="max-w-lg mx-auto">
        <div>
          <div class="text-center">
            <ClipboardListIcon class="mx-auto h-12 w-12 text-gray-400" />
            <h2 class="mt-2 text-lg font-medium text-gray-900">
              Create A Workflow
            </h2>
            <p class="mt-1 text-sm text-gray-500">
              Create a step by step guide on how you would like your projects to
              be executed
            </p>
          </div>
          <form @submit.prevent="" action="#" class="my-6 text-center sm:flex">
            <label for="email" class="sr-only">Type WorkFlow Name</label>
            <input
              type="text"
              name="email"
              v-model="inputWorkFlowName"
              @focus="hideToolTip"
              id="email"
              class="
                shadow-sm
                focus:ring-blue-500 focus:border-blue-500
                block
                w-full
                py-2
                sm:py-0
                sm:text-sm
                border-gray-300
                rounded-md
                border
                pl-2
              "
              placeholder="Type WorkFlow Name"
            />
            <button
              type="button"
              :disabled="disableAddWorkFlowButton"
              @click="addWorkFlow"
              class="
                ml-4
                flex-shrink-0
                px-4
                py-2
                border border-transparent
                text-sm
                font-medium
                rounded-md
                shadow-sm
                mt-3
                sm:mt-0
                text-white
                bg-blue-600
                hover:bg-blue-700
                focus:outline-none
                focus:ring-2
                focus:ring-offset-2
                focus:ring-blue-500
              "
            >
              Add New Workflow
            </button>
          </form>
        </div>
      </div>

      <AlertSuccess
        v-if="successMessage"
        :message="successMessage"
        @hide="successMessage = null"
      />
      <AlertError
        v-if="errorMessage"
        :message="[errorMessage]"
        @hide="errorMessage = null"
      />

      <div class="mx-auto mt-20">
        <div class="grid md:grid-cols-2 lg:grid-cols-4 gap-5">
          <div
            v-for="workFlow in listOfWorkFlows"
            :key="workFlow.workFlowId"
            class="
              h-60
              bg-white
              border
              shadow
              rounded-xl
              flex flex-col
              justify-center
              items-center
            "
          >
            <div class="text-gray-500 text-left block w-full py-4 px-5">
              <div v-if="workFlow.editWorkFlowName" class="flex">
                <input
                  name="name"
                  :disabled="!workFlow.editWorkFlowName"
                  :value="`${workFlow.workFlowName}`"
                  v-on:keyup.enter="submitNewWorkFlowName($event, workFlow)"
                  class="w-full border px-3 py-1 text-gray-800 rounded-md"
                />
                <InlineLoader
                  v-if="saving"
                  :show="saving"
                  color="#ccc"
                  class="ml-4"
                />

                <XIcon
                  v-else
                  @click="workFlow.editWorkFlowName = false"
                  class="
                    ml-4
                    h-8
                    w-8
                    text-red-500
                    hover:text-red-600
                    cursor-pointer
                  "
                />
              </div>
              <div v-else class="font-bold text-gray-600 flex">
                <a
                  href="#"
                  class="hover:underline workflow-link"
                  @click="
                    swapComponent(
                      'MyWorkFlowStages',
                      workFlow.workFlowId,
                      workFlow.workFlowName
                    )
                  "
                >
                  {{ workFlow.workFlowName }}</a
                >

                <PencilIcon
                  @click.stop="editWorkFlowName(workFlow)"
                  class="
                    mx-2
                    h-4
                    w-4
                    text-gray-500
                    hover:text-blue-500
                    cursor-pointer
                  "
                />
              </div>
              <div class="text-sm">
                Created on {{ formatDate(workFlow.created_at) }}
              </div>
            </div>
            <div class="flex-1 w-full h-full cursor-pointer" 
               @click="swapComponent('MyWorkFlowStages',workFlow.workFlowId, workFlow.workFlowName)" >
            </div>
            <div class="w-full flex justify-end px-2 py-2">
              <TrashIcon
                class="
                  -ml-1
                  mr-2
                  h-6
                  w-6
                  text-gray-400
                  hover:text-red-500
                  cursor-pointer
                "
                @click="
                  showDeleteModal = true;
                  activeDelete = workFlow;
                "
              />
            </div>
          </div>
        </div>
        <DeleteAlert
          :show="showDeleteModal"
          item="Workflow"
          @delete="deleteCreatedWorkflow"
          @cancel="showDeleteModal = false"
        />
      </div>

      <AlertWithDescription
        v-if="!listOfWorkFlows"
        header="You do not have any workflows"
        body="Add a workflow that your content tasks will have to go through"
      />
    </div>
  </div>
  <v-tour  v-if="!canShowGuide" class="text-gray-500 bg-white" name="myTour" :steps="steps"></v-tour>
  <GuideModal  v-if="!canShowGuide" :is-open="showGuide"  @close="showGuide=false" @stages="createStage"/>
  <!-- END OF TABLE DIVS -->
</template>

<script>
import { ref,inject } from "vue";
import Api from "@/api/Api.js";
import { computed } from "vue";
import MyWorkFlowStages from "@/components/MyWorkFlowStages.vue";
import AlertSuccess from "./AlertSuccess";
import AlertError from "./AlertError";
import DeleteAlert from "./DeleteAlert";
import AlertWithDescription from "./AlertWithDescription";
import { PencilIcon } from "@heroicons/vue/solid";
import GuideModal from "./GuideModal";
import {
  XIcon,
  TrashIcon,
  ArrowLeftIcon,
  ClipboardListIcon,
} from "@heroicons/vue/outline";
import { useLoading } from "vue-loading-overlay";
import InlineLoader from "./InlineLoader";
export default {
  name: "MyWorkFlows",
  components: {
    MyWorkFlowStages,
    AlertSuccess,
    AlertError,
    DeleteAlert,
    PencilIcon,
    TrashIcon,
    XIcon,
    ArrowLeftIcon,
    AlertWithDescription,
    ClipboardListIcon,
    InlineLoader,
    GuideModal
  },


  setup() {
    const showDeleteModal = ref(false);
    const activeDelete = ref();
    const $loading = useLoading();
    let userFromStorage = JSON.parse(localStorage.getItem("user"));
    let userid = userFromStorage.id;
    const saving = ref(false);
    let currentComponent = ref(null);
    let inputWorkFlowName = ref("");
    const showGuide = ref(false);
    const canShowGuide = inject('canShowGuide');

    const disableAddWorkFlowButton = computed(() => {
      if (inputWorkFlowName.value.trim().length > 0) return false;

      return true;
    });
    let error = ref("");
    let alertAddingWorkFlowName = ref(false);
    let alertWorkflowText = ref("");
    let listOfWorkFlows = ref(null);
    let tempWorkFlowId = ref(0);
    let tempWorkFlowName = ref("");
    let successMessage = ref("");
    let errorMessage = ref("");
    const tours = inject('tours');
    const  steps = [
      {
        target: '#email',
        content: 'Add workflow name and submit',
        params: {
          placement: 'top'
        }
      },
    ]

    const deleteCreatedWorkflow = () => {
      deleteWorkFlow(activeDelete.value);
      showDeleteModal.value = false;
    };

    function swapComponent(component, varTempWorkflowId, varTempworkflowName) {
      successMessage.value = null;
      errorMessage.value = null;
      error.value = null;
      tempWorkFlowId.value = varTempWorkflowId;
      tempWorkFlowName.value = varTempworkflowName;
      currentComponent.value = component;
    }

    function loadListOfWorkFlows(showGuide = false) {
      const loader = $loading.show({
        color: "#2581ef",
        opacity: 1,
      });
      Api()
        .get(`/workflows/show-my-workflows/${userid}`)
        .then((response) => {
          if (
            Array.isArray(response.data.workflows) &&
            response.data.workflows.length != 0
          ) {
            response.data.workflows.forEach(function (row) {
              row.editWorkFlowName = false;
              row.isDeletingWorkFlow = false;
              row.isEditing = false;
              row.isViewing = false;
            });

            listOfWorkFlows.value = response.data.workflows;
          } else {
            listOfWorkFlows.value = null;
          }

          let message = response.data.message;
          
        })
        .catch((error) => {
          
        })
        .finally(() => {
          loader.hide();
          if (!canShowGuide.value && showGuide){
            tours['myTour'].start();
          }
        });
    }

    function addWorkFlow() {
      if (!canShowGuide.value){
        tours['myTour'].stop();
      }
      const loader = $loading.show({
        color: "#2581ef",
        opacity: 1,
      });

      alertWorkflowText.value = inputWorkFlowName.value;
      alertAddingWorkFlowName.value = true;

      let tempInputWorkFlowName = "";
      tempInputWorkFlowName = inputWorkFlowName.value;

      let workFlow = {
        workFlowName: tempInputWorkFlowName,
        createdBy: userid,
      };

      inputWorkFlowName.value = "";
      error.value = null;
      successMessage.value = null;
      errorMessage.value = null;

      Api()
        .post("/workflows/create-workflow", workFlow)
        .then((response) => {
          let message = response.data.message;
          let status = response.data.status;

          if (status === "error") {
            inputWorkFlowName.value = tempInputWorkFlowName;
            errorMessage.value = message;
            
          } else {
            successMessage.value = message;
            showGuide.value = true;
          }
        })
        .catch((error) => {
          
          inputWorkFlowName.value = tempInputWorkFlowName;
        })
        .finally(() => {
          loadListOfWorkFlows();

          alertAddingWorkFlowName.value = false;

          alertWorkflowText.value = "";
          loader.hide();
        });
    }

    function deleteWorkFlow(workFlowObj) {
      const loader = $loading.show({
        color: "#2581ef",
        opacity: 1,
      });
      let workflowName = workFlowObj.workFlowName;
      let workflowId = workFlowObj.workFlowId;
      successMessage.value = null;
      errorMessage.value = null;
      error.value = null;

      //disable delete button
      workFlowObj.isDeletingTeam = true;

      workFlowObj.isEditing = true;

      workFlowObj.isViewing = true;

      Api()
        .delete(`/workflows/delete-workflow/${workflowId}`)
        .then((response) => {
          let message = response.data.message;
          let status = response.data.status;

          if (status === "error") {
            errorMessage.value = message;
            
          } else {
            successMessage.value = message;
          }
        })
        .catch((error) => {
          errorMessage.value = "An Error Occurred!";
        })
        .finally(() => {
          //load tasks afterwards
          loadListOfWorkFlows();
          loader.hide();
        });
    }

    function submitNewWorkFlowName(e, workFlowObj) {
      if (e.target.value.length > 0) {
        let newWorkflowName = e.target.value;
        let UpdateWorkFlowId = workFlowObj.workFlowId;

        successMessage.value = null;
        errorMessage.value = null;
        error.value = null;

        e.target.disabled = true;
        saving.value = true;

        let workflow = {
          workflowName: newWorkflowName,
        };

        Api()
          .patch(
            `/workflows/update-workflow-name/${UpdateWorkFlowId}`,
            workflow
          )
          .then((response) => {
            let message = response.data.message;

            let status = response.data.status;

            if (status === "error") {
              errorMessage.value = message;
              
            } else {
              successMessage.value = "Workflow Name Edited Successfully";
              workFlowObj.editWorkFlowName = false;
              workFlowObj.workFlowName = newWorkflowName;
            }
          })
          .catch((error) => {
            errorMessage.value = "An Error has Occurred!";
            
          })
          .finally(() => {
            saving.value = false;
          });
      }
    }

    function editWorkFlowName(workFlowObj) {
      workFlowObj.editWorkFlowName = !workFlowObj.editWorkFlowName;

      workFlowObj.isDeletingWorkFlow = workFlowObj.editWorkFlowName;

      workFlowObj.isViewing = workFlowObj.editWorkFlowName;
    }

    function formatDate(date) {
      var d1 = new Date(date);
      var ms = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      return d1.getDate() + " " + ms[d1.getMonth()] + " " + d1.getFullYear();
    }

    const createStage = () => {
      showGuide.value = false;
      document.querySelectorAll('.workflow-link')[document.querySelectorAll('.workflow-link').length - 1].click();
    }

    const hideToolTip = () => {
      if (!canShowGuide.value){
        tours['myTour'].stop();
      }
    }


    loadListOfWorkFlows(true);



    return {
      currentComponent,
      tempWorkFlowId,
      inputWorkFlowName,
      disableAddWorkFlowButton,
      addWorkFlow,
      error,
      alertAddingWorkFlowName,
      alertWorkflowText,
      listOfWorkFlows,
      tempWorkFlowName,
      deleteWorkFlow,
      submitNewWorkFlowName,
      editWorkFlowName,
      swapComponent,
      formatDate,
      successMessage,
      errorMessage,
      deleteCreatedWorkflow,
      showDeleteModal,
      activeDelete,
      saving,
      steps,
      showGuide,
      createStage,
      hideToolTip,
      canShowGuide
    };
  },
};
</script>

<style></style>
