<template>
  <div class="max-w-lg mx-auto">
    <div>
      <form @submit.prevent action="#" class="my-6 text-center sm:flex">
        <label for="stageName" class="sr-only">stageName</label>
        <input
          type="text"
          name="stageName"
          v-model="stageName"
          @focus="hideToolTip"
          id="stageName"
          class="
            shadow-sm
            focus:ring-blue-500 focus:border-blue-500
            block
            w-full
            py-2
            sm:py-0
            sm:text-sm
            border-gray-300
            rounded-md
            border
            pl-2
          "
          placeholder="Enter stage name"
        />
        <button
          type="button"
          :disabled="disableAddStageButton"
          @click="addStageToWorkflow"
          class="
            ml-4
            flex-shrink-0
            px-4
            py-2
            border border-transparent
            text-sm
            font-medium
            rounded-md
            shadow-sm
            mt-3
            sm:mt-0
            text-white
            bg-blue-600
            hover:bg-blue-700
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-blue-500
          "
        >
          Add Stage
        </button>
      </form>
    </div>
  </div>

  <AlertSuccess
    v-if="successMessage"
    :message="successMessage"
    @hide="successMessage = null"
  />
  <AlertError
    v-if="errorMessage"
    :message="[errorMessage]"
    @hide="errorMessage = null"
  />

  <AlertWithDescription
    v-if="!listOfStages"
    header="Stages not found"
    body="You have not added any stages to this workflow"
  />

  <DeleteAlert
    :show="showDeleteModal"
    item="workflow stage"
    @delete="deleteCreatedWorkflowStage"
    @cancel="showDeleteModal = false"
  />

  <div class="max-w-2xl mx-auto mt-20">
    <div class="flow-root">

        <draggable  role="list" :value="listOfStages" class="dragArea list-group w-full -mb-8"  @end="updateStageOrder">
        <div v-for="(stage) in listOfStages" :key="stage.id">
          <div class="relative">
            <div class="relative flex space-x-3 cursor-move bg-gray-50 py-4 px-4 my-2 rounded">
              <div>
<!--                <span-->
<!--                  :class="[-->
<!--                    'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white border bg-white',-->
<!--                  ]"-->
<!--                >-->
<!--                  {{ ++index }}-->
<!--                </span>-->
              </div>
              <div class="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                <div>
                  <p
                    v-if="!stage.editStageName"
                    class="font-medium text-gray-900"
                  >
                    {{ stage.stageName }}
                  </p>
                  <input
                    v-else
                    :disabled="!stage.editStageName"
                    v-on:keyup.enter="submitNewStageName($event, stage)"
                    class="w-full border px-3 py-1 text-gray-800 rounded-md"
                    type="text"
                    :value="`${stage.stageName}`"
                  />
                </div>
                <div class="text-right text-sm whitespace-nowrap text-gray-500">
                  <button
                    type="button"
                    class="hover:underline text-blue-500 mr-10"
                    :disabled="stage.isEditing"
                    @click="editStageName(stage)"
                  >
                    {{ stage.editStageName ? "cancel" : "Edit" }}
                  </button>

                  <button
                    type="button"
                    class="text-red-500 hover:underline"
                    :disabled="stage.isDeleting"
                    @click="
                      showDeleteModal = true;
                      activeDelete = stage;
                    "
                  >
                    Remove
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        </draggable>
    </div>
  </div>
  <v-tour v-if="!canShowGuide"  name="myTour" :steps="steps"></v-tour>
  <GuideModal v-if="!canShowGuide" :is-open="showGuide"  @close="showGuide=false"/>

</template>

<script>
import { ref,inject } from "vue";
import Api from "@/api/Api.js";
import { computed } from "vue";
import AlertWithDescription from "./AlertWithDescription";
import AlertError from "./AlertError";
import AlertSuccess from "./AlertSuccess";
import DeleteAlert from "./DeleteAlert";
import { useLoading } from "vue-loading-overlay";
import GuideModal from "./GuideModal";
import { VueDraggableNext } from 'vue-draggable-next'

export default {
  name: "MyWorkFlowStages",
  components: { AlertSuccess, AlertError, AlertWithDescription, DeleteAlert,GuideModal,
    draggable: VueDraggableNext},
  props: {
    workflowId: {
      type: Number,
      required: false,
      default: false,
    },
  },

  setup(props, context) {
    const showDeleteModal = ref(false);
    const activeDelete = ref();
    const $loading = useLoading();
    let propWorkflowId = ref(props.workflowId).value;
    let listOfStages = ref(null);
    let message = ref("");
    let msg = ref("");
    let stageName = ref("");
    let successMessage = ref("");
    let errorMessage = ref("");
    const showGuide = ref(false)
    const tours = inject('tours');
    const canShowGuide = inject('canShowGuide');
    const workflowId = inject('workflowId')

    const  steps = [
      {
        target: '#stageName',
        content: 'Add Stage name and submit',
        params: {
          placement: 'top'
        }
      },
    ]
    const deleteCreatedWorkflowStage = () => {
      deleteStage(activeDelete.value);
      showDeleteModal.value = false;
    };

    const disableAddStageButton = computed(() => {
      if (stageName.value.trim().length > 0) return false;
      return true;
    });

    function loadStages(guide=false) {
      const loader = $loading.show({
        color: "#2581ef",
        opacity: 1,
      });

      Api()
        .get(`/workflows/show-all-stages-of-workflow/${propWorkflowId}`)
        .then((response) => {
          if (response.data.status === "success") {
            response.data.workflowStages.forEach(function (row) {
              row.editStageName = false;
              row.isDeleting = false;
              row.isEditing = false;
            });

            listOfStages.value = response.data.workflowStages;
            
          } else {
            listOfStages.value = null;
          }

          message = response.data.message;
          
        })
        .catch((error) => {
          
        })
        .finally(() => {
          loader.hide();
          if (!canShowGuide.value && guide){
            tours['myTour'].start();
          }
        });
    }

    function deleteStage(stageObj) {
      const loader = $loading.show({
        color: "#2581ef",
        opacity: 1,
      });
      msg.value = null;
      successMessage.value = null;
      errorMessage.value = null;
      let stageName = stageObj.stageName;
      let stageId = stageObj.id;

      //disable button
      stageObj.isDeleting = true;
      stageObj.isEditing = true;
      Api()
        .delete(`/workflows/delete-stage/${stageId}`)
        .then((response) => {
          let message = response.data.message;
          let status = response.data.status;

          if (status === "success") {
            successMessage.value = message;
          } else {
            // errorMessage.value = "An Error has Occurred! Please try again.";
            errorMessage.value = message;
          }
        })
        .catch((error) => {
          errorMessage.value = "An Error has Occurred!";
        })
        .finally(() => {
          //load load stages afterwards
          loadStages();
          loader.hide();
        });
    }

    function addStageToWorkflow() {
      if (!canShowGuide.value){
        tours['myTour'].stop();
      }
      const loader = $loading.show({
        color: "#2581ef",
        opacity: 1,
      });
      let newStageName = stageName.value.trim();
      stageName.value = "";
      msg.value = `Adding ${newStageName} ...`;
      let theWorkflowId = propWorkflowId;
      let stage = {
        stageName: newStageName,
        theWorkflowId: theWorkflowId,
      };
      successMessage.value = null;
      errorMessage.value = null;
      //
      Api()
        .post("/workflows/add-stage", stage)
        .then((response) => {
          let status = response.data.status;
          let message = response.data.message;

          if (status === "success") {
            successMessage.value = message;
            showGuide.value = true;
            workflowId.value = theWorkflowId;
          } else {
            errorMessage.value = message;
          }
        })
        .catch((error) => {
          errorMessage.value = "An Error has Occurred!";
        })
        .finally(() => {
          //reload stages
          loadStages();
          loader.hide();
        });
    }

    function editStageName(stageObj) {
      stageObj.editStageName = !stageObj.editStageName;

      stageObj.isDeleting = stageObj.editStageName;
    }

    function submitNewStageName(e, stageObj) {
      if (e.target.value.length > 0) {
        const loader = $loading.show({
          color: "#2581ef",
          opacity: 1,
        });

        successMessage.value = null;
        errorMessage.value = null;
        msg.value = null;
        let newStageName = e.target.value;
        let UpdateStageId = stageObj.id;

        e.target.disabled = true;
        stageObj.isEditing = true;

        stageObj.stageName = newStageName;

        let stage = {
          stageName: newStageName,
        };

        Api()
          .patch(`/workflows/update-stage-name/${UpdateStageId}`, stage)
          .then((response) => {
            let message = response.data.message;
            let status = response.data.status;

            if (status === "success") {
              successMessage.value = message;
            } else {
              errorMessage.value = message;
            }
          })
          .catch((error) => {
            errorMessage.value = "An Error has Occurred!";
          })
          .finally(() => {
            loadStages();
            loader.hide();
          });
      }
    }

    function moveUp(rowIndex) {
      
      // listOfStages.value.splice(
      //   rowIndex - 1,
      //   0,
      //   listOfStages.value.splice(rowIndex, 1)[0]
      // );
    }
    function moveDown(rowIndex) {
     
      // listOfStages.value.splice(
      //   rowIndex + 1,
      //   0,
      //   listOfStages.value.splice(rowIndex, 1)[0]
      // );
    }

    const hideToolTip = () => {
      if (!canShowGuide.value){
        tours['myTour'].stop();
      }
    }

   const list =  [
      { name: 'John', id: 1 },
      { name: 'Joao', id: 2 },
      { name: 'Jean', id: 3 },
      { name: 'Gerard', id: 4 },
    ];
    loadStages(true);

    const updateStageOrder = (event) => {
      if (event.newDraggableIndex === event.oldDraggableIndex ) return;
      const stage1= listOfStages.value[event.newDraggableIndex];
      const stage2= listOfStages.value[event.oldDraggableIndex];

      const loader = $loading.show({
        color: "#2581ef",
        opacity: 1,
      });
      Api()
          .patch(`/workflows/swap-stages/${stage1.id}/${stage2.id}`)
          .then((response) => {
            let message = response.data.message;
            let status = response.data.status;

            if (status === "success") {
              successMessage.value = message;
            } else {
              errorMessage.value = message;
            }
          })
          .catch((error) => {
            errorMessage.value = "An Error has Occurred!";
          })
          .finally(() => {
            loadStages();
            loader.hide();
          });

      
    }

    return {
      listOfStages,
      disableAddStageButton,
      deleteStage,
      stageName,
      addStageToWorkflow,
      msg,
      editStageName,
      submitNewStageName,
      moveUp,
      moveDown,
      successMessage,
      errorMessage,
      deleteCreatedWorkflowStage,
      showDeleteModal,
      activeDelete,
      steps,showGuide,
      hideToolTip,
      canShowGuide,
      list,
      updateStageOrder
    };
  },
};
</script>

<style></style>
