<template>
  <div>
    <div class="px-4 sm:px-6 md:px-8 mb-10">
      <button
          class="capitalize flex text-blue-500 hover:underline float-left -ml-4 mr-10 items-center"
          @click="$router.back()"
      >
        <ArrowLeftIcon class="mr-2 h-6 w-6" />
        back
      </button>
      <h1 class="text-2xl font-semibold text-gray-700">Your Report</h1>
    </div>
  </div>

  <div class="mt-12 flex justify-center">
    <div class="flex flex-col w-full mr-2">
      <h3 class="text-lg leading-6 font-medium text-gray-900  text-center">
        Teams Attached to <span class="text-blue-500">{{$route.params.project.replaceAll('-',' ')}}</span>
      </h3>
      <div class="my-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="shadow overflow-hidden border border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead>
              <tr>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Team Name
                </th>

                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">
                 Tasks In Progress
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">
                  Tasks Pending
                </th>

                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">
                  Task stage Completed
                </th>

                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">
                  Workflow Completed
                </th>


              </tr>
              </thead>
              <tbody class="bg-white">
              <tr v-for="team in yourTeams" :key="team.teamId">
                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  <router-link v-if="team.teamId" class="text-blue-500 hover:text-blue-600 hover:underline" :to="{ name: 'team-member-report', params: { id: team.
                  teamId,project:team.project_id,name:team.teamName }}">
                  {{ team.teamName }}
                  </router-link>
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500  text-center">
                  {{team.data.inProgress}}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500  text-center">
                  {{ team.data.pending }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500  text-center">
                  {{ team.data.stageCompleted }}
                </td>

                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500  text-center">
                  {{ team.data.workflowCompleted }}
                </td>

              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>


</template>

<script>
import Api from "../api/Api";
import {useRoute} from "vue-router";
import {inject,ref} from "vue";
import {ArrowLeftIcon} from "@heroicons/vue/outline";
import {useLoading} from "vue-loading-overlay";


export default {
  name: "ProjectReport",
  components:{ArrowLeftIcon,},
  setup() {
    const user  =  inject('user');
    const route = useRoute();
    const yourTeams= ref();
    const $loading = useLoading();
    const getReportTeamSummary = () => {
      const loader = $loading.show({
        color: "#2581ef",
        opacity: 1,
      });
      Api()
          .get(`/reports/show-teams-in-project-summary/${user.id}/${route.params.id}`)
          .then((response) => {
            if (response.data.status === "success") {
              yourTeams.value = response.data.report
            }
          })
          .catch((error) => {})
          .finally(() => {
            loader.hide();
          });
    }
    getReportTeamSummary();
    return {
      yourTeams
    }
  }
}
</script>

<style scoped>

</style>