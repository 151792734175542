<template>
  <node-view-wrapper class="vue-component" >
    <div ref="container">
     <span class="label" :contenteditable="canEdit">{{ node.attrs.title }}</span>
    <div class="flex justify-end">
        <button v-if="isTemplate"  @click="deleteElement" class="text-black hover:bg-black hover:text-white w-10 h-10 text-center flex justify-center items-center">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
            <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
          </svg>
        </button>
    </div>

    <node-view-content  :contenteditable="canEdit" class="content"/>
    </div>

  </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, NodeViewContent, nodeViewProps } from '@tiptap/vue-3'
import {ref,inject} from 'vue';
export default {
  components: {
    NodeViewWrapper,
    NodeViewContent,
  },
  props: nodeViewProps,

  setup(props){
    const container =  ref();
    const title = props.title;
    const isTemplate = inject('canDeleteTemplate');
    const canEdit = inject('canEditNodeTemplate');
    const deleteElement = () => {
  container.value.parentElement.remove();
    }

    const makeEditorEditable = () => {
       // props.editor.options.editable = true;
    }
    return {
      deleteElement,
      container,
      title,makeEditorEditable,isTemplate,
      canEdit
    }


  }
}
</script>

<style>
.vue-component {
  background: rgba(37, 129, 239, 0.65);
  border: 3px solid #0D0D0D;
  border-radius: 0.5rem;
  margin: 1rem 0;
  position: relative;
}
.label {
  margin-left: 1rem;
  background-color: #0D0D0D;
  font-size: 0.6rem;
  letter-spacing: 1px;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  position: absolute;
  top: 0;
  padding: 0.25rem 0.75rem;
  border-radius: 0 0 0.5rem 0.5rem;
}
.content {
  margin: 1rem;
  padding: 0.5rem;
  background-color: #f1f1f1;
  border-radius: 0.5rem;
}
</style>